import Constants from "./constants";
import { Check, Error, Warning } from '@mui/icons-material';
import React from "react";

export enum Status {
    Warning = 'warning',
    Error = 'error',
    Primary = 'primary'
}

export type ValidationResult = {
    helperText: string;
    adornment: React.ReactNode;
    colorHelper: Status;
    isError: boolean;
    isWarning: boolean;
}

type Options = {
    maxLength?: number
    minLength?: number
    regex?: RegExp
}

export const initialValidationResult = {
    helperText: '', 
    adornment: React.createElement(Check, {color: Constants.PRIMARY}), 
    colorHelper: Status.Primary , 
    isError: false, 
    isWarning: false}

export default function useValidateFields() {

    const validateName = (value: string, options?: Options): ValidationResult => {
        const response: ValidationResult = Object.create(initialValidationResult)
        const regex = options?.regex || /^[a-zA-Z]*$/;

        if(validateEmpty(value)) {
            response.isError = true
            response.helperText = Constants.EMPTY_FIELD
            response.adornment = React.createElement(Error, {color: Constants.ERROR})
            response.colorHelper = Status.Error
        }

        if(!regex.test(value)) {
            response.isError = true
            response.helperText = Constants.ONLY_TEXT
            response.adornment = React.createElement(Error, {color: Constants.ERROR})
            response.colorHelper = Status.Error
        }

        if(validateMoreThanOrEqual(value, options?.maxLength || Constants.DEFAULT_MAX_LENGTH)) {
            response.isError = false
            response.isWarning = true
            response.helperText = Constants.MAX_LENGTH + ' ' + (options?.minLength?.toString() || Constants.DEFAULT_MAX_LENGTH) + ' ' + Constants.CHARACTERS
            response.adornment = React.createElement(Warning, {color: Constants.WARNING})
            response.colorHelper = Status.Warning
        }

        if(validateLessThanOrEqual(value, options?.minLength || Constants.DEFAULT_MIN_LENGTH)) {
            response.isError = true
            response.helperText = Constants.NAME_NOT_LONG_ENOUGH + ' ' + (options?.minLength?.toString() || Constants.DEFAULT_MIN_LENGTH)+ ' ' + Constants.CHARACTERS
            response.adornment = React.createElement(Error, {color: Constants.ERROR})
            response.colorHelper = Status.Error
        }
        
        if(response.isError || response.isWarning) {
            return response
        }
        
        return initialValidationResult

    }

    const validatePhone = (value: string, options?: Options): ValidationResult => {
        const response: ValidationResult = initialValidationResult

        if(validateEmpty(value)){
            response.isError = true
            response.helperText = Constants.EMPTY_FIELD
            response.colorHelper = Status.Error
            response.adornment = Error
        }

        return response
    }

    const validateZip = (value: string): ValidationResult => {
        const response: ValidationResult = initialValidationResult

        return response
    }
    
    const validateEmail = (value: string): ValidationResult => {
        const response: ValidationResult = initialValidationResult

        return response
    }

    const validateEmpty = (value: string): boolean => {
        if(value.trim() === '' || value === null) {
            return true
        }
        return false
    }

    const validateMoreThanOrEqual = (value: string, limit: number): boolean => (value.length >= limit ? true : false)
    const validateMoreThan = (value: string, limit: number): boolean => (value.length > limit ? true : false)
    const validateLessThanOrEqual = (value: string, limit: number): boolean => (value.length <= limit ? true : false)
    const validateLessThan = (value: string, limit: number): boolean => (value.length < limit ? true : false)
    const validateEqual = (value: string | number, aValue: string | number): boolean => (value === aValue ? true : false)
    const validateDifferent = (value: string | number, aValue: string | number): boolean => (value !== aValue ? true : false)

    return { validateEmail, validatePhone, validateZip, validateName }
}