import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import CreditariaLogo from '@assets/images/creditaria_logo.svg';
import InterLogo from '@assets/images/logo-primary.png';
import { useClamp } from '@utils/useClamp';

export const InsuranceLogos: React.FC = () => {
    const {clamp} = useClamp();

    const insurances = [
      CreditariaLogo,
      InterLogo,
    ];

  return (
    <Container
      style={{ padding: 'initial' }}
      maxWidth={clamp('lg', 'md', 'sm')}
      sx={{
        marginBottom: '2rem',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        maxWidth={clamp('1180px', '688px', '688px')}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          component="h2"
          sx={{
            maxWidth: clamp('1180px', '1180px', '340px'),
            fontWeight: 700,
            fontSize: clamp('28px', '24px'),
            lineHeight: clamp('34px', '27px', '25px'),
            textAlign: 'center',
          }}
        >
          Este es un servicio respaldado por...
        </Typography>
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: clamp('space-between', 'space-around', 'space-around'),
            alignItems: 'center',
            textAlign: 'center',
            padding: '24px 0 40px 0'
          }}
        >
          {insurances.map((insurance, index) => (
            <Grid
              key={index}
              xs={5}
              sm={5}
              md={4}
            >
              <Box
                component={'img'}
                loading='lazy'
                src={insurance}
                height={clamp('unset', 'unset', '33px')}
                width={clamp('unset', 'unset', '150px')}
              ></Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};
