import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Benefits } from '@screens/home/benefits/Benefits';
import { BenefitHero } from '@screens/home/benefits/BenefitHero';
import { PartnersList } from '@screens/home/partners/PartnersList';
import { PartnerBanner } from '@screens/home/partners/PartnerBanner';
import { BenefitsAdditional } from '@screens/home/benefits/BenefitsAdditional';
import backgroundCircle from '@assets/images/circles.png';
import { useChangePrice } from '@hooks/useChangePrice';
import {useVisibleButtonHelp} from "@hooks/useVisibleButtonHelp";

const useStyles = makeStyles({
  root: {
    paddingBottom: '40px',
    backgroundImage: `url(${backgroundCircle})`,
    backgroundSize: '20%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundPositionY: '35%',
  },
});

export const HomeAlt: React.FC = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useChangePrice();
    useVisibleButtonHelp();


    return (
    <div className={classes.root}>
      <PartnersList />
      <Benefits />
      <PartnerBanner />
      <BenefitHero />
      <BenefitsAdditional />
    </div>
  );
};
