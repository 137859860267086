import React, { useEffect } from 'react';
import { Container, Box, useTheme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TagManager from 'react-gtm-module';

import { Header } from '@options/Header';
import { Form } from '@options/Form';
import { Brand } from '@options/Brand';
import circleBack1 from '@assets/images/Circlescut.png';
import circleBack3 from '@assets/images/Circles1.png';
import circleBack2 from '@assets/images/Circles2.png';
import { useTokenStore } from '@store/TokenStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useCarStore } from '@store/carInfoStore';
import { useHistory } from 'react-router-dom';
import {useVisibleButtonHelp} from "@hooks/useVisibleButtonHelp";

const useStyles = makeStyles({
  root: {
    background: '#f5f5f5',
    paddingBottom: '90px',
  },
});

export const Options: React.FC = () => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const { token } = useTokenStore();
  const classes = useStyles();
  const history = useHistory();

  //DataLayer
  const { driver } = useDriverStore();
  const { car } = useCarStore();

  if (driver.email === '' || car.assembler === '') {
    history.push('/');
  }

  const DataLayer = {
    event: 'CSform_step_2_view',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_2_view',
    eventLabel: undefined,
    telefono: driver.telefono,
    correo: driver.email,
    noCotizacion: 'noCotizacion',
    modelo: car.description,
    marca: car.assembler,
    anio: car.model,
    paquete: undefined,
    forma_pago: undefined,
    aseguradora: undefined,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    colaborador: undefined,
  };

  const tagManagerArgs = {
    dataLayer: DataLayer,
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    TagManager.dataLayer(tagManagerArgs);
  }, []);

    useVisibleButtonHelp();


    return (
    <div className={classes.root}>
      {/* <Box
        component={'img'}
        src={circleBack2}
        left={0}
        sx={{
          width: '38%',
        }}
        position={'absolute'}
        top={0}
        alt={'Circle Background'}
      />
      <Box
        component={'img'}
        src={circleBack3}
        right={200}
        sx={{
          width: '40%',
        }}
        position={'absolute'}
        top={130}
        alt={'Circle Background'}
      />
      <Box
        component={'img'}
        src={circleBack1}
        left={110}
        sx={{
          width: '35%',
        }}
        position={'absolute'}
        zIndex={0}
        top={600}
        alt={'Circle Background'}
      /> */}
      <Container maxWidth={'lg'}>
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            marginTop: isTablet ? 140 : 100,
            minHeight: '70vh',
            position: 'relative',
          }}
          textAlign="center"
        >
          <Header isMobile={isMobile} />
          <Brand isMobile={isMobile} isTablet={isTablet} />
          <Form isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop} />

        </Box>
      </Container>
    </div>
  );
};
