import {Alert, AlertTitle, Box, Collapse} from "@mui/material";
import IconWarningFraud from "../../assets/antiFraudeImage/IconWarningFraud.svg";
import React from "react";

interface AlertAntiFraudCloseI {
    customStyles: any
}

const AlertAntiFraudWarning= ({customStyles}: AlertAntiFraudCloseI) => {
    return (

        <Box sx={customStyles}>
            <Alert
                sx={{color: 'black', backgroundColor:"#FFE7C3",justifyContent:'center',alignItems:'center',height:130}}
                icon={
                    <Box
                        component={'img'}
                        src={IconWarningFraud}
                        width={'auto'}
                        height={'auto'}
                    />
                }
                severity="warning"
            >
                <AlertTitle sx={{fontWeight: 600, color: 'black'}}>
                    Recuerda que en inter.mx nunca te pediremos pagos a cuentas de terceros ni transferencias.
                </AlertTitle>
            </Alert>
        </Box>


    )
}
export default AlertAntiFraudWarning
