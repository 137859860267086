import React, {useEffect} from 'react';
import {
    Box,
    Collapse,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';

import WarningIcon from '../../assets/images/WarningOutline.svg';
import SuccessIcon from '../../assets/images/WelcomeCheckGreen2.svg';
import CloseSuccessIcon from '../../assets/images/closeSuccessToast.svg';
import CloseIcon from '../../assets/images/close.svg';

type AlertProps = {
    Text: string;
    showToast: boolean;
    setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
    isWarning?: boolean;
};

export const ToastDynamic: React.FC<AlertProps> = ({
                                                       Text,
                                                       showToast,
                                                       setShowToast,
                                                       isWarning = false,
                                                   }) => {
    const {breakpoints} = useTheme();

    const isTablet = useMediaQuery(breakpoints.down('md'));
    const isMobile = useMediaQuery(breakpoints.down('sm'));

    useEffect(() => {
        const timeOut = setTimeout(() => {
            setShowToast(false);
        }, 15000);

        return () => {
            clearTimeout(timeOut);
        };
    }, [showToast]);

    return (
        <>
            <Collapse in={showToast}>
                {isWarning ? (
                    <Box
                        sx={{
                            backgroundColor: '#FFF3BB',
                            height: '73px',
                            width: '100%',
                            position: 'fixed',
                            zIndex: window.location.pathname === '/plans' ? 3 : 999999,
                            display: 'flex',
                            alignItems: 'center',
                            top: isMobile || isTablet ? 100 : 64,
                            left: 0,
                        }}
                    >
                        <Box
                            sx={{
                                width: '72px',
                                height: '100%',
                                backgroundColor: '#f4e088',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                component="img"
                                sx={{
                                    width: '24px',
                                    height: '24px',
                                }}
                                alt="Warning toast"
                                src={WarningIcon}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    marginLeft: isMobile ? '10px' : '24px',
                                    maxWidth: '1090px',
                                    minWidth: '200px',
                                    fontSize: isMobile ? '9px' : isTablet ? '10px' : null,
                                    paddingTop: isMobile ? '5px' : null,
                                    paddingBottom: isMobile ? '5px' : null,
                                }}
                            >
                                {Text}
                            </Typography>
                            <Box
                                onClick={() => setShowToast(false)}
                                component="img"
                                sx={{
                                    width: '16px',
                                    height: '16px',
                                    marginRight: isMobile ? '20px' : '25px',
                                    marginLeft: isMobile ? '20px' : null,
                                    cursor: 'pointer',
                                }}
                                alt="Warning toast"
                                src={CloseIcon}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            backgroundColor: '#C3E2BE',
                            height: '73px',
                            width: '100%',
                            position: 'fixed',
                            zIndex: 999999,
                            display: 'flex',
                            alignItems: 'center',
                            top: isMobile || isTablet ? 100 : 64,
                            left: 0,
                        }}
                    >
                        <Box
                            sx={{
                                width: '72px',
                                height: '100%',
                                backgroundColor: '#5AB52F',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                component="img"
                                sx={{
                                    width: '24px',
                                    height: '24px',
                                }}
                                alt="success toast"
                                src={SuccessIcon}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    marginLeft: isMobile ? '10px' : '24px',
                                    maxWidth: '1090px',
                                    minWidth: '200px',
                                    fontSize: isMobile ? '9px' : isTablet ? '10px' : null,
                                    paddingTop: isMobile ? '5px' : null,
                                    paddingBottom: isMobile ? '5px' : null,
                                }}
                            >
                                {Text}
                            </Typography>
                            <Box
                                onClick={() => setShowToast(false)}
                                component="img"
                                sx={{
                                    width: '16px',
                                    height: '16px',
                                    marginRight: isMobile ? '20px' : '25px',
                                    marginLeft: isMobile ? '20px' : null,
                                    cursor: 'pointer',
                                }}
                                alt="Error toast"
                                src={CloseSuccessIcon}
                            />
                        </Box>
                    </Box>
                )}
            </Collapse>
        </>
    );
};
