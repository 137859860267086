import { Box, Grid, Typography, useTheme, useMediaQuery } from '@mui/material';

import ClipboardIcon from '@assets/images/clipboard.svg';
import CarRepairIcon from '@assets/images/car-repair.svg';
import HandIcon from '@assets/images/hand.svg';

const moments = [
  { img: CarRepairIcon, text: 'Elije el plan que necesites', description: 'De acuerdo a tus necesidades, puedes seleccionar el mejor plan para tí.'},
  { img: HandIcon, text: 'Modalidades de pago', description: 'Puedes contratar tu seguro en pagos mensuales, trimestrales, semestrales o de contado.'},
  { img: ClipboardIcon, text: 'Estamos contigo en cada momento', description: '¿Necesitas ayuda con algún trámite con la aseguradora o tienes  dudas? Te ayudamos  para resolver lo que necesites.'}
];

export const EnsuranceMoments = () => {
  const { breakpoints } = useTheme();

  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <Box
      sx={{ backgroundColor: '', paddingBottom: '40px' }}
      zIndex={9999}
    >
      <Typography
        component="h2"
        sx={{
          paddingBottom: '40px',
          textAlign: 'center',
          fontSize: isMobile ? '20px' : isTablet ? '22px' : '28px',
          fontWeight: '900',
          width: isMobile ? '350px' : 'inherit',
          margin: isMobile ? 'auto' : 'inherit',
        }}
      >
        ¿Por qué contratar mi seguro con Creditaria?
      </Typography>
      <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid
          item
          container
          sx={{
            width: 1050,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {moments.map((item, indx) => (
            <Grid
              key={item.img}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 350,
              }}
              item
            >
              <Box
                component={'img'}
                loading='lazy'
                src={item.img}
                sx={{
                  width: 70,
                  height: 70,
                }}
                alt={item.text}
                marginBottom={'20px'}
              />
              <Typography
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
              >
                {item.text}
              </Typography>
              <Typography>
                {item.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};
