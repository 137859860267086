import { Location } from 'history';
import { Box, Paper, Typography } from '@mui/material';
import { List, AutoSizer } from 'react-virtualized';

import { CarListData } from '@models/store/carListStore';
import { useQuoteStore } from '@store/QuoteStore';
import TagManager from 'react-gtm-module';

interface Props {
  data: CarListData[];
  location: Location;
  handleSubmit: (car: CarListData) => void;
}

const CarList = ({ data, location, handleSubmit }: Props) => {
  const { setQuoteList } = useQuoteStore();

  return (
    <Box sx={{ width: '100%', height: '280px' }}>
      <AutoSizer>
        {({ width, height }) => (
          // @ts-ignore
          <List
            width={width}
            height={height}
            rowHeight={90}
            rowCount={data.length}
            rowRenderer={({ key, index, style }) => {
              const car = data[index];

              return (
                <Box key={key} paddingBottom={2} style={style}>
                  <Paper
                    elevation={0}
                    onClick={
                      location.pathname === '/plans'
                        ? () => {
                            TagManager.dataLayer({ dataLayer: {
                              event: 'CS_interactions',
                              eventCategory:'Formulario.Seguro.Auto.v2',
                              eventAction: 'Interacciones',
                              eventLabel: 'update_datosVehiculo_submit'//Texto del botón al que se hizo clic.
                            }});
                            handleSubmit(car);
                            setQuoteList([]);
                          }
                        : () => {
                            TagManager.dataLayer({ dataLayer: {
                              event: 'CS_interactions',
                              eventCategory:'Formulario.Seguro.Auto.v2',
                              eventAction: 'Interacciones',
                              eventLabel: 'update_datosVehiculo_submit'//Texto del botón al que se hizo clic.
                            }});
                            handleSubmit(car);
                            setQuoteList([]);
                          }
                    }
                    style={{
                      borderRadius: 8,
                      cursor: 'pointer',
                    }}
                  >
                    <Box padding={2}>
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              xs: 16,
                              lg: 18,
                              xl: 18,
                            },
                          }}
                          fontWeight={'600'}
                        >
                          {`${car.assembler}`}
                        </Typography>
                        <Typography
                          fontWeight={'400'}
                          sx={{
                            fontSize: {
                              xs: 12,
                              lg: 14,
                              xl: 14,
                            },
                          }}
                        >
                          {`${
                            car.description.length > 42
                              ? `${car.description.substring(0, 42)}...`
                              : car.description
                          }`}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              );
            }}
          />
        )}
      </AutoSizer>
    </Box>
  );
};

export default CarList;
