import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { DataPrice } from './DataPrice';

interface FeaturesProps {
  logo: string;
  title: string;
  descripcion: string;
  titleTicket: string;
  numberTicket: string;
  feature: any;
}

export const Features: React.FC<FeaturesProps> = ({
  logo,
  title,
  descripcion,
  titleTicket,
  numberTicket,
  feature,
}) => {
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <Box
      sx={{
        width: '100%',
        height: isMobile ? '144px' : '104px',
        backgroundColor: '#fcfcfc',
        marginTop: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <DataPrice
        logo={logo}
        title={title}
        desc={descripcion}
        titleTicket={titleTicket}
        numberTicket={numberTicket}
        feature={feature}
      />
    </Box>
  );
};
