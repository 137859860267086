import React, { useEffect } from 'react';
import { Box, Theme, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Header } from '@navigationMenu/Header';
import { FailWelcome } from '@screens/welcomeToInter/FailWelcome';
import { useChangePrice } from '@hooks/useChangePrice';
import {useVisibleButtonHelp} from "@hooks/useVisibleButtonHelp";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#f5f5f5',
    paddingBottom: '40px',
  },
}));

export const WelcomeToInterPaymentFail: React.FC = () => {
  const classes = useStyles();
  useEffect(() => {
    window.scroll(0,0);
  }, []);
  useChangePrice();
    useVisibleButtonHelp();


    return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            marginTop: 80,
            minHeight: '70vh',
            position: 'relative',
          }}
        >
          <Header title="Método de pago" subtitle="" />
          <FailWelcome />
        </Box>
      </Container>
    </div>
  );
};
