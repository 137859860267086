import create from 'zustand';
import { persist } from 'zustand/middleware';

export type VehiculoType = 'coche' | 'moto' | 'camioneta';

interface Vehiculo {
  selectedVehiculo: VehiculoType;
  setSelectedVehiculo: (vehiculo: VehiculoType) => void;
}

export const useVehiculoStore = create<Vehiculo>(
  persist(
    (set) => ({
      selectedVehiculo: 'moto' as VehiculoType,
      setSelectedVehiculo: (vehiculo) => {
        set({ selectedVehiculo: vehiculo });
      },
    }),
    {
      name: 'vehiculo-storage',
      getStorage: () => sessionStorage,
    }
  )
);
