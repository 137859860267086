import { InterInstance } from '@api/config';

export type Worker = {
    apellidomaternoempleado: string,
    apellidopaternoempleado: string,
    createdDate: number,
    doc_name: string,
    franquicia: string,
    nombreempleado: string,
    numeroempleado: string,
    updatedDate: number
}

export const getWorker = async (
    token: string,
    id: string
): Promise<Worker> => {
    try {
        const {data} = await InterInstance.get(
            `/catalogs/creditaria-workers/${id}`,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        return data
    } catch (error) {
        console.log(error)
        return new Promise((resolve) => resolve({
            apellidomaternoempleado: '',
            apellidopaternoempleado: '',
            createdDate: 0,
            doc_name: '',
            franquicia: '',
            nombreempleado: '',
            numeroempleado: '',
            updatedDate: 0
        }))
    }
}

export const getWorkers = async (): Promise<Worker[]> => {
    try {
        const { data } = await InterInstance.get(
            '/catalogs/creditaria-workers'
        )

        if(data.status === 404) throw new Error("Not Found")

        return data.data
    } catch (error) {
        return new Promise((resolve) => resolve([{
            apellidomaternoempleado: '',
            apellidopaternoempleado: '',
            createdDate: 0,
            doc_name: '',
            franquicia: '',
            nombreempleado: '',
            numeroempleado: '',
            updatedDate: 0
        }]))
    }
}
