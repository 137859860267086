import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Fab, useMediaQuery, useTheme } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useInactiveStore } from '@store/inactiveStore';
import { useIdleTimer } from 'react-idle-timer';
import TagManager from 'react-gtm-module';
import TooltipSticky from '@buttons/TooltipSticky';
import { useButtonNeedHelpVisibleStore } from '@store/buttonNeedHelpVisible';

interface InterfaceDraggableFab {
  title: any;
  success: boolean;
  loading?: boolean;
  action: () => void;
}

const StickyButton = ({
  title,
  success,
  action,
  loading = false,
}: InterfaceDraggableFab) => {
  let timer: any = null;
  const { inactive, setInactive } = useInactiveStore();
  const { visibleButton, setVisibleButton } = useButtonNeedHelpVisibleStore();

  const [hiddenComponent, setHiddenComponent] = useState(false);
  const [start_, setStart_] = useState(true);
  const [hidden, setHidden] = useState(false);
  const [value, setValue] = useState(0);
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const mq_xs = useMediaQuery(breakpoints.only('xs'));
  const mq_sm = useMediaQuery(breakpoints.only('sm'));
  const mq_md = useMediaQuery(breakpoints.only('md'));
  const mq_lg = useMediaQuery(breakpoints.only('lg'));
  const mq_xl = useMediaQuery(breakpoints.only('xl'));
  const resizeH = window?.screen.height;
  const resizeW = window?.screen.width;

  const policyNumber = JSON.parse(
    sessionStorage.getItem('policy-storage') as string
  );

  const getBreakPointName = () => {
    let top_ = 188;
    if (mq_xs) {
      let rest = resizeW / 1.5;
      setValue(resizeH - (rest + top_));
      return 'xs';
    }
    if (mq_sm) {
      let rest = resizeW > 600 ? resizeW / 2.7 : resizeW / 1.5;
      setValue(resizeH - (rest + top_));
      return 'sm';
    }
    if (mq_md) {
      let rest = resizeW > 700 ? resizeW / 2.7 : resizeW / 1.5;
      setValue(resizeH - (rest + top_));
      return 'md';
    }
    if (mq_lg) {
      return 'lg';
    }
    if (mq_xl) {
      return 'xl';
    }
  };
  const gets = () => {
    getBreakPointName();
  };

  useEffect(() => {
    gets();
  }, [mq_xs, mq_sm, mq_md, mq_lg, mq_xl]);

  const handleOpen = () => {
    setHidden(!hidden);
    if (!hidden) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'coche_seguro_boton_ayuda',
          no_cotizacion: policyNumber.state.policy.policyNumber,
          url: window.location.href,
        },
      });
    }
  };

  const xAction = () => {
    const phoneNumber = '5589790960'; // número de teléfono al que se realizará la llamada
    window.location.href = `tel:${phoneNumber}`;
  };

  function isActivated(hoursActive: any) {
    const dates = hoursActive.map((dateString: string) => {
      const [hour, minute] = dateString.split(':');
      let date = new Date();
      date.setHours(Number(hour), Number(minute), 0, 0);
      return date;
    });

    let isActive = false;
    const now = new Date();

    for (let i = 0; i < dates.length; i = i + 2) {
      isActive =
        isActive ||
        (now.valueOf() >= dates[i].valueOf() &&
          now.valueOf() <= dates[i + 1].valueOf());
    }
    return isActive;
  }

  const onIdle = () => {
    setInactive(true);
    setHidden(true);
    TagManager.dataLayer({
      dataLayer: {
        event: 'coche_seguro_boton_ayuda_inactividad',
        no_cotizacion: policyNumber.state.policy.policyNumber,
        url: window.location.href,
      },
    });
  };

  const { pause, start } = useIdleTimer({
    onIdle,
    timeout: 240000,
    throttle: 500,
  });

  const onStart = () => {
    start();
  };
  useEffect(() => {
    if (start_) {
      onStart();
      setStart_(false);
    }
  }, [start_]);

  useEffect(() => {
    if (inactive) {
      pause();
    }
    return () => {
      setInactive(false);
    };
  }, [inactive]);

  /* useEffect(() => {
           const interval = setInterval(() => {
               ///console.log('timer===>>>>', Math.ceil(getRemainingTime() / 1000));
           }, 500);

           return () => {
               clearInterval(interval);
           };
       });*/
  const weekdayAction = () => {
    timer = setInterval(function () {
      checkButton();
    }, 2000); //1000 milliseconds * 60 seconds * 60 minutes

    function checkButton() {
      let hoursActive = ['08:00', '20:50'];
      if (isActivated(hoursActive)) {
        setHiddenComponent(false);
        setVisibleButton(false);
        if (inactive) {
          pause();
        } else {
          if (start_) {
            setStart_(false);
          }
        }
      } else {
        pause();
        setHiddenComponent(false);
        setVisibleButton(true);
      }
    }
  };

  const weekendAction = () => {
    timer = setInterval(function () {
      checkButton();
    }, 2000); //1000 milliseconds * 60 seconds * 60 minutes

    function checkButton() {
      let hoursActive = ['08:00', '14:50'];
      if (isActivated(hoursActive)) {
        setHiddenComponent(true);
        setVisibleButton(false);
        if (inactive) {
          pause();
        } else {
          if (start_) {
            setStart_(false);
          }
        }
      } else {
        pause();
        setHiddenComponent(false);
        setVisibleButton(true);
      }
    }
  };

  const getDay_ = () => {
    //get current date
    let date = new Date();
    //get current day
    let day = date.getDay();
    let ans = day === 6 || day === 0;
    if (ans) {
      //weekend
      weekendAction();
    } else {
      weekdayAction();
      //weekday
    }
  };

  useEffect(() => {
    getDay_();
    return () => clearInterval(timer);
  }, []);

  return (
    <div style={{ display: hiddenComponent ? 'block' : 'none' }}>
      {isMobile ? (
        <motion.div
          drag={'y'}
          dragConstraints={{ left: 0, right: 0, top: -value, bottom: 0 }}
          style={styles.containerFab}
        >
          <Fab
            onClick={() => {
              handleOpen();
            }}
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              backgroundColor: '#FFD60E',

              bgcolor: '#FFD60E',
              '&:hover': {
                bgcolor: '#FFD60E',
              },
              transition: '1s all',
            }}
          >
            {hidden ? (
              <CancelOutlinedIcon fontSize={'large'} />
            ) : (
              <HelpOutlineIcon fontSize={'large'} />
            )}
          </Fab>

          {hidden && (
            <TooltipSticky
              title={title}
              isMobile={isMobile}
              success={false}
              action={xAction}
              closeAction={handleOpen}
            />
          )}
        </motion.div>
      ) : isTablet ? (
        <motion.div
          drag={'y'}
          dragConstraints={{ left: 0, right: 0, top: -value, bottom: 0 }}
          style={styles.containerFab}
        >
          <Fab
            onClick={() => {
              handleOpen();
            }}
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              backgroundColor: '#FFD60E',

              bgcolor: '#FFD60E',
              '&:hover': {
                bgcolor: '#FFD60E',
              },
              transition: '1s all',
            }}
          >
            {hidden ? (
              <CancelOutlinedIcon fontSize={'large'} />
            ) : (
              <HelpOutlineIcon fontSize={'large'} />
            )}
          </Fab>

          {hidden && (
            <TooltipSticky
              title={title}
              loading={loading}
              success={success}
              action={success ? handleOpen : action}
              closeAction={handleOpen}
            />
          )}
        </motion.div>
      ) : (
        <>
          <Fab
            onClick={() => {
              handleOpen();
            }}
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              backgroundColor: '#FFD60E',

              bgcolor: '#FFD60E',
              '&:hover': {
                bgcolor: '#FFD60E',
              },
              transition: '1s all',
            }}
          >
            {hidden ? (
              <CancelOutlinedIcon fontSize={'large'} />
            ) : (
              <HelpOutlineIcon fontSize={'large'} />
            )}
          </Fab>
          {hidden && (
            <TooltipSticky
              title={title}
              success={success}
              loading={loading}
              action={success ? handleOpen : action}
              closeAction={handleOpen}
            />
          )}
        </>
      )}
    </div>
  );
};

const styles = {
  containerFab: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    transition: ' .2s all',
    width: 'auto',
    color: '#fff',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    flexDirection: 'row',
    zIndex: 10000,
    display: 'inline-flex',
    padding: '4px 5px 14px 2px',
  } as React.CSSProperties,
};

export default StickyButton;
