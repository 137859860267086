import React from 'react';
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Container,
  Theme,
} from '@mui/material';
import { useHistory } from 'react-router';
import { makeStyles } from '@mui/styles';

import { CustomButton } from '@buttons/CustomButton';
import driver from '@assets/images/driver.png';
import { ZustandContext } from '@store/modalStore';
import {useVisibleButtonHelp} from "@hooks/useVisibleButtonHelp";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#f5f5f5',
    paddingBottom: '40px',
  },
}));

export const WelcomeToInterFailInterLast: React.FC = () => {
  const { breakpoints, palette } = useTheme();
  const { show, hide } = React.useContext(ZustandContext);
  const classes = useStyles();

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));

  const history = useHistory();

    useVisibleButtonHelp();


    return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            marginTop: 80,
            minHeight: '70vh',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                paddingTop: 7,
                flexDirection: isTablet ? 'column' : 'row',
              }}
            >
              <Box
                component={'img'}
                src={driver}
                sx={{
                  width: isTablet ? 350 : 458,
                }}
                alt="Error"
                style={{ objectFit: 'contain' }}
              />
              <Box sx={{ width: 364, marginLeft: isTablet ? 0 : 4 }}>
                <Typography
                  sx={{ fontWeight: 700, fontSize: 32, textAlign: 'center' }}
                >
                  Y ahora, ¿qué sigue?
                </Typography>
                <Typography
                  sx={{
                    fontSize: 20,
                    textAlign: 'center',
                    marginTop: 5,
                    marginBottom: 7,
                  }}
                >
                  ¡No te preocupes! Ya levantamos un ticket con nuestro equipo
                  del Centro de Servicio Digital para que puedan ayudarte lo
                  antes posible. Recuerda checar tu correo electrónico, ahí vas
                  a recibir toooda la info de tu ticket.
                </Typography>
                <CustomButton
                  text={'OK, ENTENDIDO'}
                  onClick={() => {
                    history.push('/');
                  }}
                  size={isDesktop ? 'medium' : 'small'}
                  variant={'contained'}
                  color={'primary'}
                  style={{
                    textTransform: 'capitalize',
                    fontSize: '13px',
                    fontWeight: 600,
                    padding: '13px 18.5px 13px 18.5px',
                    width: '100%',
                    marginBottom: isTablet ? 100 : 0,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
};
