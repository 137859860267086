import { Close } from '@mui/icons-material';
import { Button, Dialog, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { usePolicyStore } from '@store/policyStore';

interface PagoProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalPagoMensual: React.FC<PagoProps> = ({ open, setOpen }) => {
  const { policy, addPolicyInfo } = usePolicyStore();
  return (
    <Dialog open={open} maxWidth={'xs'}>
      <Grid container sx={{ paddingX: '18px', paddingY: '16px' }}>
        <Grid
          item
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton size="small" onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Grid>
        <Grid
          item
          container
          display={'flex'}
          direction={'row'}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <Grid item xs={10} sm={10} lg={11} md={11} xl={11}>
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '20px',
                textAlign: 'center',
              }}
            >
              ¡Importante mencionarte! Para la forma de pago mensual no hay devolución en caso de que canceles anticipadamente.
            </Typography>
          </Grid>
        </Grid>
        <Grid item container rowGap={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              fullWidth
              variant="contained"
              sx={{
                textTransform: 'none',
                height: '55.09px',
                fontSize: '16px',
              }}
              onClick={() => {
                policy.payment_warning = 'Enterado';
                addPolicyInfo({ ...policy });
                setOpen(false);
              }}
            >
              Enterado
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
