import React, { useState } from 'react';
import { Benefit } from '@models/ui/Benefit';
import { Grid, useMediaQuery, useTheme } from '@mui/material';

//import component
import CarSelectInfo from './CarSelectInfo';
import DriverSelectInfo from './DriverSelectInfo';
import PlanSelect from './PlanSelect';
import SelectWrapperDesktop from './SelectWrapperDesktop';
import QuoteWrapper from './QuoteWrapper';
import QuoteHeader from './QuoteHeader';
import QuoteContainer from './QuoteContainer';
import AlertAntiFraudClose from "@components/antiFraud/AlertAntiFrauClose";

interface Props {
  coverage: string;
  setCoverage: React.Dispatch<React.SetStateAction<string>>;
}

export const CoverageForm = ({ coverage, setCoverage }: Props) => {

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'))
  const [options, setOptions] = useState<string>('Todas las opciones');
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2, lg: 3 }}>
      {isMobile ? (<>
      <CarSelectInfo />
      <SelectWrapperDesktop>
        <PlanSelect
          setCoverage={setCoverage}
          options={options}
          setOptions={setOptions}
        />
        <DriverSelectInfo />
      </SelectWrapperDesktop>
      </>) : ( <SelectWrapperDesktop>
        <CarSelectInfo />
        <PlanSelect
          setCoverage={setCoverage}
          options={options}
          setOptions={setOptions}
        />
        <DriverSelectInfo />
      </SelectWrapperDesktop>)}

      <QuoteWrapper>
        <QuoteHeader options={options} isLoading={isLoading} />
        <AlertAntiFraudClose customStyles={{
          margin: {xs :'5% 0% 5% 0%',sm :'5% 10% 5% 10%', md :'2% 20% 2% 20%'}
        }}/>
        <QuoteContainer setIsLoading={setIsLoading} isLoading={isLoading} />
      </QuoteWrapper>
    </Grid>
  );
};
