import React from 'react';
import {
  Container,
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { Plan } from '@screens/home/benefits/Plan';
import { useClamp } from '@utils/useClamp';

interface PlanModel {
  title: string;
  details: string[];
}

const plans: PlanModel[] = [
  {
    title: 'Amplio',
    details: [
      'Daños materiales',
      'Robo total',
      'Responsabilidad Civil',
      'Gastos Médicos Ocupantes',
      'Asistencia Legal y Jurídica',
      'Asistencia Vial'
    ],
  },
  {
    title: 'Limitado',
    details: [
      'Robo total',
      'Responsabilidad civil',
      'Gastos Médicos Ocupantes',
      'Asistencia Legal y Jurídica',
      'Asistencia Vial'
    ],
  },
  {
    title: 'Responsabilidad Civil',
    details: [
      'Responsabilidad civil',
      'Gastos Médicos Ocupantes',
      'Asistencia Legal y Jurídica',
      'Asistencia Vial'
    ],
  },
];

export const Plans: React.FC = () => {
  const { breakpoints } = useTheme();
  const {clamp} = useClamp();

  return (
    <Container maxWidth={'lg'}>
      <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 50 }}>
        <Typography
          variant={'h2'}
          fontWeight={'bold'}
          style={{ marginBottom: clamp('48px', '18px'), fontSize: clamp('32px', '24px') }}
          textAlign={'center'}
        >
          {'Elije el plan a tu medida'}
        </Typography>
        <Grid container rowGap={2} columnSpacing={3} alignItems={'stretch'}>
          {plans.map((plan, i) => (
            <Plan key={i} plan={plan} />
          ))}
        </Grid>
      </Box>
    </Container>
  );
};
