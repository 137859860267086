import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Clear } from '@mui/icons-material';
import TabContext from '@mui/lab/TabContext';
import {
  Modal,
  Box,
  Paper,
  Typography,
  TextField,
  IconButton,
  Backdrop,
  Fade,
  FormControl,
  Autocomplete,
} from '@mui/material';

import { useStyles } from './useStyle';
import { ZustandContext } from '@store/modalStore';
import { years } from './Data';
import { useCarStore, initialCarListData } from '@store/carInfoStore';
import { useCarListStore } from '@store/carListStore';
import { CarListData } from '@models/store/carListStore';
import { useCarModalHomeStore } from '@store/ModalHomeStore';
import {
  CarList,
  FoundSearchText,
  LoadingSpinner,
  NotFoundText,
  SearchNotFound,
  YearTab,
} from '@modals/ModalHome';
import { useTokenStore } from '@store/TokenStore';
import { VehiculoType, useVehiculoStore } from '@store/vehiculoStore';

type ModalHomeProps = {
  onClose?: () => void;
  marcaModelo?: string;
  anio?: string;
  isHeroAction?: boolean;
};

export const ModalHome: React.FC<ModalHomeProps> = ({
  onClose,
  marcaModelo,
  anio,
  isHeroAction,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { show, hide } = useContext(ZustandContext);
  const { setCar, car: carStore } = useCarStore();
  const { carList, assamblerList, getCarList } = useCarListStore();
  const { car, setCar: setCarModal } = useCarModalHomeStore();

  const modelo = marcaModelo ? marcaModelo : car.modelo;
  const carAnio = anio ? anio : car.anio;

  const [data, setData] = useState<CarListData[]>(carList);
  const [search, setSearch] = useState(modelo);
  const [value, setValue] = useState(years.indexOf(+carAnio).toString());
  const [notFound, setNotFound] = useState(false);
  const [yearFilter, setYearFilter] = useState(carAnio ? true : false);
  const [yearSelected, setYearSelected] = useState<string>(carAnio);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [marca, setMarca] = useState(carStore.assembler);
  const { token } = useTokenStore();
  const { setSelectedVehiculo, selectedVehiculo } = useVehiculoStore();

  useEffect(() => {
    setData(
      carList.filter((item) => item.description.includes(search)).length === 0
        ? [initialCarListData]
        : carList.filter((item) => item.description.includes(search))
    );
    // setData(carList);
  }, [carList]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSearch('');
    setValue(newValue);
  };

  useEffect(() => {
    (async () =>
      getCarList(
        search,
        yearSelected,
        car.marca,
        token,
        selectedVehiculo === 'coche'
          ? 'CAR'
          : selectedVehiculo === 'camioneta'
          ? 'PICKUP'
          : 'MOTO'
      ))();
  }, []);

  const handleSearch = async () => {
    if (!search) {
      await getCarList(
        search,
        yearSelected,
        car.marca,
        token,
        selectedVehiculo === 'coche'
          ? 'CAR'
          : selectedVehiculo === 'camioneta'
          ? 'PICKUP'
          : 'MOTO'
      );
      return;
    }

    setData(
      carList.filter((item) => item.description.includes(search)).length === 0
        ? [initialCarListData]
        : carList.filter((item) => item.description.includes(search))
    );
  };

  useEffect(() => {
    setIsLoading(true);
    handleSearch();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearSelected, search]);

  const handleSubmit = (item: CarListData) => {
    setCar(item);
    if (location.pathname === '/plans') {
      onClose && onClose();
    } else {
      history.push('/plans');
    }
  };

  const YearTabProps = {
    carList,
    setNotFound,
    setYearFilter,
    yearFilter,
    handleChange,
    setYearSelected,
  };

  const FoundSearchTextProps = {
    carList,
    data,
    show,
    hide,
  };

  const CarListProps = {
    data,
    location,
    handleSubmit,
  };

  if (isHeroAction) {
    return (
      <Paper sx={{ borderRadius: 2 }} className={classes.rootHeroAction}>
        <Box padding="16px 16px 0px 16px">
          <Box
            marginBottom={2}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography
              variant={'h6'}
              fontWeight={'bold'}
              marginTop={3}
              marginBottom={2}
            >
              {'¿Qué vehículo deseas asegurar?'}
            </Typography>
          </Box>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={assamblerList.sort()}
              value={car.marca ? car.marca : marca}
              onChange={async (event: any, newValue: any) => {
                setIsLoading(true);
                setMarca(newValue);
                setCarModal({ ...car, marca: newValue });
                await getCarList(
                  car.modelo,
                  car.anio,
                  newValue,
                  token,
                  selectedVehiculo === 'coche'
                    ? 'CAR'
                    : selectedVehiculo === 'camioneta'
                    ? 'PICKUP'
                    : 'MOTO'
                );
                setSearch('');
                setIsLoading(false);
              }}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Marca"
                  value={car.marca ? car.marca : marca}
                />
              )}
            />
            <Box marginBottom={2} />
          </FormControl>
          <TextField
            fullWidth
            placeholder="Ejemplo: YARIS SEDAN CORE STD 1.5L 4CIL 4PTAS"
            label="Modelo"
            variant="outlined"
            value={search}
            InputProps={{
              style: {
                borderRadius: 8,
              },
              endAdornment:
                search !== '' ? (
                  <IconButton
                    onClick={() => {
                      setSearch('');
                    }}
                  >
                    <Clear />
                  </IconButton>
                ) : null,
            }}
            inputRef={(input) => {
              if (input != null) input.focus();
            }}
            onChange={(e) => {
              setSearch(e.target.value.toUpperCase());
              setCarModal({ ...car, modelo: e.target.value.toUpperCase() });
            }}
          />
          <Box marginBottom={2} />
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <YearTab {...YearTabProps} />
              </Box>
            </TabContext>
          </Box>
        </Box>
        <Box className={classes.container}>
          <Box className={classes.overflow}>
            <Box className={classes.delegate}>
              {data.length === 0 && <LoadingSpinner />}
              {data[0].description !== '' && notFound === false && (
                <FoundSearchText {...FoundSearchTextProps} />
              )}
              <Box paddingBottom={2} />
              <>
                {notFound && <NotFoundText />}
                {data[0].description === '' && notFound !== true && (
                  <SearchNotFound />
                )}
                {data[0].description !== '' &&
                notFound === false &&
                !isLoading ? (
                  <CarList {...CarListProps} />
                ) : null}
                {isLoading ? <LoadingSpinner /> : null}
              </>
            </Box>
          </Box>
        </Box>
      </Paper>
    );
  }

  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper className={classes.root}>
          <Box padding="16px 16px 0px 16px">
            <Box
              marginBottom={2}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography
                variant={'h6'}
                fontWeight={'bold'}
                marginTop={3}
                marginBottom={2}
              >
                {'¿Qué vehículo deseas asegurar?'}
              </Typography>
              <IconButton onClick={onClose}>
                <Clear />
              </IconButton>
            </Box>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={assamblerList.sort()}
                value={car.marca ? car.marca : marca}
                onChange={async (event: any, newValue: any) => {
                  setIsLoading(true);
                  setMarca(newValue);
                  setCarModal({ ...car, marca: newValue });
                  await getCarList(
                    car.modelo,
                    car.anio,
                    newValue,
                    token,
                    selectedVehiculo === 'coche'
                      ? 'CAR'
                      : selectedVehiculo === 'camioneta'
                      ? 'PICKUP'
                      : 'MOTO'
                  );
                  setIsLoading(false);
                }}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Marca"
                    value={car.marca ? car.marca : marca}
                  />
                )}
              />
              <Box marginBottom={2} />
            </FormControl>
            <TextField
              fullWidth
              placeholder="Ejemplo: YARIS SEDAN CORE STD 1.5L 4CIL 4PTAS"
              label="Modelo"
              variant="outlined"
              value={search}
              InputProps={{
                style: {
                  borderRadius: 8,
                },
                endAdornment:
                  search !== '' ? (
                    <IconButton
                      onClick={() => {
                        setSearch('');
                      }}
                    >
                      <Clear />
                    </IconButton>
                  ) : null,
              }}
              inputRef={(input) => {
                if (input != null) input.focus();
              }}
              onChange={(e) => {
                setSearch(e.target.value.toUpperCase());
                setCarModal({ ...car, modelo: e.target.value.toUpperCase() });
              }}
            />
            <Box marginBottom={2} />
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <YearTab {...YearTabProps} />
                </Box>
              </TabContext>
            </Box>
          </Box>
          <Box className={classes.container}>
            <Box className={classes.overflow}>
              <Box className={classes.delegate}>
                {data.length === 0 && <LoadingSpinner />}
                {data[0].description !== '' && notFound === false && (
                  <FoundSearchText {...FoundSearchTextProps} />
                )}
                <Box paddingBottom={2} />
                <>
                  {notFound && <NotFoundText />}
                  {/* {loading && <LoadingSpinner />} */}
                  {data[0].description === '' && notFound !== true && (
                    <SearchNotFound />
                  )}
                  {data[0].description !== '' &&
                  notFound === false &&
                  !isLoading ? (
                    <CarList {...CarListProps} />
                  ) : null}
                  {isLoading ? <LoadingSpinner /> : null}
                </>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
