import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Clear, Error, Warning } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ExpandMore } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Check } from '@mui/icons-material';
import validator from 'validator';
import TagManager from 'react-gtm-module';
import InfoIcon from '@mui/icons-material/Info';
import {
  Fade,
  Modal,
  Paper,
  Box,
  Backdrop,
  Theme,
  Typography,
  IconButton,
  TextField,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useForm } from '@hooks/useForm';
import { CustomButton } from '@buttons/CustomButton';
import { useDriverStore } from '@store/driverInfoStore';
import { usePolicyStore } from '@store/policyStore';
import { useCPStore } from '@store/cpStore';
import { createRFC } from '@utils/createRFC';
import { formatDate } from '@utils/formatDate';
import {
  allEqual,
  isThereFourConsecutiveNumber,
} from '@utils/utilityFunctions';
import { PhoneCustomMask } from '@inputCustomMasks/PhoneCustomMask';
import { inherits } from 'util';

type ModalContratorDataProps = {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    top: '50%',
    left: '50%',
    width: 600,
    borderRadius: 8,
    padding: '10px',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
}));

const useStylesDropdown = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
}));

export const ModalContratorData: React.FC<ModalContratorDataProps> = ({
  onClose,
}) => {
  const classes = useStyles();
  const dropdownClasess = useStylesDropdown();
  const { policy, addPolicyInfo } = usePolicyStore();
  const { driver, setDriver } = useDriverStore();
  const { cpData } = useCPStore();

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const [date, setDate] = useState<Date | null | string>(
    policy.contrator?.fechaNacimiento
      ? policy.contrator?.fechaNacimiento
      : //@ts-ignore
        policy.driver?.fechaNacimiento
  );
  const [selector, setSelector] = React.useState<string>(
    policy.contrator?.genero ? policy.contrator?.genero : driver.genero
  );
  const [colorM, setColorM] = useState<string>('transparent');
  const [anterior, setAnterior] = useState<string>(driver.genero || 'Mujer');
  const [colorF, setColorF] = useState<string>('#F5FBFD');
  const [backM, setBackM] = useState<string>('#424242');
  const [backF, setBackF] = useState<string>('#039ECC');
  const [city, setCity] = useState(policy.colonia || '');
  const [state, setState] = useState(cpData[0]['state_code'] || '');
  const [contratorRFC, setContratorRFC] = useState(
    policy.contrator?.RFC ? policy.contrator?.RFC : policy.driver?.RFC
  );
  const [formContratorState, setFormContratorState] = useForm({
    contratorName: policy.contrator?.firstName
      ? policy.contrator?.firstName
      : policy.driver?.firstName,
    contratorMiddleName: policy.contrator?.middleName,
    contratorLastName: policy.contrator?.lastName
      ? policy.contrator?.lastName
      : policy.driver?.lastName,
    contratorEmail: policy.contrator?.email
      ? policy.contrator?.email
      : driver.email,
    confirmContratorEmail: '',
    contratorPhone: policy.contrator?.telefono
      ? policy.contrator?.telefono
      : driver.telefono,
    zip: driver.codigoPostal || '',
    street: policy.street || '',
    ext: policy.numeroExterior || '',
    int: policy.numeroInterior || '',
  });
  const [errorName, setErrorName] = useState({ msg: '', isError: false });
  const [warningName, setWarningName] = useState({ msg: '', isWarning: false });
  const [errorRFC, setErrorRFC] = useState({ msg: '', isError: false });
  const [warningRFC, setWarningRFC] = useState({ msg: '', isWarning: false });
  const [errorMiddleName, setErrorMiddleName] = useState({
    msg: '',
    isError: false,
  });
  const [warningMiddleName, setWarningMiddleName] = useState({
    msg: '',
    isWarning: false,
  });
  const [errorLastName, setErrorLastName] = useState({
    msg: '',
    isError: false,
  });
  const [warningLastName, setWarningLastName] = useState({
    msg: '',
    isWarning: false,
  });
  const [errorTelefono, setErrorTelefono] = useState({
    msg: '',
    isError: false,
  });
  const [warningTelefono, setWarningTelefono] = useState({
    msg: '',
    isWarning: false,
  });
  const [errorCalle, setErrorCalle] = useState({ msg: '', isError: false });
  const [warningCalle, setWarningCalle] = useState({
    msg: '',
    isWarning: false,
  });
  const [errorExterior, setErrorExterior] = useState({
    msg: '',
    isError: false,
  });
  const [warningExterior, setWarningExterior] = useState({
    msg: '',
    isWarning: false,
  });

  const {
    contratorName,
    contratorMiddleName,
    contratorLastName,
    contratorEmail,
    confirmContratorEmail,
    contratorPhone,
    zip,
    street,
    ext,
    int,
  } = formContratorState;

  const handleChange = (newValue: Date | null) => setDate(formatDate(newValue));

  const colorToggle = (attribute: string, value: string): string => {
    return attribute === value ? '#595959' : '#595959';
  };

  const backgroundToggle = (attribute: string, value: string): string => {
    return attribute === value ? '#C7C7C7' : 'transparent';
  };

  const handleChangeGender = (
    _: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment != null && anterior != null) {
      setAnterior(newAlignment);
    } else if (anterior != null) {
      newAlignment = anterior;
    } else {
      newAlignment = 'Mujer';
    }
    setSelector(newAlignment);
    if (newAlignment === 'Hombre' || newAlignment == null) {
      setSelector('Hombre');
      setColorM('#F5FBFD');
      setColorF('transparent');
      setBackM('#039ECC');
      setBackF('#424242');
    } else {
      setColorF('#F5FBFD');
      setColorM('transparent');
      setBackM('#424242');
      setBackF('#039ECC');
    }
  };

  const isLonger = (value: string): boolean => {
    if (value.length <= 13) {
      return true;
    }
    return false;
  };

  const isCorrectLonger = (value: string): boolean => {
    if (value.length === 13) {
      return true;
    }
    return false;
  };

  const handleCity = (event: SelectChangeEvent) => {
    setCity(event.target.value as string);
  };

  const handleState = (event: SelectChangeEvent) => {
    setState(event.target.value as string);
  };

  const isValidContratorForm = (): boolean => {
    if (contratorName === '') {
      return false;
    } else if (contratorPhone.length < 10) {
      return false;
    } else if (contratorMiddleName === '') {
      return false;
    } else if (contratorLastName === '') {
      return false;
    } else if (!validator.isMobilePhone(contratorPhone)) {
      return false;
    } else if (validator.isEmpty(contratorPhone)) {
      return false;
    } else if (validator.isEmpty(contratorEmail)) {
      return false;
    } else if (contratorRFC && contratorRFC?.length < 10) {
      return false;
    } else if (contratorRFC === '') {
      return false;
    } else if (contratorRFC && !validator.isAlphanumeric(contratorRFC)) {
      return false;
    } else if (!validator.isEmail(contratorEmail)) {
      return false;
    } else if (contratorEmail !== confirmContratorEmail) {
      return false;
    } else if (selector === null) {
      return false;
    } else if (zip === '') {
      return false;
    } else if (!validator.isPostalCode(zip, 'MX')) {
      return false;
    } else if (street === '') {
      return false;
    } else if (ext === '') {
      return false;
    } else if (!validator.isDecimal(ext)) {
      return false;
    }
    return true;
  };

  const update = () => {
    if (policy.contrator?.firstName === '') {
      addPolicyInfo({
        ...policy,
        driver: {
          firstName: contratorName,
          middleName: contratorMiddleName,
          lastName: contratorLastName,
          RFC: contratorRFC,
        },
        street,
        numeroExterior: ext,
        numeroInterior: int,
        colonia: city,
        estado: state,
      });
      setDriver({
        ...driver,
        fechaNacimiento: date,
        email: contratorEmail,
        telefono: contratorPhone,
        genero: selector,
        name: contratorName,
        codigoPostal: zip,
      });
    } else {
      addPolicyInfo({
        ...policy,
        contrator: {
          firstName: contratorName,
          middleName: contratorMiddleName,
          lastName: contratorLastName,
          RFC: contratorRFC || '',
          email: contratorEmail,
          fechaNacimiento: date,
          telefono: contratorPhone,
          genero: selector,
        },
        street,
        numeroExterior: ext,
        numeroInterior: int,
        colonia: city,
        estado: state,
      });
      setDriver({
        ...driver,
        genero: selector,
        codigoPostal: zip,
      });
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'CS_interactions',
        eventCategory: 'Formulario.Seguro.Auto.v2',
        eventAction: 'Interacciones',
        eventLabel: 'update_contratante_submit', //Texto del botón al que se hizo clic.
      },
    });
    onClose();
  };

  const validateEndAdorment = () => {
    if (validator.isEmail(confirmContratorEmail)) {
      if (confirmContratorEmail !== contratorEmail) {
        return <InfoIcon color={'error'} />;
      }
      return <Check color={'success'} />;
    }

    if (
      !validator.isEmpty(confirmContratorEmail) &&
      !validator.isEmail(confirmContratorEmail)
    ) {
      return <InfoIcon color={'error'} />;
    }

    return null;
  };

  const validateColor = () => {
    if (
      (!validator.isEmail(confirmContratorEmail) &&
        !validator.isEmpty(confirmContratorEmail)) ||
      confirmContratorEmail !== contratorEmail
    ) {
      return 'error';
    }

    if (validator.isEmail(confirmContratorEmail)) return 'success';

    return 'primary';
  };

  const colorTelefonoAdornment = () => {
    if (warningTelefono.isWarning) {
      return 'warning';
    }

    if (errorTelefono.isError) {
      return 'error';
    }

    return 'success';
  };

  const colorHelperTextTelefono = () => {
    if (warningTelefono.isWarning) {
      return '#FF9E1B';
    }

    if (errorTelefono.isError) {
      return '#f44336';
    }

    return '#5AB52F';
  };

  const telefonoAdornment = () => {
    if (warningTelefono.isWarning) {
      return <Warning color={'warning'} />;
    }

    if (errorTelefono.isError) {
      return <Error color={'error'} />;
    }

    return <Check color={'success'} />;
  };

  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper className={classes.root}>
          <Box
            padding={2}
            sx={{ height: isMobile ? 500 : '100%', overflow: 'scroll' }}
          >
            <Box
              marginBottom={2}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant={'h6'} fontWeight={'bold'}>
                {'¿La info del contratante es correcta?'}
              </Typography>
              <IconButton onClick={onClose}>
                <Clear />
              </IconButton>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={contratorName}
                    name={'contratorName'}
                    label="Nombre (s)*"
                    variant="outlined"
                    onBlur={() => {
                      if (contratorName) {
                        let formattedDate = JSON.stringify(date);
                        formattedDate = formattedDate.slice(1, 11);
                        const finalDate = formattedDate.split('-');
                        const rfc = createRFC({
                          name: contratorName,
                          lastName: contratorMiddleName,
                          secondName: contratorLastName,
                          birthday: `${finalDate[0]
                            .split(' ')
                            .join('')}-${finalDate[1].split(' ').join('')}-${
                            +finalDate[2].split(' ').join('') - 1
                          }`,
                        });
                        setContratorRFC(rfc);
                      }
                    }}
                    onChange={(e) => {
                      const regex = /^[a-zA-Z ]*$/;
                      if (regex.test(e.target.value)) {
                        setFormContratorState(e);
                        if (e.target.value.length === 0) {
                          setErrorName({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningName({
                            msg: '',
                            isWarning: false,
                          });
                          return;
                        }
                        if (e.target.value.length < 2) {
                          setErrorName({
                            msg: '',
                            isError: false,
                          });
                          setWarningName({
                            msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2.',
                            isWarning: true,
                          });
                        }
                        if (e.target.value.length >= 2) {
                          setErrorName({
                            msg: '',
                            isError: false,
                          });
                          setWarningName({
                            msg: '',
                            isWarning: false,
                          });
                        }
                      } else {
                        setErrorName({
                          msg: '¡Oops! Aquí sólo puedes escribir letras.',
                          isError: true,
                        });
                        setWarningName({
                          msg: '',
                          isWarning: false,
                        });
                      }
                    }}
                    error={errorName.isError}
                    helperText={
                      <label
                        style={{
                          color: errorName.isError ? '#f44336' : '#FF9E1B',
                        }}
                      >
                        {errorName.msg || warningName.msg}
                      </label>
                    }
                    color={
                      contratorName.length === 0
                        ? 'error'
                        : contratorName.length < 2
                        ? 'warning'
                        : 'success'
                    }
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment:
                        contratorName !== '' &&
                        contratorName.length >= 2 &&
                        !errorName.isError ? (
                          <Check color={'success'} />
                        ) : errorName.isError ? (
                          <Error color={'error'} />
                        ) : (
                          <Warning color={'warning'} />
                        ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={contratorMiddleName}
                    name={'contratorMiddleName'}
                    label="Primer Apellido*"
                    variant="outlined"
                    error={errorMiddleName.isError}
                    onBlur={() => {
                      if (contratorMiddleName) {
                        let formattedDate = JSON.stringify(date);
                        formattedDate = formattedDate.slice(1, 11);
                        const finalDate = formattedDate.split('-');
                        const rfc = createRFC({
                          name: contratorName,
                          lastName: contratorMiddleName,
                          secondName: contratorLastName,
                          birthday: `${finalDate[0]
                            .split(' ')
                            .join('')}-${finalDate[1].split(' ').join('')}-${
                            +finalDate[2].split(' ').join('') - 1
                          }`,
                        });
                        setContratorRFC(rfc);
                      }
                    }}
                    onChange={(e) => {
                      const regex = /^[a-zA-Z ]*$/;
                      if (regex.test(e.target.value)) {
                        setFormContratorState(e);
                        if (e.target.value.length === 0) {
                          setErrorMiddleName({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningMiddleName({
                            msg: '',
                            isWarning: false,
                          });
                          return;
                        }
                        if (e.target.value.length < 2) {
                          setErrorMiddleName({
                            msg: '',
                            isError: false,
                          });
                          setWarningMiddleName({
                            msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2.',
                            isWarning: true,
                          });
                        }
                        if (e.target.value.length >= 2) {
                          setErrorMiddleName({
                            msg: '',
                            isError: false,
                          });
                          setWarningMiddleName({
                            msg: '',
                            isWarning: false,
                          });
                        }
                      } else {
                        setErrorMiddleName({
                          msg: '¡Oops! Aquí sólo puedes escribir letras.',
                          isError: true,
                        });
                        setWarningMiddleName({
                          msg: '',
                          isWarning: false,
                        });
                      }
                    }}
                    color={
                      contratorMiddleName.length >= 2 &&
                      !errorMiddleName.isError
                        ? 'success'
                        : !warningMiddleName.isWarning &&
                          !errorMiddleName.isError
                        ? 'primary'
                        : errorMiddleName.isError
                        ? 'error'
                        : contratorMiddleName.length < 2
                        ? 'warning'
                        : 'primary'
                    }
                    helperText={
                      <label
                        style={{
                          color: errorMiddleName.isError
                            ? '#f44336'
                            : '#FF9E1B',
                        }}
                      >
                        {errorMiddleName.msg || warningMiddleName.msg}
                      </label>
                    }
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment:
                        contratorMiddleName !== '' &&
                        contratorMiddleName.length >= 2 &&
                        !errorMiddleName.isError ? (
                          <Check color={'success'} />
                        ) : errorMiddleName.isError ? (
                          <Error color={'error'} />
                        ) : warningMiddleName.isWarning ? (
                          <Warning color={'warning'} />
                        ) : null,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    onBlur={() => {
                      if (contratorLastName) {
                        let formattedDate = JSON.stringify(date);
                        formattedDate = formattedDate.slice(1, 11);
                        const finalDate = formattedDate.split('-');
                        const rfc = createRFC({
                          name: contratorName,
                          lastName: contratorMiddleName,
                          secondName: contratorLastName,
                          birthday: `${finalDate[0]
                            .split(' ')
                            .join('')}-${finalDate[1].split(' ').join('')}-${
                            +finalDate[2].split(' ').join('') - 1
                          }`,
                        });
                        setContratorRFC(rfc);
                      }
                    }}
                    value={contratorLastName}
                    name={'contratorLastName'}
                    label="Segundo Apellido*"
                    variant="outlined"
                    error={errorLastName.isError}
                    onChange={(e) => {
                      const regex = /^[a-zA-Z ]*$/;
                      if (regex.test(e.target.value)) {
                        setFormContratorState(e);
                        if (e.target.value.length === 0) {
                          setErrorLastName({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningLastName({
                            msg: '',
                            isWarning: false,
                          });
                          return;
                        }
                        if (e.target.value.length < 2) {
                          setErrorLastName({
                            msg: '',
                            isError: false,
                          });
                          setWarningLastName({
                            msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2.',
                            isWarning: true,
                          });
                        }
                        if (e.target.value.length >= 2) {
                          setErrorLastName({
                            msg: '',
                            isError: false,
                          });
                          setWarningLastName({
                            msg: '',
                            isWarning: false,
                          });
                        }
                      } else {
                        setErrorLastName({
                          msg: '¡Oops! Aquí sólo puedes escribir letras.',
                          isError: true,
                        });
                        setWarningLastName({
                          msg: '',
                          isWarning: false,
                        });
                      }
                    }}
                    color={
                      contratorLastName.length >= 2 && !errorLastName.isError
                        ? 'success'
                        : !warningLastName.isWarning && !errorLastName.isError
                        ? 'primary'
                        : errorLastName.isError
                        ? 'error'
                        : contratorLastName.length < 2
                        ? 'warning'
                        : 'primary'
                    }
                    helperText={
                      <label
                        style={{
                          color: errorLastName.isError ? '#f44336' : '#FF9E1B',
                        }}
                      >
                        {errorLastName.msg || warningLastName.msg}
                      </label>
                    }
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment:
                        contratorLastName !== '' &&
                        contratorLastName.length >= 2 &&
                        !errorLastName.isError ? (
                          <Check color={'success'} />
                        ) : errorLastName.isError ? (
                          <Error color={'error'} />
                        ) : warningLastName.isWarning ? (
                          <Warning color={'warning'} />
                        ) : null,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disabled={true}
                      openTo="year"
                      views={['year', 'month', 'day']}
                      minDate={new Date('1900-01-01')}
                      maxDate={new Date()}
                      label="Fecha de nacimiento"
                      inputFormat="dd/MM/yyyy"
                      value={new Date(`${date}T00:00:00`)}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField
                          sx={{ backgroundColor: '#F4F4F4' }}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box marginTop={2}>
                    <Typography fontSize={14} fontWeight={'normal'}>
                      {'Sexo de nacimiento'}
                    </Typography>
                  </Box>
                  <Box marginTop={2}>
                    <Box>
                      <ToggleButtonGroup
                        disabled={true}
                        fullWidth
                        size={'small'}
                        sx={{
                          height: 40,
                          backgroundColor: '#F4F4F4',
                        }}
                        exclusive
                        value={selector}
                        onChange={handleChangeGender}
                        style={{
                          borderRadius: 8,
                          marginBottom: 40,
                          padding: 3,
                        }}
                      >
                        <ToggleButton
                          sx={{
                            textTransform: 'none',
                            backgroundColor: '#fafafa',
                          }}
                          value="Mujer"
                          style={{
                            fontWeight: 600,
                            borderRadius: 8,
                            border: 'none',
                            textTransform: 'none',
                            color: colorToggle(selector, 'Mujer'),
                            backgroundColor: backgroundToggle(
                              selector,
                              'Mujer'
                            ),
                          }}
                        >
                          Mujer
                        </ToggleButton>
                        <ToggleButton
                          value="Hombre"
                          style={{
                            fontWeight: 600,
                            borderRadius: 8,
                            border: 'none',
                            textTransform: 'none',
                            color: colorToggle(selector, 'Hombre'),
                            backgroundColor: backgroundToggle(
                              selector,
                              'Hombre'
                            ),
                          }}
                        >
                          Hombre
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    height: 115,
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}
                >
                  <TextField
                    fullWidth
                    value={contratorRFC}
                    name={'contratorRFC'}
                    error={errorRFC.isError}
                    onChange={(e) => {
                      if (e.target.value.length <= 13) {
                        const regex = /^[a-zA-Z 0-9]*$/;
                        if (regex.test(e.target.value)) {
                          setContratorRFC(e.target.value);
                        }

                        if (
                          e.target.value.length < 10 &&
                          e.target.value.length > 0
                        ) {
                          setErrorRFC({ msg: '', isError: false });
                          setWarningRFC({
                            msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 10.',
                            isWarning: true,
                          });
                          return;
                        }
                        if (e.target.value.length === 0) {
                          setErrorRFC({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningRFC({
                            msg: '',
                            isWarning: false,
                          });
                          return;
                        }
                      }
                      setErrorRFC({ msg: '', isError: false });
                      setWarningRFC({
                        msg: '',
                        isWarning: false,
                      });
                    }}
                    helperText={
                      <label
                        style={{
                          color: errorRFC.isError ? '#f44336' : '#FF9E1B',
                        }}
                      >
                        {errorRFC.msg || warningRFC.msg}
                      </label>
                    }
                    label="RFC*"
                    variant="outlined"
                    onBlur={() => {
                      // @ts-ignore
                      if (contratorRFC.length === 0) {
                        setErrorRFC({
                          msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                          isError: true,
                        });
                      }
                    }}
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment:
                        // @ts-ignore
                        isLonger(contratorRFC) &&
                        // @ts-ignore
                        validator.isAlphanumeric(contratorRFC) &&
                        // @ts-ignore
                        isCorrectLonger(contratorRFC) ? (
                          <Check color={'success'} />
                        ) : // @ts-ignore
                        (!validator.isAlphanumeric(contratorRFC) &&
                            // @ts-ignore
                            !validator.isEmpty(contratorRFC)) ||
                          // @ts-ignore
                          !isLonger(contratorRFC) ||
                          errorRFC.isError ? (
                          <InfoIcon color={'error'} />
                        ) : warningRFC.isWarning ? (
                          <Warning color={'warning'} />
                        ) : null,
                    }}
                    color={
                      // @ts-ignore
                      isLonger(contratorRFC) &&
                      // @ts-ignore
                      validator.isAlphanumeric(contratorRFC) &&
                      // @ts-ignore
                      isCorrectLonger(contratorRFC)
                        ? 'success'
                        : // @ts-ignore
                        (!validator.isEmpty(contratorRFC) &&
                            // @ts-ignore
                            !validator.isAlphanumeric(contratorRFC)) ||
                          // @ts-ignore
                          !isLonger(contratorRFC) ||
                          errorRFC.isError
                        ? 'error'
                        : warningRFC.isWarning
                        ? 'warning'
                        : 'primary'
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Box marginTop={2} marginBottom={5}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={contratorEmail}
                    name={'contratorEmail'}
                    onChange={(e) => setFormContratorState(e)}
                    label="Correo electrónico"
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment: validator.isEmail(contratorEmail) ? (
                        <Check color={'success'} />
                      ) : !validator.isEmpty(contratorEmail) &&
                        !validator.isEmail(contratorEmail) ? (
                        <InfoIcon color={'error'} />
                      ) : null,
                    }}
                    color={
                      !validator.isEmail(contratorEmail) &&
                      !validator.isEmpty(contratorEmail)
                        ? 'error'
                        : validator.isEmail(contratorEmail)
                        ? 'success'
                        : 'primary'
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={confirmContratorEmail}
                    name={'confirmContratorEmail'}
                    onChange={(e) => setFormContratorState(e)}
                    label="Confirma tu correo electrónico"
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment: validateEndAdorment(),
                    }}
                    color={validateColor()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    sx={{ marginTop: 1 }}
                    name="contratorPhone"
                    value={contratorPhone}
                    label="Telefono"
                    fullWidth
                    variant="outlined"
                    placeholder="Telefono"
                    onChange={(event) => {
                      const regex = /^[0-9]*$/;
                      const telefono = event.target.value;
                      if (regex.test(telefono)) {
                        if (telefono.length <= 10) {
                          setFormContratorState(event);
                          if (telefono.length < 10) {
                            setWarningTelefono({
                              msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 10.',
                              isWarning: true,
                            });
                          } else {
                            setWarningTelefono({
                              msg: '',
                              isWarning: false,
                            });
                          }
                          setErrorTelefono({
                            msg: '',
                            isError: false,
                          });
                          return;
                        } else {
                          setWarningTelefono({
                            msg: '',
                            isWarning: false,
                          });
                          setErrorTelefono({
                            msg: '',
                            isError: false,
                          });
                        }
                        return;
                      } else {
                        setErrorTelefono({
                          msg: '¡Oops! Aquí sólo puedes escribir números.',
                          isError: true,
                        });
                        setWarningTelefono({
                          msg: '',
                          isWarning: false,
                        });
                      }
                      return;
                    }}
                    error={errorTelefono.isError}
                    onBlur={() => {
                      const telefonoArray = contratorPhone.split('');

                      const isResultAllEquals = allEqual(telefonoArray);
                      const isConsecutive =
                        isThereFourConsecutiveNumber(contratorPhone);

                      if (
                        isResultAllEquals ||
                        contratorPhone === '0123456789' ||
                        isConsecutive
                      ) {
                        setErrorTelefono({
                          msg: '¡Oops! Debes ingresar un teléfono válido.',
                          isError: true,
                        });
                        return;
                      }

                      setErrorTelefono({
                        msg: '',
                        isError: false,
                      });
                    }}
                    helperText={
                      <label style={{ color: colorHelperTextTelefono() }}>
                        {errorTelefono.msg || warningTelefono.msg}
                      </label>
                    }
                    color={colorTelefonoAdornment()}
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment: telefonoAdornment(),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography variant={'h6'} fontWeight={'bold'}>
                {'¿Cuál es su dirección fiscal?'}
              </Typography>
            </Box>
            <Box marginTop={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    value={street}
                    name={'street'}
                    label="Calle*"
                    helperText={
                      <label
                        style={{
                          color: errorCalle.isError ? '#f44336' : '#FF9E1B',
                        }}
                      >
                        {errorCalle.msg || warningCalle.msg}
                      </label>
                    }
                    onChange={(e) => {
                      setFormContratorState(e);
                      if (e.target.value.length === 0) {
                        setErrorCalle({
                          msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                          isError: true,
                        });
                        setWarningCalle({ msg: '', isWarning: false });
                      } else if (e.target.value.length < 2) {
                        setErrorCalle({
                          msg: '',
                          isError: false,
                        });
                        setWarningCalle({
                          msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2.',
                          isWarning: true,
                        });
                      } else {
                        setWarningCalle({ msg: '', isWarning: false });
                        setErrorCalle({
                          msg: '',
                          isError: false,
                        });
                      }
                    }}
                    onBlur={() => {
                      if (street.length === 0) {
                        setErrorCalle({
                          msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                          isError: true,
                        });
                        setWarningCalle({ msg: '', isWarning: false });
                      } else if (street.length < 2) {
                        setErrorCalle({
                          msg: '',
                          isError: false,
                        });
                        setWarningCalle({
                          msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2.',
                          isWarning: true,
                        });
                      } else {
                        setWarningCalle({ msg: '', isWarning: false });
                        setErrorCalle({
                          msg: '',
                          isError: false,
                        });
                      }
                    }}
                    variant="outlined"
                    maxRows={4}
                    error={errorCalle.isError}
                    color={
                      errorCalle.isError
                        ? 'error'
                        : warningCalle.isWarning
                        ? 'warning'
                        : street.length >= 2
                        ? 'success'
                        : 'primary'
                    }
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment: errorCalle.isError ? (
                        <Error color={'error'} />
                      ) : warningCalle.isWarning ? (
                        <Warning color={'warning'} />
                      ) : street.length >= 2 ? (
                        <Check color={'success'} />
                      ) : null,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <TextField
                      fullWidth
                      value={ext}
                      name={'ext'}
                      label="No. exterior*"
                      variant="outlined"
                      onChange={(e) => {
                        setFormContratorState(e);
                        if (e.target.value.length === 0) {
                          setErrorExterior({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningExterior({ msg: '', isWarning: false });
                        } else if (e.target.value.length < 1) {
                          setErrorExterior({
                            msg: '',
                            isError: false,
                          });
                          setWarningExterior({
                            msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 1.',
                            isWarning: true,
                          });
                        } else {
                          setWarningExterior({ msg: '', isWarning: false });
                          setErrorExterior({
                            msg: '',
                            isError: false,
                          });
                        }
                      }}
                      onBlur={() => {
                        if (ext.length === 0) {
                          setErrorExterior({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningExterior({ msg: '', isWarning: false });
                        } else if (ext.length < 1) {
                          setErrorExterior({
                            msg: '',
                            isError: false,
                          });
                          setWarningExterior({
                            msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2.',
                            isWarning: true,
                          });
                        } else {
                          setWarningExterior({ msg: '', isWarning: false });
                          setErrorExterior({
                            msg: '',
                            isError: false,
                          });
                        }
                      }}
                      maxRows={4}
                      error={errorExterior.isError}
                      color={
                        errorExterior.isError
                          ? 'error'
                          : warningExterior.isWarning
                          ? 'warning'
                          : ext.length >= 1
                          ? 'success'
                          : 'primary'
                      }
                      helperText={
                        <label
                          style={{
                            color: errorExterior.isError
                              ? '#f44336'
                              : '#FF9E1B',
                          }}
                        >
                          {errorExterior.msg || warningExterior.msg}
                        </label>
                      }
                      InputProps={{
                        style: {
                          borderRadius: 8,
                        },
                        endAdornment: errorExterior.isError ? (
                          <Error color={'error'} />
                        ) : warningExterior.isWarning ? (
                          <Warning color={'warning'} />
                        ) : ext.length >= 1 ? (
                          <Check color={'success'} />
                        ) : null,
                      }}
                    />
                    <TextField
                      fullWidth
                      value={int}
                      name={'int'}
                      label="No. interior"
                      variant="outlined"
                      onChange={(e) => setFormContratorState(e)}
                      maxRows={4}
                      color={validator.isDecimal(int) ? 'success' : 'primary'}
                      InputProps={{
                        style: {
                          borderRadius: 8,
                        },
                        endAdornment: validator.isDecimal(int) ? (
                          <Check color={'success'} />
                        ) : null,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box marginTop={2} marginBottom={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Colonia</InputLabel>
                    <Select
                      value={city}
                      label="Colonia"
                      onChange={handleCity}
                      className={dropdownClasess.root}
                      style={{ borderRadius: '8px' }}
                      IconComponent={(props) => (
                        <ExpandMore style={{ color: '#039ECC' }} {...props} />
                      )}
                    >
                      {cpData.map((cp, i) => (
                        <MenuItem key={i} value={cp.suburb_code}>
                          {cp.suburb_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Estado</InputLabel>
                    <Select
                      disabled
                      value={state}
                      label="Estado"
                      onChange={handleState}
                      className={dropdownClasess.root}
                      style={{
                        borderRadius: '8px',
                        backgroundColor: '#F4F4F4',
                      }}
                      IconComponent={(props) => (
                        <ExpandMore style={{ color: '#039ECC' }} {...props} />
                      )}
                    >
                      <MenuItem value={cpData[0]['state_code']}>
                        {cpData[0]['state_name']}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-end'}
              marginTop={4}
            >
              <CustomButton
                text={'¡Ahora sí están bien!'}
                disabled={!isValidContratorForm()}
                onClick={update}
                endIcon={<ArrowForwardIcon />}
                color={'primary'}
                variant={'contained'}
                style={{ height: 40, width: '50%', textTransform: 'none' }}
              />
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
