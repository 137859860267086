import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import CreditariaLogo from '@assets/images/creditaria_logo.svg';
import { useHistory } from 'react-router-dom';
import Asesor from '@assets/images/asesor.svg';
import Franquicia from '@assets/images/franquicia.svg';
import {
  AppBar,
  Toolbar,
  Container,
  useScrollTrigger,
  Theme,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { Worker, getWorker, getWorkers } from '@api/getWorker';
import { useTokenStore } from '@store/TokenStore';
import { useClamp } from '@utils/useClamp';
import { getToken } from '@api/getToken';
import { useIdStore } from '@store/UrlIdStore';
import {usePolicyStore} from "@store/policyStore";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  spacer: {
    marginLeft: theme.spacing(2),
  },
  menu: {
    padding: 0,
  },
  popOverPaper: {
    width: '100%',
    marginTop: 40,
  },
}));

export const Navbar: React.FC = () => {
  const { policy } = usePolicyStore();
  const { push } = useHistory();
  const classes = useStyles();
  const {clamp} = useClamp();
  const { pathname } = useLocation();
  const { setToken, token } = useTokenStore();
  const { id } = useIdStore();
  const trigger = useScrollTrigger({
    threshold: 80,
    disableHysteresis: true,
  });
  const [worker, setWorker] = useState<Worker>({
    apellidomaternoempleado: '',
    apellidopaternoempleado: '',
    createdDate: 0,
    doc_name: '',
    franquicia: '',
    nombreempleado: '',
    numeroempleado: '',
    updatedDate: 0
  });

  useEffect(() => {
    (async () => {
      await setToken(await getToken());
      const data = await getWorker(token, id || policy.employee_id);
      setWorker(data);
    })()
  }, [id])

  const isCurrentHome = (): boolean => {
    if (pathname === '/') {
      return false;
    } else if (pathname === '/home-alt') {
      return false;
    } else if (pathname === '/quotation') {
      return false;
    }
    return true;
  };

  return (
    <div className={classes.root}>
      <AppBar
        elevation={trigger || isCurrentHome() ? 4 : 0}
        position={'fixed'}
        style={{ background: '#fafafa' }}
      >
        <Container maxWidth="xl" sx={{display: 'flex', justifyContent: 'center'}}>
          <Toolbar
            style={{width: '1200px'}}
          >
            <Grid container sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  component={'img'}
                  src={CreditariaLogo}
                  sx={{
                    maxWidth: { xs: 120, md: 120 },
                  }}
                  style={{
                    objectFit: 'contain',
                    cursor: 'pointer',
                  }}
                  alt={'Inter, seguro, seguro'}
                  onClick={() => push('/')}
                />
              </Grid>
              <Grid item container xl={6} lg={6} md={6} sm={6} xs={6} columnGap={'0.5rem'} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{ display: 'flex', columnGap: '0.5rem', flexDirection: clamp('column', 'row-reverse') }}>
                  <Typography sx={{ textAlign: 'right', fontSize: clamp('1rem', '10px') }}>{worker?.franquicia}</Typography>
                  {clamp(<Typography sx={{ fontSize: '10px', fontWeight: 700, textAlign: 'right'}}> <Box component={'img'} src={Franquicia} /> Franquicia</Typography>, <Box component={'img'} sx={{ height: '1rem' }} src={Franquicia} />)}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ display: 'flex', columnGap: '0.5rem', flexDirection: clamp('column', 'row-reverse') }}>
                  <Typography sx={{ textAlign: 'right', fontSize: clamp('1rem', '10px') }}>{`${worker?.nombreempleado} ${worker?.apellidopaternoempleado} ${worker?.apellidomaternoempleado}`}</Typography>
                  {clamp(<Typography  sx={{ fontSize: '10px', fontWeight: 700, textAlign: 'right' }}><Box component={'img'} src={Asesor} /> Asesor</Typography>, <Box component={'img'} sx={{ height: '1rem' }} src={Asesor} />)}
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};
