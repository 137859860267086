import { useState, useContext, useEffect } from 'react';
import {
  Box,
  Paper,
  useMediaQuery,
  useTheme,
  TextField,
  FormControl,
} from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
import { CustomButton } from '@buttons/CustomButton';
import { useStyles } from './useStyle';
import { useDriverStore } from '@store/driverInfoStore';
import { useTokenStore } from '@store/TokenStore';
import { getCRM } from '@api/getCRM';
import { getDataTracking } from '@api/getTracking';
import { useCPStore } from '@store/cpStore';
import { useCarStore } from '@store/carInfoStore';
import { usePolicyStore } from '@store/policyStore';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { useTrackingStore } from '@store/TrackingStore';
import { ModalCotizacion } from '@modals/ModalHome/ModalCotizacion';
import { ZustandContext } from '@store/modalStore';
import {useIdStore} from "@store/UrlIdStore";

interface Props {
  padding?: number;
  elevation?: number;
}

export const AlreadyQuotation = ({
  elevation,
}: Props) => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const classes = useStyles({ isMobile, isTablet, isDesktop });
  const { token } = useTokenStore();
  const history = useHistory();
  const { setCar } = useCarStore();
  const { driver, setDriver } = useDriverStore();
  const { searchCP } = useCPStore();
  const { addPolicyInfo, policy } = usePolicyStore();
  const { setSelectedQuote } = useSelectedQuoteStore();
  const { setDataTracking } = useTrackingStore();
  const { show, hide } = useContext(ZustandContext);
  const { setId } = useIdStore();

  const [errorCotizacion, setErrorCotizacion] = useState({
    msg: '',
    isError: false,
  });
  const [errorNumCotizacion, setErrorNumCotizacion] = useState({
    msg: '',
    isError: false,
  });
  const [numCotizacion, setNumCotizacion] = useState('');
  const [emailCotizacion, setEmailCotizacion] = useState('');

  const handleInputChangeCotizacion = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.id === 'numCotizacion') {
      setNumCotizacion(event.target.value);
      setDriver({
        ...driver,
        numCotizacion: event.target.value,
      });
      return;
    }

    if (event.target.id === 'emailCotizacion') {
      setEmailCotizacion(event.target.value);
      setDriver({
        ...driver,
        emailCotizacion: event.target.value,
      });
      return;
    }
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleNextStep = () => {
    (async () => {
      let cotiza = await getCRM(numCotizacion, emailCotizacion, token);
      if (cotiza?.tracking_id){
        let dataTracking = await getDataTracking(token, cotiza?.tracking_id);
        if (dataTracking?.data?.data?.driver){
          await setDataTracking(dataTracking.data);
          await setDriver(dataTracking.data.data.driver);
          await searchCP(dataTracking.data.data.driver.codigoPostal, token);
          await setCar(dataTracking.data.data.car);
          await addPolicyInfo(dataTracking.data.data.policy);
          await setSelectedQuote(dataTracking.data.data.selectedQuote);

          if (policy.employee_id) setId(policy.employee_id)

          if (
            cotiza?.sub_stage === '1. Datos Generales' &&
            cotiza?.stage === '1. Registro (Coche)'
          ) {
            history.push('/plans');
          }
          if (
            cotiza?.sub_stage === '2. Datos Cotización' &&
            cotiza?.stage === '1. Registro (Coche)'
          ) {
            history.push('/insurer-details');
          }
          if (
            cotiza?.sub_stage === '3. Elección' &&
            cotiza?.stage === '2. Cotización (Coche)'
          ) {
            history.push('/hiring');
          }
          if (
            cotiza?.sub_stage === '4. Datos Complementarios Asegurado' &&
            cotiza?.stage === '3. Contratación (Coche)'
          ) {
            history.push('/hiring');
          }
          if (
            cotiza?.sub_stage === '5. Datos Complementarios Contratante' &&
            cotiza?.stage === '3. Contratación (Coche)'
          ) {
            history.push('/confirm');
          }
          if (
            cotiza?.sub_stage === '6. Datos Bancarios' &&
            cotiza?.stage === '3. Contratación (Coche)'
          ) {
            history.push('/payment');
          }
          if (
            cotiza?.sub_stage === '7. Pago' &&
            cotiza?.stage === '4. Emisión (Coche)'
          ) {
            history.push('/welcome');
          }
        }
        else 
        {
          show(ModalCotizacion, { onClose: hide });
        }
      }
      else 
      {
        show(ModalCotizacion, { onClose: hide });
      }
      
    })();
  };

  return (
    <Paper
      elevation={elevation}
      style={{
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <Box
        style={{
          paddingTop: 18,
          paddingLeft: 23,
          paddingRight: 23,
          paddingBottom: 18,
        }}
      >
        <label className={classes.firstText}>
          {'Consultar cotización'}
        </label>
        <div className={classes.blockSpace}>
          <FormControl fullWidth>
            <label className={classes.label}>
              {'Número de cotización'}
            </label>
            <TextField
              id="numCotizacion"
              fullWidth
              variant="outlined"
              placeholder="No. de cotización"
              value={driver.numCotizacion}
              InputProps={{
                style: {
                  borderRadius: 8,
                },
              }}
              onChange={handleInputChangeCotizacion}
              error={errorNumCotizacion.isError}
              helperText={errorNumCotizacion.msg}
            />
            <label className={classes.emailLabel}>
              {'Correo electrónico del conductor asegurado que registraste'}
            </label>
            <TextField
              id="emailCotizacion"
              fullWidth
              variant="outlined"
              placeholder="Correo"
              value={driver.emailCotizacion}
              onChange={handleInputChangeCotizacion}
              error={errorCotizacion.isError}
              helperText={errorCotizacion.msg}
              color={
                validator.isEmail(driver.emailCotizacion)
                  ? 'primary'
                  : 'primary'
              }
              InputProps={{
                style: {
                  borderRadius: 8,
                },
              }}
            />
          </FormControl>
          <Box style={{ marginBottom: 20 }} />
          <CustomButton
            text={'Seguir cotizando'}
            color={'primary'}
            variant={'contained'}
            endIcon={<ArrowForward />}
            style={{ width: '100%', height: 56, textTransform: 'none', color: '#FFF' }}
            onClick={handleNextStep}
          />
          <Box style={{ marginBottom: 20 }} />
        </div>
      </Box>
    </Paper>
  );
};
