import { InterInstance } from '@api/config';

export const getCPById = async (id: string, token: string) => {
  try {
    const { data } = await InterInstance.get(`/catalogs/location/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data.data;
  } catch (e) {
    return e;
  }
};

export const getCPByInsurance = async (
  id: string,
  insurance: string,
  token: string
) => {
  try {
    const { data } = await InterInstance.get(
      `/catalogs/location/creditaria/${insurance}/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data.data;
  } catch (e) {
    return e;
  }
};
