import { InterInstance } from './config';

export const getPromotion = async (token: string) => {
  try {
    const { data } = await InterInstance.get(`/catalogs/promotions`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data.data[0];
  } catch (e) {
    return;
  }
};

export const getDataPromotion = async (token: string, initialData: {}) => {
  try {
    const { data } = await InterInstance({
      method: 'post',
      url: '/catalogs/promotions/send/email',
      headers: { Authorization: `Bearer ${token}` },
      data: initialData,
    });
    return data;
  } catch (e) {
    return;
  }
};
