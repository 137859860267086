import React, { useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  Radio,
  Grid,
} from '@mui/material';

import { ModalHomeAlt } from '@modals/ModalHomeAlt';
import { CustomButton } from '@buttons/CustomButton';
import { ZustandContext } from '@store/modalStore';
import EmailHomeAlt from '@assets/images/email-home-alt.png';
import PhoneHomeAlt from '@assets/images/phone-home-alt.png';
import { ModalHome } from '@modals/ModalHome/ModalHome';
import { useDriverStore } from '@store/driverInfoStore';

type HeroActionProps = {
  title?: string;
  isAlt?: boolean;
  padding?: number;
  elevation?: number;
  isAbsolute?: boolean;
};

const useStyles = makeStyles({
  root: {
    width: ({ isAbsolute, isMobile, isTablet, isDesktop }: any) => {
      return isAbsolute
        ? isMobile
          ? ''
          : isTablet
          ? ''
          : isDesktop
          ? 364
          : 320
        : '';
    },
    position: ({ isAbsolute }: any) => {
      return isAbsolute ? 'absolute' : 'relative';
    },
    left: ({ isMobile, isTablet, isDesktop, isAbsolute }: any) => {
      return isAbsolute
        ? isMobile
          ? '3vw'
          : isTablet
          ? '8vw'
          : isDesktop
          ? ''
          : ''
        : '';
    },
    top: ({ isMobile, isTablet, isDesktop, isAbsolute }: any) => {
      return isAbsolute
        ? isMobile
          ? 344
          : isTablet
          ? 334
          : isDesktop
          ? 140
          : 138
        : '';
    },
    right: ({ isMobile, isTablet, isAbsolute }: any) => {
      return isAbsolute ? (isMobile ? '3vw' : isTablet ? '8vw' : '10vw') : '';
    },
    zIndex: 3,
  },
});

export const HeroAction: React.FC<HeroActionProps> = ({
  title = '',
  padding = 0,
  elevation = 0,
  isAlt = false,
  isAbsolute = false,
}) => {
  const { breakpoints } = useTheme();
  const [name, setName] = useState<string>('');
  const { show, hide } = useContext(ZustandContext);
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const { driver } = useDriverStore();

  const [selectedValue, setSelectedValue] = useState<string>('email');
  const classes = useStyles({ isMobile, isTablet, isDesktop, isAbsolute });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const isValidForm = (): boolean => {
    if (name === '') {
      return false;
    }

    return true;
  };

  alert('modal');

  return (
    <Box className={classes.root}>
      {isAlt && (
        <Typography
          color={'white'}
          marginBottom={2}
          fontWeight={'600'}
          lineHeight={'24px'}
          sx={{ fontSize: { lg: 24 } }}
        >
          {driver.name === ''
            ? '¡Encontremos la mejor opción para ti!'
            : `De acuerdo, ${driver.name}`}
        </Typography>
      )}
      <Paper elevation={elevation} style={{ borderRadius: 8 }}>
        <Box style={{ padding: padding }}>
          {driver.name === '' ? (
            <>
              {title !== '' && (
                <Typography
                  fontWeight={'bold'}
                  sx={{
                    fontSize: {
                      xl: 16,
                      lg: 16,
                      md: 14,
                      sm: 24,
                      xs: 16,
                    },
                  }}
                >
                  {title}
                </Typography>
              )}
              <Box style={{ marginBottom: 10 }} />
              <TextField
                fullWidth
                label="e.g. Selma"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputProps={{
                  style: {
                    borderRadius: 8,
                  },
                }}
              />
              <Box style={{ marginBottom: 25 }} />
              <Typography
                fontWeight={'bold'}
                sx={{
                  fontSize: {
                    xl: 16,
                    lg: 16,
                    md: 14,
                    sm: 24,
                    xs: 16,
                  },
                }}
              >
                {'¿Cómo prefieres que te asesoremos?'}
              </Typography>
              <Box style={{ marginBottom: 12 }} />
              <Box
                paddingY={1}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedValue('email')}
              >
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Box
                    component={'img'}
                    src={EmailHomeAlt}
                    sx={{
                      maxWidth: { xs: 20 },
                    }}
                    style={{ objectFit: 'contain', cursor: 'pointer' }}
                    alt={'Por este buscador y e-mail'}
                  />
                  <Box marginRight={'21px'} />
                  <Typography
                    fontWeight={'400'}
                    lineHeight={'24px'}
                    letterSpacing={'0.15px'}
                    sx={{ fontSize: { xs: 14 } }}
                  >
                    {'Por este buscador y e-mail'}
                  </Typography>
                </Box>

                <Radio
                  value="email"
                  onChange={handleChange}
                  checked={selectedValue === 'email'}
                />
              </Box>
              <Box display={'flex'} flexDirection={'row-reverse'}>
                <Box
                  width={'87%'}
                  height={'1px'}
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.08)' }}
                />
              </Box>
              <Box
                paddingY={1}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedValue('phone')}
              >
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Box
                    component={'img'}
                    src={PhoneHomeAlt}
                    sx={{
                      maxWidth: { xs: 20 },
                    }}
                    style={{ objectFit: 'contain', cursor: 'pointer' }}
                    alt={'Por teléfono'}
                  />
                  <Box marginRight={'21px'} />
                  <Typography
                    fontWeight={'400'}
                    lineHeight={'24px'}
                    letterSpacing={'0.15px'}
                    sx={{ fontSize: { xs: 14 } }}
                  >
                    {'Por teléfono'}
                  </Typography>
                </Box>

                <Radio
                  value="phone"
                  onChange={handleChange}
                  checked={selectedValue === 'phone'}
                />
              </Box>
              <Box display={'flex'} flexDirection={'row-reverse'}>
                <Box
                  width={'87%'}
                  height={'1px'}
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.08)' }}
                />
              </Box>
              <Box style={{ marginBottom: 30 }} />
              <CustomButton
                text={'¡Comencemos!'}
                color={'primary'}
                variant={'contained'}
                endIcon={<ArrowForward />}
                disabled={!isValidForm()}
                style={{ width: '100%', height: 56, textTransform: 'none' }}
                onClick={() =>
                  show(ModalHomeAlt, {
                    onClose: hide,
                    name: name,
                    value: selectedValue,
                  })
                }
              />
            </>
          ) : (
            <>
              <Box>
                <Typography
                  fontWeight={'bold'}
                  sx={{
                    fontSize: {
                      xl: 16,
                      lg: 16,
                      md: 14,
                      sm: 24,
                      xs: 16,
                    },
                  }}
                >
                  {'¿Qué vehículo quieres asegurar ?'}
                </Typography>
              </Box>
              <Box marginBottom={2} width={'100%'}>
                <Typography
                  textAlign={'start'}
                  variant={'caption'}
                  fontSize={'13px'}
                  style={{ fontWeight: 'normal' }}
                >
                  {'Busca tu moto, coche o camioneta'}
                </Typography>
              </Box>
              <Box style={{ marginBottom: 10 }} />
              <Grid container spacing={0}>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    label="Marca/Modelo"
                    variant="outlined"
                    defaultValue={'e.g. Mazda, Nissan'}
                    onClick={() => show(ModalHome, { onClose: hide })}
                    InputProps={{
                      style: {
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      },
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Año"
                    variant="outlined"
                    defaultValue={'2021'}
                    onClick={() => show(ModalHome, { onClose: hide })}
                    InputProps={{
                      style: {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                      },
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Box style={{ marginBottom: 16 }} />
              <Box style={{ marginBottom: 16 }} />
              <CustomButton
                text={'Buscar'}
                color={'primary'}
                variant={'contained'}
                endIcon={<ArrowForward />}
                style={{ width: '100%', height: 56, textTransform: 'none' }}
                onClick={() => show(ModalHome, { onClose: hide })}
              />
              <Box
                border={'1px solid #b0bec5'}
                borderRadius={2}
                marginTop={2}
                padding={2}
                style={{
                  backgroundColor: '#eceff1',
                }}
              >
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'flex-start'}
                >
                  <Typography
                    textAlign={'start'}
                    variant={'caption'}
                    style={{ fontWeight: 'bold', color: 'grey' }}
                  >
                    {'¡ATENCION!'}
                  </Typography>
                  <Typography
                    marginTop={1}
                    textAlign={'start'}
                    variant={'caption'}
                    style={{ fontWeight: 'bold' }}
                  >
                    {'¿Eres socio conductor?'}
                  </Typography>
                  <Typography
                    textAlign={'start'}
                    variant={'caption'}
                    marginBottom={0.6}
                    style={{ fontWeight: 'normal' }}
                  >
                    {
                      'Si tu vehículo brinda servicios en apps de transporte privado, ¡Inter está contigo! Conoce los productos especiales que tenemos para tí.'
                    }
                  </Typography>
                  <CustomButton
                    text={'Conócelos aqui'}
                    color={'primary'}
                    variant={'text'}
                    endIcon={<ArrowForward />}
                    style={{
                      padding: 0,
                      textTransform: 'none',
                      textDecoration: 'underline',
                    }}
                    onClick={() => null}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
};
