import create from 'zustand';
import { persist } from 'zustand/middleware';

interface DriverInfo {
  name: string;
  medio: string;
  telefono: string;
  email: string;
  genero: string;
  fechaNacimiento: any;
  codigoPostal: string;
  numCotizacion: string;
  emailCotizacion: string;
  recaptcha: string;
}

interface Driver {
  driver: DriverInfo;
  setDriver: (driver: DriverInfo) => void;
  code: string;
  setCode: (code: string) => void;
}

export const initialDriver = {
  name: '',
  medio: '',
  telefono: '',
  email: '',
  genero: '',
  fechaNacimiento: null,
  codigoPostal: '',
  numCotizacion: '',
  emailCotizacion: '',
  recaptcha: '',
  coppelEmployeeId: '',
}

export const useDriverStore = create<Driver>(
  //@ts-ignore
  persist(
    (set) => ({
      driver: {
        name: '',
        medio: '',
        telefono: '',
        email: '',
        genero: '',
        fechaNacimiento: null,
        codigoPostal: '',
        numCotizacion: '',
        emailCotizacion: '',
        recaptcha: '',
      },
      code: '',
      setDriver: (driver) => {
        set({ driver });
      },
      setCode: (code) => {
        set({ code });
      },
    }),
    {
      name: 'driverInfo-storage',
      getStorage: () => sessionStorage,
    }
  )
);
