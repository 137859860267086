import { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import TagManager from 'react-gtm-module';
import { useTrackingStore } from '../store/TrackingStore';
import { useTokenStore } from '../store/TokenStore';
import { usePolicyStore } from '@store/policyStore';
import { useVisibleButtonHelp } from '@hooks/useVisibleButtonHelp';
import { SlideShow } from '@screens/home/slideshow/SlideShow';
import FirstSlide from '@assets/images/firstBlueSlide.jpg';
import SecondSlide from '@assets/images/secondBlueSlide.jpg';
import { SlideShowItem } from '@models/ui/SlideShowItem';
import { Plans } from '@screens/home/benefits/Plans';
import { EnsuranceMoments } from '@screens/home/ensuranceMoments';
import { InsuranceLogos } from '@screens/home/newLanding/insurances/InsuranceLogos';
import { useParams } from 'react-router-dom';
import { useIdStore } from '../store/UrlIdStore';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    paddingBottom: '40px',
    backgroundSize: '20%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundPositionY: '35%',
    backgroundColor: '#f4f4f4'
  },
});

const DataLayer = {
  event: 'CSform_step_0_view',
  eventCategory: 'Formulario.Seguro.Auto.v2',
  eventAction: 'step_0_view',
  eventLabel: undefined,
  telefono: undefined,
  correo: undefined,
  noCotizacion: undefined,
  modelo: undefined,
  marca: undefined,
  anio: undefined,
  paquete: undefined,
  forma_pago: undefined,
  aseguradora: undefined,
  tarjeta: undefined,
  tipo_tarjeta: undefined,
  tipo_vehiculo: undefined,
  colaborador: undefined,
  cupon: undefined,
};

const tagManagerArgs = {
  dataLayer: DataLayer,
};
interface Props {
  showInfo: Function;
}

const items: SlideShowItem[] = [
  {
    title: 'Compara y contrata el seguro de tu vehículo en un sólo lugar',
    image: FirstSlide,
    subtitle: 'Fácil, rápido y seguro',
  },
  {
    title: 'Encuentra un seguro de auto, moto y pickup',
    image: SecondSlide,
  },
];

type UrlParams = {
  id: string;
}

export const Home = ({
}: Props) => {
  const { id: tmpId } = useParams<UrlParams>()
  const classes = useStyles();
  const { setToken, token } = useTokenStore();
  const { tracking, setDataTracking } = useTrackingStore();
  const { setEmployeeId } = usePolicyStore();
  const scrollToElementRef = useRef<null | HTMLDivElement>(null);
  const { setId, id} = useIdStore();
  const history = useHistory();

  useEffect(() => {
    if (scrollToElementRef.current) {
      scrollToElementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  
  useEffect(() => {
    if(tmpId){
      setEmployeeId(tmpId)
      setId(tmpId)
      history.replace({pathname: '/'})
      window.location.reload();
    }
    setToken(token);
    setDataTracking(tracking);
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  useVisibleButtonHelp();  

  return (
    <div className={classes.root}>
      <SlideShow items={items} />
      <InsuranceLogos />
      <Plans />
      <EnsuranceMoments />
    </div>
  );
};
