import create from 'zustand';
import { persist } from 'zustand/middleware';

interface ButtonNeedHelpVisibleStore {
    visibleButton: boolean;
    setVisibleButton: (visibleButton: boolean) => void;
}

export const useButtonNeedHelpVisibleStore = create<ButtonNeedHelpVisibleStore>(
    //@ts-ignore
    persist(
        (set) => ({
            visibleButton: true,
            setVisibleButton: (visibleButton: boolean) => {
                set({ visibleButton });
            },
        }),
        {
            name: 'buttonNeedHelp-storage',
            getStorage: () => sessionStorage,
        }
    )
);





