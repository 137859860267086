import { useContext } from 'react';
import React from 'react';
import { Box, Button } from '@mui/material';
import { ZustandContext } from '@store/modalStore';
import { ModalPay } from '@modals/ModalPay';
import TimeSVG from '@assets/images/TimeIcon.svg';

import TagManager from 'react-gtm-module';
import { useCarStore } from '@store/carInfoStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useSelectedQuoteStore } from '@store/QuoteStore';

import { upCRM } from '@utils/CRM';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { usePolicyStore } from '@store/policyStore';
import { useCPStore } from '@store/cpStore';
import { useHistory } from 'react-router-dom';
import { getPayment } from '@api/getPayment';
import { getPoliza } from '@api/getPoliza';
import { getRecibo } from '@api/getRecibo';
import { useCarListStore } from '@store/carListStore';
import { getTracking } from '@api/getTracking';
import { ModalNext } from '@modals/ModalHome/ModalNext';
import Loading from '@components/Loading/Loading';

interface PagoProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ButtonConfirm: React.FC<PagoProps> = ({ open, setOpen }) => {
  const { show, hide } = useContext(ZustandContext);
  const history = useHistory();

  //DataLayer
  const { car: Car } = useCarStore();
  const { driver } = useDriverStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const { token } = useTokenStore();
  const { policy, addPolicyInfo } = usePolicyStore();
  const { tracking } = useTrackingStore();
  const { cpData } = useCPStore();
  const { carList } = useCarListStore();
  const [loading, setLoading] = React.useState(false);

  const DataLayer = {
    event: 'CSform_step_8_submit',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_8_submit',
    eventLabel: undefined,
    telefono: driver.telefono,
    correo: driver.email,
    noCotizacion: policy.policyNumber,
    modelo: Car.description,
    marca: Car.assembler,
    anio: Car.model,
    paquete: selectedQuote.package,
    forma_pago: selectedQuote.payment_option,
    aseguradora: selectedQuote.insurance,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: Car.type,
    colaborador: policy.employee_id,
    cupon: policy.code,
  };

  const completar = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'CS_interactions',
        eventCategory: 'Formulario.Seguro.Auto.v2',
        eventAction: 'Interacciones',
        eventLabel: 'CompletarMasTarde_3', //Texto del botón al que se hizo clic.
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'coche_seguro_completar_mas_tarde', //Static data
        no_cotizacion: policy.policyNumber,
        paso: 'Paso 4',
        boton_contratante: 'Completar más tarde',
      },
    });
    show(ModalNext, { onClose: hide });
  };

  const handleClick = () => {
    if (selectedQuote.payment_option === 'M') {
      setOpen(true);
    } else {
      setOpen(false);
    }
    TagManager.dataLayer({ dataLayer: DataLayer });
    TagManager.dataLayer({
      dataLayer: {
        event: 'coche_seguro_confirmar_compra',
        no_cotizacion: policy.policyNumber,
        modalidad_pago: selectedQuote.payment_options_json[0].label,
        plan: policy.package_label,
        colaborador: policy.employee_id,
        codigo_promocion: policy.code,
        aseguradora: selectedQuote.insurance,
        costo_anual: selectedQuote.insurance_premium.total,
        boton_confirmar: '¡Ya Quiero Pagar!',
      },
    });
    policy.stage = '3. Contratación (Coche)';
    policy.sub_stage = '6. Datos Bancarios';
    policy.state_code = 'Activa';
    policy.status_code = 'En curso';
    addPolicyInfo({ ...policy });
    let initialData = {
      id: tracking.id,
      business: 'creditaria',
      data: {
        car: Car,
        driver: driver,
        selectedQuote: selectedQuote,
        policy: policy,
        cpData: cpData,
      },
    };
    getTracking(token, initialData);
    upCRM(
      token,
      Car,
      driver,
      selectedQuote,
      policy,
      tracking,
      cpData,
      selectedQuote
    );
    if (selectedQuote.insurance !== 'zurich') {
      show(ModalPay, { onClose: hide });
    } else {
      setLoading(true);
      policy.stage = '3. Contratación (Coche)';
      policy.sub_stage = '6. Datos Bancarios';
      policy.state_code = 'Lograda';
      policy.status_code = 'Lograda';
      addPolicyInfo({ ...policy });
      upCRM(
        token,
        Car,
        driver,
        selectedQuote,
        policy,
        tracking,
        cpData,
        selectedQuote
      );
      // TagManager.dataLayer({
      //   dataLayer: {
      //     event: 'coche_seguro_confirmar_compra',
      //     no_cotizacion: policy.policyNumber,
      //     modalidad_pago: policy.payment_label,
      //     colaborador: policy.employee_id ? 'Si' : 'No',
      //     codigo_promocion: policy.employee_id ? policy.code : '',
      //     aseguradora: selectedQuote.insurance.toUpperCase(),
      //     costo_anual: selectedQuote.insurance_premium.total,
      //     boton_confirmar: 'Ya Quiero Pagar',
      //   },
      // });
      (async () => {
        let date = new Date();
        let day = `${date.getDate()}`.padStart(2, '0');
        let month = `${date.getMonth() + 1}`.padStart(2, '0');
        let year = date.getFullYear();
        let fechaHoy = `${year}-${month}-${day}`;
        const Quote = {
          tracking_id: tracking.id,
          quote_id: selectedQuote.quote_id,
          business: 'creditaria',
          insurance: selectedQuote.insurance,
          package: selectedQuote.package,
          payment_plan: '',
          payment_option: selectedQuote.payment_option,
          payment_card: '',
          driver_first_name: policy.driver.firstName || driver.name,
          driver_last_name: policy.driver.middleName,
          driver_second_last_name: policy.driver.lastName,
          driver_phone: driver.telefono,
          driver_mail: driver.email,
          driver_gender: driver.genero === 'Hombre' ? 'M' : 'F',
          driver_birth_date: driver.fechaNacimiento,
          driver_location: '',
          driver_rfc: policy.driver.RFC,
          driver_zip_code: driver.codigoPostal,
          driver_state_code: policy.driver.stateCode,
          driver_township_code: cpData[0].township_code,
          driver_suburb_code: policy.driver.suburbCode,
          driver_street: policy.street,
          driver_ext: policy.numeroExterior,
          driver_int: policy.numeroInterior,
          vehicle_id: Car.vehicle_id,
          vehicle_service: 'PARTICULAR',
          vehicle_use: 'NORMAL',
          vehicle_vin: policy.Niv,
          vehicle_plate: policy.plate,
          vehicle_type: Car.type.toUpperCase(),
          vehicle_model: Car.model,
          legal_agreement: [
            {
              agreement:
                'Estoy de acuerdo con la descripción del vehículo seleccionada y misma que  se  muestra  en  la  parte superior de esta pantalla.',
              acceptance: true,
            },
            {
              agreement:
                'Mi vehículo no es utilizado ni será utilizado para presentar servicios de entrega por paquetería, a domicilio y/o similares.',
              acceptance: true,
            },
            {
              agreement:
                'No he recibido indemnización alguna de parte de alguna aseguradora derivado de la pérdida total de mi vehículo.',
              acceptance: true,
            },
            {
              agreement:
                'Mi vehículo no se encuentra chocado o siniestrado de alguna forma.',
              acceptance: true,
            },
            {
              agreement: 'He leído y acepto el Aviso de privacidad.',
              acceptance: true,
            },
            {
              agreement:
                'Estoy de acuerdo con las Condiciones Generales y Términos de Uso.',
              acceptance: true,
            },
          ],
          contractor: policy.selectorDriver === 'No' ? 'false' : 'true',
          contractor_first_name: policy.contrator?.firstName,
          contractor_lastname: policy.contrator?.middleName,
          contractor_second_lastname: policy.contrator?.lastName,
          contractor_birth_date: policy.contrator?.fechaNacimiento,
          contractor_rfc: policy.contrator?.RFC,
          contractor_email: policy.contrator?.email,
          contractor_phone: policy.contrator?.telefono,
          contractor_gender: policy.contrator?.genero === 'Hombre' ? 'M' : 'F',
          contractor_zip_code: policy.taxResidence.zip,
          custom_DM: policy.customDM,
          custom_RT: policy.customRT,
          contractor_state_code: policy.taxResidence.estado,
          contractor_township_code: policy.taxResidence.city,
          contractor_suburb_code: policy.taxResidence.colonia,
          contractor_street: policy.taxResidence.street,
          contractor_ext: policy.taxResidence.numeroExterior,
          contractor_int: policy.taxResidence.numeroInterior,
          legal_zip_code: policy.taxResidence.zip,
          legal_state_code: policy.taxResidence.estado,
          legal_township_code: policy.taxResidence.city,
          legal_suburb_code: policy.taxResidence.colonia,
          legal_street: policy.taxResidence.street,
          legal_ext: policy.taxResidence.numeroExterior,
          legal_int: policy.taxResidence.numeroInterior,
          invoice: policy.bill,
          cfdi_tax_regime: policy.regimen || '616',
          use_tax_regime: 'G03',
          promo_code: policy.code,
          associate_id: policy.employee_id,
          emision_date: fechaHoy,
          start_at: fechaHoy,
        };

        const { data } = await getPayment(token, Quote);
        var dataZurich = {
          Poliza: data.policy,
          insurance: selectedQuote.insurance,
          tracking_id: tracking.id,
        };

        let dataRecibo = await getRecibo(token, dataZurich);
        let dataPoliza = await getPoliza(token, dataZurich);
        policy.url_poliza = dataPoliza?.data?.policy_url;
        policy.url_recibo = dataRecibo?.data?.receipts_url;
        policy.no_poliza = data.policy;
        await setLoading(false);
        await addPolicyInfo({ ...policy });
        await upCRM(
          token,
          Car,
          driver,
          selectedQuote,
          policy,
          tracking,
          cpData,
          selectedQuote
        );
        history.push('zurichPayment');
      })();
    }
  };

  return (
    <Box marginTop={3}>
      <Loading open={loading} handleClose={() => true} />
      <Button
        variant="outlined"
        onClick={completar}
        size={'medium'}
        style={{
          textTransform: 'none',
          height: '55px',
          borderRadius: '10px',
          width: '100%',
          marginBottom: 20,
        }}
      >
        Completar más tarde
        <Box
          component="img"
          src={TimeSVG}
          alt="digicert"
          width="20px"
          height="20px"
          sx={{ marginLeft: 2 }}
        />
      </Button>
      <Button
        variant="contained"
        onClick={handleClick}
        size={'medium'}
        style={{
          textTransform: 'none',
          height: '55px',
          borderRadius: '10px',
          width: '100%',
        }}
      >
        ¡Ya quiero pagar!
      </Button>
    </Box>
  );
};
