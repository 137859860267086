import create from 'zustand';
import { persist } from 'zustand/middleware';

interface DataPromotion {
  id: string;
  title: string;
  description: string;
  code: string;
  total: number;
  start_at: any;
  end_at: any;
  promo_picture: string;
    doc_name: string;
    type_promotion: string;
    amount: number;
    updated_by: string;
    created_by: string;
    updated_at: string;
    created_at: string;
}

interface Selection {
  selected: string;
}

interface PromoData {
    name: string,
    promotion_id: string,
    email: string,
    folio:string,
}

interface Promotion {
  infoPromotion: DataPromotion;
  dataPromo: PromoData;
  select: Selection;
  setPromotion: (infoPromotion: DataPromotion) => void;
  sendPromotion: (dataPromo: PromoData) => void;
  setChooseSelected: (select: Selection) => void;
}

export const usePromotionStore = create<Promotion>(
  //@ts-ignore
  persist(
    (set) => ({
        infoPromotion: {
            id: '',
            title: '',
            description: '',
            code: '',
            total: 0,
            start_at: 0,
            end_at: 0,
            promo_picture: '',
            doc_name: '',
            type_promotion: '',
            amount: 0,
            updated_by: '',
            created_by: '',
            updated_at: '',
            created_at: '',
        },
      setPromotion: (infoPromotion) => {
        set({ infoPromotion });
      },
      dataPromo: {
          name: '',
          promotion_id: '',
          email: '',
          folio:''
      },
      sendPromotion: (dataPromo) => {
        set({ dataPromo });
      },
      select: {
        selected: '',
      },
      setChooseSelected: (select) => {
        set({ select });
      },
    }),
    {
      name: 'promotion-storage',
      getStorage: () => sessionStorage,
    }
  )
);
