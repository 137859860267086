import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Theme,
  Container,
  Box,
  useTheme,
  useMediaQuery,
  Grid, Button,
} from '@mui/material';

import TagManager from 'react-gtm-module';

import { Header } from '@navigationMenu/Header';
import { Code } from '@screens/payment/Code';
import { useChangePrice } from '@hooks/useChangePrice';
import { useCarStore } from '@store/carInfoStore';
import { usePolicyStore } from '@store/policyStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { useTrackingStore } from '@store/TrackingStore';
import { useTokenStore } from '@store/TokenStore';
import { useCPStore } from '@store/cpStore';
import { getPayment } from '@api/getPayment';
import { getVIN } from '@api/getVIN';
import { payment } from '@api/payment';
import {upCRM, upCRMContact} from '@utils/CRM';
import { useHistory } from 'react-router-dom';
import { useCarListStore } from '@store/carListStore';
import { getTracking } from '@api/getTracking';
import Loading from '@components/Loading/Loading';
import { getPoliza } from '@api/getPoliza';
import { CustomButton } from '@buttons/CustomButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import StickyButton from "@buttons/StickyButton";
import {useButtonNeedHelpVisibleStore} from "@store/buttonNeedHelpVisible";
import {NameSubstring} from "@utils/utilityFunctions";
import {useVisibleButtonHelp} from "@hooks/useVisibleButtonHelp";
import AlertAntiFraudWarning from "@components/antiFraud/AlertAntiFrauWarning";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#f5f5f5',
    paddingBottom: '60px',
  },
}));

export const Payment: React.FC = () => {
  const classes = useStyles();

  const { breakpoints, palette } = useTheme();

  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const [url, setUrl] = useState<any>('');

  const [success, setSuccess] = useState(false);
  const [loadingSticky, setLoadingSticky] = useState(false);

  //DataLayer
  const { car: Car } = useCarStore();
  const { driver } = useDriverStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const { policy, addPolicyInfo } = usePolicyStore();
  const { tracking } = useTrackingStore();
  const { token } = useTokenStore();
  const { cpData } = useCPStore();
  const history = useHistory();
  const { carList } = useCarListStore();
  const [loading, setLoading] = React.useState(false);
  const insurance = selectedQuote.insurance;

  const DataLayer = {
    event: 'CSform_step_9_view',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_9_view',
    eventLabel: undefined,
    telefono: driver.telefono,
    correo: driver.email,
    noCotizacion: policy.policyNumber,
    modelo: Car.description,
    marca: Car.assembler,
    anio: Car.model,
    paquete: selectedQuote.package,
    forma_pago: selectedQuote.payment_option,
    aseguradora: selectedQuote.insurance,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: Car.type,
    colaborador: policy.employee_id,
    cupon:policy.code
  };
  function handleIframe() {
    setLoading(true);
    (async () => {
      let qdata = {
        business: 'creditaria',
        insurance: selectedQuote.insurance,
        quote_id: selectedQuote.quote_id,
      };
      let initialData = {
        id: tracking.id,
        business: 'creditaria',
        data: {
          car: Car,
          driver: driver,
          selectedQuote: selectedQuote,
          policy: policy,
          cpData: cpData
        },
      };
      getTracking(token, initialData);
      
      setInterval(function() {
        (async () => {
          const { data } = await payment(token, qdata);
          if(data==undefined && qdata?.insurance=='aig'){
            history.push('/welcome3');
          }
              if (data.policy_paid) {
                var dataInfo = {
                  Poliza: data.policy_id,
                  insurance: selectedQuote.insurance,
                  tracking_id: tracking.id,
                };
                let dataPoliza = await getPoliza(token, dataInfo);
                setLoading(false);
                policy.stage = '4. Emisión (Coche)';
                policy.sub_stage = '7. Pago';
                policy.state_code = 'Lograda';
                policy.status_code = 'Lograda';
                policy.url_poliza = dataPoliza?.data?.policy_url;
                policy.no_poliza = data.policy_id;
                addPolicyInfo({ ...policy });
                upCRM(
                  token,
                  Car,
                  driver,
                  selectedQuote,
                  policy,
                  tracking,
                  cpData,
                  selectedQuote,
                  url
                );
                history.push('/welcome');
              } else {
                setLoading(false);
                policy.stage = '3. Contratación (Coche)';
                policy.sub_stage = '6. Datos Bancarios';
                policy.state_code = 'Activa';
                policy.status_code = 'Lograda';
                addPolicyInfo({ ...policy });
                upCRM(
                  token,
                  Car,
                  driver,
                  selectedQuote,
                  policy,
                  tracking,
                  cpData,
                  selectedQuote,
                  url
                );
              }
          })();
      }, 10000);
    })();
  }

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      TagManager.dataLayer({ dataLayer: DataLayer });
      const { data } = await getVIN(policy.Niv, insurance, token);
      if (!data?.is_active_policy) {
        let date = new Date();
        let day = `${date.getDate()}`.padStart(2, '0');
        let month = `${date.getMonth() + 1}`.padStart(2, '0');
        let year = date.getFullYear();
        let fechaHoy = `${year}-${month}-${day}`;
        const Quote = {
          tracking_id: tracking.id,
          quote_id: selectedQuote.quote_id,
          business: 'creditaria',
          insurance: selectedQuote.insurance,
          package: selectedQuote.package,
          payment_plan: '',
          payment_option: selectedQuote.payment_option,
          payment_card: '',
          driver_first_name: policy.driver.firstName || driver.name,
          driver_last_name: policy.driver.middleName,
          driver_second_last_name: policy.driver.lastName,
          driver_phone: driver.telefono,
          driver_mail: driver.email,
          driver_gender: driver.genero === 'Hombre' ? 'M' : 'F',
          driver_birth_date: driver.fechaNacimiento,
          driver_location: '',
          driver_rfc: policy.driver.RFC,
          driver_zip_code: driver.codigoPostal,
          driver_state_code: policy.driver.stateCode,
          driver_township_code: cpData[0].township_code,
          driver_suburb_code: policy.driver.suburbCode,
          driver_street: policy.street,
          driver_ext: policy.numeroExterior,
          driver_int: policy.numeroInterior,
          vehicle_id: Car.vehicle_id,
          vehicle_service: 'PARTICULAR',
          vehicle_use: 'NORMAL',
          vehicle_vin: policy.Niv,
          vehicle_plate: policy.plate,
          vehicle_type: Car.type.toUpperCase(),
          vehicle_model: Car.model,
          legal_agreement: [
            {
              agreement:
                'Estoy de acuerdo con la descripción del vehículo seleccionada y misma que  se  muestra  en  la  parte superior de esta pantalla.',
              acceptance: true,
            },
            {
              agreement:
                'Mi vehículo no es utilizado ni será utilizado para presentar servicios de entrega por paquetería, a domicilio y/o similares.',
              acceptance: true,
            },
            {
              agreement:
                'No he recibido indemnización alguna de parte de alguna aseguradora derivado de la pérdida total de mi vehículo.',
              acceptance: true,
            },
            {
              agreement:
                'Mi vehículo no se encuentra chocado o siniestrado de alguna forma.',
              acceptance: true,
            },
            {
              agreement: 'He leído y acepto el Aviso de privacidad.',
              acceptance: true,
            },
            {
              agreement:
                'Estoy de acuerdo con las Condiciones Generales y Términos de Uso.',
              acceptance: true,
            },
          ],
          contractor: policy.selectorDriver === 'No' ? 'false' : 'true',
          contractor_first_name: policy.contrator?.firstName,
          contractor_lastname: policy.contrator?.middleName,
          contractor_second_lastname: policy.contrator?.lastName,
          contractor_birth_date: policy.contrator?.fechaNacimiento,
          contractor_rfc: policy.contrator?.RFC,
          contractor_email: policy.contrator?.email,
          contractor_phone: policy.contrator?.telefono,
          contractor_gender: policy.contrator?.genero === 'Hombre' ? 'M' : 'F',
          contractor_zip_code: policy.taxResidence.zip,
          contractor_state_code: policy.taxResidence.estado,
          contractor_township_code: policy.taxResidence.city,
          contractor_suburb_code: policy.taxResidence.colonia,
          contractor_street: policy.taxResidence.street,
          contractor_ext: policy.taxResidence.numeroExterior,
          contractor_int: policy.taxResidence.numeroInterior,
          custom_DM: policy.customDM,
          custom_RT: policy.customRT,
          legal_zip_code: policy.taxResidence.zip,
          legal_state_code: policy.taxResidence.estado,
          legal_township_code: policy.taxResidence.city,
          legal_suburb_code: policy.taxResidence.colonia,
          legal_street: policy.taxResidence.street,
          legal_ext: policy.taxResidence.numeroExterior,
          legal_int: policy.taxResidence.numeroInterior,
          promo_code: policy.code,
          associate_id: policy.employee_id,
          invoice: policy.bill,
          cfdi_tax_regime: policy.regimen || '616',
          use_tax_regime: 'G03',
          emision_date: fechaHoy,
          start_at: fechaHoy,
        };
        try {
          const { data } = await getPayment(token, Quote);
          if (data) {
            await setUrl(data?.payment_data?.service_url);
            setLoading(false);
            if (selectedQuote.insurance === 'aba') {
              window.location.href = data?.payment_data?.service_url;
            }
          } else if (data === undefined) {
            if (
              policy.Niv === '' ||
              policy.contrator?.firstName === '' ||
              policy.driver.firstName === '' ||
              policy.street === '' ||
              Car.assembler === '' ||
              driver.codigoPostal === ''
            ) {
              history.push('/hiring');
            } else {
              history.push('/welcome3');
            }
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          if (
            policy.Niv === '' ||
            policy.contrator?.firstName === '' ||
            policy.driver.firstName === '' ||
            policy.street === '' ||
            Car.assembler === '' ||
            driver.codigoPostal === ''
          ) {
            history.push('/hiring');
          } else {
            history.push('/welcome3');
          }
          setLoading(false);
        }
      } else {
        if (
          policy.Niv === '' ||
          policy.contrator?.firstName === '' ||
          policy.driver.firstName === '' ||
          policy.street === '' ||
          Car.assembler === '' ||
          driver.codigoPostal === ''
        ) {
          setLoading(false);
          history.push('/hiring');
        } else {
          if (selectedQuote.insurance === data.insurance) {
            var dataInfo = {
              Poliza: data.policy_id,
              insurance: selectedQuote.insurance,
              tracking_id: tracking.id,
            };
            let dataPoliza = await getPoliza(token, dataInfo);
            policy.url_poliza = dataPoliza?.data?.policy_url;
            addPolicyInfo({ ...policy });
            setLoading(false);
            history.push('/welcome');
          } else {
            setLoading(false);
            history.push('/welcome3');
          }
        }
      }
    })();
  }, []);
  useChangePrice();



  const sendApi = async () => {
    try {
      setLoadingSticky(true);
      let response = await upCRMContact(
          token,
          Car,
          driver,
          selectedQuote,
          policy,
          tracking,
          cpData,
          selectedQuote,
          url
      );
      setTimeout(() => {
        if (response.success) {
          setLoadingSticky(false);
          setSuccess(true);
        } else {
          setLoadingSticky(false);
        }
      }, 1000);
    } catch (e) {
      setLoadingSticky(false);
      setSuccess(false);
    }
  };

  const title = (
      <p style={{ margin: 0 }}>
        Hola 👋 <strong>{NameSubstring(driver.name)}</strong> ¿Necesitas ayuda con el pago de tu
        seguro?
      </p>
  );
  useVisibleButtonHelp();
  return (
    <div className={classes.root}>
      <Loading open={loading} handleClose={() => true} />
      <Container maxWidth={'lg'}>
        <Box
            sx={{ flexGrow: 1 }}
            style={{
              marginTop: 100,
              position: 'relative',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start'
            }}
        >
          <CustomButton
              text={'Regresar'}
              onClick={() => history.push('/insurer-details')}
              size={isDesktop ? 'medium' : 'small'}
              variant={'text'}
              startIcon={<KeyboardArrowLeftIcon />}
              style={{
                color: '#006782',
                fontSize: isMobile ? 14 : 16,
                textTransform: 'none',
                textDecoration: 'underline',
              }}
          />
          <Button
              startIcon={<AddIcon sx={{color: '#006782'}}  />}
              onClick={() => {
                history.push('/home');
              }}
              variant="outlined"
              style={{
                textTransform: 'none',
                backgroundColor: '#FFFFFF',
                padding: '14px 19px 14px 19px',
                borderRadius: '4px',
                height: '40px',
                color: '#006782',
                border: '1px solid #006782'
              }}
          >
            Nueva cotización
          </Button>
        </Box>
      </Container>
      <Container maxWidth={'lg'}>
        <Box
          sx={{ flexGrow: 1 }}
          padding={isMobile ? 0 : 2}
          style={{
            position: 'relative',
            minHeight: '70vh',
            marginTop: 10,
          }}
        >
          <Header title="Método de pago" subtitle="" />
          <AlertAntiFraudWarning  customStyles={{
            margin: {xs :'5% 0% 0% 0%',sm :'5% 0% 0% 0%', md :'2% 15% 2% 15%'}
          }}/>
          <Grid container marginTop={2}>
            <Grid xs={12} md={12} lg={6} style={{ position: 'relative' }}>
              <Box
                sx={{
                  height: '90vh',
                }}
              >
                <iframe
                  style={{
                    width: '100%',
                    height: '100%',
                    border: '0px',
                    position: 'relative',
                  }}
                  onLoad={handleIframe}
                  src={url}
                  title="payment"
                  id="payment"
                />
              </Box>
            </Grid>
            <Grid xs={12} md={12} lg={6}>
              <Box marginLeft={isTablet ? 0 : 8}>
                {policy.Niv !== '' &&
                  policy.contrator?.firstName !== '' &&
                  policy.driver.firstName !== '' &&
                  policy.street !== '' &&
                  Car.assembler !== '' &&
                  driver.codigoPostal !== '' && <Code />}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      {
          //isMobile &&
          <StickyButton
              loading={loadingSticky}
              success={success} action={sendApi} title={title} />
      }
    </div>
  );
};
