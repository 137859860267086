import { InterInstance } from '@api/config';
import { generateDate } from '@utils/generateDate';
import Constants from '@utils/constants';
import { tConvert, transformDate } from '@utils/timeUtils';

//hay que refactorizar italo
interface IContact {
  name: string;
  telefono: string;
  email: string;
  genero: string;
  fechaNacimiento?: Date | null | string;
  codigoPostal: string;
}

interface IVehicle {
  vehicle_id: string;
  description: string;
  assembler: string;
  model: string | number;
  type: string;
  insurers: string[];
}

interface ICP {
  zip_code: string;
  state_code: string;
  state_name: string;
  suburb_code: string;
  suburb_name: string;
  city_name: string;
  township_code: string;
  township_name: string;
}

export const getQuoteCreated = async (
  token: string,
  tracking_id: string,
  insurance: string,
  contact: IContact,
  vehicle: IVehicle,
  CP: ICP,
  Package: string,
  payment_option: string,
  customRT: string = vehicle.type.toUpperCase() === 'MOTO'? '20':'10',
  customDM: string = vehicle.type.toUpperCase() === 'MOTO'? '10':'5',
  retries?: string[],
) => {
  const { startDate } = generateDate();
  const contact_gender = contact.genero === 'Mujer' ? 'F' : 'M';

  let contact_birth_date;

  if (contact.fechaNacimiento === 'object') {
    const date = (contact_birth_date = transformDate(contact.fechaNacimiento)
      .split('/')
      .reverse()
      .join('-'));

    contact_birth_date = date;
  } else {
    contact_birth_date = contact.fechaNacimiento?.toString().slice(0, 10);
  }

  const Quote = {
    tracking_id,
    business: Constants.BUSINESS_CAR,
    insurance,
    contact_name: contact.name,
    contact_method: 'EMAIL',
    contact_phone: contact.telefono,
    contact_email: contact.email,
    contact_gender,
    contact_birth_date,
    contact_state_code: CP.state_code,
    contact_township_code: CP.township_code,
    contact_suburb_code: CP.suburb_code,
    contact_zip_code: CP.zip_code,
    contact_now: true,
    vehicle_id: vehicle.vehicle_id,
    vehicle_service: 'PARTICULAR',
    vehicle_use: 'NORMAL',
    vehicle_type: vehicle.type.toUpperCase(),
    payment_option,
    package: Package,
    promotion_code: '',
    associate_id: '',
    quote_date: startDate,
    start_at: startDate,
    custom_RT: customRT,
    custom_DM: customDM
  };

  retries && retries.push(insurance)
  const countRetries = retries ? retries.filter(item => item === insurance).length : 0

  if(countRetries <= 2){
    const result = await InterInstance.post('/quote', Quote, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const res = { ...result };
    res.data.data.insurance = insurance;
    res.data.data.vehicle = vehicle;
    if (insurance === 'mapfre'){
      res.data.data.insurance_premium.total = parseFloat(res.data.data?.insurance_premium?.net_premium)  + parseFloat(res.data.data?.insurance_premium?.right) + parseFloat(res.data.data?.insurance_premium?.surcharge) + parseFloat(res.data.data?.insurance_premium?.tax);
    }else if (insurance === 'qualitas'){
      res.data.data.insurance_premium.total = parseFloat(res.data.data?.insurance_premium?.net_premium)  + parseFloat(res.data.data?.insurance_premium?.right) + parseFloat(res.data.data?.insurance_premium?.surcharge) + parseFloat(res.data.data?.insurance_premium?.tax);
    }else if (insurance === 'hdi'){
      res.data.data.insurance_premium.total = parseFloat(res.data.data?.insurance_premium?.net_premium) + parseFloat(res.data.data?.insurance_premium?.commission) + parseFloat(res.data.data?.insurance_premium?.right) + parseFloat(res.data.data?.insurance_premium?.surcharge) + parseFloat(res.data.data?.insurance_premium?.tax) + parseFloat(res.data.data?.insurance_premium?.discount);
    }else if (insurance === 'aba'){
      res.data.data.insurance_premium.total = parseFloat(res.data.data?.insurance_premium?.net_premium) + parseFloat(res.data.data?.insurance_premium?.commission) + parseFloat(res.data.data?.insurance_premium?.right) + parseFloat(res.data.data?.insurance_premium?.surcharge) + parseFloat(res.data.data?.insurance_premium?.tax) - parseFloat(res.data.data?.insurance_premium?.discount);
    }else if (insurance === 'aig' && payment_option !='A' ){
      res.data.data.insurance_premium.total = parseFloat(res.data.data?.insurance_premium?.net_premium) + parseFloat(res.data.data?.insurance_premium?.commission)  + parseFloat(res.data.data?.insurance_premium?.tax);
    } else {
      res.data.data.insurance_premium.total = parseFloat(res.data.data?.insurance_premium?.net_premium) + parseFloat(res.data.data?.insurance_premium?.commission) + parseFloat(res.data.data?.insurance_premium?.right) + parseFloat(res.data.data?.insurance_premium?.surcharge) + parseFloat(res.data.data?.insurance_premium?.tax);
    }
    res.data.data.package = Package;
    res.data.data.payment_option = payment_option;
    return { ...res };
  }

  throw Error
};
