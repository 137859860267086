import { Edit } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import { getVehicleType } from '@utils/vehicleType';
import { useCarStore } from '@store/carInfoStore';
import DriverSelectInfo from "@screens/plans/form/DriverSelectInfo";
import { useClamp } from "@utils/useClamp";
import { NumeroCotizacion } from "./NumeroCotizacion";
import { ModalChangeVehicle } from "@modals/modalChangeVehicle";
import { ZustandContext } from '@store/modalStore';
import { useContext } from "react";

export function VehicleData(){
    const { car } = useCarStore();
    const { clamp } = useClamp();
    const { show, hide } = useContext(ZustandContext);

    const vehicletype = car.type ? car.type : '';

    return (
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <Grid container style={{
                backgroundColor: 'transparent',
                marginTop: '6px',
                width: '1200px',
                padding: '0px 24px'
            }}>
                { clamp(<></>, <></>, <NumeroCotizacion />) }
                <Grid item container xs={12} sm={6} md={6} lg={6} xl={6} paddingRight={clamp("12px", "12px", "0px")}>
                    <Grid item sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="caption" textAlign={'start'} sx={{ fontSize: '14px', fontWeight: '600' }}>Datos del vehículo</Typography>
                        <Edit onClick={() => show(ModalChangeVehicle, { onClose: hide })} sx={{ cursor: 'pointer', color: '#333436' }} />
                    </Grid>
                    <Grid item sx={{ height: '105px', display: 'flex', alignItems: 'center', background: "white", width: '100%', paddingLeft: '30px', marginTop: '16px', borderRadius: '8px' }}>
                        <Box
                            component={'img'}
                            src={getVehicleType(vehicletype)}
                            sx={{
                                width: '100px',
                            }}
                            alt={'Camioneta Logo'}
                            style={{ objectFit: 'cover', borderRadius: 8, marginRight: '28px' }}
                        />
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography
                                sx={{ fontWeight: '600', color: '#212121', fontSize: '14px' }}
                            >
                                {`${car.assembler}`}
                            </Typography>
                            <Typography
                                sx={{ fontWeight: '400', color: '#212121', fontSize: '13px' }}
                            >
                                {`${car.description}, ${car.model}`}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item container xs={12} sm={6} md={6} lg={6} xl={6} paddingLeft={clamp("12px", "12px", "0px")}>
                    <DriverSelectInfo />
                </Grid>
            </Grid>
        </Box>
    )
}