import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Header } from '@navigationMenu/Header';
import { useDriverStore } from '@store/driverInfoStore';
import { PolicyNumber } from '@screens/hiring/confirm/policyNumber/PolicyNumber';
import AddIcon from '@mui/icons-material/Add';
import {ChevronLeft} from "@mui/icons-material";

interface Props {
    isInsurerDetail?: boolean
}

export const HeaderPlans = ({isInsurerDetail} : Props) => {
  const { driver } = useDriverStore();
  const history = useHistory();

  return (
      <>
          {
              isInsurerDetail && (
                  <Button
                      onClick={() => history.push('/plans')}
                      variant={'text'}
                      startIcon={<ChevronLeft fontSize="large" />}
                      style={{
                          color: '#006782',
                          fontSize: '16px',
                          textTransform: 'none',
                          fontWeight: '400',
                          marginBottom: '20px',
                          textDecoration: 'underline'
                      }}
                  >
                      Regresar a mis opciones
                  </Button>
              )
          }
          <Grid container sx={{ display: 'flex', justifyContent: 'space-between', rowGap: '24px' }}>
              <Grid item xs={12} sm={12} md={6}>
                  <Header
                      title={'¡Todo listo!'}
                      subtitle={
                          'Aquí están todas las opciones que tenemos para ti. ¡Elige tu favorita y personalízala! '
                      }
                  />
              </Grid>
              <Grid item xs={5} sm={6} md={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end', width: isInsurerDetail ? '364px' : '267px'}}>
                  <PolicyNumber />
              </Grid>
              {
                  !isInsurerDetail && (
                      <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                          <Button
                              startIcon={<AddIcon sx={{color: '#006782'}}  />}
                              onClick={() => {
                                  history.push('/home');
                              }}
                              variant="outlined"
                              style={{
                                  textTransform: 'none',
                                  backgroundColor: '#FFFFFF',
                                  padding: '14px 19px 14px 19px',
                                  borderRadius: '4px',
                                  height: '40px',
                                  color: '#006782',
                                  border: '1px solid #006782'
                              }}
                          >
                              Nueva cotización
                          </Button>
                      </Grid>
                  )
              }
          </Grid>
      </>
  );
};
