import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  SelectChangeEvent,
  useTheme,
  Theme,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { data1, data2 } from './data';
import { useQuoteStore } from '@store/QuoteStore';
import { useGetQuotePayment } from '@store/QuoteData';
import { ExpandMore, Info } from '@mui/icons-material';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { useCarStore } from '@store/carInfoStore';
import { useCPStore } from '@store/cpStore';
import { ZustandContext } from '@store/modalStore';
import { useDriverStore } from '@store/driverInfoStore';
import { usePlansDataStore } from '@store/plansStore';
import { usePolicyStore } from '@store/policyStore';
import getAllByPackets from '@api/getAllByPackets';
import TagManager from 'react-gtm-module';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  aseguradotooltip: {
    width: '25%',
  },
  plantooltip: {
    width: '25%',
  },
}));

interface DataInfo {
  title: string;
  description: string;
}

const dataInfo: DataInfo[] = [
  {
    title: 'Tipos de planes',
    description:
      'Existen 3 tipos de planes (Amplio, limitado y básico). Cada una te ofrece diferentes beneficios y costos.',
  },
  {
    title: 'Conductor asegurado',
    description:
      'Los datos del conductor asegurado nos permiten encontrar las mejores opciones para ti. Si los modificas, podrían cambiar las opciones.',
  },
];

interface PropsSelect {
  setCoverage: (coverage: string) => void;
  options?: string;
  setOptions: (options: string) => void;
}

const PlanSelect: React.FC<PropsSelect> = ({
  setCoverage,
  options,
  setOptions,
}) => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const classes = useStyles();
  const { Packages, setPackage } = useGetQuotePayment();
  const [packageId, setPackageId] = useState(Packages.label);

  const [Details, setDetails] = useState({
    Package: '',
    payment_option: 'A',
  });

  const [firstSelect] = useState(data1);
  const [secondSelect] = useState(data2);

  const {
    setQuoteList,
    setIsFetching,
    quoteList,
    isFetching,
    quoteListReactQuery,
  } = useQuoteStore();
  const { token } = useTokenStore();
  const {
    tracking: { id },
  } = useTrackingStore();
  const {
    car: carData,
    car: { insurers },
  } = useCarStore();
  const { cpData } = useCPStore();
  const { show, hide } = useContext(ZustandContext);
  const { driver } = useDriverStore();
  const { car: carInfo } = useCarStore();
  const { plansData } = usePlansDataStore();
  const { policy, addPolicyInfo } = usePolicyStore();

  useEffect(() => {
    (async () => {
      setQuoteList([]);
      setIsFetching(true);
      var actualYear = new Date().getFullYear();
      if (actualYear - parseInt(carData.model) > 20 && policy.basico) {
        setPackage('Plan básico', 'RC');
        policy.package = 'RC';
        policy.payment_option = 'A';
        addPolicyInfo({ ...policy });
      } else {
        if (policy.package === 'AMP') {
          setPackage('Plan amplio', 'AMP');
        } else if (policy.package === 'LIMIT') {
          setPackage('Plan limitado', 'LIMIT');
        } else {
          setPackage('Plan básico', 'RC');
        }
      }
      /*const dataFilter = await getAllByPackets(
        token,
        id,
        driver,
        carData,
        cpData,
        policy.package,
        policy.payment_option
      );
      setQuoteList(dataFilter);*/
      setIsFetching(false);
    })();
  }, [Details]);

  useEffect(() => {
    if (isFetching == false && quoteList.length > 0) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'coche_seguro_cotizacion',
          tipo_vehiculo: carData.type,
          no_cotizacion: policy.policyNumber,
          plan: Packages.label,
          opciones: options,
          celular: driver.telefono,
          email: driver.email,
          items: quoteList.map((quote, idx) => ({
            index: idx,
            aseguradora: quote.insurance,
            costo_aseguradora_anual: quote.insurance_premium.total,
          })),
        },
      });
    }
    let allQuotes = {
      items: quoteList.map((quote, idx) => ({
        index: idx,
        aseguradora: quote.insurance,
      })),
    };
    sessionStorage.setItem('dataPlanes', JSON.stringify(allQuotes));
  }, [isFetching == false, quoteList.length, options, Packages.label]);

  const handleChange = async (event: SelectChangeEvent) => {
    setCoverage(event.target.value as string);
    const plan = event.target.value;
    if (plan === 'Plan amplio') {
      setDetails({
        Package: 'AMP',
        payment_option: 'A',
      });
      policy.basico = false;
      policy.package = 'AMP';
      policy.payment_option = 'A';
      addPolicyInfo({ ...policy });
      setPackage('Plan amplio', 'AMP');
    }
    if (plan === 'Plan limitado') {
      setDetails({
        Package: 'LIMIT',
        payment_option: 'A',
      });
      policy.basico = false;
      policy.package = 'LIMIT';
      policy.payment_option = 'A';
      addPolicyInfo({ ...policy });
      setPackage('Plan limitado', 'LIMIT');
    }
    if (plan === 'Plan básico') {
      setDetails({
        Package: 'RC',
        payment_option: 'A',
      });
      policy.basico = false;
      policy.package = 'RC';
      policy.payment_option = 'A';
      addPolicyInfo({ ...policy });
      setPackage('Plan básico', 'RC');
    }
  };
  const handleChange2 = (event: SelectChangeEvent) => {
    policy.order = event.target.value as string;
    policy.stage = '1. Registro (Coche)';
    policy.sub_stage = '2. Datos Cotización';
    policy.state_code = 'Borrador';
    policy.status_code = 'En revisión';
    policy.customDM = carData.type.toUpperCase() === 'MOTO'? '10':'5';
    policy.customRT = carData.type.toUpperCase() === 'MOTO'? '20':'10';
    addPolicyInfo({ ...policy });
    setOptions(event.target.value as string);
    if (
      event.target.value === 'Todas las opciones' ||
      event.target.value === 'Las opciones más económicas'
    ) {
      quoteList.sort(
        (a, b) =>
          // @ts-ignore
          parseFloat(a.insurance_premium.total) -
          // @ts-ignore
          parseFloat(b.insurance_premium.total)
      );
    }
    if (event.target.value === 'Las opciones más costosas') {
      quoteList.sort(
        (a, b) =>
          // @ts-ignore
          parseFloat(b.insurance_premium.total) -
          // @ts-ignore
          parseFloat(a.insurance_premium.total)
      );
    }
  };

  
  const tooltip = `${dataInfo[0].description}`;

  return (
    <Grid
      item
      xl={3}
      style={{
        width: `${isMobile ? '100%' : isTablet ? '50%' : '33%'}`,
      }}
    >
      <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid>
          <Typography fontSize={14} fontWeight={'600'}>
            {'Estás viendo los planes en'}
          </Typography>
        </Grid>

        <Grid>
          <Tooltip
            title={tooltip}
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: '#F2FAFC',
                  color: '#212121',
                },
              },
            }}
          >
            <Info
              data-for="tooltip"
              data-tip=""
              data-iscapture="true"
              sx={{ cursor: 'pointer', color: '#006782' }}
            />
          </Tooltip>
        </Grid>
      </Grid>

      <Box>
        <Box marginTop={2}>
          <FormControl fullWidth>
            <InputLabel id="plan">Plan</InputLabel>
            <Select
              MenuProps={{
                disableScrollLock: true,
              }}
              disabled={isFetching}
              labelId="plan"
              label="Plan"
              value={
                Packages.label === 'Plan básico'
                  ? 'Plan básico'
                  : Packages.label === 'Plan limitado'
                  ? 'Plan limitado'
                  : 'Plan amplio'
              }
              onChange={handleChange}
              className={classes.root}
              style={{ borderRadius: 8 }}
              IconComponent={(props) => (
                <ExpandMore style={{ color: '#006782' }} {...props} />
              )}
            >
              {firstSelect.map((value, i) => (
                <MenuItem key={i} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box marginTop={1}>
          <FormControl fullWidth>
            <InputLabel id="opciones">Opciones</InputLabel>
            <Select
              MenuProps={{
                disableScrollLock: true,
              }}
              labelId="opciones"
              label="Opciones"
              value={options}
              onChange={handleChange2}
              className={classes.root}
              style={{ borderRadius: 8 }}
              IconComponent={(props) => (
                <ExpandMore style={{ color: '#006782' }} {...props} />
              )}
            >
              {secondSelect.map((value, i) => (
                <MenuItem key={i} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Grid>
  );
};

export default PlanSelect;
