import React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  useMediaQuery,
  useTheme,
  TextField,
  FormControl,
  FormHelperText,
  Typography,
} from '@mui/material';
import { ArrowForward, Check, Error, Warning } from '@mui/icons-material';
import ReactTooltip from 'react-tooltip';

import { CustomButton } from '@buttons/CustomButton';
import { useStyles } from './useStyle';
import { useDriverStore, initialDriver } from '@store/driverInfoStore';
import { useSelectedQuoteStore, defaultQuote } from '@store/QuoteStore';
import { useCarStore, initialCarListData } from '@store/carInfoStore';
import {
  isThereFourConsecutiveNumber,
  allEqual,
} from '@utils/utilityFunctions';

import ReCAPTCHA from 'react-google-recaptcha';
import useValidateFields, { Status, initialValidationResult } from '@utils/validateFields';
import { ValidationResult } from '@utils/validateFields';
import Constants from '@utils/constants';
import { InterInstance } from '@api/config';
import { getToken } from '@api/getToken';
import { useTokenStore } from '@store/TokenStore';
import { usePolicyStore } from '@store/policyStore';
import { useIdStore } from '@store/UrlIdStore';

interface Props {
  elevation?: number;
  showInfo: Function;
}

export const NewQuotation = ({
  elevation,
  showInfo,
}: Props) => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const classes = useStyles({ isMobile, isTablet, isDesktop });

  const { driver, setDriver } = useDriverStore();
  const { policy, addPolicyInfo } = usePolicyStore();
  const { setSelectedQuote } = useSelectedQuoteStore();
  const { setCar: setCarStore } = useCarStore();
  const { token } = useTokenStore();
  const { id } = useIdStore();

  const [recaptcha, setRecaptcha] = useState(false);
  const [errorTelefono, setErrorTelefono] = useState({
    msg: '',
    isError: false,
  });
  const [warningTelefono, setWarningTelefono] = useState({
    msg: '',
    isWarning: false,
  });
  const [errorEmail, setErrorEmail] = useState({
    msg: '',
    isError: false,
  });
  const [errorCheckbox, setErrorCheckbox] = useState({
    msg: '',
    isError: false,
  });
  const [validateFields, setValidateFields] = useState({
    name: false,
    phone: false,
    email: false
  })
  const [dataEmployee, setDataEmployee] = useState({
    name: "",
    employeeId: ""
  });
  const [creditariaEmployeeId, setCreditariaEmployeeId] = useState('');

  useEffect(()=>{
    if(id) {
      asyncLoad(id)
    } else {
      setDriver({
        ...initialDriver
      })
      sessionStorage.setItem('driverInfo-storage', JSON.stringify({
        ...initialDriver
      }))
      setSelectedQuote(defaultQuote)
      sessionStorage.setItem('quote-storage', JSON.stringify(defaultQuote))
      setCarStore(initialCarListData)
      sessionStorage.setItem('carInfo-storage', JSON.stringify(initialCarListData))
    }
  },[])

  const asyncLoad = async (id: string) => {
    await verifyCreditariaEmployeeId(id)
  }

  const verifyCreditariaEmployeeId = async (id: string) => {
    let tmpToken = token
    if(!tmpToken){
      tmpToken = await getToken()
    }
    const { data } = await InterInstance({
      method: 'GET',
      url: `/catalogs/creditaria-workers/${id}`,
      headers: { Authorization: `Bearer ${tmpToken}` },
    }).catch(error => {
      console.log(error)
      return error
    });

    if(data && data.success && data.allowed){
      const { data: { employee } } = data
      setDataEmployee({
        name: employee.nombreempleado,
        employeeId: employee.numeroempleado
      })
      setCreditariaEmployeeId(employee.numeroempleado)
      addPolicyInfo({
        ...policy,
        employee_id: employee.numeroempleado
      })
      return data.success
    }
    return false
  }

  const { validateName } = useValidateFields();
  const [ validationNameResult, setValidationNameResult ] = useState<ValidationResult>(initialValidationResult) 

  const validateEmail = (email: string) => {
    const beforeAtSign = email.split('@')[0]
    const afterAtSign = email.split('@')[1]
    if(
      !email.includes('@')
      || beforeAtSign.length < 4
      || !afterAtSign
      || afterAtSign.length < 4
      ){
      setErrorEmail({
        msg: 'El email que ingresaste no es correcto.',
        isError: true,
      });
      return false
    }
    setErrorEmail({
      msg: '',
      isError: false,
    });
    return true
  }

  const handleInputChange = async (event: any) => {
    let regex = /^[0-9]*$/
    
    const telefono = event.target.value;
    if (event.target.id === 'telefono') {
      setValidateFields({
        ...validateFields,
        phone: true
      })

      if ( telefono.length <= 10 && !regex.test(telefono)){
        setErrorTelefono({
          msg: 'Aquí sólo puedes escribir números.',
          isError: true,
        });
        setWarningTelefono({
          msg: '',
          isWarning: false,
        });
        return
      }

      if (telefono.length <= 10 && validateFields.phone) {
        setDriver({
          ...driver,
          telefono: telefono,
        });
        setErrorTelefono({
          msg: 'El teléfono no es válido.',
          isError: true,
        });
        setWarningTelefono({
          msg: '',
          isWarning: false,
        });
      }

      if(telefono.length === 10){
        setErrorTelefono({
          msg: '',
          isError: false,
        });
        setWarningTelefono({
          msg: '',
          isWarning: false,
        });
      }
      return;
    }

    if (event.target.id === 'email') {
      setValidateFields({
        ...validateFields,
        email: true
      })
      setDriver({
        ...driver,
        email: event.target.value,
      });
      validateEmail(event.target.value);
    }

    if(event.target.id === 'employee_id'){
      regex = /^[a-zA-Z0-9]*$/;
      const employee = event.target.value
      if(!regex.test(employee))
        return
      
      setValidateFields({
        ...validateFields
      })
    }
  }

  const handleNextStep = () => {

    if (validationNameResult.isError || driver.name === '') {
      setValidationNameResult({adornment: React.createElement(Error, {color: Constants.ERROR}), 
      helperText: Constants.EMPTY_FIELD, colorHelper: Status.Error, isError: true, isWarning: false})
      return
    }

    const telefonoArray = driver.telefono.split('');
    const isResultAllEquals = allEqual(telefonoArray);
    const isConsecutive = isThereFourConsecutiveNumber(driver.telefono);

    if (
      isResultAllEquals ||
      driver.telefono === '0123456789' ||
      isConsecutive
    ) {
      setErrorTelefono({
        msg: '¡Oops! Debes ingresar un teléfono válido.',
        isError: true,
      });
      return;
    }

    setErrorTelefono({
      msg: '',
      isError: false,
    });
    if (driver.telefono === '') {
      setErrorTelefono({
        msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
        isError: true,
      });
      setWarningTelefono({
        msg: '',
        isWarning: false,
      });
      return;
    }
    if (driver.telefono.length < 10) {
      setWarningTelefono({
        msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 10. ',
        isWarning: true,
      });
      setErrorTelefono({
        msg: '',
        isError: false,
      });
      return;
    }
    setWarningTelefono({
      msg: '',
      isWarning: false,
    });
    setErrorTelefono({
      msg: '',
      isError: false,
    });                                            
    if (driver.email === '') {
      setErrorEmail({
        msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
        isError: true,
      });
      return;
    }

    if (!validateEmail(driver.email)) {
      setErrorEmail({
        msg: '¡Oh, no! Debes ingresar un correo válido.',
        isError: true,
      });
      return;
    }
    if(!recaptcha){
      setErrorCheckbox({
        msg: '¡Oh, no! Te falta llenar el campo captcha para poder continuar.',
        isError: true,
      });
      return;
    }
    showInfo(false);
  };

  const colorTelefonoAdornment = () => {
    if (warningTelefono.isWarning) {
      return 'warning';
    }

    if (errorTelefono.isError) {
      return 'error';
    }
  };

  const colorHelperTextTelefono = () => {
    if (warningTelefono.isWarning) {
      return '#FF9E1B';
    }

    if (errorTelefono.isError) {
      return '#f44336';
    }
  };

  const telefonoAdornment = () => {
    if (warningTelefono.isWarning) {
      return <Warning color={'warning'} />;
    }

    if (errorTelefono.isError) {
      return <Error color={'error'} />;
    }

    return <Check color={'primary'} />;
  };

  function onChange(value: any) {
    setRecaptcha(value);
  }

  const emailAdornment = () => {
    if (errorEmail.isError) {
      return <Error color={'error'} />;
    }

    return <Check color={'primary'} />;
  };

  return (
    <Paper
      elevation={elevation}
      style={{
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <Box
        style={{
          paddingTop: 18,
          paddingLeft: 23,
          paddingRight: 23,
          paddingBottom: 18,
        }}
      >
        <label className={classes.firstText}>{`Primero, lo primero`}</label>
        <div className={classes.blockSpace}>
          <FormControl fullWidth sx={{ marginBottom: '1.25rem' }}>
            <ReactTooltip
              id="name-tooltip"
              type="light"
              place="top"
              effect="solid"
              backgroundColor="#EBF8FB"
            >
              <Typography width={330}>
              Es la persona que maneja de forma recurrente el vehículo a asegurar.
              </Typography>
            </ReactTooltip>
            <label className={classes.label}>
              {'Primer nombre del conductor'}
            </label>
            <TextField
              data-for="name-tooltip"
              data-tip={''}
              id="name"
              value={driver.name}
              label="Nombre"
              fullWidth
              variant="outlined"
              placeholder="Nombre"
              onChange={(event) => {
                setValidationNameResult(validateName(event.target.value))
                setDriver({
                  ...driver,
                  name: event.target.value,
                }); 
              }}
              error={validationNameResult.isError || validationNameResult.isWarning}
              helperText={validationNameResult.helperText}
              color={validationNameResult.colorHelper}
              InputProps={{
                style: {
                  borderRadius: 8,
                },
                endAdornment: validationNameResult.adornment,
              }}
            />
          </FormControl>
          <FormControl fullWidth sx={{ marginBottom: '1.25rem' }}>
            <label className={classes.label}>
              {'Teléfono y Correo electrónico del conductor'}
            </label>
            <TextField
              name="telefono"
              value={driver.telefono}
              label="Telefono"
              id="telefono"
              fullWidth
              variant="outlined"
              placeholder="Telefono"
              onChange={handleInputChange}
              error={errorTelefono.isError}
              helperText={
                <label style={{ color: colorHelperTextTelefono() }}>
                  {errorTelefono.msg || warningTelefono.msg}
                </label>
              }
              color={colorTelefonoAdornment()}
              InputProps={{
                style: {
                  borderRadius: 8,
                },
                endAdornment: validateFields.phone && telefonoAdornment(),
              }}
            />

          </FormControl>
          <FormControl fullWidth sx={{ marginBottom: '1.25rem' }}>
            <TextField
              id="email"
              label="Correo electrónico"
              fullWidth
              variant="outlined"
              value={driver.email}
              placeholder="Correo electrónico"
              onChange={handleInputChange}
              error={errorEmail.isError}
              helperText={errorEmail.msg}
              color={errorEmail.isError ? 'error' : 'primary'}
              InputProps={{
                style: {
                  borderRadius: 8,
                },
                endAdornment: validateFields.email && emailAdornment(),
              }}
            />
          </FormControl>
          <FormControl fullWidth sx={{marginBottom: '1rem'}}>
            <FormHelperText error={errorCheckbox.isError}>
              {errorCheckbox.msg}
            </FormHelperText>
            <ReCAPTCHA
              sitekey="6LdVLtUjAAAAAK63bwg6YPLQa1GV6_H1ibukGzz8"
              onChange={onChange}
              hl='es'
            />
          </FormControl>
          <CustomButton
            text={'Siguiente'}
            color={'primary'}
            variant={'contained'}
            endIcon={<ArrowForward />}
            style={{ width: '100%', height: 56, textTransform: 'none', color: '#FFF', fontSize: '1rem' }}
            onClick={handleNextStep}
          />
        </div>
      </Box>
    </Paper>
  );
};
