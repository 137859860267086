import React from 'react';
import { Info } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  styled, useMediaQuery, useTheme,
} from '@mui/material';

interface PercentageSelectorProps {
  label: string;
  options: number[];
  tooltip?: string;
  defaultValue?: number;
  value: number;
  setValue: Function;
}

const StyledTextField = styled(TextField)({
  '.Mui-disabled': {
    color: '#000',
    fontSize: '16px',
    fontWeight: '700',
    textAlign: 'center',
  },
});

export function PercentageSelector({
  defaultValue,
  label,
  options,
  setValue,
  value,
  tooltip,
}: PercentageSelectorProps) {
  const [disableAdd, setDisableAdd] = React.useState(false);
  const [disableSub, setDisableSub] = React.useState(false);
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));

  React.useEffect(() => {
    if (options.length === 1) {
      setDisableAdd(true);
      setDisableSub(true);
    }
  }, []);

  const changePercentage = (reason: string) => {
    const length = options.length;
    const currentIndexOption = options.indexOf(value);

    if (reason === 'add') {
      const nextItemIndex =
        currentIndexOption === length - 1
          ? currentIndexOption
          : currentIndexOption + 1;
      const nextItem = options[nextItemIndex];

      if (nextItemIndex + 1 === options.length) setDisableAdd(true);

      setDisableSub(false);
      setValue(nextItem);
    } else {
      const prevItemIndex =
        currentIndexOption === 0 ? currentIndexOption : currentIndexOption - 1;
      const prevItem = options[prevItemIndex];

      if (prevItemIndex === 0) setDisableSub(true);

      setDisableAdd(false);
      setValue(prevItem);
    }
  };

  return (
    <Grid rowGap={'24px'} sx={{display: 'flex', alignItems: 'center', flexDirection: isDesktop ? 'row' : 'column'}}>
      <Grid
        item
        container
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Grid item>
          <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
            {label}
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip
            title={tooltip || ''}
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: '#F2FAFC',
                  color: '#212121',
                },
              },
            }}
          >
            <Info
              data-for="tooltip"
              data-tip=""
              data-iscapture="true"
              sx={{ cursor: 'pointer', marginLeft: '10px', color: '#039ECC' }}
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid
        item
        container
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Grid item>
          <IconButton
            disabled={disableSub}
            onClick={() => changePercentage('sub')}
          >
            <RemoveCircleOutlineIcon
              sx={{ fontSize: '40px', color: disableSub ? '#C7C7C7' :'#039ECC' }}
            />
          </IconButton>
        </Grid>
        <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledTextField
            disabled
            size="small"
            defaultValue={`${defaultValue}%`}
            value={`${value}%`}
            sx={{
              width: '140px',
              display: 'flex',
              justifyContent: 'center',
            }}
          />
        </Grid>
        <Grid item>
          <IconButton
            disabled={disableAdd}
            onClick={() => changePercentage('add')}
          >
            <AddCircleOutlineIcon
                sx={{ fontSize: '40px', color: disableSub ? '#C7C7C7' :'#039ECC' }}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
