import React, { useState } from 'react';
import '../../assets/styles/TooltipSticky.css';
import {Box, Button, CircularProgress, Grid, IconButton} from '@mui/material';
import mani from '@assets/ManiFab.svg';
import mani2 from '@assets/ManiFab2.svg';
import CallIcon from '@mui/icons-material/Call';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import CloseIcon from '@mui/icons-material/Close';
import TagManager from 'react-gtm-module';

interface InterfaceTooltipSticky{
    title: any;
    isMobile?: boolean;
    loading?: boolean;
    success: boolean;
    action: () => void;
    closeAction: () => void;
}

const TooltipSticky = ({
                      title,
                      isMobile = false,
                      loading = false,
                      success,
                      action,
                      closeAction,
                  }: InterfaceTooltipSticky) => {

    const policyNumber = JSON.parse(sessionStorage.getItem('policy-storage') as string);
    const handleBtnClick = () => {
        // onClick={isMobile ? action : success ? closeAction : action}
        if (isMobile) {
            action();
            TagManager.dataLayer({dataLayer:{
                    event: 'coche_seguro_boton_ayuda_asesor',
                    no_cotizacion: policyNumber.state.policy.policyNumber,
                    url: window.location.href,
                    boton_contacto: 'Enlazar con un experto'
                }
            })
        }
        else if (success) {
            closeAction();
        }
        else {
            action();
            TagManager.dataLayer({dataLayer:{
                    event: 'coche_seguro_boton_ayuda_asesor',
                    no_cotizacion: policyNumber.state.policy.policyNumber,
                    url: window.location.href,
                    boton_contacto: 'Enlazar con un experto'
                }
            })
        }
    }
    return (
        <div className="vineta">
            <div className="vineta">
                <div className="globo abajo-derecha">
                    <Box
                        sx={{
                            width: '100%',
                            height: '172px',
                        }}
                    >
                        <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 0, sm: 0, md: 0 }}
                        >
                            <Grid item xs={0} sm={success ? 3.5:3} md={success ? 3.5:3} sx={{ marginLeft: -1}}>
                                {
                                    isMobile ?
                                        <img src={mani} alt="mani" />
                                        :
                                        success ?
                                            <img src={mani2} alt="mani2"
                                            />
                                            :
                                            <img src={mani} alt="mani" />

                                }
                            </Grid>

                            <Grid
                                item
                                xs={10}
                                sm={success ? 8.5:9}
                                md={success ? 8.5:9}

                                sx={{
                                    width: '100%',
                                    marginTop: 1,
                                    borderRadius: 1,
                                    borderColor: '#000',
                                    borderWidth: 1,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        alignItems: 'flex-end',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => {
                                            TagManager.dataLayer({dataLayer: {
                                                    event: 'coche_seguro_boton_ayuda_cerrar',
                                                    no_cotizacio: policyNumber.state.policy.policyNumber,
                                                    url: window.location.href,
                                                    boton_contacto: 'Cerrar'
                                                }});
                                            closeAction();
                                        }}
                                        sx={{
                                            position: 'relative',
                                            padding: 0,
                                            marginBottom: 0,
                                            top: -5,
                                            color: '#595959',
                                        }}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        padding: '8px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '8px',
                                        borderColor: '#039ECC',
                                        borderWidth: '1px',
                                        borderStyle: 'solid',
                                        alignSelf: 'stretch',
                                        borderRadius: '8px',
                                        opacity: 0.8,
                                        marginTop: '2px',
                                    }}
                                >
                  <span
                      style={{
                          fontFamily: 'Montserrat',
                          fontSize: '16px',
                          fontWeight: '400',
                          color: '#22282B',
                      }}
                  >
                    {success
                        ? 'En los próximos minutos un ejecutivo te va contactar para resolver tus dudas'
                        : title}
                  </span>
                                </div>
                                <Box sx={{ mt: 2 }} />
                                {
                                    isMobile ?
                                        <Button
                                            onClick={handleBtnClick}
                                            variant="contained"
                                            fullWidth
                                            sx={{
                                                padding: '10px 0px 10px 0px',
                                                fontFamily: 'Montserrat',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: '20px',
                                                background: '#039ECC',
                                                textTransform: 'none',
                                            }}
                                            startIcon={
                                                isMobile ? (
                                                    <PhoneEnabledIcon />
                                                ) : success ? null : (
                                                    <PhoneEnabledIcon />
                                                )
                                            }
                                        >
                                            {isMobile
                                                ? 'Hablar con un experto'
                                                : success
                                                    ? 'Entendido'
                                                    : 'Enlazar con un experto'}
                                        </Button>
                                        :
                                        success ? <></>
                                            :
                                            <Box sx={{ m: 0, position: 'relative' }}>
                                                <Button
                                                    onClick={handleBtnClick}
                                                    disabled={loading}
                                                    variant="contained"
                                                    fullWidth
                                                    sx={{
                                                        "&.Mui-disabled": {
                                                            backgroundColor: '#039ECC',
                                                            // color: "#c0c0c0"
                                                        },
                                                        height:'40px',
                                                        padding: '10px 0px 10px 0px',
                                                        fontFamily: 'Montserrat',
                                                        fontSize: '16px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                        lineHeight: '20px',
                                                        backgroundColor: '#039ECC',
                                                        textTransform: 'none',
                                                    }}
                                                    startIcon={
                                                        loading ? null : (
                                                            <PhoneEnabledIcon />
                                                        )
                                                    }
                                                >
                                                    { !loading
                                                        ? 'Enlazar con un experto'
                                                        :
                                                        ""}
                                                </Button>

                                                {loading && (
                                                    <CircularProgress
                                                        size={25}
                                                        sx={{
                                                            color: 'white',
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            marginTop: '-12px',
                                                            marginLeft: '-12px',
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                }

                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
        </div>
    );
};
export default TooltipSticky;
