import React from 'react';
import { Clear } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import {
  Modal,
  Box,
  Paper,
  Typography,
  IconButton,
  Backdrop,
  Fade,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { useStyles } from './useStyles';
import { CustomButton } from '@buttons/CustomButton';
import phoneLogo from '@assets/images/Group 6867.svg';
import envelop from '@assets/images/Envelop.svg';
import backarrow from '@assets/images/BackArrow.svg';
import { ZustandContext } from '@store/modalStore';
import { ModalContact } from './ModalContact';
import { usePolicyStore } from '@store/policyStore';
import TagManager from 'react-gtm-module';

type ModalHomeProps = {
  onClose: () => void;
  marca?: string;
  modelo?: string;
  anio?: string;
  type?: string;
};

export const ModalHelp: React.FC<ModalHomeProps> = ({
  onClose,
  marca,
  modelo,
  anio,
  type,
}) => {
  const classes = useStyles();
  const { breakpoints, palette } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const { show, hide } = React.useContext(ZustandContext);
  const history = useHistory();

  const pages = {
    options: '/options',
    principal: '/',
  };

  const policy = usePolicyStore();

  const DataLayer = {
    event: 'CSform_step_0_noEncuentro',
    eventCategory: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_0_noEncuentro',
    eventLabel: '{{Telefono|WhatsApp|Email|X}}',
    telefono: undefined,
    correo: undefined,
    noCotizacion: policy.policy.policyNumber,
    modelo: modelo,
    marca: marca,
    anio: anio,
    paquete: undefined,
    forma_pago: undefined,
    aseguradora: undefined,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: type,
    colaborador: undefined,
    cupon: undefined,
  };

  const tagManagerArgs = {
    dataLayer: DataLayer,
  };

  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper
          className={classes.root}
          style={{ height: isMobile ? '340px' : '365px' }}
        >
          <Box
            padding={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              textAlign="center"
              position={'relative'}
            >
              <Typography
                variant={'h6'}
                fontWeight={'bold'}
                paddingTop="25px"
                marginBottom="20px"
                fontSize="20px"
              >
                ¿No encuentras tu vehículo?
              </Typography>
              <Box position={'absolute'} top={-5} right={isMobile ? -35 : -25}>
                <IconButton
                  onClick={() => {
                    tagManagerArgs.dataLayer.eventLabel = 'X';
                    TagManager.dataLayer(tagManagerArgs);
                    onClose();
                  }}
                >
                  <Clear />
                </IconButton>
              </Box>
            </Box>
            <Typography fontWeight={700} textAlign={'center'} fontSize={16}>
              Teléfono de Asistencia:
            </Typography>
            <Box textAlign={'center'}>
              <CustomButton
                text="55 4424 6837"
                onClick={() => {
                  tagManagerArgs.dataLayer.eventLabel = 'Telefono';
                  TagManager.dataLayer(tagManagerArgs);
                  onClose();
                }}
                size={isDesktop ? 'medium' : 'small'}
                variant={'text'}
                fontWeight={'400'}
                style={{
                  color: '#006782',
                  fontSize: isMobile ? '20px' : '24px',
                  textTransform: 'none',
                  textDecoration: 'underline',
                  marginBottom: '15px',
                  marginTop: '15px',
                }}
              />
            </Box>
            <Typography
              fontWeight={700}
              textAlign={'center'}
              fontSize={16}
              width={250}
            >
              o mándanos un mensajito por WhatsApp
            </Typography>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Box
                component={'img'}
                src={phoneLogo}
                sx={{
                  width: 24,
                  height: 24,
                }}
                alt="Phone"
              />
              <CustomButton
                text="55 4326 9037"
                onClick={() => {
                  tagManagerArgs.dataLayer.eventLabel = 'Whatsapp';
                  TagManager.dataLayer(tagManagerArgs);
                  onClose();
                }}
                size={isDesktop ? 'medium' : 'small'}
                variant={'text'}
                fontWeight={'400'}
                style={{
                  color: '#006782',
                  fontSize: isMobile ? '20px' : '24px',
                  textTransform: 'none',
                  textDecoration: 'underline',
                  marginBottom: '24px',
                  marginTop: '15px',
                }}
              />
            </Box>
            {/* <Call onClose={onClose} /> */}
          </Box>
          {/* <Footer /> */}
        </Paper>
      </Fade>
    </Modal>
  );
};
