import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Box, useTheme, useMediaQuery } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useHistory } from 'react-router-dom';

import { CustomButton } from '@buttons/CustomButton';
import { DetailsLayout } from '@screens/details/layout/DetailsLayout';
import { useChangePrice } from '@hooks/useChangePrice';
import {useVisibleButtonHelp} from "@hooks/useVisibleButtonHelp";

const useStyles = makeStyles({
  root: {
    background: '#f5f5f5',
    paddingBottom: '40px',
  },
});

export const Details: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const { breakpoints, palette } = useTheme();

  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));

  useEffect(() => {
    window.scroll(0,0);
  }, []);

  useChangePrice();
    useVisibleButtonHelp();


    return (
    <div className={classes.root}>
      <Container maxWidth={'xl'}>
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            marginTop: isTablet ? 140 : 100,
            minHeight: '70vh',
            position: 'relative',
          }}
        >
          <CustomButton
            text={'Regresar a mis opciones'}
            onClick={() => history.push('/plans')}
            size={isDesktop ? 'medium' : 'small'}
            variant={'text'}
            startIcon={<KeyboardArrowLeftIcon />}
            style={{
              color: palette.primary.main,
              fontSize: isMobile ? 14 : 16,
              textTransform: 'none',
              fontWeight: 'normal',
              textDecoration: 'underline',
            }}
          />
          <DetailsLayout />
        </Box>
      </Container>
    </div>
  );
};
