import React, { useState } from 'react';
import {
  AlertTitle,
  Box,
  Grid,
  Typography,
  Alert,
  Collapse,
} from '@mui/material';
import { PaymentSelectField } from './PaymentSelectField';
import { PlanSelectField } from './PlanSelectField';
import { useClamp } from '@utils/useClamp';
import { PercentageSelector } from './PercentageSelector';
import { PaymentToggleGroup } from './PaymentToggleGroup';
import { usePolicyStore } from '@store/policyStore';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { getQuoteCreated } from '@api/getQuoteCreated';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useCarStore } from '@store/carInfoStore';
import { useCPStore } from '@store/cpStore';
import { useGetQuotePayment } from '@store/QuoteData';
import { ModalPagoMensual } from '@modals/ModalPagoMensual';
import Idea from '../../../assets/images/Idea.svg';

type InsuranceCustomizerProps = {
  setLoading: Function;
};

export function InsuranceCustomizer({ setLoading }: InsuranceCustomizerProps) {
  const tooltipText =
    'El porcentaje que elijas será el porcentaje (del valor de la unidad) que deberás cubrir en caso de hacer uso de la cobertura. Si tu porcentaje es mayor, el precio de tu póliza disminuye, y viceversa.';
  const { clamp } = useClamp();
  const { policy, addPolicyInfo } = usePolicyStore();
  const { materialDamageOptions, theftOptions } = useOptionsByInsurer();
  const { selectedQuote, setSelectedQuote } = useSelectedQuoteStore();
  const { token } = useTokenStore();
  const {
    tracking: { id },
  } = useTrackingStore();
  const { driver } = useDriverStore();
  const { car: carData } = useCarStore();
  const { cpData } = useCPStore();
  const { Packages, payment_option } = useGetQuotePayment();
  const [percentageDM, setPercentageDM] = React.useState<number>(
    Number(policy.customDM) || materialDamageOptions?.find((x) => x === 5) || 0
  );
  const [percentageRT, setPercentageRT] = React.useState<number>(
    Number(policy.customRT) || theftOptions?.find((x) => x === 10) || 0
  );
  const [open, setOpen] = useState<boolean>(false);
  const [openMessage, setOpenMessage] = useState<boolean>(true);

  React.useEffect(() => {
    if (selectedQuote?.vehicle?.type === 'MOTO') {
      setPercentageDM(10);
      setPercentageRT(20);
    }
  }, []);

  React.useEffect(() => {
    fetchQuote();
  }, [percentageDM, percentageRT, Packages.value, Packages, payment_option]);

  const fetchQuote = async () => {
    let percentageRTpivot = percentageRT.toString();
    let percentageDMpivot = percentageDM.toString();

    setLoading(true);

    if (Packages.value === 'LIMIT') {
      if (selectedQuote?.vehicle?.type === 'MOTO') {
        setPercentageDM(10);
        setPercentageRT(20);
      } else {
        setPercentageDM(5);
      }
      percentageDMpivot = '0';
    }
    if (Packages.value === 'RC') {
      percentageDMpivot = '0';
      percentageRTpivot = '0';
      if (selectedQuote?.vehicle?.type === 'MOTO') {
        setPercentageDM(10);
        setPercentageRT(20);
      } else {
        setPercentageDM(5);
        setPercentageRT(10);
      }
      
    }

    try {
      const { data } = await getQuoteCreated(
        token,
        id,
        selectedQuote.insurance,
        driver,
        carData,
        cpData[0],
        Packages.value,
        payment_option.value,
        percentageRTpivot,
        percentageDMpivot
      );
      await addPolicyInfo({
        ...policy,
        customDM: percentageDM.toString(),
        customRT: percentageRT.toString(),
      });
      await setSelectedQuote(data.data);
      policy.payment_new = payment_option.value;
      policy.package_new = Packages.value;
      if (payment_option.value === 'M') {
        setOpen(true);
        policy.payment_label = 'Mensual';
      } else if (payment_option.value === '3M') {
        setOpen(false);
        policy.payment_label = 'Trimestral';
      } else if (payment_option.value === '6M') {
        setOpen(false);
        policy.payment_label = 'Semestral';
      } else if (payment_option.value === 'A') {
        setOpen(false);
        policy.payment_label = 'Anual';
      }
      if (Packages.value === 'AMP') {
        policy.package_label = 'Plan Amplio';
      } else if (Packages.value === 'RC') {
        policy.package_label = 'Plan Basico';
      } else if (Packages.value === 'LIMIT') {
        policy.package_label = 'Plan Limitado';
      }
      policy.error = false;
      await addPolicyInfo({ ...policy });
      await setLoading(false);
      return data.data;
    } catch (e) {
      policy.error = true;
      policy.payment_new = policy.payment_anterior;
      policy.package_new = policy.package_anterior;
      if (policy.payment_anterior === 'M') {
        policy.payment_label = 'Mensual';
      } else if (policy.payment_anterior === '3M') {
        policy.payment_label = 'Trimestral';
      } else if (policy.payment_anterior === '6M') {
        policy.payment_label = 'Semestral';
      } else if (policy.payment_anterior === 'A') {
        policy.payment_label = 'Anual';
      }
      if (policy.package_anterior === 'AMP') {
        policy.package_label = 'Plan Amplio';
      } else if (policy.package_anterior === 'RC') {
        policy.package_label = 'Plan Basico';
      } else if (policy.package_anterior === 'LIMIT') {
        policy.package_label = 'Plan Limitado';
      }
      if (policy.customDM !== percentageDM.toString()) {
        const indexDM =
          materialDamageOptions?.findIndex((val) => val === percentageDM) || 0;
        const prevDM = materialDamageOptions?.at(indexDM - 1) || 10;
        if (selectedQuote?.vehicle?.type === 'MOTO') {
          setPercentageDM(20);
        } else {
          setPercentageDM(prevDM);
        }
      }

      if (policy.customRT !== percentageRT.toString()) {
        const indexRT =
          theftOptions?.findIndex((val) => val === percentageRT) || 0;
        const prevRT = theftOptions?.at(indexRT - 1) || 5;
        if (selectedQuote?.vehicle?.type === 'MOTO') {
          setPercentageRT(10);
        } else {
          setPercentageRT(prevRT);
        }
      }
      await addPolicyInfo({ ...policy });

      await setLoading(false);
    }
  };

  const showTheftPercentageByCondition = () => {
    if (
      (policy.package_new === 'AMP' || policy.package_new === 'LIMIT')

    ) {
      return (
        <PercentageSelector
          setValue={setPercentageRT}
          value={percentageRT}
          label={'Porcentaje de Robo Total'}
          options={theftOptions || []}
          tooltip={tooltipText}
        />
      );
    }
  };

  const showMaterialDamagePercentageByCondition = () => {
    if (
      policy.package_new === 'AMP'
    ) {
      return (
        <PercentageSelector
          setValue={setPercentageDM}
          value={percentageDM}
          label={'Porcentaje de Daños Materiales'}
          options={materialDamageOptions || []}
          tooltip={tooltipText}
        />
      );
    }
  };

  const showMessageDeductive = () => {
    if (
      (policy.package_new === 'AMP' || policy.package_new === 'LIMIT')
    ) {
      return (
        <Collapse in={openMessage}>
          <Alert
            sx={{ color: 'black' }}
            icon={
              <Box
                component={'img'}
                src={Idea}
                width={'23px'}
                height={'33px'}
              ></Box>
            }
            severity="info"
            onClose={() => {
              setOpenMessage(false);
            }}
          >
            <AlertTitle sx={{ fontWeight: 600, color: 'black' }}>
              ¡Tenemos deducibles flexibles!
            </AlertTitle>
            Personaliza el monto que deseas pagar de deducible en caso de Robo
            Total y Daños Materiales
          </Alert>
        </Collapse>
      );
    }
  };

  return (
      <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        <Grid
            container
            style={{
              display: 'flex',
              alignContent: 'center',
              backgroundColor: '#FFFFFF',
              padding: clamp('24px', '16px 40px', '16px 8px'),
              flexDirection: 'row',
              marginTop: '12px',
              width: '1152px',
              borderRadius: '8px'
            }}
        >
          <Grid item xs={12} sm={12} marginBottom={'16px'}>
            <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
              Personaliza tu seguro
            </Typography>
          </Grid>
          <Grid item container rowGap={'24px'} justifyContent={'space-between'}>
            <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                rowGap={'16px'}
            >
              <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  paddingRight={clamp('12px', '12px', '0px')}
              >
                {clamp(
                    <PaymentSelectField />,
                    <PaymentSelectField />,
                    <PaymentToggleGroup />
                )}
              </Grid>
              <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  paddingLeft={clamp('12px', '12px', '0px')}
              >
                <PlanSelectField setOpen={setOpen} />
              </Grid>
            </Grid>
            {/*<Box*/}
            {/*    sx={{*/}
            {/*      margin: 'auto',*/}
            {/*    }}*/}
            {/*>*/}
            {/*  {showMessageDeductive()}*/}
            {/*</Box>*/}

            <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                rowGap={'16px'}
            >
              <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  paddingRight={clamp('12px', '12px', '0px')}
              >
                {showMaterialDamagePercentageByCondition()}
              </Grid>
              <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  paddingLeft={clamp('12px', '12px', '0px')}
              >
                {showTheftPercentageByCondition()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item container></Grid>
          <ModalPagoMensual open={open} setOpen={setOpen} />
        </Grid>
      </Box>
  );
}

const useOptionsByInsurer = () => {
  const { selectedQuote } = useSelectedQuoteStore();

  if (selectedQuote?.vehicle?.type === 'MOTO') {
    const materialDamageOptions = new Map<string, number[]>([
      ['gnp', [5, 10]],
      ['mapfre', [5, 10, 15]],
      ['qualitas', [10]],
    ]);

    const theftOptions = new Map<string, number[]>([
      ['gnp', [10, 20]],
      ['mapfre', [10, 20, 25]],
      ['qualitas', [20]],
    ]);

    return {
      materialDamageOptions: materialDamageOptions.get(selectedQuote?.insurance),
      theftOptions: theftOptions.get(selectedQuote?.insurance),
    };
  }

  const materialDamageOptions = new Map<string, number[]>([
    ['aig', [3, 5, 8, 10]],
    ['axa', [3, 5, 8, 10]],
    ['aba', [3, 5, 8, 10]],
    ['gnp', [5, 10]],
    ['hdi', [3, 5]],
    ['mapfre', [3, 5, 8, 10]],
    ['primeroseguros', [3, 5, 8, 10]],
    ['qualitas', [3, 5, 10]],
    ['zurich', [3, 5, 8, 10]],
  ]);

  const theftOptions = new Map<string, number[]>([
    ['aig', [5, 10, 15, 20]],
    ['axa', [5, 10, 15, 20]],
    ['aba', [5, 10, 15, 20]],
    ['gnp', [10, 20]],
    ['hdi', [5, 10, 15]],
    ['mapfre', [5, 10, 20]],
    ['primeroseguros', [5, 10, 15, 20]],
    ['qualitas', [5, 10, 20]],
    ['zurich', [5, 10, 15, 20]],
  ]);

  return {
    materialDamageOptions: materialDamageOptions.get(selectedQuote?.insurance),
    theftOptions: theftOptions.get(selectedQuote?.insurance),
  };
};
