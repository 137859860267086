import { Box, Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { getVehicleType } from '@utils/vehicleType';
import { ModalChangeVehicle } from '@modals/modalChangeVehicle';
import { useCarStore } from '@store/carInfoStore';
import { ZustandContext } from '@store/modalStore';
import { useContext } from 'react';

function CarSelectInfo() {
  const { car } = useCarStore();
  const { show, hide } = useContext(ZustandContext);
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.up('md'));
  

  const vehicletype = car.type ? car.type : '';

  return (
    <Grid
      item
      lg={4}
      xl={4}
      style={{
        width: isMobile ? '100%' : '33%',
        paddingLeft: isMobile ? 0 : '20px',
        height: '100%'
      }}
    >
      {isMobile || isTablet ? (
        <>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography fontSize={14} fontWeight={'600'}>
              {'Vehículo'}
            </Typography>
            <Edit
              onClick={() => show(ModalChangeVehicle, { onClose: hide })}
              sx={{ cursor: 'pointer', color: '#333436' }}
            />
          </Box>

          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-evenly'}
            gap={2}
            marginTop={ 2}
            borderRadius={2}
            style={{ backgroundColor: 'white', height:'70%', padding: isMobile ? '20px' : 0 }}
          >
            <Box
              component={'img'}
              src={getVehicleType(vehicletype)}
              sx={{
                width: '106px',
              }}
              alt={'Circle Background'}
              style={{ objectFit: 'cover', borderRadius: 8 }}
            />
            <Box display={'flex'} flexDirection={'column'}>
              <Typography
                variant={'caption'}
                fontSize={'14px'}
                style={{
                  fontWeight: 'bold',
                  color: '#424242',
                }}
                textAlign={'start'}
              >
                {`${car.assembler}`}
              </Typography>
              <Typography
                variant={'caption'}
                fontSize={'13px'}
                style={{
                  fontWeight: 'normal',
                  color: '#424242',
                }}
                textAlign={'start'}
              >
                {`${car.description}, ${car.model}`}
              </Typography>
            </Box>
          </Box>
        </>
      ) : null}
    </Grid>
  );
}

export default CarSelectInfo;
