import React from 'react';
import { Box, Typography } from '@mui/material';

import correct from '@assets/images/correct.svg';
import { useDriverStore } from '@store/driverInfoStore';

interface HeaderProps {
  isMobile: boolean;
}

export const Header: React.FC<HeaderProps> = ({ isMobile }) => {
  const { driver } = useDriverStore();

  return (
    <>
      <Box
        component={'img'}
        src={correct}
        sx={{
          width: 50,
        }}
        alt={correct}
        style={{ objectFit: 'contain' }}
      />
      <Typography
        variant={'h6'}
        fontWeight={'bold'}
        marginTop={isMobile ? '5px' : '20px'}
        style={{ fontSize: '28px' }}
      >
        Cotizar
      </Typography>
      <Typography marginBottom={isMobile ? '5px' : '30px'}>
        Encontremos
        <span
          style={{
            color: 'black',
            fontWeight: 'bold',
            padding: '0px 3px 0px 3px',
          }}
        >
          la mejor opción
        </span>
        para ti.
      </Typography>
    </>
  );
};
