import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';

import { SlideShowItem } from '@models/ui/SlideShowItem';
import { useClamp } from '@utils/useClamp';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 600,
    },
    [theme.breakpoints.down('md')]: {
      height: 480,
    },
  },
  info: {
    left: ({ isMobile, isTablet }: any) =>
      isMobile ? '4vw' : isTablet ? '8vw' : '10vw',
    top: ({ isMobile, isTablet }: any) => {
      return isMobile || isTablet ? '15%' : '35%';
    },
    position: 'absolute',
    [theme.breakpoints.up('xl')]: {
      width: 450,
    },
    [theme.breakpoints.down('xl')]: {
      width: 450,
    },
    [theme.breakpoints.down('lg')]: {
      width: 350,
    },
    display: 'flex',
    flexDirection: 'column',
  },
  complement: {
    [theme.breakpoints.only('xl')]: {
      fontSize: 58,
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: 52,
    },
    [theme.breakpoints.only('md')]: {
      fontSize: 44,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 36,
    },
    fontSize: 32,
    fontWeight: 'bold',
  },
}));

type SlideItemProps = {
  item: SlideShowItem;
};

export const SlideItem: React.FC<SlideItemProps> = ({ item }) => {
  const {clamp} = useClamp();
  const { breakpoints, palette } = useTheme();
  const isMini = useMediaQuery(breakpoints.down('xs'));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const classes = useStyles({ isMini, isMobile, isTablet, isDesktop, image: item.image });
  return (
    <Box className={classes.root}>
      <Box
        component={'img'}
        src={item.image}
        sx={{
          width: '100%',
          height: isMobile ? '440px' : 950,
        }}
        alt={item.title}
        className={classes.root}
        style={{ objectFit: 'cover' }}
      />
      <Box className={classes.info}>
        <Typography
          sx={{
            fontSize: clamp('46px', '28px'),
          }}
          fontWeight={'bold'}
          color={palette.common.white}
        >
          {item.title}
        </Typography>
        <Typography
          sx={{
            fontSize: clamp('40px', '16px'),
          }}
          fontWeight={'normal'}
          color={palette.common.white}
        >
          {item.subtitle}
        </Typography>
      </Box>
    </Box>
  );
};
