export default class Constants {
    public static readonly EMPTY_FIELD = '¡Oh, no! Te falta llenar este campo para poder continuar.'
    public static readonly ONLY_NUMBERS = '¡Oops! Aquí sólo puedes escribir números.'
    public static readonly ONLY_TEXT = '¡Oops! Aquí sólo puedes escribir letras.'
    public static readonly ONLY_TEXT_AND_NUMBERS = '¡Oops! Sólo puedes ingresar letras y números.'
    public static readonly NOT_SPECIAL_CHARACTERS = 'Lo sentimos, no puedes agregar caracteres especiales aquí.'
    public static readonly NOT_CHOOSE = '¡Te faltó elegir una opción aquí!'
    public static readonly NAME_NOT_LONG_ENOUGH = '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de'
    public static readonly MAX_LENGTH = '¡Oops! Parece que estás superando el máximo de caracteres. Sólo puedes escribir'
    public static readonly ZIP_NOT_LONG_ENOUGH = '¿Tu Código Postal tiene menos de 5 números? Agrega un 0 a la izquierda. Ejemplo: 01000'
    public static readonly BAD_ZIP_CODE = '¡Oops! Parece que hay un error en tu Código Postal.'
    public static readonly BUSINESS_CAR = 'creditaria'
    public static readonly WARNING = 'warning'
    public static readonly ERROR = 'error'
    public static readonly PRIMARY = 'primary'
    public static readonly CHARACTERS = 'caracteres'
    public static readonly DEFAULT_MAX_LENGTH = 30
    public static readonly DEFAULT_MIN_LENGTH = 2
}