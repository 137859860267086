import React, { useState, useEffect, useContext } from 'react';
import validator from 'validator';
import InfoIcon from '@mui/icons-material/Info';
import {
  ArrowForward,
  Check,
  Error,
  ExpandMore,
  Today,
  Warning,
} from '@mui/icons-material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Box,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Button,
  Tooltip,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { useForm } from '@hooks/useForm';
import { CustomButton } from '@buttons/CustomButton';
import { usePolicyStore } from '@store/policyStore';
import { useDriverStore } from '@store/driverInfoStore';
import { formatDate } from '@utils/formatDate';
import { getTracking } from '@api/getTracking';
import { useCarListStore } from '@store/carListStore';
import TimeSVG from '@assets/images/TimeIcon.svg';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { upCRM } from '@utils/CRM';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { initialCPData, useCPStore } from '@store/cpStore';
import { useCarStore } from '@store/carInfoStore';
import { ZustandContext } from '@store/modalStore';
import { ModalNext } from '@modals/ModalHome/ModalNext';
import TagManager from 'react-gtm-module';
import { CPData } from '@models/store/cpStore';
import ModalAdditionalConfig from './ModalAdditionalConfig';
import { createRFC } from '@utils/createRFC';

type ErrorControl = {
  isError?: boolean;
  isWarning?: boolean;
  correct?: boolean;
  msg: string;
};

type ContratorDataProps = {
  expanded: boolean;
  setExpanded: () => void;
  setActive: React.Dispatch<
    React.SetStateAction<{ first: boolean; second: boolean; third: boolean }>
  >;
  setShowErrorToast: React.Dispatch<React.SetStateAction<boolean>>;
  setAdditionalConfiguration: Function;
};

export const ContratorData: React.FC<ContratorDataProps> = ({
  expanded,
  setExpanded,
  setActive,
  setShowErrorToast,
  setAdditionalConfiguration,
}) => {
  const errorControl: ErrorControl = {
    msg: '',
    isError: false,
    isWarning: false,
    correct: false,
  };
  const [openAdditionalConfig, setOpenAdditionalConfig] =
    useState<boolean>(false);
  const { searchCP, cpData, searchCPInsurance } = useCPStore();
  const [coloniasPorCP, setColoniasPorCP] = useState<any>([initialCPData]);
  const { policy, addPolicyInfo } = usePolicyStore();
  const { driver, setDriver } = useDriverStore();
  const { car } = useCarStore();
  const { token } = useTokenStore();
  const { tracking } = useTrackingStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const { carList } = useCarListStore();
  const selector = driver.genero;
  const [errorTelefono, setErrorTelefono] =
    useState<ErrorControl>(errorControl);
  const [warningTelefono, setWarningTelefono] = useState({
    msg: '',
    isWarning: false,
  });
  const [valueDriver, setValueDriver] = useState<Date | null | string>(
    driver.fechaNacimiento
  );
  const [errorName, setErrorName] = useState<ErrorControl>(errorControl);
  const [warningName, setWarningName] = useState<ErrorControl>(errorControl);
  const [errorMiddleName, setErrorMiddleName] =
    useState<ErrorControl>(errorControl);
  const [warningMiddleName, setWarningMiddleName] = useState({
    msg: '',
    isWarning: false,
  });
  const [errorLastName, setErrorLastName] =
    useState<ErrorControl>(errorControl);
  const [warningLastName, setWarningLastName] = useState({
    msg: '',
    isWarning: false,
  });
  const [errorEmail, setErrorEmail] = useState<ErrorControl>(errorControl);
  const [errorConfirmEmail, setErrorConfirmEmail] =
    useState<ErrorControl>(errorControl);

  const [formDriverState, setFormDriverState] = useForm({
    driverName: policy.driver.firstName
      ? policy.driver.firstName
      : driver.name
      ? driver.name
      : '',
    driverMiddleName: policy.driver.middleName || '',
    driverLastName: policy.driver.lastName || '',
    driverEmail: driver.email || '',
    driverConfirmEmail: driver.email || '',
    driverPhone: driver.telefono || '',
  });
  const [driverPostalCode, setDriverPostalCode] = useState<string>('');
  const [errorCP, setErrorCP] = useState<ErrorControl>(errorControl);
  const [driverState, setDriverState] = useState(policy.driver.estado || '');
  const [errorState, setErrorState] = useState<ErrorControl>(errorControl);
  const [driverColonia, setDriverColonia] = useState<string>(
    policy.driver.colonia || coloniasPorCP[0].suburb_name
  );
  const [coloniaName, setColoniaName] = useState<string>(
    policy.colonia_name || ''
  );
  const [errorColonia, setErrorColonia] = useState<ErrorControl>(errorControl);
  const [driverStreet, setDriverStreet] = useState<string>(
    policy.driver.street || ''
  );
  const [errorStreet, setErrorStreet] = useState<ErrorControl>(errorControl);
  const [driverExternalNumber, setDriverExternalNumber] = useState<string>(
    policy.driver.numeroExterior || ''
  );
  const [errorExtNumber, setErrorExtNumber] =
    useState<ErrorControl>(errorControl);
  const [driverInternalNumber, setDriverInternalNumber] = useState<string>(
    policy.driver.numeroInterior || ''
  );
  const [sexoContractor, setSexoContractor] = useState(
    policy.contrator?.genero || ''
  );
  const [openTooltip, setOpenTooltip] = useState(false);
  const { show, hide } = useContext(ZustandContext);
  const [driverStateCode, setDriverStateCode] = useState<string>(
    policy.driver.stateCode || ''
  );
  const [driverSuburbCode, setDriverSuburbCode] = useState<string>(
    policy.driver.suburbCode || ''
  );

  const [driverRFC, setDriverRFC] = useState(policy.driver.RFC || '');
  const [errorRFC, setErrorRFC] = useState<ErrorControl>(errorControl);
  const [warningRFC, setWarningRFC] = useState({
    msg: '',
    isWarning: false,
  });

  useEffect(() => {
    setDriverPostalCode(policy.driver.zip || cpData[0].zip_code);
    setErrorColonia({ isError: false, correct: true, msg: '' });
    setErrorStreet({ isError: false, correct: true, msg: '' });
    setErrorExtNumber({ isError: false, correct: true, msg: '' });
    setErrorCP({ isError: false, correct: true, msg: '' });
    setErrorState({ isError: false, correct: true, msg: '' });
    getCitiesByPostalCode(policy.driver.zip || cpData[0].zip_code);
    window.scroll(0, 0);
  }, []);

  const completar = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'CS_interactions',
        eventCategory: 'Formulario.Seguro.Auto.v2',
        eventAction: 'Interacciones',
        eventLabel: 'CompletarMasTarde_2', //Texto del botón al que se hizo clic.
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'coche_seguro_completar_mas_tarde', //Static data
        no_cotizacion: policy.policyNumber,
        paso: 'Paso 2',
        boton_contratante: 'Completar más tarde',
      },
    });
    show(ModalNext, { onClose: hide });
  };

  const {
    driverName,
    driverMiddleName,
    driverLastName,
    driverEmail,
    driverConfirmEmail,
    driverPhone,
  } = formDriverState;

  const handleChangeGender: any = (
    _: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setSexoContractor(newAlignment === null ? sexoContractor : newAlignment);
  };

  const getCitiesByPostalCode = async (zipCode: string) => {
    // @ts-ignore
    const data: CPData[] =
      selectedQuote.insurance === 'hdi' ||
      selectedQuote.insurance === 'aig' ||
      selectedQuote.insurance === 'aba'
        ? await searchCPInsurance(zipCode, selectedQuote.insurance, token)
        : await searchCP(zipCode, token);
    if (Object.keys(data).find((k) => k === 'errors')) {
      setErrorCP({
        isError: true,
        msg: '¡Oops! Parece que hay un error en tu Código Postal.',
      });
      setErrorState({
        isError: true,
        msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
      });
      setDriverState('');
      setColoniasPorCP([]);
    } else {
      setDriverState(data[0]['state_name'] || '');
      setColoniasPorCP(data);
      setDriverStateCode(data[0]['state_code'] || '');
    }
  };

  const colorToggleSexo = (attribute: string, value: string): string => {
    return attribute === value ? '#595959' : '#595959';
  };

  const backgroundToggleSexo = (attribute: string, value: string): string => {
    return attribute === value ? '#C7C7C7' : 'transparent';
  };

  const isCorrectEmail = (email1: string, email2: string): boolean => {
    if (email1 === email2) {
      return true;
    }
    return false;
  };

  const [blackList, setBlackList] = useState<boolean>(false);
  const [validation, setValidation] = useState<boolean>(false);

  useEffect(() => {
    if (!validator.isEmail(driverEmail) || blackList || validation) {
      setErrorEmail({
        msg: '¡Oops! Debes ingresar un email válido.',
        isError: true,
      });
    } else {
      setErrorEmail({ msg: '', isError: false });
    }
  }, [driverEmail, setBlackList, blackList, validation, setValidation]);

  const handleChangeDriverBirthday = (newValue: Date | null) => {
    (async () => {
      await setValueDriver(formatDate(newValue));
      if (driverLastName) {
        let formattedDate = JSON.stringify(formatDate(newValue));
        formattedDate = formattedDate.slice(1, 11);
        const date = formattedDate.split('-');

        const rfc = createRFC({
          name: driverName,
          lastName: driverMiddleName,
          secondName: driverLastName,
          birthday: `${date[0].split(' ').join('')}-${date[1]
            .split(' ')
            .join('')}-${+date[2].split(' ').join('')}`,
        });
        setDriverRFC(rfc);
      }
      setErrorRFC({ msg: '', isError: false });
      setWarningRFC({ msg: '', isWarning: false });
    })();
  };

  const isLonger = (value: string): boolean => {
    if (value.length <= 13) {
      return true;
    }
    return false;
  };

  const isCorrectLonger = (value: string): boolean => {
    if (value.length >= 10) {
      return true;
    }
    return false;
  };

  const isValidDriverForm = (): boolean => {
    if (driverName === '') {
      return false;
    } else if (driverLastName === '') {
      return false;
    } else if (driverMiddleName === '') {
      return false;
    } else if (driverName.length < 2) {
      return false;
    } else if (driverLastName.length < 2) {
      return false;
    } else if (driverMiddleName.length < 2) {
      return false;
    } else if (!validator.isMobilePhone(driverPhone)) {
      return false;
    } else if (validator.isEmpty(driverPhone)) {
      return false;
    } else if (validator.isEmpty(driverEmail)) {
      return false;
    } else if (validator.isEmpty(driverConfirmEmail)) {
      return false;
    } else if (!validator.isEmail(driverEmail)) {
      return false;
    } else if (!validator.isEmail(driverConfirmEmail)) {
      return false;
    } else if (!isCorrectEmail(driverEmail, driverConfirmEmail)) {
      return false;
    } else if (selector === '') {
      return false;
    } else if (errorTelefono.isError) {
      return false;
    } else if (driver.telefono.length < 10) {
      return false;
    } else if (errorCP.isError || !errorCP.correct) {
      return false;
    } else if (errorState.isError || !errorState.correct) {
      return false;
    } else if (errorColonia.isError || !errorColonia.correct) {
      return false;
    } else if (errorStreet.isError || !errorStreet.correct) {
      return false;
    } else if (errorExtNumber.isError || !errorExtNumber.correct) {
      return false;
    } else if (
      driverColonia === '' ||
      driverStreet === '' ||
      driverExternalNumber === ''
    ) {
      return false;
    }
    return true;
  };

  const returnAdornment = (
    error?: boolean,
    warning?: boolean,
    noError?: boolean
  ) => {
    if (error) {
      return <Error color="error" />;
    } else if (warning) {
      return <Warning color="warning" />;
    } else if (noError) {
      return <Check color="success" />;
    }
    return <></>;
  };

  const continuar = () => {
    if (!isValidDriverForm()) {
      setShowErrorToast(true);
      return;
    }

    setShowErrorToast(false);
    let colonia = {
      colonia_name: coloniaName as string,
    };
    const info = {
      driver: {
        firstName: driverName.trim(),
        middleName: driverMiddleName.trim(),
        lastName: driverLastName.trim(),
        email: driverEmail,
        telefono: driverPhone,
        fechaNacimiento: valueDriver || new Date(),
        genero: selector,
        zip: driverPostalCode,
        estado: driverState,
        stateCode: driverStateCode,
        suburbCode: driverSuburbCode,
        colonia: driverColonia,
        street: driverStreet,
        numeroExterior: driverExternalNumber,
        numeroInterior: driverInternalNumber,
        RFC: driverRFC,
      },
    };

    TagManager.dataLayer({
      dataLayer: {
        event: 'cocheapp_seguro_datos_personales',
        fecha_nacimiento: info.driver.fechaNacimiento,
        sexo: info.driver.genero,
        cp: driver.codigoPostal,
        cotizacion: policy.policyNumber,
        boton_datos_personales: 'Continuar',
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'coche_seguro_datos_conductor_asegurado',
        no_cotizacion: policy.policyNumber,
        boton_conductor_asegurado: '¡Listo!',
      },
    });

    const driverUpdate = {
      name: driverName.trim(),
      telefono: driverPhone,
      email: driverEmail,
      genero: selector,
      fechaNacimiento: valueDriver,
    };
    policy.stage = '3. Contratación (Coche)';
    policy.sub_stage = '5. Datos Complementarios Contratante';
    policy.state_code = 'Activa';
    policy.status_code = 'En curso';
    addPolicyInfo({ ...policy, ...info, ...colonia });
    setDriver({ ...driver, ...driverUpdate });
    let initialData = {
      id: tracking.id,
      business: 'creditaria',
      data: {
        car: car,
        driver: driver,
        selectedQuote: selectedQuote,
        policy: policy,
        cpData: cpData,
      },
    };
    getTracking(token, initialData);
    upCRM(
      token,
      car,
      driver,
      selectedQuote,
      policy,
      tracking,
      cpData,
      selectedQuote
    );
    TagManager.dataLayer({
      dataLayer: {
        event: 'coche_seguro_datos_contratacion',
        no_cotizacion: policy.policyNumber,
        regimen_fiscal: policy.regimenFiscal,
        boton_contratante: 'Listo',
        boton_contratacion: 'Listo',
      },
    });
    setOpenAdditionalConfig(true);
  };

  const colorHelperTextTelefono = () => {
    if (warningTelefono.isWarning) {
      return '#FF9E1B';
    }

    if (errorTelefono.isError) {
      return '#f44336';
    }

    return '#5AB52F';
  };

  const colorTelefonoAdornment = () => {
    if (warningTelefono.isWarning) {
      return 'warning';
    }

    if (errorTelefono.isError) {
      return 'error';
    }

    return 'success';
  };

  return (
    <Accordion
      expanded={expanded}
      style={{
        border: 'none',
        marginBottom: '25px',
        borderRadius: '8px',
      }}
      elevation={0}
    >
      <AccordionSummary
        style={{
          height: 0,
          padding: 0,
          minHeight: 0,
          border: 'none',
        }}
      ></AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <Box
          paddingY={{ xs: 2 }}
          paddingX={{ xs: 2, md: 4 }}
          paddingTop={{ xs: 3 }}
        >
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            sx={{ display: 'flex', justifyContent: 'right' }}
          >
            <Grid item container xs={12} md={8}>
              <Typography marginBottom={1.5} sx={{ fontSize: '14px' }}>
                ¿Nos ayudas con la info del conductor?
              </Typography>
              <Grid container spacing={{ xs: 2, md: 3 }} marginBottom={5}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    value={driverName}
                    name={'driverName'}
                    label="Nombre (s)*"
                    variant="outlined"
                    onChange={(e) => {
                      const regex = /^[a-zA-Z\u00f1\u00d1 ]*$/;
                      if (regex.test(e.target.value)) {
                        setFormDriverState(e);
                        if (e.target.value.length === 0) {
                          setErrorName({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningName({
                            msg: '',
                            isWarning: false,
                          });
                          return;
                        }
                        if (e.target.value.length < 2) {
                          setErrorName({
                            msg: '',
                            isError: false,
                          });
                          setWarningName({
                            msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2.',
                            isWarning: true,
                          });
                        }
                        if (e.target.value.length >= 2) {
                          setErrorName({
                            msg: '',
                            isError: false,
                          });
                          setWarningName({
                            msg: '',
                            isWarning: false,
                          });
                        }
                      } else {
                        setErrorName({
                          msg: '¡Oops! Aquí sólo puedes escribir letras.',
                          isError: true,
                        });
                        setWarningName({
                          msg: '',
                          isWarning: false,
                        });
                      }
                    }}
                    onBlur={() => {
                      if (driverLastName) {
                        let formattedDate = JSON.stringify(valueDriver);
                        formattedDate = formattedDate.slice(1, 11);
                        const date = formattedDate.split('-');

                        const rfc = createRFC({
                          name: driverName,
                          lastName: driverMiddleName,
                          secondName: driverLastName,
                          birthday: `${date[0].split(' ').join('')}-${date[1]
                            .split(' ')
                            .join('')}-${+date[2].split(' ').join('')}`,
                        });
                        if (driverName === '') {
                          setErrorName({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningName({ msg: '', isWarning: false });
                        }
                        if (driverName.length >= 2 && errorName.isError) {
                          setErrorName({
                            msg: '',
                            isError: false,
                          });
                          setWarningName({
                            msg: '',
                            isWarning: false,
                          });
                        }
                        setDriverRFC(rfc);
                      }
                    }}
                    error={errorName.isError}
                    helperText={
                      <label
                        style={{
                          color: errorName.isError ? '#f44336' : '#FF9E1B',
                        }}
                      >
                        {errorName.msg || warningName.msg}
                      </label>
                    }
                    color={
                      driverName.length === 0
                        ? 'error'
                        : driverName.length < 2
                        ? 'warning'
                        : 'success'
                    }
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment:
                        driverName !== '' &&
                        driverName.length >= 2 &&
                        !errorName.isError ? (
                          <Check color={'success'} />
                        ) : errorName.isError ? (
                          <Error color={'error'} />
                        ) : (
                          <Warning color={'warning'} />
                        ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    value={driverMiddleName}
                    name={'driverMiddleName'}
                    label="Primer Apellido*"
                    variant="outlined"
                    error={errorMiddleName.isError}
                    onChange={(e) => {
                      const regex = /^[a-zA-Z\u00f1\u00d1 ]*$/;
                      if (regex.test(e.target.value)) {
                        setFormDriverState(e);
                        if (e.target.value.length === 0) {
                          setErrorMiddleName({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningMiddleName({
                            msg: '',
                            isWarning: false,
                          });
                          return;
                        }
                        if (e.target.value.length < 2) {
                          setErrorMiddleName({
                            msg: '',
                            isError: false,
                          });
                          setWarningMiddleName({
                            msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2.',
                            isWarning: true,
                          });
                        }
                        if (e.target.value.length >= 2) {
                          setErrorMiddleName({
                            msg: '',
                            isError: false,
                          });
                          setWarningMiddleName({
                            msg: '',
                            isWarning: false,
                          });
                        }
                      } else {
                        setErrorMiddleName({
                          msg: '¡Oops! Aquí sólo puedes escribir letras.',
                          isError: true,
                        });
                        setWarningMiddleName({
                          msg: '',
                          isWarning: false,
                        });
                      }
                    }}
                    onBlur={() => {
                      if (driverLastName) {
                        let formattedDate = JSON.stringify(valueDriver);
                        formattedDate = formattedDate.slice(1, 11);
                        const date = formattedDate.split('-');

                        const rfc = createRFC({
                          name: driverName,
                          lastName: driverMiddleName,
                          secondName: driverLastName,
                          birthday: `${date[0].split(' ').join('')}-${date[1]
                            .split(' ')
                            .join('')}-${+date[2].split(' ').join('')}`,
                        });
                        if (driverMiddleName === '') {
                          setErrorMiddleName({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningMiddleName({ msg: '', isWarning: false });
                        }
                        if (
                          driverMiddleName.length >= 2 &&
                          errorMiddleName.isError
                        ) {
                          setErrorMiddleName({
                            msg: '',
                            isError: false,
                          });
                          setWarningMiddleName({
                            msg: '',
                            isWarning: false,
                          });
                        }
                        setDriverRFC(rfc);
                      }
                    }}
                    color={
                      driverMiddleName.length >= 2 && !errorMiddleName.isError
                        ? 'success'
                        : !warningMiddleName.isWarning &&
                          !errorMiddleName.isError
                        ? 'primary'
                        : errorMiddleName.isError
                        ? 'error'
                        : driverMiddleName.length < 2
                        ? 'warning'
                        : 'primary'
                    }
                    helperText={
                      <label
                        style={{
                          color: errorMiddleName.isError
                            ? '#f44336'
                            : '#FF9E1B',
                        }}
                      >
                        {errorMiddleName.msg || warningMiddleName.msg}
                      </label>
                    }
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment:
                        driverMiddleName !== '' &&
                        driverMiddleName.length >= 2 &&
                        !errorMiddleName.isError ? (
                          <Check color={'success'} />
                        ) : errorMiddleName.isError ? (
                          <Error color={'error'} />
                        ) : warningMiddleName.isWarning ? (
                          <Warning color={'warning'} />
                        ) : null,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    value={driverLastName}
                    name={'driverLastName'}
                    label="Segundo Apellido*"
                    variant="outlined"
                    error={errorLastName.isError}
                    onChange={(e) => {
                      const regex = /^[a-zA-Z\u00f1\u00d1 ]*$/;
                      if (regex.test(e.target.value)) {
                        setFormDriverState(e);
                        if (e.target.value.length === 0) {
                          setErrorLastName({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningLastName({
                            msg: '',
                            isWarning: false,
                          });
                          return;
                        }
                        if (e.target.value.length < 2) {
                          setErrorLastName({
                            msg: '',
                            isError: false,
                          });
                          setWarningLastName({
                            msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2.',
                            isWarning: true,
                          });
                        }
                        if (e.target.value.length >= 2) {
                          setErrorLastName({
                            msg: '',
                            isError: false,
                          });
                          setWarningLastName({
                            msg: '',
                            isWarning: false,
                          });
                        }
                      } else {
                        setErrorLastName({
                          msg: '¡Oops! Aquí sólo puedes escribir letras.',
                          isError: true,
                        });
                        setWarningLastName({
                          msg: '',
                          isWarning: false,
                        });
                      }
                    }}
                    onBlur={() => {
                      if (driverLastName) {
                        let formattedDate = JSON.stringify(valueDriver);
                        formattedDate = formattedDate.slice(1, 11);
                        const date = formattedDate.split('-');

                        const rfc = createRFC({
                          name: driverName,
                          lastName: driverMiddleName,
                          secondName: driverLastName,
                          birthday: `${date[0].split(' ').join('')}-${date[1]
                            .split(' ')
                            .join('')}-${+date[2].split(' ').join('')}`,
                        });
                        setDriverRFC(rfc);
                      }
                      if (driverLastName === '') {
                        setErrorLastName({
                          msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                          isError: true,
                        });
                        setWarningLastName({ msg: '', isWarning: false });
                      }
                      if (driverLastName.length >= 2 && errorLastName.isError) {
                        setErrorLastName({
                          msg: '',
                          isError: false,
                        });
                        setWarningLastName({
                          msg: '',
                          isWarning: false,
                        });
                      }
                      setErrorRFC({ msg: '', isError: false });
                      setWarningRFC({ msg: '', isWarning: false });
                    }}
                    color={
                      driverLastName.length >= 2 && !errorLastName.isError
                        ? 'success'
                        : !warningLastName.isWarning && !errorLastName.isError
                        ? 'primary'
                        : errorLastName.isError
                        ? 'error'
                        : driverLastName.length < 2
                        ? 'warning'
                        : 'primary'
                    }
                    helperText={
                      <label
                        style={{
                          color: errorLastName.isError ? '#f44336' : '#FF9E1B',
                        }}
                      >
                        {errorLastName.msg || warningLastName.msg}
                      </label>
                    }
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment:
                        driverLastName !== '' &&
                        driverLastName.length >= 2 &&
                        !errorLastName.isError ? (
                          <Check color={'success'} />
                        ) : errorLastName.isError ? (
                          <Error color={'error'} />
                        ) : warningLastName.isWarning ? (
                          <Warning color={'warning'} />
                        ) : null,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disabled
                      openTo="year"
                      views={['year', 'month', 'day']}
                      minDate={new Date('1900-01-01')}
                      maxDate={new Date()}
                      label="Fecha de nacimiento"
                      inputFormat="dd/MM/yyyy"
                      value={new Date(`${valueDriver}T00:00:00`)}
                      onChange={
                        /*(newValue: Date | null) =>
                      setValueDriver(newValue || new Date())*/
                        handleChangeDriverBirthday
                      }
                      InputProps={{
                        style: { borderRadius: 8 },
                        endAdornment: <Today color={'primary'} />,
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          style={{ borderRadius: 8 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography fontSize={'14px'}>
                    {'Sexo de nacimiento'}
                  </Typography>
                  <Box>
                    <ToggleButtonGroup
                      disabled={true}
                      fullWidth
                      size={'small'}
                      sx={{
                        height: 40,
                        backgroundColor: '#F4F4F4',
                      }}
                      exclusive
                      value={selector}
                      onChange={handleChangeGender}
                      style={{ borderRadius: 8, padding: 3 }}
                    >
                      <ToggleButton
                        sx={{
                          textTransform: 'none',
                          backgroundColor: '#fafafa',
                        }}
                        value="Mujer"
                        style={{
                          fontWeight: 600,
                          borderRadius: 8,
                          border: 'none',
                          textTransform: 'none',
                          color: colorToggleSexo(selector, 'Mujer'),
                          backgroundColor: backgroundToggleSexo(
                            selector,
                            'Mujer'
                          ),
                        }}
                      >
                        Mujer
                      </ToggleButton>
                      <ToggleButton
                        value="Hombre"
                        style={{
                          fontWeight: 600,
                          borderRadius: 8,
                          border: 'none',
                          textTransform: 'none',
                          color: colorToggleSexo(selector, 'Hombre'),
                          backgroundColor: backgroundToggleSexo(
                            selector,
                            'Hombre'
                          ),
                        }}
                      >
                        Hombre
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: '35px !important',
                  }}
                >
                  <TextField
                    fullWidth
                    value={driverRFC}
                    name={'driverRFC'}
                    error={errorRFC.isError}
                    onChange={(e) => {
                      if (e.target.value.length <= 13) {
                        const regex = /^[a-zA-Z 0-9]*$/;
                        if (regex.test(e.target.value)) {
                          setDriverRFC(e.target.value);
                        }

                        if (
                          e.target.value.length < 10 &&
                          e.target.value.length > 0
                        ) {
                          setErrorRFC({ msg: '', isError: false });
                          setWarningRFC({
                            msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 10.',
                            isWarning: true,
                          });
                          return;
                        }
                        if (e.target.value.length === 0) {
                          setErrorRFC({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningRFC({
                            msg: '',
                            isWarning: false,
                          });
                          return;
                        }
                      }
                      setErrorRFC({ msg: '', isError: false });
                      setWarningRFC({
                        msg: '',
                        isWarning: false,
                      });
                    }}
                    helperText={
                      <label
                        style={{
                          color: errorRFC.isError ? '#f44336' : '#FF9E1B',
                        }}
                      >
                        {errorRFC.msg || warningRFC.msg}
                      </label>
                    }
                    label="RFC*"
                    variant="outlined"
                    onBlur={() => {
                      if (driverRFC.length === 0) {
                        setErrorRFC({
                          msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                          isError: true,
                        });
                      }
                      if (driverRFC.length < 10 && driverRFC.length > 0) {
                        setErrorRFC({
                          msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 10.',
                          isError: true,
                        });
                        setWarningRFC({
                          msg: '',
                          isWarning: false,
                        });
                        return;
                      }
                    }}
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment:
                        isLonger(driverRFC) &&
                        validator.isAlphanumeric(driverRFC) &&
                        isCorrectLonger(driverRFC) ? (
                          <Check color={'success'} />
                        ) : (!validator.isAlphanumeric(driverRFC) &&
                            !validator.isEmpty(driverRFC)) ||
                          !isLonger(driverRFC) ||
                          errorRFC.isError ? (
                          <InfoIcon color={'error'} />
                        ) : warningRFC.isWarning ? (
                          <Warning color={'warning'} />
                        ) : null,
                    }}
                    color={
                      isLonger(driverRFC) &&
                      validator.isAlphanumeric(driverRFC) &&
                      isCorrectLonger(driverRFC)
                        ? 'success'
                        : (!validator.isEmpty(driverRFC) &&
                            !validator.isAlphanumeric(driverRFC)) ||
                          !isLonger(driverRFC) ||
                          errorRFC.isError
                        ? 'error'
                        : warningRFC.isWarning
                        ? 'warning'
                        : 'primary'
                    }
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={{ xs: 2, md: 3 }} marginBottom={3}>
                <Grid item xs={12} md={12}>
                  <Typography sx={{ fontSize: '14px' }}>
                    ¿Cuál es su domicilio?
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Tooltip
                    title="Este es el código postal donde duerme tu coche. Con él podemos calcular tu tarifa."
                    placement="right"
                    arrow
                    open={openTooltip}
                    onClose={() => setOpenTooltip(false)}
                  >
                    <TextField
                      id="driverPostalCode"
                      name={'driverPostalCode'}
                      label="Código Postal*"
                      disabled
                      fullWidth
                      variant="outlined"
                      value={driverPostalCode}
                      onClick={() => setOpenTooltip(true)}
                      onChange={(e) => {
                        if (validator.isEmpty(e.target.value)) {
                          setErrorCP({
                            isError: true,
                            isWarning: false,
                            msg: 'Oh, no! Te falta llenar este campo para poder continuar.',
                          });
                        } else {
                          if (!validator.isPostalCode(e.target.value, 'MX')) {
                            setErrorCP({
                              isError: true,
                              msg: '¡Oops! Parece que hay un error en tu Código Postal.',
                            });
                            setDriverState('');
                            setColoniasPorCP([]);
                            setErrorState({
                              isError: true,
                              msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            });
                          } else {
                            getCitiesByPostalCode(e.target.value);
                            setErrorState({
                              isError: false,
                              isWarning: false,
                              correct: true,
                              msg: '',
                            });
                            setErrorCP({
                              isError: false,
                              isWarning: false,
                              correct: true,
                              msg: '',
                            });
                          }
                        }
                        setDriverPostalCode(e.target.value);
                      }}
                      error={errorCP.isError}
                      helperText={errorCP.msg}
                      color={
                        errorCP.isError
                          ? 'success'
                          : errorCP.isWarning
                          ? 'warning'
                          : 'primary'
                      }
                      InputProps={{
                        style: {
                          borderRadius: 8,
                        },
                        endAdornment: returnAdornment(
                          errorCP.isError,
                          errorCP.isWarning,
                          errorCP.correct
                        ),
                      }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="driverState"
                    name={'driverState'}
                    disabled
                    label="Estado*"
                    fullWidth
                    variant="outlined"
                    value={driverState}
                    error={errorState.isError}
                    helperText={errorState.msg}
                    color={errorState.isError ? 'success' : 'primary'}
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment: returnAdornment(
                        errorState.isError,
                        errorState.isWarning,
                        errorState.correct
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Colonia*</InputLabel>
                    <Select
                      value={driverColonia}
                      label="Colonia"
                      onChange={(e) => {
                        setDriverColonia(e.target.value);
                        const cpResult = coloniasPorCP.find(
                          (cp: any) => cp.suburb_name === e.target.value
                        );
                        setDriverSuburbCode(cpResult.suburb_code);
                      }}
                      sx={{ borderRadius: '8px' }}
                      IconComponent={(props) => (
                        <ExpandMore style={{ color: '#039ECC' }} {...props} />
                      )}
                    >
                      {coloniasPorCP.map((cp: any, i: number) => (
                        <MenuItem
                          key={i}
                          value={cp.suburb_name}
                          onBlur={() => {
                            if (driverColonia === '') {
                              setErrorColonia({
                                isError: true,
                                msg: '¡Te faltó elegir una opción aquí!',
                              });
                            } else {
                              setErrorColonia({
                                isError: false,
                                msg: '',
                                correct: true,
                              });
                            }
                            setColoniaName(cp.suburb_name);
                          }}
                        >
                          {cp.suburb_name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errorColonia.msg}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="driverStreet"
                    name={'driverStreet'}
                    label="Calle*"
                    fullWidth
                    variant="outlined"
                    value={driverStreet}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setErrorStreet({
                          isError: true,
                          correct: false,
                          msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                        });
                      } else {
                        setErrorStreet({
                          isError: false,
                          correct: true,
                          msg: '',
                        });
                      }
                    }}
                    onChange={(e) => {
                      setDriverStreet(e.target.value);
                    }}
                    error={errorStreet.isError}
                    helperText={errorStreet.msg}
                    color={errorStreet.isError ? 'success' : 'primary'}
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment: returnAdornment(
                        errorStreet.isError,
                        errorStreet.isWarning,
                        errorStreet.correct
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    columnGap: '1rem',
                  }}
                >
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="driverExternalNumber"
                      name={'driverExternalNumber'}
                      label="No. exterior*"
                      fullWidth
                      variant="outlined"
                      value={driverExternalNumber}
                      onBlur={(e) => {
                        if (validator.isEmpty(e.target.value)) {
                          setErrorExtNumber({
                            isError: true,
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                          });
                        } else {
                          setErrorExtNumber({
                            isError: false,
                            msg: '',
                            correct: true,
                          });
                        }
                      }}
                      onChange={(e) => {
                        setDriverExternalNumber(e.target.value);
                      }}
                      error={errorExtNumber.isError}
                      helperText={errorExtNumber.msg}
                      color={errorExtNumber.isError ? 'success' : 'primary'}
                      InputProps={{
                        style: {
                          borderRadius: 8,
                        },
                        endAdornment: returnAdornment(
                          errorExtNumber.isError,
                          errorExtNumber.isWarning,
                          errorExtNumber.correct
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="driverInternalNumber"
                      name={'driverInternalNumber'}
                      label="No. interior"
                      fullWidth
                      variant="outlined"
                      value={driverInternalNumber}
                      onChange={(e) => {
                        setDriverInternalNumber(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12} md={12}>
                  <small style={{ fontSize: '12px', fontWeight: '400' }}>
                    *Campos obligatorios
                  </small>
                </Grid>
              </Grid>
              <Grid
                item
                container
                spacing={{ xs: 2, md: 3 }}
                paddingTop={3}
                paddingBottom={{ xs: 5, md: 2 }}
              >
                <Grid item xs={12} md={12}>
                  <Typography>Confirma los datos de contacto</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="driverEmail"
                    name={'driverEmail'}
                    label="Correo electrónico"
                    fullWidth
                    variant="outlined"
                    value={driverEmail}
                    onChange={(e) => {
                      const emailValidate =
                        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
                      if (!emailValidate.test(e.target.value)) {
                        setValidation(true);
                        setErrorEmail({
                          msg: '¡Oh, no! Debes ingresar un email válido.',
                          isError: true,
                        });
                      } else {
                        setValidation(false);
                        setErrorEmail({
                          msg: '',
                          isError: false,
                        });
                      }
                      const [beforeAt, afterAt] = e.target.value.split('@');
                      const blacklist = [
                        'gmail.mx',
                        'hotmail.mx',
                        'aol',
                        'hotmail.com.mx',
                        'gmail.com.mx',
                        'live.com.mx',
                        'gmail.co',
                        'ensamble.dev',
                        'outloot.com',
                        'yahoo.com.sg',
                        '@',
                        'yopmail.com',
                        'hotmail.co',
                      ];
                      if (beforeAt.length <= 2 || blacklist.includes(afterAt)) {
                        setBlackList(true);
                        setErrorEmail({
                          msg: '¡Oh, no! Debes ingresar un email válido.',
                          isError: true,
                        });
                      } else {
                        setBlackList(false);
                        setErrorEmail({
                          msg: '',
                          isError: false,
                        });
                      }
                      if (!validator.isEmail(e.target.value)) {
                        setErrorEmail({
                          msg: '¡Oops! Debes ingresar un email válido.',
                          isError: true,
                        });
                      } else {
                        setErrorEmail({ msg: '', isError: false });
                      }

                      if (e.target.value !== driverConfirmEmail) {
                        setErrorConfirmEmail({
                          msg: '¡Oops! Los Emails deben coincidir.',
                          isError: true,
                        });
                      } else {
                        setErrorConfirmEmail({
                          msg: '',
                          isError: false,
                          correct: true,
                        });
                      }
                      setFormDriverState(e);
                    }}
                    error={errorEmail.isError}
                    helperText={errorEmail.msg}
                    color={
                      validator.isEmail(driverEmail) ? 'success' : 'primary'
                    }
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment: returnAdornment(
                        errorEmail.isError,
                        errorEmail.isWarning,
                        errorEmail.correct
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    value={driverConfirmEmail}
                    name={'driverConfirmEmail'}
                    error={errorConfirmEmail.isError}
                    onChange={(e) => {
                      setFormDriverState(e);
                      if (driverEmail !== e.target.value) {
                        setErrorConfirmEmail({
                          msg: '¡Oops! Los Emails deben coincidir.',
                          isError: true,
                        });
                      } else {
                        setErrorConfirmEmail({
                          msg: '',
                          isError: false,
                          correct: true,
                        });
                      }
                    }}
                    label="Confirmar correo electrónico*"
                    variant="outlined"
                    helperText={errorConfirmEmail.msg}
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment:
                        validator.isEmail(driverConfirmEmail) &&
                        isCorrectEmail(driverEmail, driverConfirmEmail) ? (
                          <Check color={'success'} />
                        ) : (!validator.isEmpty(driverConfirmEmail) &&
                            !validator.isEmail(driverConfirmEmail)) ||
                          errorConfirmEmail.isError ? (
                          <InfoIcon color={'error'} />
                        ) : !validator.isEmpty(driverConfirmEmail) &&
                          validator.isEmail(driverConfirmEmail) &&
                          !isCorrectEmail(driverEmail, driverConfirmEmail) ? (
                          <InfoIcon color={'error'} />
                        ) : null,
                    }}
                    color={
                      (!validator.isEmail(driverConfirmEmail) &&
                        !validator.isEmpty(driverConfirmEmail)) ||
                      !isCorrectEmail(driverEmail, driverConfirmEmail)
                        ? 'error'
                        : validator.isEmail(driverConfirmEmail)
                        ? 'success'
                        : 'primary'
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    sx={{ marginTop: 1 }}
                    name="telefono"
                    label="Telefono"
                    id="telefono"
                    fullWidth
                    variant="outlined"
                    placeholder="Telefono"
                    value={driver.telefono}
                    onChange={(event) => {
                      const regex = /^[0-9]*$/;
                      const telefono = event.target.value;
                      if (regex.test(telefono)) {
                        if (telefono === '') {
                          setErrorTelefono({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningTelefono({ msg: '', isWarning: false });
                        } else {
                          if (telefono.length < 10) {
                            setErrorTelefono({
                              msg: '',
                              isError: false,
                            });
                            setWarningTelefono({
                              msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 10.',
                              isWarning: true,
                            });
                          }
                        }
                        if (telefono.length <= 10) {
                          setFormDriverState(event);
                          setDriver({ ...driver, telefono });
                        }
                        if (telefono.length === 10) {
                          setErrorTelefono({
                            msg: '',
                            isError: false,
                          });
                          setWarningTelefono({ msg: '', isWarning: false });
                        }
                      } else {
                        setErrorTelefono({
                          msg: '¡Oops! Aquí sólo puedes escribir números.',
                          isError: true,
                        });
                        setWarningTelefono({ msg: '', isWarning: false });
                      }
                    }}
                    error={errorTelefono.isError}
                    onBlur={() => {
                      if (driver.telefono === '') {
                        setErrorTelefono({
                          msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                          isError: true,
                        });
                        setWarningTelefono({ msg: '', isWarning: false });
                      } else {
                        if (driver.telefono.length === 10) {
                          setErrorTelefono({
                            msg: '',
                            isError: false,
                            correct: true,
                          });
                          setWarningTelefono({ msg: '', isWarning: false });
                        }
                      }
                    }}
                    helperText={
                      <label style={{ color: colorHelperTextTelefono() }}>
                        {errorTelefono.msg || warningTelefono.msg}
                      </label>
                    }
                    color={colorTelefonoAdornment()}
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment: returnAdornment(
                        errorTelefono.isError,
                        errorTelefono.isWarning,
                        errorTelefono.correct
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction={'row-reverse'}>
            <Grid item display={'flex'} width={'700px'}>
              <Button
                variant="outlined"
                onClick={completar}
                size={'medium'}
                style={{
                  textTransform: 'none',
                  height: '55px',
                  borderRadius: '10px',
                  width: '100%',
                  marginBottom: 20,
                  marginRight: 5,
                }}
              >
                Completar más tarde
                <Box
                  component="img"
                  src={TimeSVG}
                  alt="digicert"
                  width="20px"
                  height="20px"
                  sx={{ marginLeft: 2 }}
                />
              </Button>
              <CustomButton
                color={'primary'}
                onClick={() => {
                  continuar();
                }}
                variant="contained"
                disabled={!isValidDriverForm()}
                style={{
                  textTransform: 'none',
                  height: 56,
                  width: '100%',
                  color: 'white'
                }}
                text={'Siguiente'}
                endIcon={<ArrowForward />}
              />
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
      <ModalAdditionalConfig
        openModal={openAdditionalConfig}
        setExpanded={setExpanded}
        setOpenModal={setOpenAdditionalConfig}
        setAdditionalConfiguration={setAdditionalConfiguration}
        setActive={setActive}
      />
    </Accordion>
  );
};
