import { FormLabel, ToggleButton, ToggleButtonGroup, styled } from "@mui/material";
import { useGetQuotePayment } from "@store/QuoteData";
import { usePolicyStore } from "@store/policyStore";

export function PaymentToggleGroup(){
    const { policy, addPolicyInfo } = usePolicyStore();
    const { Packages, setPackage } = useGetQuotePayment();

    const changePackage = (e: any) => {
        if(!policy.error){
          policy.package_anterior = Packages.value;
        }
        policy.package_new = e.target.value;
        addPolicyInfo({ ...policy });
        if (e.target.value === 'AMP') {
          setPackage('Plan Amplio', 'AMP');
          return;
        } else if (e.target.value === 'RC') {
          setPackage('Plan Basico', 'RC');
          return;
        } else if (e.target.value === 'LIMIT') {
          setPackage('Plan Limitado', 'LIMIT');
          return;
        }
      };

    const StyledToggleButton = styled(ToggleButton)(() => ({
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: '600',
        color: '#212121',
        '&:first-child': {
            borderRadius: '8px'
        },
        '&:last-child': {
            borderRadius: '8px'
        }
    }))
      
    return (
        <>
          <FormLabel sx={{ fontSize: '14px', fontWeight: '400' }}>Plan</FormLabel>
          <ToggleButtonGroup value={policy.package_new} onChange={changePackage} exclusive color="primary" fullWidth>
              <StyledToggleButton value={'AMP'}>Amplio</StyledToggleButton>
              <StyledToggleButton value={'LIMIT'}>Limitado</StyledToggleButton>
              <StyledToggleButton value={'RC'}>Básico</StyledToggleButton>
          </ToggleButtonGroup>
        </>
        )
}