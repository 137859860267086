import {Alert, AlertTitle, Box, Collapse} from "@mui/material";
import IconAntiFraudInfo from "../../assets/antiFraudeImage/iconAntiFraudInfo.svg"
import React, {CSSProperties, useState} from "react";

interface AlertAntiFraudCloseI {
    customStyles:any
}
const AlertAntiFraudClose =({customStyles}:AlertAntiFraudCloseI)=>{
    const [open,setOpen] = useState(true)
    return(
        <>
            {
               open &&
                <Box sx={customStyles}>
                    <Collapse in={open}>
                        <Alert
                            sx={{ color: 'black' }}
                            icon={
                                <Box
                                    component={'img'}
                                    src={IconAntiFraudInfo}
                                    width={'23px'}
                                    height={'33px'}
                                ></Box>
                            }
                            severity="info"
                            onClose={() => {
                                setOpen(false);
                            }}
                        >
                            <AlertTitle sx={{ fontWeight: 600, color: 'black' }}>
                                Aviso
                            </AlertTitle>
                            Recuerda que en inter.mx nunca te pediremos pagos a cuentas de terceros ni transferencias.

                        </Alert>
                    </Collapse>
                </Box>
            }
        </>

    )
}
export default AlertAntiFraudClose
