import create from 'zustand';
import { persist } from 'zustand/middleware';

import { CPData } from '@models/store/cpStore';
import { getCPById, getCPByInsurance } from '@api/getCP';

interface CPInitialData {
  zip_code: string;
  state_code: string;
  state_name: string;
  suburb_code: string;
  suburb_name: string;
  city_name: string;
  township_code: string;
  township_name: string;
}

export const initialCPData: CPInitialData = {
  zip_code: '',
  state_code: '',
  state_name: '',
  suburb_code: '',
  suburb_name: '',
  city_name: '',
  township_code: '',
  township_name: '',
};

interface CP {
  cpData: CPData[];
  searchCP: (id: string, token: string) => CPData;
  searchCPInsurance: (id: string, insurance: string, token: string) => CPData;
}

export const useCPStore = create<CP>(
  //@ts-ignore
  persist(
    (set) => ({
      cpData: [],
      searchCP: async (id, token) => {
        const cp = await getCPById(id, token);
        set({ cpData: cp || [] });
        return cp;
      },
      searchCPInsurance: async (id, insurance, token) => {
        const cp = await getCPByInsurance(id, insurance, token);
        set({ cpData: cp || [] });
        return cp;
      },
    }),
    {
      name: 'cp-storage',
      getStorage: () => sessionStorage,
    }
  )
);
