import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Responsability from '@assets/images/responsabilidad_civil.svg';
import Medic from '@assets/images/Medic.svg';
import Legal from '@assets/images/Shield.svg';
import Thief from '@assets/images/Robo.svg';
import Car from '@assets/images/daños-materiales.svg';
import Assist from '@assets/images/asistencia_viajes.svg';
import { useClamp } from '@utils/useClamp';

type DataPriceProps = {
  logo: string;
  title: string;
  desc: string;
  titleTicket: string;
  numberTicket: string;
  feature: any;
};

const findLogo = (title: string) => {
  switch (title) {
    case 'DM':
      return Car;
    case 'RT':
      return Thief;
    case 'RC':
      return Responsability;
    case 'GM':
      return Medic;
    case 'RC en el extranjero':
      return Car;
    case 'AV':
      return Assist;
    case 'DL':
      return Legal;
    default:
      return Car;
  }
};

export const DataPrice: React.FC<DataPriceProps> = ({
  numberTicket,
  feature,
}) => {
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <Box
      sx={{
        padding: '0 10px',
        display: 'flex',
        width: '100%',
        height: isMobile ? '122px' : '72px',
      }}
    >
      <Box
        sx={{
          width: '81%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box
            component="img"
            sx={{
              width: '24px',
              height: '24px',
            }}
            src={findLogo(feature.code)}
          />
            <Box sx={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
                <Typography
                    sx={{
                        fontWeight: 700,
                        fontSize: '14px',
                        width: '100%',
                    }}
                >
                    {feature.name}
                </Typography>
                <Typography
                    sx={{
                        height: '56%',
                        fontSize: '13px',
                        fontWeight: 400,
                    }}
                >
                    {feature.descripcion || feature.description}
                </Typography>
            </Box>
        </Box>

      </Box>
      <NumberTicketDesktop
        isMobile={isMobile}
        numberTicket={
          feature.deductive === 0
            ? feature.sum_assured === 'A'
              ? 'Amparado'
              : `$${parseFloat(
                  parseFloat(feature.sum_assured).toFixed(2)
                ).toLocaleString('es-MX', { minimumFractionDigits: 2 })}`
            : `${numberTicket}%`
        }
        titleTicket={
          feature.deductive === 0
            ? feature.sum_assured === 'A'
              ? ''
              : 'Te cubre hasta'
            : 'Deducible'
        }
      />
    </Box>
  );
};

interface TicketInfo {
  numberTicket: string;
  titleTicket: string;
  isMobile: boolean;
}

export const NumberTicketDesktop: React.FC<TicketInfo> = ({
  numberTicket,
  titleTicket,
  isMobile,
}) => {
  const {clamp} = useClamp();

  return (
    <Box
      sx={{
        width: isMobile ? '43%' : '27%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#f4f4f4',
        mixBlendMode: 'multiply',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            textAlign: 'center',
          }}
        >
          {titleTicket}
        </Typography>
        <Typography
          sx={{
            fontSize: clamp('1.2vw', '20px', '16px'),
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          {numberTicket}
        </Typography>
      </Box>
    </Box>
  );
};
