import React from 'react';

import ZURICH from '@assets/images/Zurich.svg';
import HDI from '@assets/images/hdi.png';
import Mapfre from '@assets/images/mapfre.png';
import Primero from '@assets/images/primero.png';
import Qualitas from '@assets/images/qualitas.png';
import AIG from '@assets/images/AIG.png';
import GNP from '@assets/images/gnp.png';
import AXA from '@assets/images/AXA.png';
import Chubb from '@assets/images/Chubb.png';

import './styles.css';

interface Item {
  image: string;
  title: string;
}

const items: Item[] = [
  {
    title: 'Qualitas',
    image: Qualitas,
  },
  {
    title: 'Primero',
    image: Primero,
  },
  {
    title: 'AXA',
    image: AXA,
  },
  {
    title: 'Mapfre',
    image: Mapfre,
  },
  {
    title: 'HDI',
    image: HDI,
  },
  {
    title: 'AIG',
    image: AIG,
  },
  {
    title: 'GNP',
    image: GNP,
  },
  {
    title: 'Zurich',
    image: ZURICH,
  },
  {
    title: 'Chubb',
    image: Chubb,
  },
];

export const PartnersList: React.FC = () => {
  return (
    <div className="slider">
      <div className="slide-track">
        {items.map((item) => (
          <div className="slide">
            <img src={item.image} alt={'icon-insurance'} />
          </div>
        ))}
        {items.map((item) => (
          <div className="slide">
            <img src={item.image} alt={'icon-insurance'} />
          </div>
        ))}
        {items.map((item) => (
          <div className="slide">
            <img src={item.image} alt={'icon-insurance'} />
          </div>
        ))}
        {items.map((item) => (
          <div className="slide">
            <img src={item.image} alt={'icon-insurance'} />
          </div>
        ))}
      </div>
    </div>
  );
};
