import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {Theme, Box, Container, useTheme, useMediaQuery, Button} from '@mui/material';

import ImageBackground from '@assets/images/circless.png';
import { AccordionHiring } from '@screens/hiring/AccordionHiring';
import { Toats } from '@components/alerts/Toats';
import { useChangePrice } from '@hooks/useChangePrice';
import { useCarStore } from '@store/carInfoStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { useHistory } from 'react-router-dom';
import { useCPStore } from '@store/cpStore';
import { useTokenStore } from '@store/TokenStore';
import { CustomButton } from '@buttons/CustomButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import StickyButton from "@buttons/StickyButton";
import {upCRMContact} from "@utils/CRM";
import {useTrackingStore} from "@store/TrackingStore";
import {usePolicyStore} from "@store/policyStore";
import {NameSubstring} from "@utils/utilityFunctions";
import {useVisibleButtonHelp} from "@hooks/useVisibleButtonHelp";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#f5f5f5',
    paddingBottom: '40px',
  },
}));

export const Hiring: React.FC = () => {
  const { searchCP, searchCPInsurance } = useCPStore();
  const { token } = useTokenStore();
  const { car } = useCarStore();
  const { driver } = useDriverStore();
  const [zip, setZip] = useState<string>(driver.codigoPostal || '');
  const { selectedQuote } = useSelectedQuoteStore();
  const { tracking } = useTrackingStore();
  const { cpData } = useCPStore();
  const history = useHistory();
  const { policy } = usePolicyStore();

    const [url, setUrl] = useState<any>('');
    const [showToast, setShowToast] = useState(true);
  const [showErrorToast, setShowErrorToast] = useState(false);

    const [success, setSuccess] = useState(false);
    const [loadingSticky, setLoadingSticky] = useState(false);


    const classes = useStyles();
  const { breakpoints, palette } = useTheme();

  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));

  useEffect(() => {
    window.scroll(0, 0);
    selectedQuote.insurance === 'hdi' ||
    selectedQuote.insurance === 'aig' ||
    selectedQuote.insurance === 'aba'
      ? searchCPInsurance(zip, selectedQuote.insurance, token)
      : searchCP(zip, token);
  }, []);

  useChangePrice();

  if (
    car.assembler === '' ||
    driver.codigoPostal === '' ||
    selectedQuote.insurance === undefined
  ) {
    history.push('/insurer-details');
  }

    const sendApi = async () => {
        try {
            setLoadingSticky(true);
            let response = await upCRMContact(
                token,
                car,
                driver,
                selectedQuote,
                policy,
                tracking,
                cpData,
                selectedQuote,
                url
            );
            setTimeout(() => {
                if (response.success) {
                    setLoadingSticky(false);
                    setSuccess(true);
                } else {
                    setLoadingSticky(false);
                }
            }, 1000);
        }catch (e){
            setLoadingSticky(false);
            setSuccess(false);
        }


    };

    const title = <p style={{margin:0}}>Hola 👋 <strong>{NameSubstring(driver.name)}</strong> ¿Tienes dudas sobre cómo llenar tus datos?</p>;
    useVisibleButtonHelp();


  return (
    <div className={classes.root}>
      <Toats
        showToast={showToast}
        setShowToast={setShowToast}
        Text="Los datos que nos compartas en esta sección serán los que usaremos para la póliza, verifica que sean correctos."
        isWarning
      />
      <Toats
        showToast={showErrorToast}
        setShowToast={setShowErrorToast}
        Text="¡Debes llenar todos los campos que son obligatorios! Checa cuál te falta para continuar."
      />
      {!isTablet && (
        <Box
          component={'img'}
          src={ImageBackground}
          sx={{
            width: '100%',
          }}
          position={'absolute'}
          marginTop={20}
          alt={'Circle Background'}
          style={{ objectFit: 'cover', borderRadius: 8 }}
        />
      )}
        <Container maxWidth={'lg'}>
            <Box
                sx={{ flexGrow: 1 }}
                style={{
                    marginTop: 100,
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start'
                }}
            >
                <CustomButton
                    text={'Regresar'}
                    onClick={() => history.push('/insurer-details')}
                    size={isDesktop ? 'medium' : 'small'}
                    variant={'text'}
                    startIcon={<KeyboardArrowLeftIcon />}
                    style={{
                        color: '#006782',
                        fontSize: isMobile ? 14 : 16,
                        textTransform: 'none',
                        textDecoration: 'underline',
                    }}
                />
                <Button
                    startIcon={<AddIcon sx={{color: '#006782'}}  />}
                    onClick={() => {
                        history.push('/home');
                    }}
                    variant="outlined"
                    style={{
                        textTransform: 'none',
                        backgroundColor: '#FFFFFF',
                        padding: '14px 19px 14px 19px',
                        borderRadius: '4px',
                        height: '40px',
                        color: '#006782',
                        border: '1px solid #006782'
                    }}
                >
                    Nueva cotización
                </Button>
            </Box>
        </Container>
      <Container maxWidth={'lg'}>
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            marginTop: 10,
            minHeight: '70vh',
            position: 'relative',
          }}
        >
          {car.assembler !== '' &&
            driver.codigoPostal !== '' &&
            selectedQuote.insurance !== undefined && (
              <AccordionHiring
                setShowToast={setShowToast}
                setShowErrorToast={setShowErrorToast}
              />
            )}
        </Box>
      </Container>
        {
            ///isMobile &&
            <StickyButton
                loading={loadingSticky}
                success={success} action={sendApi} title={title} />
        }
    </div>
  );
};
