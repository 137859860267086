import { InterInstance } from '@api/config';

export const getPoliza = async (token: string, quote: any) => {
  try {
    const { data } = await InterInstance({
      method: 'post',
      url: '/pdf/policy',
      headers: { Authorization: `Bearer ${token}` },
      data: quote,
    });
    return data;
  } catch (e) {
    return e;
  }
};
