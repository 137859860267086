import create from 'zustand';
import { persist } from 'zustand/middleware';

interface InactiveStore {
    inactive: boolean;
    setInactive: (inactive: boolean) => void;
}

export const useInactiveStore = create<InactiveStore>(
    //@ts-ignore
    persist(
        (set) => ({
            inactive: false,
            setInactive: (inactive: boolean) => {
                set({ inactive });
            },
        }),
        {
            name: 'inactive-storage',
            getStorage: () => sessionStorage,
        }
    )
);





