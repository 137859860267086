interface Producto {
  name: string;
  url: string;
}

export const productos: Producto[] = [
  { name: 'Médico Seguro', url: 'https://medicoseguro.inter.mx/inicio' },
  {
    name: 'Mascota Segura',
    url: 'https://mascotasegura.inter.mx/seguro-mascota/landing',
  },
];
export const contactanos: string[] = ['55 4424 6837', 'hola@inter.mx'];
export const clientes: Producto[] = [
  //{ name: 'Login', url: 'https://www.interbi.mx/qlikview/FormLogin.htm' },
  { name: 'Aviso de privacidad', url: 'https://inter.mx/aviso-de-privacidad' },
];
