import { useMediaQuery, useTheme } from "@mui/material";

export function useClamp(){
    const { breakpoints } = useTheme();
    const isTablet = useMediaQuery(breakpoints.up('sm'));
    const isDesktop = useMediaQuery(breakpoints.up('md'));

    /**
     * This function selects the value you give, whether one, two or three values. According with the viewport width. If you give one value, this corresponds to desktop, two values are for desktop and tablet, and three values are for desktop, tablet and mobile. Order matters
     * @param {any} [desktopValue] - The value you want to use when desktop breakpoint has been reached.
     * @param {any} [tabletValue] - The value you want to use when tablet breakpoint has been reached.
     * @param {any} [mobileValue] - The value you want to use when mobile breakpoint has been reached.
     * @returns The value you want, according with the viewport width.
     */
    const clamp = (desktopValue: any, tabletValue?: any, mobileValue?: any) => {
        if (desktopValue && tabletValue && mobileValue) {
            return isDesktop ? desktopValue : isTablet ? tabletValue : mobileValue
        } 
        else if (desktopValue && tabletValue) {
            return isDesktop ? desktopValue : tabletValue
        } else {
            return desktopValue;
        }
    }

    return { clamp }
}