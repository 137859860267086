import React, { useState } from 'react';
import validator from 'validator';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { Check, Clear } from '@mui/icons-material';
import {
  Fade,
  Modal,
  Paper,
  Box,
  Backdrop,
  Typography,
  Theme,
  IconButton,
  TextField,
  Checkbox,
} from '@mui/material';

import { useForm } from '@hooks/useForm';
import { CustomButton } from '@buttons/CustomButton';

type ModalBenefitProps = {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    top: '50%',
    left: '50%',
    width: 420,
    borderRadius: 8,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
}));

export const ModalBenefit: React.FC<ModalBenefitProps> = ({ onClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const [checked, setChecked] = useState<boolean>(false);

  const [formState, setFormState] = useForm({
    email: '',
    phone: '',
  });

  const { email, phone } = formState;

  const isValidForm = (): boolean => {
    if (!validator.isEmail(email)) {
      return false;
    } else if (!validator.isMobilePhone(phone)) {
      return false;
    } else if (phone.length !== 10) {
      return false;
    } else if (!checked) {
      return false;
    }
    return true;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked);
  };

  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper className={classes.root}>
          <Box padding={2}>
            <Box
              marginBottom={2}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant={'h6'} fontWeight={'bold'}>
                {'¡Excelente decisión!'}
              </Typography>
              <IconButton onClick={onClose}>
                <Clear />
              </IconButton>
            </Box>
            <Typography marginBottom={2.4} variant={'subtitle2'}>
              {
                'Antes de que llenes los datos de contratación para crear tu póliza'
              }
            </Typography>
            {Object.keys(formState).map((key, i) => (
              <Box marginBottom={2} key={i}>
                <Typography
                  fontWeight={'600'}
                  marginBottom={1.4}
                  variant={'subtitle1'}
                >
                  {i === 0
                    ? '¿En qué correo deseas recibir tu póliza?'
                    : 'Para ayudarte en caso de que lo necesites ¿A qué teléfono podemos marcarte?'}
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name={key}
                  value={formState[key]}
                  onChange={(e) => setFormState(e)}
                  color={
                    (key === 'email' && validator.isEmail(formState[key])) ||
                    (key === 'phone' &&
                      validator.isMobilePhone(formState[key]) &&
                      formState[key].length === 10)
                      ? 'success'
                      : 'primary'
                  }
                  InputProps={{
                    style: {
                      borderRadius: 8,
                    },
                    endAdornment:
                      key === 'email' && validator.isEmail(formState[key]) ? (
                        <Check color={'success'} />
                      ) : key === 'phone' &&
                        validator.isMobilePhone(formState[key]) &&
                        formState[key].length === 10 ? (
                        <Check color={'success'} />
                      ) : null,
                  }}
                  label={key === 'email' ? 'Correo electrónico' : 'Teléfono'}
                />
              </Box>
            ))}
            <Box
              marginBottom={2.4}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
            >
              <Checkbox
                checked={checked}
                style={{ padding: 0 }}
                onChange={handleChange}
              />
              <Box marginLeft={1}>
                <Typography margin={0} variant={'subtitle2'}>
                  {'Acepto '}
                  <span
                    style={{
                      color: '#039ECC',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                  >
                    {'Aviso de Privacidad'}
                  </span>
                </Typography>
              </Box>
            </Box>
            <CustomButton
              text={'Entendido'}
              color={'primary'}
              variant={'contained'}
              disabled={!isValidForm()}
              onClick={() => {
                onClose();
                history.push('/hiring');
              }}
              style={{ width: '100%', height: 56, textTransform: 'none' }}
            />
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
