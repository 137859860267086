import axios from 'axios';

 const baseURL = 'https://api.inter.mx/v1';
//const baseURL = 'https://demos.inter.mx/v1';

export const InterInstance = axios.create({
  baseURL,
  headers: {
    AccessControlAllowOrigin: '*',
    AccessControlAllowMethods: 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

export const InterInstanceWithoutV1 = axios.create({
  baseURL: baseURL.slice(0, -3),
  headers: {
    AccessControlAllowOrigin: '*',
    AccessControlAllowMethods: 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});