import { useEffect, useContext } from 'react';

import { ZustandContext } from '@store/modalStore';
import { ModalTime } from '@modals/ModalHome/ModalTime';
import {useButtonNeedHelpVisibleStore} from "@store/buttonNeedHelpVisible";

export const useVisibleButtonHelp = (showModal: boolean = true) => {
  const { visibleButton, setVisibleButton } = useButtonNeedHelpVisibleStore();

  useEffect(() => {
      setTimeout(()=>{
          if (!visibleButton) {
              setVisibleButton(true)
          }
      },500)
  },[]);
};
