import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';

import { CodeDetailts } from './CodeDetailts';
import { CodePrice } from './CodePrice';
import { BanerPrices } from './BanerPrices';
import { Coupons } from './Coupons';

export const Code: React.FC = () => {
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));

  return (
    <Box
      padding={isMobile ? 1 : isTablet ? 2 : 2}
      marginTop={isTablet ? 2 : 0}
      width={isTablet ? '96%' : isMobile ? '100%' : '100%'}
      style={{
        backgroundColor: 'white',
        borderRadius: '10px',
      }}
    >
      <CodeDetailts />
      <CodePrice />
      <BanerPrices />
      {/* <PayButton /> */}
    </Box>
  );
};
