import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme, Typography, Tooltip, Grid } from '@mui/material';
import { ZustandContext } from '@store/modalStore';
import { Edit, Info } from '@mui/icons-material';
import { ModalChangeCP } from '@modals/ModalChangeCP';

interface DataInfo {
  title: string;
  description: string;
}

const dataInfo: DataInfo[] = [
  {
    title: 'Conductor asegurado',
    description:
      'Los datos del conductor asegurado nos permiten encontrar las mejores opciones para ti. Si los modificas, podrían cambiar las opciones.',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  aseguradotooltip: {
    width: '25%',
  },
  plantooltip: {
    width: '25%',
  },
}));

const DriverHeader = () => {
  const classes = useStyles();
  const { show, hide } = useContext(ZustandContext);

  const tooltip = `${dataInfo[0].description}`;

  return (
    <Grid>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        marginTop={0}
        justifyContent={'space-between'}
      >
        <Typography
          variant={'caption'}
          fontSize={'14px'}
          style={{
            fontWeight: 'bold',
            color: '#424242',
          }}
          textAlign={'start'}
        >
          {'Conductor asegurado'}
        </Typography>
        <Grid sx={{
          display:'flex'
        }}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Edit
            onClick={() => show(ModalChangeCP, { onClose: hide })}
            sx={{ cursor: 'pointer', marginRight:'5px', color: '#333436' }}
          />
        </Box>
        <Tooltip
          title={tooltip}
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: '#F2FAFC',
                color: '#212121',
              },
            },
          }}
        >
          <Info
            data-for="tooltip"
            data-tip=""
            data-iscapture="true"
            sx={{ cursor: 'pointer', color: '#006782' }}
          />
        </Tooltip>
      </Grid>
      </Box>
    </Grid>
  );
};

export default DriverHeader;
