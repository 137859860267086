import { InterInstance } from '@api/config';

export const getToken = async () => {
  try {
    const {
      data: { data },
    } = await InterInstance({
      method: 'post',
      url: `/authorization/token`,
      data: {
        client_id: 'MX1F7S90KJmA3a5YRuFCgK8u4iyEJbI3',
        client_secret:
          'SRj1uPRZok7zhpCE9y1HL26G_-tSphAcmGJcb_pTVVXgW3EBmCLbUmnLuSpG-veM',
      },
    });
    return data.access_token;
  } catch (e) {
    return e;
  }
};
