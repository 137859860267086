import { ArrowForward } from '@mui/icons-material';
import { CustomButton } from '@buttons/CustomButton';
import { useHistory } from 'react-router-dom';
import { usePlansStore, usePlansDataStore } from '@store/plansStore';
import DollarIcon from '@assets/images/dollar.svg';
import MedicineIcon from '@assets/images/icono-medical.svg';
import TuercaIcon from '@assets/images/tuerca.svg';

import React, { useEffect } from 'react';
import { useQuoteStore, useSelectedQuoteStore } from '@store/QuoteStore';

import TagManager from 'react-gtm-module';

import { upCRM } from '@utils/CRM';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { usePolicyStore } from '@store/policyStore';
import { useCPStore } from '@store/cpStore';
import { getTracking } from '@api/getTracking';

import {
  Grid,
  Paper,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Grow,
} from '@mui/material';

import { useCarStore } from '@store/carInfoStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useGetQuotePayment } from '@store/QuoteData';
import axios from "axios";

interface PlanProps {
  plan: any;
  showToast?: boolean;
  setShowToast?: Function;
}

export const Plan: React.FC<PlanProps> = ({
  plan,
  setShowToast,
}) => {
  const { breakpoints } = useTheme();
  const { plans } = usePlansStore();
  const { setSelectedPlan, selectedPlan } = usePlansDataStore();
  const { setSelectedQuote } = useSelectedQuoteStore();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const history = useHistory();
  const { token } = useTokenStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const { policy, addPolicyInfo } = usePolicyStore();
  const { tracking } = useTrackingStore();
  const { cpData } = useCPStore();
  const { Packages } = useGetQuotePayment();

  //DataLayer
  const { car } = useCarStore();
  const { driver } = useDriverStore();

  //DataLayer
  const DataLayer = {
    event: 'CSform_step_3_submit',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_3_submit',
    eventLabel: undefined,
    telefono: driver.telefono,
    correo: driver.email,
    noCotizacion: policy.policyNumber,
    modelo: car.description,
    marca: car.assembler,
    anio: car.model,
    paquete: plan.package,
    forma_pago: plan.payment_option,
    aseguradora: plan.insurance,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: car.type,
    colaborador: undefined,
    cupon: undefined,
    ecommerce: {
      currencyCode: 'MXN',
      add: {
        products: [
          {
            name: plan.insurance,
            id: undefined, //string
            price: plan.insurance_premium.total, //número
            brand:
              '[paquete:' + plan.package + '_pago:' + plan.payment_option + ']',
            category: 'SeguroCoche',
            variant: plan.vehicle.model,
            quantity: 1,
          },
        ],
      },
    },
  };
  const produc_detail = {
    event: 'productDetail',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_3_submit',
    eventLabel: 'productDetail',
    ecommerce: {
      detail: {
        actionField: {
          list: '[' + plan.insurance + ',' + plan.insurance_premium.total + ']',
          action: 'productDetail',
        },
        products: [
          {
            name: plan.insurance,
            id: undefined, //string
            price: plan.insurance_premium.total, //número
            brand:
              '[paquete:' + plan.package + '_pago:' + plan.payment_option + ']',
            category: 'SeguroCoche',
            variant: plan.vehicle.model,
            quantity: 1,
          },
        ],
      },
    },
  };
  const produc_click = {
    event: 'productClick',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_3_submit',
    eventLabel: 'productClick',
    ecommerce: {
      click: {
        detail: {
          actionField: {
            list:
              '[' + plan.insurance + ',' + plan.insurance_premium.total + ']',
            action: 'productDetail',
          },
          products: [
            {
              name: plan.insurance,
              id: undefined, //string
              price: plan.insurance_premium.total, //número
              brand:
                '[paquete:' +
                plan.package +
                '_pago:' +
                plan.payment_option +
                ']',
              category: 'SeguroCoche',
              variant: plan.vehicle.model,
              quantity: 1,
            },
          ],
        },
      },
    },
  };

  const { isFetching, quoteList } = useQuoteStore();

  useEffect(() => {
    (async () => {
      policy.stage = '1. Registro (Coche)';
      policy.sub_stage = '2. Datos Cotización';
      policy.state_code = 'Borrador';
      policy.status_code = 'En revisión';
      policy.customDM = car.type.toUpperCase() === 'MOTO'? '10':'5';
      policy.customRT = car.type.toUpperCase() === 'MOTO'? '20':'10';
      addPolicyInfo({ ...policy });
    })();
  }, []);

  useEffect(() => {
    /*@ts-ignore*/
    plans[0].title === '' && setShowToast(true);
    const DataLayerView = {
      event: 'CSform_step_3_view',
      eventCategoy: 'Formulario.Seguro.Auto.v2',
      eventAction: 'step_3_view',
      eventLabel: undefined,
      telefono: driver.telefono,
      correo: driver.email,
      noCotizacion: policy.policyNumber,
      modelo: car.description,
      marca: car.assembler,
      anio: car.model,
      paquete: undefined,
      forma_pago: undefined,
      aseguradora: undefined,
      tarjeta: undefined,
      tipo_tarjeta: undefined,
      tipo_vehiculo: car.type,
      colaborador: undefined,
      cupon: undefined,
      ecommerce: {
        currencyCode: 'MXN',
        impressions: [
          {
            name: plan.insurance,
            id: undefined, //string
            price: plan.insurance_premium.total, //numero
            brand:
              '[paquete:' + plan.package + '_pago:' + plan.payment_option + ']',
            category: 'SeguroCoche',
            variant: car.description,
            list: undefined,
            position: undefined,
          },
        ],
      },
    };
    const tagManagerView = {
      dataLayer: DataLayerView,
    };
    if(quoteList.length == 1){
      TagManager.dataLayer(tagManagerView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);


  const getIp = async () => {
    let ip: string = '';
    if (policy.ipInicio === '') {
      const res = await axios.get('https://api.ipify.org/?format=json');
      // await addPolicyInfo({ ...policy, ipInicio: res.data.ip });
      ip = res.data.ip;
    }
    return ip;
  };
  // const selectedQuoteIndex = quoteList.findIndex(quote => quote = selectedQuote)

  const tagManagerArgs = {
    dataLayer: DataLayer,
  };
  const tagManagerProduct = {
    dataLayer: produc_detail,
  };
  const tagManagerClick = {
    dataLayer: produc_click,
  };

  const personalizar = () => {
    setSelectedPlan(plan);
    setSelectedQuote(plan);
    TagManager.dataLayer(tagManagerArgs);
    TagManager.dataLayer(tagManagerProduct);
    TagManager.dataLayer(tagManagerClick);
    TagManager.dataLayer({
      dataLayer: {
        event: 'cocheapp_seguro_contratar',
        aseguradora: policy.package_new,
        modalidad_pago: policy.payment_new,
        costo_anual: selectedQuote.insurance_premium.total,
        Cotizacion: policy.policyNumber,
        boton_contratar: 'Contratar',
      },
    });
    const allQuotes = JSON.parse(sessionStorage.dataPlanes);
    for (let i = 0; i < allQuotes.items.length; i++) {
      if (JSON.stringify(allQuotes.items[i].aseguradora) == JSON.stringify(plan.insurance)){
        TagManager.dataLayer({
          dataLayer: {
            event : 'coche_seguro_cotizacion_lo_quiero',
            no_cotizacion : policy.policyNumber,
            tipo_vehiculo: car.type,
            plan: Packages.label,
            opciones: policy.order,
            celular : driver.telefono,
            email : driver.email,
            boton_cotizacion: '!LO QUIERO!',
            items:[
              {
                index: allQuotes.items[i].index,
                aseguradora: plan.insurance,
                costo_aseguradora_anual: plan.insurance_premium.total,
              },
            ]
          }
        })
      }
    }
    policy.stage = '1. Registro (Coche)';
    policy.sub_stage = '2. Datos Cotización';
    policy.state_code = 'Borrador';
    policy.status_code = 'En revisión';
    policy.customDM = car.type.toUpperCase() === 'MOTO'? '10':'5';
    policy.customRT = car.type.toUpperCase() === 'MOTO'? '20':'10';
    getIp().then((res) => {
      const ip_: string = res;
      policy.ipInicio = ip_;
    });
    addPolicyInfo({ ...policy });
    let initialData = {
      id: tracking.id,
      business: 'creditaria',
      data: {
        car: car,
        driver: driver,
        selectedQuote: selectedQuote,
        policy: policy,
        cpData: cpData,
      },
    };
    getTracking(token, initialData);
    upCRM(
      token,
      car,
      driver,
      selectedQuote,
      policy,
      tracking,
      cpData,
      selectedQuote
    );
    history.push('/insurer-details');
  };

  if (quoteList.length === 0 && !isFetching) {
    return (
      <Paper
        elevation={2}
        style={{
          borderRadius: 8,
          marginTop: 25,
          padding: 40,
        }}
      >
        <Box sx={{ color: 'red', fontSize: 20 }}>
          No hay cotizaciones para el Plan seleccionado.
        </Box>
      </Paper>
    );
  }
  return (
    <Grow
      in={true}
      style={{ transformOrigin: '0 0 0' }}
      {...(true ? { timeout: 1000 } : {})}
    >
      <Paper
        elevation={2}
        style={
          (plans.length < 3 && !isTablet) || (plans.length < 2 && isTablet)
            ? {
                borderRadius: 8,
                margin: 5,
                padding: 10,
              }
            : plans.find((i) => i.title === plan.title)
            ? {
                borderRadius: 8,
                margin: 5,
                padding: 10,
              }
            : {
                borderRadius: 8,
                margin: 5,
                padding: 10,
                opacity: '0.5',
              }
        }
      >
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} md={6} lg={4}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              padding={2}
            >
              {plan.insurance === 'axa' ? (
                <Box
                  component={'img'}
                  src={plan.company_logo}
                  sx={{
                    height: 70,
                    width: 100,
                  }}
                  style={{ objectFit: 'contain' }}
                  alt={plan.title}
                />
              ) : (
                <Box
                  component={'img'}
                  src={plan.company_logo}
                  sx={{
                    height: 32,
                    width: 100,
                  }}
                  style={{ objectFit: 'contain' }}
                  alt={plan.title}
                />
              )}

              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'end'}
                paddingLeft={3}
                marginLeft={2}
                style={{
                  borderLeftStyle: 'dashed',
                  borderLeft: '1px dashed #c7c7c7',
                }}
              >
                <Typography
                  textAlign={'start'}
                  variant={'body2'}
                  fontSize={'13px'}
                  style={{ color: '#757575' }}
                >
                  {'Costo anual:'}
                </Typography>
                <Typography
                  textAlign={'start'}
                  fontWeight={'bold'}
                  fontSize={isMobile ? '20px' : '28px'}
                >
                  $
                  {parseFloat(
                    parseFloat(plan.insurance_premium.total.toString()).toFixed(
                      2
                    )
                  ).toLocaleString('es-MX', { minimumFractionDigits: 2 })}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Box
              display={'flex'}
              flexDirection={isTablet ? 'column' : 'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              flexWrap={'wrap'}
              padding={2}
              borderRadius={2}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'flex-start'}
              >
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginTop={1}
                >
                  <Box
                    component="img"
                    sx={{
                      width: '20px',
                      height: '20px',
                    }}
                    alt={'dollar icon'}
                    src={DollarIcon}
                  />
                  <Typography
                    textAlign={'start'}
                    fontWeight={'400'}
                    fontSize={14}
                    marginLeft={1}
                  >
                    {
                      'Por si ocasionas daños con tu vehículo, tienes $3,000,000'
                    }
                  </Typography>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginTop={1}
                >
                  <Box
                    component="img"
                    sx={{
                      width: '20px',
                      height: '20px',
                    }}
                    alt={'dollar icon'}
                    src={MedicineIcon}
                  />
                  <Typography
                    textAlign={'start'}
                    fontWeight={'400'}
                    fontSize={14}
                    marginLeft={1}
                  >
                    {'Por si visitas al médico por un accidente'}
                  </Typography>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginTop={1}
                >
                  <Box
                    component="img"
                    sx={{
                      width: '20px',
                      height: '20px',
                    }}
                    alt={'Tuerca icon'}
                    src={TuercaIcon}
                  />
                  <Typography
                    textAlign={'start'}
                    fontWeight={'400'}
                    fontSize={14}
                    marginLeft={1}
                  >
                    {'Por si necesitas ayuda con tu vehículo o apoyo legal'}
                  </Typography>
                </Box>
              </Box>
              <Box
                display={'flex'}
                flexDirection={isTablet ? 'row' : 'column'}
                alignItems={'center'}
                marginTop={isTablet ? 2 : 0}
                justifyContent={isTablet ? 'center' : 'space-between'}
                width={isTablet ? '100%' : '40%'}
              >
                <CustomButton
                  size={isTablet ? 'small' : 'medium'}
                  text={'¡LO QUIERO!'}
                  color={'primary'}
                  variant={'contained'}
                  endIcon={<ArrowForward />}
                  style={
                    isTablet
                      ? {
                          textTransform: 'none',
                          width: '220px',
                          height: '40px',
                          color: "white"
                        }
                      : {
                          textTransform: 'none',
                          width: '187.37px',
                          height: '56px',
                          fontSize: '16px',
                          color: "white"
                        }
                  }
                  onClick={personalizar}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Grow>
  );
};

//Code to future use

/* {plans.find((i) => i.title === plan.title) ? (
                <Button
                  size={isTablet ? 'small' : 'medium'}
                  onClick={handleCompare}
                  onMouseLeave={() => setSelectionButton(<CheckIcon />)}
                  onMouseOver={() => setSelectionButton(<HighlightOffIcon />)}
                  variant={'outlined'}
                  disableElevation
                  style={
                    isTablet
                      ? {
                          backgroundColor: 'white',
                          color: '#039ECC',
                          width: '120px',
                          borderRadius: '8px',
                          height: '40px',
                        }
                      : {
                          backgroundColor: 'white',
                          color: '#039ECC',
                          width: '150px',
                          marginTop: '10px',
                          borderRadius: '8px',
                        }
                  }
                >
                  {selectionButton}
                </Button>
              ) : (
                <CustomButton
                  size={isTablet ? 'small' : 'medium'}
                  onClick={handleCompare}
                  text={'Comparar'}
                  variant={'contained'}
                  startIcon={<CompareArrowsIcon />}
                  style={
                    isTablet
                      ? {
                          textTransform: 'none',
                          backgroundColor: 'white',
                          color: '#039ECC',
                          width: '120px',
                          height: '40px',
                        }
                      : {
                          textTransform: 'none',
                          marginTop: '10px',
                          backgroundColor: 'white',
                          color: '#039ECC',
                          width: '150px',
                        }
                  }
                />
              )} */
