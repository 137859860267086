import create from 'zustand';
import { persist } from 'zustand/middleware';

interface CouponData {
  id: string;
  title: string;
  description: string;
  code: string;
  total: number;
  start_at: any;
  end_at: any;
  promo_picture: string;
  doc_name: string;
  type_promotion: string;
  amount: number;
  updated_by: string;
  created_by: string;
  updated_at: string;
  created_at: string;
}
interface Driver {
  firstName: string;
  middleName: string;
  lastName: string;
  RFC?: string;
  colonia?: string;
  street?: string;
  estado?: string;
  stateCode?: string;
  suburbCode?: string;
  numeroExterior?: string;
  numeroInterior?: string;
  zip?: string;
}

interface Contrator {
  firstName: string;
  middleName: string;
  lastName: string;
  fechaNacimiento: Date | null | string;
  RFC: string;
  email: string;
  telefono: string;
  genero: string;
}

interface PolicyInfo {
  customDM: string;
  customRT: string;
  policyNumber: string;
  acceptCoupon: string;
  uuid: string;
  ipInicio: string;
  ipRecuperado: string;
  endDateTime: string;
  code: string;
  codeCoupon: CouponData;
  employee_id: string;
  no_poliza: string;
  url_poliza: string;
  order: string;
  url_recibo: string;
  stage: string;
  sub_stage: string;
  status_code: string;
  state_code: string;
  package: string;
  payment_option: string;
  package_anterior: string;
  payment_anterior: string;
  package_new: string;
  payment_new: string;
  package_label: string;
  payment_label: string;
  payment_warning?: string;
  error: boolean;
  basico: boolean;
  Niv: string;
  plate: string;
  device: string;
  scoreLead: string;
  checks: {
    first: boolean;
    second: boolean;
    third: boolean;
    fourth: boolean;
  };
  bill: string;
  regimen: string;
  regimenFiscal: string;
  driver: Driver;
  dataPromotion?: {
    name: string;
    option: string;
    email?: string;
    telefono?: string;
  };
  contrator?: Contrator;
  street: string;
  numeroExterior: string;
  numeroInterior: string;
  colonia: string;
  colonia_name?: string;
  estado_name?: string;
  estado: string;
  selectorDriver: string;
  taxResidence: {
    zip: string;
    street: string;
    numeroExterior: string;
    numeroInterior: string;
    colonia_name?: string;
    estado_name?: string;
    colonia: string;
    estado: string;
    city?: string;
  };
}

interface Policy {
  policy: PolicyInfo;
  addPolicyInfo: (policy: PolicyInfo) => void;
  removePolicyInfo: () => void;
  setEmployeeId: (id: string) => void;
}

const initialDriverData = {
  firstName: '',
  middleName: '',
  lastName: '',
  RFC: '',
};
const initialCouponData = {
  id: '',
  title: '',
  description: '',
  code: '',
  total: 0,
  start_at: 0,
  end_at: 0,
  promo_picture: '',
  doc_name: '',
  type_promotion: '',
  amount: 0,
  updated_by: '',
  created_by: '',
  updated_at: '',
  created_at: '',
};

export const usePolicyStore = create<Policy>(
  //@ts-ignore
  persist(
    (set, get) => ({
      policy: {
        customDM: '5',
        customRT: '10',
        policyNumber: '0001',
        acceptCoupon: '',
        uuid: '',
        ipInicio: '',
        ipRecuperado: '',
        endDateTime: '',
        codeCoupon: initialCouponData,
        code: '',
        employee_id: '',
        stage: '',
        sub_stage: '',
        status_code: '',
        state_code: '',
        order: 'Todas las opciones',
        no_poliza: '',
        url_poliza: '',
        url_recibo: '',
        package: 'AMP',
        payment_option: 'A',
        package_anterior: 'AMP',
        payment_anterior: 'A',
        package_new: 'AMP',
        payment_new: 'A',
        package_label: 'Plan Amplio',
        payment_label: 'Anual',
        payment_warning: '',
        error: false,
        basico: true,
        Niv: '',
        plate: '',
        device: '',
        scoreLead: '',
        checks: {
          first: false,
          second: false,
          third: false,
          fourth: false,
        },
        bill: false,
        regimen: '',
        regimenFiscal: '',
        driver: initialDriverData,
        street: '',
        colonia_name: '',
        estado_name: '',
        numeroExterior: '',
        numeroInterior: '',
        colonia: '',
        estado: '',
        selectorDriver: '',
        taxResidence: {
          zip: '',
          street: '',
          numeroExterior: '',
          numeroInterior: '',
          colonia_name: '',
          estado_name: '',
          colonia: '',
          estado: '',
          city: '',
        },
      },
      addPolicyInfo: (policy) => {
        set({
          policy,
        });
      },
      setEmployeeId: (id) => {
        const newState = { policy: { ...get().policy, employee_id: id }}
        set(newState)
      },
      removePolicyInfo: () => {
        set({
          policy: {
            customDM: '5',
            customRT: '10',
            policyNumber: '0001',
            acceptCoupon: '',
            uuid: '',
            ipInicio: '',
            ipRecuperado: '',
            endDateTime: '',
            codeCoupon: initialCouponData,
            code: '',
            employee_id: '',
            stage: '',
            sub_stage: '',
            status_code: '',
            state_code: '',
            order: 'Todas las opciones',
            no_poliza: '',
            url_poliza: '',
            url_recibo: '',
            package: 'AMP',
            payment_option: 'A',
            package_anterior: 'AMP',
            payment_anterior: 'A',
            package_new: 'AMP',
            payment_new: 'A',
            package_label: 'Plan Amplio',
            payment_label: 'Anual',
            payment_warning: '',
            error: false,
            basico: true,
            Niv: '',
            plate: '',
            device: '',
            scoreLead: '',
            checks: {
              first: false,
              second: false,
              third: false,
              fourth: false,
            },
            bill: '',
            regimen: '',
            regimenFiscal: '',
            driver: initialDriverData,
            street: '',
            colonia_name: '',
            estado_name: '',
            numeroExterior: '',
            numeroInterior: '',
            colonia: '',
            estado: '',
            selectorDriver: '',
            taxResidence: {
              zip: '',
              street: '',
              numeroExterior: '',
              numeroInterior: '',
              colonia_name: '',
              estado_name: '',
              colonia: '',
              estado: '',
              city: '',
            },
          },
        });
      },
    }),
    {
      name: 'policy-storage',
      getStorage: () => localStorage,
    }
  )
);
