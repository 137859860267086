import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material';

import { CustomButton } from '@buttons/CustomButton';
import welcomeCheckGreen from '@assets/images/WelcomeCheckGreen.svg';
import googlePlay from '@assets/images/googlePlay.svg';
import appStore from '@assets/images/appStore.svg';
import { ZurichWelcome } from '../payment/ZurichWelcome';
import { useHistory } from 'react-router-dom';
import { usePolicyStore } from '@store/policyStore';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useCarStore } from '@store/carInfoStore';
import TagManager from 'react-gtm-module';
import React, { useEffect } from 'react';
import { useChangePrice } from '@hooks/useChangePrice';
import { Tag } from '@mui/icons-material';

interface Props {
  isZurich?: boolean;
}

export const GoodWelcome = ({ isZurich }: Props) => {
  const { breakpoints, palette } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const history = useHistory();
  const { policy } = usePolicyStore();
  const { driver } = useDriverStore();
  const { car: Car } = useCarStore();
  const { selectedQuote } = useSelectedQuoteStore();

  const handleRecibo = () => {
    if (policy.url_poliza) {
      window.open(policy.url_poliza, '_blank');
    }
  };
  const DataLayer = {
    event: 'CSform_step_10_view',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_10_view',
    eventLabel: undefined,
    telefono: driver.telefono,
    correo: driver.email,
    noCotizacion: policy.policyNumber,
    modelo: Car.description,
    marca: Car.assembler,
    anio: Car.model,
    paquete: selectedQuote.package,
    forma_pago: selectedQuote.payment_option,
    aseguradora: selectedQuote.insurance,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: Car.type,
    colaborador: policy.employee_id,
    cupon:policy.code,
    ecommerce: {
      purchase: {
        actionField: {
          id: policy.no_poliza,
          affiliation: 'Inter-CocheSeguro',
          revenue: selectedQuote.insurance_premium.total,
          tax: selectedQuote.insurance_premium.tax,
          shipping: undefined,
          coupon: policy.code,
        },
        products: [
          {
            name: selectedQuote.insurance,
            id: undefined, //string
            price: parseInt(selectedQuote.insurance_premium.total), //numero
            brand:
              '[paquete:' +
              selectedQuote.package +
              '_pago:' +
              selectedQuote.payment_option +
              ']',
            category: 'SeguroCoche',
            variant: Car.model,
            quantity: 1,
          },
        ],
      },
    },
  };
  const DataLayerSubmit = {
    event: 'CSform_step_10_submit',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_10_submit',
    eventLabel: undefined,
    telefono: driver.telefono,
    correo: driver.email,
    noCotizacion: policy.policyNumber,
    modelo: Car.description,
    marca: Car.assembler,
    anio: Car.model,
    paquete: selectedQuote.package,
    forma_pago: selectedQuote.payment_option,
    aseguradora: selectedQuote.insurance,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: Car.type,
    colaborador: policy.employee_id,
    cupon:policy.code
  };
  useEffect(() => {
    TagManager.dataLayer({ dataLayer: DataLayer });
    TagManager.dataLayer({
      dataLayer: {
        event : 'coche_seguro_confirmar_compra',
        no_cotizacion:  policy.policyNumber,
        modalidad_pago : selectedQuote.payment_options_json[0].label,
        plan: policy.package_label,
        no_poliza: policy.no_poliza,
        colaborador: policy.employee_id,
        codigo_promocion: policy.code,
        aseguradora: selectedQuote.insurance,
        costo_anual: selectedQuote.insurance_premium.total,
      },
    });
  }, []);

  const handleClick = () => {
    TagManager.dataLayer({ dataLayer: DataLayerSubmit });
    TagManager.dataLayer({
      dataLayer: {
        event : 'coche_seguro_pago_exitoso_click',
        boton_descarga_app: '',
        boton_compra_exitosa: 'Ya estuvo',    
      }
    });
    history.push('/');
  };

  const handleAndroidClick = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=grut.inter.mx',
      '_blank'
    );
    TagManager.dataLayer({
      dataLayer: {
        event : 'coche_seguro_pago_exitoso_click',
        boton_descarga_app: 'Android',
        boton_compra_exitosa: '',    
      }
    })
  }

  const handleAppleClick = () => {
    window.open(
      'https://apps.apple.com/mx/app/intermx/id1548367767?l=en',
      '_blank'
    );
    TagManager.dataLayer({
      dataLayer: {
        event : 'coche_seguro_pago_exitoso_click',
        boton_descarga_app: 'Apple',
        boton_compra_exitosa: '',    
      }
    })
  }

  useChangePrice(false);

  if (isZurich) {
    return <ZurichWelcome />;
  }

  return (
    <Box
      padding={`${isMobile ? '0px 20px' : '30px'}`}
      marginTop={isTablet ? 2 : 0}
      marginBottom="45px"
      width={isTablet ? 'auto' : '60%'}
      style={{
        backgroundColor: 'white',
        borderRadius: '10px',
        margin: `${isMobile ? '0px 0px 60px 0px' : '30px auto 50px auto'}`,
      }}
      textAlign="center"
    >
      <Box
        component={'img'}
        src={welcomeCheckGreen}
        sx={{
          width: 80,
        }}
        alt="Bienvenido"
        style={{ objectFit: 'contain' }}
        margin="25px 0px 20px 0px"
      />
      <Typography
        style={{ fontWeight: 700 }}
        fontSize={`${isDesktop ? '28px' : '20px'}`}
      >
        ¡Ya eres parte de Inter!
      </Typography>
      <Typography
        style={{
          fontWeight: 700,
          maxWidth: '600px',
          wordSpacing: '2px',
        }}
        fontSize="16px"
        margin="20px auto "
      >
        Gracias por ayudarnos a construir un mundo cada vez más seguro, seguro.
        ¡Ya está lista tu póliza!
      </Typography>
      <Typography
        fontSize={`${isDesktop ? '16px' : '16px'}`}
        margin="8px 0px 20px 0px"
        fontWeight="400"
      >
        Hoy seguro que duermes seguro <br />{' '}
        <span role="img" aria-label="arrow">
          😴
        </span>{' '}
        <span role="img" aria-label="arrow">
          ✨
        </span>
      </Typography>
      <Typography
        style={{
          fontWeight: 700,
          maxWidth: '600px',
          wordSpacing: '2px',
        }}
        fontSize="16px"
        margin="20px auto  0px auto "
      >
        ¡Checa tu correo!
      </Typography>
      <Typography
        style={{ fontWeight: 400 }}
        fontSize="16px"
        margin="0px 20px 20px 20px"
        padding={isDesktop ? '0px 130px' : '0px'}
      >
        En un momentito te enviaremos el resumen de tu compra y tu póliza.
      </Typography>
      <Typography
        style={{
          fontWeight: 700,
          maxWidth: '600px',
          wordSpacing: '2px',
        }}
        fontSize="16px"
        margin="0px auto "
      >
        ¡Recuerda descargar la App INTER.MX!
      </Typography>
      <Typography
        style={{ fontWeight: 400, textAlign: 'center' }}
        fontSize="16px"
        margin="26px 0px 20px 0px"
        padding={isDesktop ? '0px 130px' : '0px'}
      >
        Para que puedas llevar a donde sea todos tus seguros y encontrar
        exclusivos beneficios por ser parte de
        <strong> inter.mx</strong>
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box marginTop="23px">
          <Box
            onClick={() => handleAndroidClick()}
            component="img"
            src={googlePlay}
            sx={{
              width: 110,
            }}
            alt="Google Play"
            style={{ objectFit: 'contain', cursor: 'pointer' }}
            margin="0px 10px 6px"
          />
          <Box
            onClick={() => handleAppleClick() }
            component="img"
            src={appStore}
            sx={{
              width: 110,
            }}
            alt="App Store"
            style={{ objectFit: 'contain', cursor: 'pointer' }}
            margin="20px 10px 6px"
          />
          <Typography
            fontSize="16px"
            marginTop={`${isDesktop ? '23px' : '50px'}`}
            style={{
              maxWidth: '500px',
            }}
          >
            ¿Te confundiste o no sabes qué onda? Nosotros te ayudamos, échanos
            un telefonazo al
            <a href="tel:+525544246837">
              <CustomButton
                text="55 4424 6837"
                onClick={() => null}
                size="small"
                color={'inherit'}
                variant={'text'}
                style={{
                  color: palette.primary.main,
                  textTransform: 'none',
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                }}
              />
            </a>
          </Typography>
        </Box>
        {selectedQuote.insurance === 'mapfre' && (
          <Button
            variant="contained"
            onClick={handleRecibo}
            style={
              isDesktop
                ? {
                    width: '40%',
                    textTransform: 'none',
                    height: '56px',
                    marginTop: '45px',
                  }
                : {
                    width: '100%',
                    textTransform: 'none',
                    marginTop: '20px',
                    height: '56px',
                  }
            }
          >
            <Typography fontSize="16px" fontWeight={600}>
              ¡Descargar póliza!
            </Typography>
          </Button>
        )}
        <Button
          variant="contained"
          onClick={handleClick}
          style={
            isDesktop
              ? {
                  width: '40%',
                  textTransform: 'none',
                  height: '56px',
                  marginTop: '45px',
                }
              : {
                  width: '100%',
                  textTransform: 'none',
                  marginTop: '20px',
                  height: '56px',
                }
          }
        >
          <Typography fontSize="16px">¡Ya estuvo!</Typography>
        </Button>
      </Box>
    </Box>
  );
};
