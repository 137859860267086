import { InterInstance } from '@api/config';
import { CarListData } from '@models/store/carListStore';

export const mockData: CarListData[] = [
  {
    vehicle_id: '609068-888712-248119-1649164012',
    description: 'YARIS SEDAN CORE STD 1.5L 4CIL 4PTAS',
    assembler: 'TOYOTA',
    model: '2015',
    type: 'Car',
    insurers: [
      'zurich',
      'mapfre',
      'gnp',
      'aig',
      'qualitas',
      'axa',
      'aba',
      'primeroseguros',
      'hdi',
    ],
  },
];

export const getCarListApi = async (
  year: string,
  assembler: string,
  token: string,
  type: string
) => {
  try {
    const { data } = await InterInstance.get(
      `/catalogs/vehicle/creditaria/search?assembler=${assembler.toUpperCase()}&mod=${year}&type=${type}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return data.data;
  } catch (e) {
    return mockData;
  }
};

export const getAssamblerList = async (token: string, type: string) => {
  try {
    const { data } = await InterInstance.get(
      `/catalogs/assembler?type=${type}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data.data;
  } catch (e) {
    return mockData;
  }
};
