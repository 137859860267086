import React, { useState, useEffect, useContext } from 'react';
import validator from 'validator';
import { validateEmail } from '@utils/utilityFunctions';
import InfoIcon from '@mui/icons-material/Info';
import { useHistory } from 'react-router-dom';
import {
  ArrowForward,
  Check,
  Error,
  ExpandMore,
  Info,
  Today,
  Warning,
} from '@mui/icons-material';
import Switch from '@mui/material/Switch';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Box,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Autocomplete,
  Theme,
  FormHelperText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useForm } from '@hooks/useForm';
import { CustomButton } from '@buttons/CustomButton';
import { usePolicyStore } from '@store/policyStore';
import { useDriverStore } from '@store/driverInfoStore';
import { getTracking } from '@api/getTracking';
import { useCarListStore } from '@store/carListStore';
import { initialCPData } from '@store/cpStore';
import { calculateAge } from '@utils/utilityFunctions';
import TimeSVG from '@assets/images/TimeIcon.svg';
import { createRFC } from '@utils/createRFC';

import { useSelectedQuoteStore } from '@store/QuoteStore';
import { upCRM } from '@utils/CRM';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { useCPStore } from '@store/cpStore';
import { useCarStore } from '@store/carInfoStore';
import { formatDate } from '@utils/formatDate';
import { ZustandContext } from '@store/modalStore';
import { ModalNext } from '@modals/ModalHome/ModalNext';
import ReactTooltip from 'react-tooltip';

import TagManager from 'react-gtm-module';
import { CPCustomMask } from '../../inputCustomMasks/CPCustomMask';

const regimenFiscal: { [key: string]: string } = {
  '605': 'Sueldos y Salarios e Ingresos Asimilados a Salarios',
  '606': 'Arrendamiento',
  '607': 'Régimen de Enajenación o Adquisición de Bienes',
  '608': 'Demás ingresos',
  '610': 'Residentes en el Extranjero sin Establecimiento Permanente en México',
  '611': 'Ingresos por Dividendos (socios y accionistas)',
  '612': 'Personas Físicas con Actividades Empresariales y Profesionales',
  '614': 'Ingresos por intereses',
  '615': 'Régimen de los ingresos por obtención de premios',
  '616': 'Sin obligaciones fiscales',
  '621': 'Incorporación Fiscal',
  '625':
    'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
  '626': 'Régimen Simplificado de Confianza',
};

type ErrorControl = {
  isError?: boolean;
  isWarning?: boolean;
  correct?: boolean;
  msg: string;
};

type DataContratorProps = {
  additionalConfiguration: {
    shouldBill: boolean;
    isDriverSameContractor: boolean;
  };
  expanded: boolean;
  setActive: React.Dispatch<
    React.SetStateAction<{ first: boolean; second: boolean; third: boolean }>
  >;
  setShowErrorToast: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme: Theme) => ({
  aseguradotooltip: {
    width: '25%',
    '@media (max-width:780px)': {
      width: '80%',
    },
  },
  plantooltip: {
    width: '25%',
    '@media (max-width:780px)': {
      width: '80%',
    },
  },
}));
export const DataContrator: React.FC<DataContratorProps> = ({
  additionalConfiguration,
  expanded,
  setActive,
  setShowErrorToast,
}) => {
  const errorControl: ErrorControl = {
    msg: '',
    isError: false,
    isWarning: false,
    correct: false,
  };
  const { policy, addPolicyInfo } = usePolicyStore();
  const { driver } = useDriverStore();
  const [isSameAddress, setIsSameAddress] = useState<boolean>(true);
  
  const handleSame = (event: any) => {
    setIsSameAddress(event.target.checked);
  };

  const { car } = useCarStore();
  const { token } = useTokenStore();
  const { tracking } = useTrackingStore();
  const { cpData } = useCPStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const history = useHistory();
  const [colorM, setColorM] = useState<string>('transparent');
  const [colorF, setColorF] = useState<string>('#F5FBFD');
  const [backM, setBackM] = useState<string>('#424242');
  const [backF, setBackF] = useState<string>('#039ECC');

  const { carList } = useCarListStore();
  const classes = useStyles();

  const [errorTelefono, setErrorTelefono] = useState({
    msg: '',
    isError: false,
  });
  const [warningTelefono, setWarningTelefono] = useState({
    msg: '',
    isWarning: false,
  });

  const [valueDriver] = useState<Date | null>(driver.fechaNacimiento || '');
  const [errorName, setErrorName] = useState({ msg: '', isError: false });
  const [warningName, setWarningName] = useState({ msg: '', isWarning: false });

  const [errorMiddleName, setErrorMiddleName] = useState({
    msg: '',
    isError: false,
  });
  const [warningMiddleName, setWarningMiddleName] = useState({
    msg: '',
    isWarning: false,
  });

  const [errorLastName, setErrorLastName] = useState({
    msg: '',
    isError: false,
  });
  const [warningLastName, setWarningLastName] = useState({
    msg: '',
    isWarning: false,
  });

  const [errorRFC, setErrorRFC] = useState({
    msg: '',
    isError: false,
  });
  const [warningRFC, setWarningRFC] = useState({
    msg: '',
    isWarning: false,
  });

  const { searchCP, searchCPInsurance } = useCPStore();
  const [coloniasPorCP, setColoniasPorCP] = useState<any>([initialCPData]);

  let dateArray: string[] | undefined;
  const [valueContrator, setValueContrator] = useState<Date | null | string>(
    ''
  );

  const [formDriverState] = useForm({
    driverName: policy.driver.firstName
      ? policy.driver.firstName
      : driver.name
      ? driver.name
      : '',
    driverMiddleName: policy.driver.middleName || '',
    driverLastName: policy.driver.lastName || '',
    driverEmail: driver.email || '',
    driverConfirmEmail: driver.email || '',
    driverPhone: driver.telefono || '',
  });

  const [contratorName, setContratorName] = useState(
    policy.contrator?.firstName || ''
  );
  const [contratorMiddleName, setContratorMiddleName] = useState(
    policy.contrator?.middleName || ''
  );
  const [contratorLastName, setContratorLastName] = useState(
    policy.contrator?.lastName || ''
  );
  const [contratorEmail, setContratorEmail] = useState(
    policy.contrator?.email || ''
  );

  const [zip, setZip] = useState(policy.taxResidence.zip || '');
  const [taxState, setTaxState] = useState(
    policy.taxResidence.estado_name || ''
  );
  const [taxCodeState, setTaxCodeState] = useState(
    policy.taxResidence.estado || ''
  );
  const [taxColonia, setTaxColonia] = useState(
    policy.taxResidence.colonia || ''
  );
  const [taxStreet, setTaxStreet] = useState(policy.taxResidence.street || '');
  const [taxExternalNumber, setTaxExternalNumber] = useState(
    policy.taxResidence.numeroExterior || ''
  );
  const [taxInternalNumber, setTaxInternalNumber] = useState(
    policy.taxResidence.numeroInterior || ''
  );
  const [phone, setPhone] = useState(policy.contrator?.telefono || '');
  const [errorBorn, setErrorBorn] = useState<ErrorControl>(errorControl);
  const [errorState, setErrorState] = useState<ErrorControl>(errorControl);
  const [errorCP, setErrorCP] = useState<ErrorControl>(errorControl);
  const [errorColonia, setErrorColonia] = useState<ErrorControl>(errorControl);
  const [errorStreet, setErrorStreet] = useState<ErrorControl>(errorControl);
  const [errorExtNumber, setErrorExtNumber] =
    useState<ErrorControl>(errorControl);
  const [errorEmail, setErrorEmail] = useState<ErrorControl>(errorControl);
  const [sexoContractor, setSexoContractor] = useState(
    driver.genero || 'Mujer'
  );
  const [anterior, setAnterior] = useState<string>(driver.genero || 'Mujer');
  const [contratorRFC, setContratorRFC] = useState(policy.contrator?.RFC || '');
  const [coloniaName, setColoniaName] = useState(
    policy.taxResidence.colonia_name || ''
  );
  const [city, setCity] = useState(policy.taxResidence.city || '');

  const { show, hide } = useContext(ZustandContext);
  const completar = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'CS_interactions',
        eventCategory: 'Formulario.Seguro.Auto.v2',
        eventAction: 'Interacciones',
        eventLabel: 'CompletarMasTarde_2', //Texto del botón al que se hizo clic.
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'coche_seguro_completar_mas_tarde', //Static data
        no_cotizacion: policy.policyNumber,
        paso: 'Paso 3',
        boton_contratante: 'Completar más tarde',
      },
    });
    show(ModalNext, { onClose: hide });
  };

  const { driverLastName } = formDriverState;

  const getCitiesByPostalCode = async (zipCode: string) => {
    // @ts-ignore
    const data: CPData[] =
      selectedQuote.insurance === 'hdi' ||
      selectedQuote.insurance === 'aig' ||
      selectedQuote.insurance === 'aba'
        ? await searchCPInsurance(zipCode, selectedQuote.insurance, token)
        : await searchCP(zipCode, token);
    if (Object.keys(data).find((k) => k === 'errors')) {
      setErrorCP({
        isError: true,
        msg: '¡Oops! Parece que hay un error en tu Código Postal.',
      });
      setErrorState({
        isError: true,
        msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
      });
      setTaxState('');
      setColoniasPorCP([]);
    } else {
      setTaxState(data[0]['state_name'] || '');
      setTaxCodeState(data[0]['state_code'] || '');
      setCity(data[0]['township_code'] || '');
      setColoniasPorCP(data);
    }
  };

  useEffect(() => {
    if (additionalConfiguration.isDriverSameContractor) {
      setSexoContractor(driver.genero || '');
      setContratorName(driver.name || '');
      setContratorMiddleName(policy.driver.middleName || '');
      setContratorLastName(policy.driver.lastName || '');
      setPhone(driver.telefono || '');
      setContratorEmail(driver.email);
      setContratorRFC(policy.driver.RFC || '');
      setValueContrator(valueDriver || '');
      setZip(driver.codigoPostal || '');
      setTaxState(policy.driver.estado || '');
      setTaxColonia(policy.driver.suburbCode || '');
      setTaxStreet(policy.driver.street || '');
      setTaxExternalNumber(policy.driver.numeroExterior || '');
      setTaxInternalNumber(policy.driver.numeroInterior || '');
    } else {
      let contratorDate;
      dateArray = policy.contrator?.fechaNacimiento?.toString().split('-');

      setSexoContractor(policy.contrator?.genero || sexoContractor);
      if (dateArray) {
        const dia = dateArray[2];
        const mes = dateArray[1];
        const anio = dateArray[0];
        contratorDate = `${dia}-${mes}-${anio}`;
        setValueContrator(policy.contrator?.fechaNacimiento || contratorDate);
      }
      setContratorName(policy.contrator?.firstName || '');
      setContratorMiddleName(policy.contrator?.middleName || '');
      setContratorLastName(policy.contrator?.lastName || '');
      setPhone(policy.contrator?.telefono || '');
      setContratorEmail(policy.contrator?.email || '');
      setContratorRFC(policy.contrator?.RFC || '');
    }

    if (!additionalConfiguration.isDriverSameContractor || !isSameAddress && additionalConfiguration.shouldBill) {
      setZip(policy.taxResidence.zip || '');
      setTaxState(policy.taxResidence.estado_name || '');
      setTaxColonia(policy.taxResidence.colonia || '');
      setColoniaName(policy.taxResidence.colonia_name || '');
      setTaxStreet(policy.taxResidence.street || '');
      setTaxExternalNumber(policy.taxResidence.numeroExterior || '');
      setTaxInternalNumber(policy.taxResidence.numeroInterior || '');
    }
  }, [
    additionalConfiguration.isDriverSameContractor,
    additionalConfiguration.shouldBill,
    isSameAddress,
  ]);

  const handleRegimenFiscalError = (): boolean => {
    if(policy.regimenFiscal === '' || policy.regimenFiscal === null) return true 
    return false
  }

  const handleRegimenFiscalErrorLabel = (): string => {
    if(policy.regimenFiscal === '' || policy.regimenFiscal === null) return '¡Oh, no! Te falta llenar este campo para poder continuar.' 
    return ''
  }

  const handleChangeGender = (
    _: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment != null && anterior != null) {
      setAnterior(newAlignment);
    } else if (anterior != null) {
      newAlignment = anterior;
    } else {
      newAlignment = 'Mujer';
    }
    setSexoContractor(newAlignment);
    if (newAlignment === 'Hombre' || newAlignment == null) {
      setColorM('#F5FBFD');
      setColorF('transparent');
      setBackM('#039ECC');
      setBackF('#424242');
    } else {
      setColorF('#F5FBFD');
      setColorM('transparent');
      setBackM('#424242');
      setBackF('#039ECC');
    }
  };

  useEffect(() => {
    setZip(cpData[0].zip_code);
    setErrorColonia({ isError: false, correct: true, msg: '' });
    setErrorStreet({ isError: false, correct: true, msg: '' });
    setErrorExtNumber({ isError: false, correct: true, msg: '' });
    setErrorCP({ isError: false, correct: true, msg: '' });
    setErrorState({ isError: false, correct: true, msg: '' });
    getCitiesByPostalCode(cpData[0].zip_code);
    if (driver.genero === 'Hombre') {
      setColorM('#F5FBFD');
      setColorF('transparent');
      setBackM('#039ECC');
      setBackF('#424242');
    } else {
      setColorF('#F5FBFD');
      setColorM('transparent');
      setBackM('#424242');
      setBackF('#039ECC');
    }
    window.scroll(0, 0);
  }, []);

  const handleChangeContratorBirthday = (newValue: Date | null) => {
    (async () => {
      await setValueContrator(formatDate(newValue));
      if (contratorLastName) {
        let formattedDate = JSON.stringify(formatDate(newValue));
        formattedDate = formattedDate.slice(1, 11);
        const date = formattedDate.split('-');

        const rfc = createRFC({
          name: contratorName,
          lastName: contratorMiddleName,
          secondName: contratorLastName,
          birthday: `${date[0].split(' ').join('')}-${date[1]
            .split(' ')
            .join('')}-${+date[2].split(' ').join('')}`,
        });
        setContratorRFC(rfc);
      }
      setErrorRFC({ msg: '', isError: false });
      setWarningRFC({ msg: '', isWarning: false });
    })();
  };

  const isLonger = (value: string): boolean => {
    if (value.length <= 13) {
      return true;
    }
    return false;
  };

  const isCorrectLonger = (value: string): boolean => {
    if (value.length >= 10) {
      return true;
    }
    return false;
  };

  const isValidContratorForm = (): boolean => {
    if (contratorName === '' || contratorName.length < 2) {
      return false;
    } else if (contratorMiddleName === '' || contratorMiddleName.length < 2) {
      return false;
    } else if (contratorLastName === '' || contratorLastName.length < 2) {
      return false;
    } else if (valueContrator === '') {
      return false;
    } else if (
      calculateAge(valueContrator) < 18 ||
      calculateAge(valueContrator) > 99
    ) {
      return false;
    } else if (!validator.isMobilePhone(phone)) {
      return false;
    } else if (validator.isEmpty(phone)) {
      return false;
    } else if (errorTelefono.isError || warningTelefono.isWarning) {
      return false;
    } else if (contratorEmail === '') {
      return false;
    } else if (validator.isEmpty(contratorEmail)) {
      return false;
    } else if (!validateEmail(contratorEmail)) {
      return false;
    } else if (phone.length < 10) {
      return false;
    } else if (contratorRFC === '') {
      return false;
    } else if (errorRFC.isError || warningRFC.isWarning) {
      return false;
    } else if (sexoContractor === '') {
      return false;
    } 
    return true;
  };

  const isTaxContratorForm = (): boolean => {
    if (zip === '') {
      return false;
    } else if (errorCP.isError) {
      return false;
    } else if (taxState === '') {
      return false;
    } else if (taxColonia === '') {
      return false;
    } else if (taxStreet === '') {
      return false;
    } else if (taxExternalNumber === '') {
      return false;
    } else if (!validator.isMobilePhone(phone)) {
      return false;
    } else if (validator.isEmpty(phone)) {
      return false;
    } else if (errorTelefono.isError || warningTelefono.isWarning) {
      return false;
    } else if (contratorEmail === '') {
      return false;
    } else if (validator.isEmpty(contratorEmail)) {
      return false;
    } else if (!validator.isEmail(contratorEmail)) {
      return false;
    } else if (phone.length < 10) {
      return false;
    } else if (policy.regimenFiscal === '' || policy.regimenFiscal === null) {
      return false
    }
    return true;
  };

  const isValidForm = (): boolean => {
    if (!additionalConfiguration.isDriverSameContractor && !additionalConfiguration.shouldBill) {
      return isValidContratorForm();
    }
    if (
      additionalConfiguration.shouldBill ||
      !isSameAddress
    ) {
      return isTaxContratorForm();
    }
    return true;
  };

  const returnAdornment = (
    error?: boolean,
    warning?: boolean,
    noError?: boolean
  ) => {
    if (error) {
      return <Error color="error" />;
    } else if (warning) {
      return <Warning color="warning" />;
    } else if (noError) {
      return <Check color="success" />;
    }
    return <></>;
  };
  const DataLayer = {
    event: 'CSform_step_7_submit',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_7_submit',
    eventLabel: undefined,
    telefono: policy.contrator?.telefono || driver.telefono,
    correo: policy.contrator?.email || driver.email,
    noCotizacion: policy.policyNumber,
    modelo: car.description,
    marca: car.assembler,
    anio: car.model,
    paquete: selectedQuote.package,
    forma_pago: selectedQuote.payment_option,
    aseguradora: selectedQuote.Insurance,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: car.type,
    colaborador: undefined,
    cupon: undefined,
  };

  const tagManagerArgs = {
    dataLayer: DataLayer,
  };

  const continuar = () => {
    if (!isValidForm()) {
      setShowErrorToast(true);
      return;
    }
    setShowErrorToast(false);
    let street = {
      street: '',
      estado: '',
      numeroExterior: '',
      colonia_name: '',
      estado_name: '',
    };

    let infoTax = {
      bill: 'false',
      regimen: '',
      regimenFiscal: '',
      taxResidence: {
        zip: '',
        estado: '',
        colonia: '',
        estado_name: '',
        colonia_name: '',
        street: '',
        numeroExterior: '',
        numeroInterior: '',
        city: '',
      },
    };

    let info = {
      contrator: {
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        RFC: '',
        telefono: '',
        fechaNacimiento: new Date(),
        genero: '',
      },
    };

    if (additionalConfiguration.isDriverSameContractor) {
      info = {
        contrator: {
          firstName: driver.name.trim(),
          middleName: policy.driver.middleName.trim(),
          lastName: policy.driver.lastName.trim(),
          RFC: policy.driver.RFC || '',
          email: driver.email,
          telefono: driver.telefono,
          fechaNacimiento: valueDriver || new Date(),
          genero: driver.genero,
        },
      };
    } else {
      info = {
        contrator: {
          firstName: contratorName.trim(),
          middleName: contratorMiddleName.trim(),
          lastName: contratorLastName.trim(),
          email: contratorEmail,
          RFC: contratorRFC,
          telefono: phone,
          // @ts-ignore
          fechaNacimiento: valueContrator || new Date(),
          genero: sexoContractor,
        },
      };
    }
    if (additionalConfiguration.isDriverSameContractor || !additionalConfiguration.shouldBill) {
      if (selectedQuote.insurance !== 'hdi') {
        infoTax = {
          bill: String(additionalConfiguration.shouldBill),
          regimen: policy.regimen || '',
          regimenFiscal: policy.regimenFiscal || '',
          taxResidence: {
            zip: driver.codigoPostal,
            estado: policy.driver.stateCode as string,
            colonia: policy.driver.suburbCode as string,
            estado_name: policy.driver.estado as string,
            colonia_name: policy.colonia_name as string,
            street: policy.driver.street as string,
            numeroExterior: policy.driver.numeroExterior as string,
            numeroInterior: policy.driver.numeroInterior as string,
            city: city,
          },
        };
      } else {
        infoTax = {
          bill: String(additionalConfiguration.shouldBill),
          regimen: policy.regimen || '',
          regimenFiscal: policy.regimenFiscal || '',
          taxResidence: {
            zip: driver.codigoPostal,
            estado: policy.driver.stateCode as string,
            colonia: policy.driver.colonia as string,
            estado_name: policy.driver.estado as string,
            colonia_name: policy.colonia_name as string,
            street: policy.driver.street as string,
            numeroExterior: policy.driver.numeroExterior as string,
            numeroInterior: policy.driver.numeroInterior as string,
            city: city,
          },
        };
      }
    }
    if (!isSameAddress || (additionalConfiguration.shouldBill && !additionalConfiguration.isDriverSameContractor)) {
      infoTax = {
        bill: String(additionalConfiguration.shouldBill),
        regimen: policy.regimen || '',
        regimenFiscal: policy.regimenFiscal || '',
        taxResidence: {
          zip: zip,
          estado: taxCodeState,
          colonia: taxColonia,
          estado_name: taxState,
          colonia_name: coloniaName,
          street: taxStreet,
          numeroExterior: taxExternalNumber,
          numeroInterior: taxInternalNumber,
          city: city,
        },
      };
    }

    street = {
      street: policy.driver.street as string,
      estado: policy.driver.stateCode as string,
      numeroExterior: policy.driver.numeroExterior as string,
      colonia_name: policy.colonia_name as string,
      estado_name: policy.driver.estado as string,
    };
    TagManager.dataLayer(tagManagerArgs);

    TagManager.dataLayer({
      dataLayer: {
        event: 'cocheapp_seguro_datos_personales', //Static data,
        fecha_nacimiento: info.contrator.fechaNacimiento,
        sexo: info.contrator.genero,
        cp: infoTax.taxResidence.zip,
        cotizacion: policy.policyNumber,
        boton_datos_personales: 'Continuar',
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'coche_seguro_datos_contratante', //Static data
        no_cotizacion: policy.policyNumber,
        cp: infoTax.taxResidence.zip,
        estado: policy.driver.estado,
        boton_domicilio_fiscal: isSameAddress ? 'Si' : 'No',
        regimen_fiscal: policy.regimenFiscal,
        boton_contratante: '¡Listo!',
      },
    });

    policy.stage = '3. Contratación (Coche)';
    policy.sub_stage = '5. Datos Complementarios Contratante';
    policy.state_code = 'Activa';
    policy.status_code = 'En curso';
    addPolicyInfo({ ...policy, ...info, ...infoTax, ...street });
    setActive({
      first: true,
      second: false,
      third: false,
    });
    let initialData = {
      id: tracking.id,
      business: 'creditaria',
      data: {
        car: car,
        driver: driver,
        selectedQuote: selectedQuote,
        policy: policy,
        cpData: cpData,
      },
    };
    getTracking(token, initialData);
    upCRM(
      token,
      car,
      driver,
      selectedQuote,
      policy,
      tracking,
      cpData,
      selectedQuote
    );
    TagManager.dataLayer({
      dataLayer: {
        event: 'coche_seguro_datos_contratacion',
        no_cotizacion: policy.policyNumber,
        regimen_fiscal: policy.regimenFiscal,
        boton_contratante: 'Listo',
        boton_contratacion: 'Listo',
      },
    });
    history.push('/confirm');
  };

  const colorHelperTextTelefono = () => {
    if (warningTelefono.isWarning) {
      return '#FF9E1B';
    }

    if (errorTelefono.isError) {
      return '#f44336';
    }

    return '#5AB52F';
  };

  const colorTelefonoAdornment = () => {
    if (warningTelefono.isWarning) {
      return 'warning';
    }

    if (errorTelefono.isError) {
      return 'error';
    }

    return 'success';
  };

  const telefonoAdornment = () => {
    if (warningTelefono.isWarning) {
      return <Warning color={'warning'} />;
    }

    if (errorTelefono.isError) {
      return <Error color={'error'} />;
    }

    return <Check color={'success'} />;
  };

  return (
    <Accordion
      expanded={expanded}
      style={{
        border: 'none',
        marginBottom: '25px',
        borderRadius: '8px',
      }}
      elevation={0}
    >
      <AccordionSummary
        style={{
          height: 0,
          padding: 0,
          minHeight: 0,
          border: 'none',
        }}
      ></AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <Box
          paddingY={{ xs: 2 }}
          paddingX={{ xs: 2, md: 4 }}
          paddingTop={{ xs: 3 }}
        >
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            paddingBottom={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Grid item xs={12} md={8}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                paddingBottom={{ xs: 0, md: 2 }}
              >
                <Typography
                  marginY={2}
                  sx={{ width: 'inherit', paddingLeft: '24px' }}
                >
                  ¿Nos ayudas con la info del contratante?
                </Typography>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name={'contratorName'}
                    label="Nombre (s)*"
                    variant="outlined"
                    disabled={
                      !additionalConfiguration.isDriverSameContractor
                        ? false
                        : true
                    }
                    value={contratorName}
                    onChange={(e) => {
                      const regex = /^[a-zA-Z\u00f1\u00d1 ]*$/;
                      if (regex.test(e.target.value)) {
                        setContratorName(e.target.value);
                        if (e.target.value.length === 0) {
                          setErrorName({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningName({
                            msg: '',
                            isWarning: false,
                          });
                          return;
                        }
                        if (e.target.value.length < 2) {
                          setErrorName({
                            msg: '',
                            isError: false,
                          });
                          setWarningName({
                            msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2.',
                            isWarning: true,
                          });
                        }
                        if (e.target.value.length >= 2) {
                          setErrorName({
                            msg: '',
                            isError: false,
                          });
                          setWarningName({
                            msg: '',
                            isWarning: false,
                          });
                        }
                      } else {
                        setErrorName({
                          msg: '¡Oops! Aquí sólo puedes escribir letras.',
                          isError: true,
                        });
                        setWarningName({
                          msg: '',
                          isWarning: false,
                        });
                      }
                    }}
                    onBlur={() => {
                      if (contratorLastName) {
                        let formattedDate = JSON.stringify(valueContrator);
                        formattedDate = formattedDate.slice(1, 11);
                        const date = formattedDate.split('-');

                        const rfc = createRFC({
                          name: contratorName,
                          lastName: contratorMiddleName,
                          secondName: contratorLastName,
                          birthday: `${date[0].split(' ').join('')}-${date[1]
                            .split(' ')
                            .join('')}-${+date[2].split(' ').join('')}`,
                        });
                        if (contratorName === '') {
                          setErrorName({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningName({ msg: '', isWarning: false });
                        }
                        if (contratorName.length >= 2 && errorName.isError) {
                          setErrorName({
                            msg: '',
                            isError: false,
                          });
                          setWarningName({
                            msg: '',
                            isWarning: false,
                          });
                        }
                        setContratorRFC(rfc);
                      }
                    }}
                    error={errorName.isError}
                    helperText={
                      <label
                        style={{
                          color: errorName.isError ? '#f44336' : '#FF9E1B',
                        }}
                      >
                        {errorName.msg || warningName.msg}
                      </label>
                    }
                    color={
                      contratorName.length === 0
                        ? 'error'
                        : contratorName.length < 2
                        ? 'warning'
                        : 'success'
                    }
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment:
                        contratorName !== '' &&
                        contratorName.length >= 2 &&
                        !errorName.isError ? (
                          <Check color={'success'} />
                        ) : errorName.isError ? (
                          <Error color={'error'} />
                        ) : warningName.isWarning ? (
                          <Warning color={'warning'} />
                        ) : null,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name={'contratorMiddleName'}
                    label="Primer Apellido*"
                    variant="outlined"
                    disabled={
                      !additionalConfiguration.isDriverSameContractor
                        ? false
                        : true
                    }
                    value={contratorMiddleName}
                    onChange={(e) => {
                      const regex = /^[a-zA-Z\u00f1\u00d1 ]*$/;
                      if (regex.test(e.target.value)) {
                        setContratorMiddleName(e.target.value);
                        if (e.target.value.length === 0) {
                          setErrorMiddleName({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningMiddleName({
                            msg: '',
                            isWarning: false,
                          });
                          return;
                        }
                        if (e.target.value.length < 2) {
                          setErrorMiddleName({
                            msg: '',
                            isError: false,
                          });
                          setWarningMiddleName({
                            msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2.',
                            isWarning: true,
                          });
                        }
                        if (e.target.value.length >= 2) {
                          setErrorMiddleName({
                            msg: '',
                            isError: false,
                          });
                          setWarningMiddleName({
                            msg: '',
                            isWarning: false,
                          });
                        }
                      } else {
                        setErrorMiddleName({
                          msg: '¡Oops! Aquí sólo puedes escribir letras.',
                          isError: true,
                        });
                        setWarningMiddleName({
                          msg: '',
                          isWarning: false,
                        });
                      }
                    }}
                    onBlur={() => {
                      if (contratorLastName) {
                        let formattedDate = JSON.stringify(valueContrator);
                        formattedDate = formattedDate.slice(1, 11);
                        const date = formattedDate.split('-');

                        const rfc = createRFC({
                          name: contratorName,
                          lastName: contratorMiddleName,
                          secondName: contratorLastName,
                          birthday: `${date[0].split(' ').join('')}-${date[1]
                            .split(' ')
                            .join('')}-${+date[2].split(' ').join('')}`,
                        });
                        if (contratorMiddleName === '') {
                          setErrorMiddleName({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningMiddleName({ msg: '', isWarning: false });
                        }
                        if (
                          contratorMiddleName.length >= 2 &&
                          errorMiddleName.isError
                        ) {
                          setErrorMiddleName({
                            msg: '',
                            isError: false,
                          });
                          setWarningMiddleName({
                            msg: '',
                            isWarning: false,
                          });
                        }
                        setContratorRFC(rfc);
                      }
                    }}
                    error={errorMiddleName.isError}
                    color={
                      contratorMiddleName.length >= 2 &&
                      !errorMiddleName.isError
                        ? 'success'
                        : !warningMiddleName.isWarning &&
                          !errorMiddleName.isError
                        ? 'primary'
                        : errorMiddleName.isError
                        ? 'error'
                        : contratorMiddleName.length < 2
                        ? 'warning'
                        : 'primary'
                    }
                    helperText={
                      <label
                        style={{
                          color: errorMiddleName.isError
                            ? '#f44336'
                            : '#FF9E1B',
                        }}
                      >
                        {errorMiddleName.msg || warningMiddleName.msg}
                      </label>
                    }
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment:
                        contratorMiddleName !== '' &&
                        contratorMiddleName.length >= 2 &&
                        !errorMiddleName.isError ? (
                          <Check color={'success'} />
                        ) : errorMiddleName.isError ? (
                          <Error color={'error'} />
                        ) : warningMiddleName.isWarning ? (
                          <Warning color={'warning'} />
                        ) : null,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    disabled={
                      !additionalConfiguration.isDriverSameContractor
                        ? false
                        : true
                    }
                    name={'contratorLastName'}
                    label="Segundo Apellido*"
                    variant="outlined"
                    value={contratorLastName}
                    onChange={(e) => {
                      const regex = /^[a-zA-Z\u00f1\u00d1 ]*$/;
                      if (regex.test(e.target.value)) {
                        setContratorLastName(e.target.value);
                        if (e.target.value.length === 0) {
                          setErrorLastName({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningLastName({
                            msg: '',
                            isWarning: false,
                          });
                          return;
                        }
                        if (e.target.value.length < 2) {
                          setErrorLastName({
                            msg: '',
                            isError: false,
                          });
                          setWarningLastName({
                            msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2.',
                            isWarning: true,
                          });
                        }
                        if (e.target.value.length >= 2) {
                          setErrorLastName({
                            msg: '',
                            isError: false,
                          });
                          setWarningLastName({
                            msg: '',
                            isWarning: false,
                          });
                        }
                      } else {
                        setErrorLastName({
                          msg: '¡Oops! Aquí sólo puedes escribir letras.',
                          isError: true,
                        });
                        setWarningLastName({
                          msg: '',
                          isWarning: false,
                        });
                      }
                    }}
                    onBlur={() => {
                      if (contratorLastName) {
                        let formattedDate = JSON.stringify(valueContrator);
                        formattedDate = formattedDate.slice(1, 11);
                        const date = formattedDate.split('-');

                        const rfc = createRFC({
                          name: contratorName,
                          lastName: contratorMiddleName,
                          secondName: contratorLastName,
                          birthday: `${date[0].split(' ').join('')}-${date[1]
                            .split(' ')
                            .join('')}-${+date[2].split(' ').join('')}`,
                        });
                        setContratorRFC(rfc);
                      }
                      if (contratorLastName === '') {
                        setErrorLastName({
                          msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                          isError: true,
                        });
                        setWarningLastName({ msg: '', isWarning: false });
                      }
                      if (
                        contratorLastName.length >= 2 &&
                        errorLastName.isError
                      ) {
                        setErrorLastName({
                          msg: '',
                          isError: false,
                        });
                        setWarningLastName({
                          msg: '',
                          isWarning: false,
                        });
                      }
                      setErrorRFC({ msg: '', isError: false });
                      setWarningRFC({ msg: '', isWarning: false });
                    }}
                    error={errorLastName.isError}
                    color={
                      contratorLastName.length >= 2 && !errorLastName.isError
                        ? 'success'
                        : !warningLastName.isWarning && !errorLastName.isError
                        ? 'primary'
                        : errorLastName.isError
                        ? 'error'
                        : driverLastName.length < 2
                        ? 'warning'
                        : 'primary'
                    }
                    helperText={
                      <label
                        style={{
                          color: errorLastName.isError ? '#f44336' : '#FF9E1B',
                        }}
                      >
                        {errorLastName.msg || warningLastName.msg}
                      </label>
                    }
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment:
                        contratorLastName !== '' &&
                        contratorLastName.length >= 2 &&
                        !errorLastName.isError ? (
                          <Check color={'success'} />
                        ) : errorLastName.isError ? (
                          <Error color={'error'} />
                        ) : warningLastName.isWarning ? (
                          <Warning color={'warning'} />
                        ) : null,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      openTo="year"
                      views={['year', 'month', 'day']}
                      minDate={new Date('1900-01-01')}
                      maxDate={new Date()}
                      label="Fecha de nacimiento"
                      inputFormat="dd/MM/yyyy"
                      disabled={additionalConfiguration.isDriverSameContractor}
                      value={
                        additionalConfiguration.isDriverSameContractor
                          ? new Date(`${valueDriver}T00:00:00`)
                          : new Date(`${valueContrator}T00:00:00`)
                      }
                      onChange={handleChangeContratorBirthday}
                      InputProps={{
                        style: { borderRadius: 8 },
                        endAdornment: <Today color={'primary'} />,
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          error={errorBorn.isError}
                          helperText={errorBorn.msg}
                          onBlur={(e) => {
                            if (e.target.value === '') {
                              setErrorBorn({
                                msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                                isError: true,
                              });
                            } else {
                              setErrorBorn({ msg: '', isError: false });
                            }
                          }}
                          style={{ borderRadius: 8 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography marginY={2}>{'Sexo de nacimiento'}</Typography>
                  <Box>
                    <ToggleButtonGroup
                      fullWidth
                      disabled={
                        !additionalConfiguration.isDriverSameContractor
                          ? false
                          : true
                      }
                      sx={{
                        height: 56,
                        backgroundColor: '#F4F4F4',
                      }}
                      exclusive
                      size={'medium'}
                      value={sexoContractor}
                      onChange={handleChangeGender}
                      style={{ borderRadius: 8, marginBottom: 59, padding: 3 }}
                    >
                      <ToggleButton
                        sx={{
                          textTransform: 'none',
                          backgroundColor: '#fafafa',
                        }}
                        value="Mujer"
                        style={{
                          fontWeight: 600,
                          borderRadius: 8,
                          border: 'none',
                          textTransform: 'none',
                          color: backF,
                          backgroundColor: colorF,
                        }}
                      >
                        Mujer
                      </ToggleButton>
                      <ToggleButton
                        value="Hombre"
                        style={{
                          fontWeight: 600,
                          borderRadius: 8,
                          border: 'none',
                          textTransform: 'none',
                          color: backM,
                          backgroundColor: colorM,
                        }}
                      >
                        Hombre
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <TextField
                    fullWidth
                    disabled={
                      !additionalConfiguration.isDriverSameContractor
                        ? false
                        : true
                    }
                    value={contratorRFC}
                    name={'contratorRFC'}
                    error={errorRFC.isError}
                    onChange={(e) => {
                      if (e.target.value.length <= 13) {
                        const regex = /^[a-zA-Z 0-9]*$/;
                        if (regex.test(e.target.value)) {
                          setContratorRFC(e.target.value);
                        }

                        if (
                          e.target.value.length < 10 &&
                          e.target.value.length > 0
                        ) {
                          setErrorRFC({ msg: '', isError: false });
                          setWarningRFC({
                            msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 10.',
                            isWarning: true,
                          });
                          return;
                        }
                        if (e.target.value.length === 0) {
                          setErrorRFC({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningRFC({
                            msg: '',
                            isWarning: false,
                          });
                          return;
                        }
                      }
                      setErrorRFC({ msg: '', isError: false });
                      setWarningRFC({
                        msg: '',
                        isWarning: false,
                      });
                    }}
                    helperText={
                      <label
                        style={{
                          color: errorRFC.isError ? '#f44336' : '#FF9E1B',
                        }}
                      >
                        {errorRFC.msg || warningRFC.msg}
                      </label>
                    }
                    label="RFC*"
                    variant="outlined"
                    onBlur={() => {
                      if (contratorRFC.length === 0) {
                        setErrorRFC({
                          msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                          isError: true,
                        });
                      }
                      if (contratorRFC.length < 10 && contratorRFC.length > 0) {
                        setErrorRFC({
                          msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 10.',
                          isError: true,
                        });
                        setWarningRFC({
                          msg: '',
                          isWarning: false,
                        });
                        return;
                      }
                      if (additionalConfiguration.shouldBill) {
                        if (
                          contratorRFC.length < 13 &&
                          contratorRFC.length > 0
                        ) {
                          setErrorRFC({
                            msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 13.',
                            isError: true,
                          });
                          setWarningRFC({
                            msg: '',
                            isWarning: false,
                          });
                          return;
                        }
                      }
                    }}
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment:
                        isLonger(contratorRFC) &&
                        validator.isAlphanumeric(contratorRFC) &&
                        isCorrectLonger(contratorRFC) ? (
                          <Check color={'success'} />
                        ) : (!validator.isAlphanumeric(contratorRFC) &&
                            !validator.isEmpty(contratorRFC)) ||
                          !isLonger(contratorRFC) ||
                          errorRFC.isError ? (
                          <InfoIcon color={'error'} />
                        ) : warningRFC.isWarning ? (
                          <Warning color={'warning'} />
                        ) : null,
                    }}
                    color={
                      isLonger(contratorRFC) &&
                      validator.isAlphanumeric(contratorRFC) &&
                      isCorrectLonger(contratorRFC)
                        ? 'success'
                        : (!validator.isEmpty(contratorRFC) &&
                            !validator.isAlphanumeric(contratorRFC)) ||
                          !isLonger(contratorRFC) ||
                          errorRFC.isError
                        ? 'error'
                        : warningRFC.isWarning
                        ? 'warning'
                        : 'primary'
                    }
                  />
                </Grid>
                {additionalConfiguration.shouldBill && (
                  <Grid item xs={12} md={6} sx={{ paddingLeft: '24px' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 1.5,
                        width: 'inherit',
                      }}
                    >
                      <Typography>
                        ¿Cúal es su régimen físcal?
                        <Info
                          color="primary"
                          data-for="fact"
                          data-tip=""
                          data-iscapture="true"
                          sx={{ cursor: 'pointer' }}
                        />
                      </Typography>
                      <ReactTooltip
                        id="fact"
                        type="light"
                        place="left"
                        backgroundColor="#EBF8FB"
                        className={classes.aseguradotooltip}
                      >
                        <Box
                          padding={2}
                          marginTop={2}
                          borderRadius={2}
                          style={{ backgroundColor: '#EBF8FB' }}
                        >
                          <Typography
                            textAlign={'start'}
                            fontWeight={'bold'}
                            fontSize={16}
                          >
                            Régimen fiscal
                          </Typography>
                          <Typography
                            textAlign={'start'}
                            fontWeight={'normal'}
                            fontSize={13}
                          >
                            Nos referimos a los derechos y las obligaciones que
                            tienes por ser persona física ante el SAT según a lo
                            que te dedicas.
                          </Typography>
                        </Box>
                      </ReactTooltip>
                    </Box>
                    <Grid sx={{ width: 'inherit' }}>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="regimenFiscal"
                          options={Object.values(regimenFiscal)}
                          value={policy.regimenFiscal || ''}
                          onChange={(event: any, newValue: any) => {
                            addPolicyInfo({
                              ...policy,
                              regimen:
                                Object.keys(regimenFiscal).find(
                                  (key) => regimenFiscal[key] === newValue
                                ) || '',
                              regimenFiscal: newValue,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              helperText={handleRegimenFiscalErrorLabel()}
                              error={handleRegimenFiscalError()}
                              label="Régimen fiscal"
                              value={policy.regimenFiscal || ''}
                              onChange={({ target: { value } }) => {
                                addPolicyInfo({
                                  ...policy,

                                  regimenFiscal: value,
                                });
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {(additionalConfiguration.shouldBill ||
                  additionalConfiguration.isDriverSameContractor) ? (
                  <Grid
                    item
                    xs={12}
                    md={
                      additionalConfiguration.shouldBill &&
                      additionalConfiguration.isDriverSameContractor
                        ? 12
                        : 6
                    }
                  >
                    <Typography
                      marginY={2}
                      fontSize={16}
                      sx={{
                        width: 'inherit',
                        paddingLeft:
                          additionalConfiguration.shouldBill &&
                          additionalConfiguration.isDriverSameContractor
                            ? '0px'
                            : '24px',
                      }}
                    >
                      {additionalConfiguration.shouldBill &&
                      !additionalConfiguration.isDriverSameContractor
                        ? '¿Cúal es su domicilio físcal?'
                        : !additionalConfiguration.shouldBill &&
                          additionalConfiguration.isDriverSameContractor
                        ? '¿Cúal es su domicilio?'
                        : '¿El domicilio que ingresaste es el mismo que el domicilio físcal?'}
                    </Typography>
                  </Grid>
                ) : null}
                {additionalConfiguration.shouldBill &&
                additionalConfiguration.isDriverSameContractor ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 1.5,
                      paddingLeft: '24px',
                      width: 'inherit',
                    }}
                  >
                    <Typography>No</Typography>
                    <Switch
                      checked={isSameAddress}
                      onChange={handleSame}
                      inputProps={{ 'aria-label': 'ant design' }}
                    />
                    <Typography>Si</Typography>
                  </Box>
                ) : null}

                {(additionalConfiguration.shouldBill ||
                  additionalConfiguration.isDriverSameContractor) ? (
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="postalCode"
                      name={'postalCode'}
                      label="Código Postal*"
                      fullWidth
                      disabled={
                        isSameAddress && additionalConfiguration.isDriverSameContractor
                      }
                      variant="outlined"
                      value={zip}
                      onChange={(e) => {
                        if (e.target.value === '') {
                          setErrorCP({
                            isError: true,
                            isWarning: false,
                            msg: 'Oh, no! Te falta llenar este campo para poder continuar.',
                          });
                        } else {
                          if (!validator.isPostalCode(e.target.value, 'MX')) {
                            setErrorCP({
                              isError: true,
                              msg: '¡Oops! Parece que hay un error en tu Código Postal.',
                            });
                            setTaxState('');
                            setColoniasPorCP([]);
                            setErrorState({
                              isError: true,
                              msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            });
                          } else {
                            getCitiesByPostalCode(e.target.value);
                            setErrorState({
                              isError: false,
                              isWarning: false,
                              correct: true,
                              msg: '',
                            });
                            setErrorCP({
                              isError: false,
                              isWarning: false,
                              correct: true,
                              msg: '',
                            });
                          }
                        }
                        setZip(e.target.value);
                      }}
                      error={errorCP.isError}
                      helperText={errorCP.msg}
                      color={
                        errorCP.isError
                          ? 'success'
                          : errorCP.isWarning
                          ? 'warning'
                          : 'primary'
                      }
                      InputProps={{
                        inputComponent: CPCustomMask as any,
                        style: {
                          borderRadius: 8,
                        },
                        endAdornment: returnAdornment(
                          errorCP.isError,
                          errorCP.isWarning,
                          errorCP.correct
                        ),
                      }}
                    />
                  </Grid>
                ) : null}
                {(additionalConfiguration.shouldBill ||
                  additionalConfiguration.isDriverSameContractor) ? (
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="contratorState"
                      name={'contratorState'}
                      label="Estado*"
                      fullWidth
                      disabled={
                        isSameAddress && additionalConfiguration.isDriverSameContractor
                      }
                      variant="outlined"
                      value={taxState}
                      error={errorState.isError}
                      helperText={errorState.msg}
                      color={errorState.isError ? 'success' : 'primary'}
                      InputProps={{
                        style: {
                          borderRadius: 8,
                        },
                        endAdornment: returnAdornment(
                          errorState.isError,
                          errorState.isWarning,
                          errorState.correct
                        ),
                      }}
                    />
                  </Grid>
                ) : null}
                {(additionalConfiguration.shouldBill ||
                  additionalConfiguration.isDriverSameContractor) && (
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel>Colonia*</InputLabel>
                      <Select
                        value={taxColonia}
                        label="Colonia"
                        disabled={
                          isSameAddress &&
                          additionalConfiguration.isDriverSameContractor
                        }
                        onChange={(e) => setTaxColonia(e.target.value)}
                        sx={{ borderRadius: '8px' }}
                        IconComponent={(props) => (
                          <ExpandMore style={{ color: '#039ECC' }} {...props} />
                        )}
                      >
                        {coloniasPorCP.map((cp: any, i: number) => (
                          <MenuItem
                            key={i}
                            value={cp.suburb_code}
                            onBlur={() => {
                              if (taxColonia === '')
                                setErrorColonia({
                                  isError: true,
                                  msg: '¡Te faltó elegir una opción aquí!',
                                });
                              else
                                setErrorColonia({
                                  isError: false,
                                  msg: '',
                                  correct: true,
                                });
                              setColoniaName(cp.suburb_name);
                            }}
                          >
                            {cp.suburb_name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{errorColonia.msg}</FormHelperText>
                    </FormControl>
                  </Grid>
                )}
                {(additionalConfiguration.shouldBill ||
                  additionalConfiguration.isDriverSameContractor) ? (
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="contratorStreet"
                      name={'contratorStreet'}
                      label="Calle*"
                      fullWidth
                      disabled={
                        isSameAddress && additionalConfiguration.isDriverSameContractor
                      }
                      variant="outlined"
                      value={taxStreet}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setErrorStreet({
                            isError: true,
                            correct: false,
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                          });
                        } else {
                          setErrorStreet({
                            isError: false,
                            correct: true,
                            msg: '',
                          });
                        }
                      }}
                      onChange={(e) => {
                        setTaxStreet(e.target.value);
                      }}
                      error={errorStreet.isError}
                      helperText={errorStreet.msg}
                      color={errorStreet.isError ? 'success' : 'primary'}
                      InputProps={{
                        style: {
                          borderRadius: 8,
                        },
                        endAdornment: returnAdornment(
                          errorStreet.isError,
                          errorStreet.isWarning,
                          errorStreet.correct
                        ),
                      }}
                    />
                  </Grid>
                ) : null}
                {(additionalConfiguration.shouldBill || additionalConfiguration.isDriverSameContractor) ? (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: 'flex',
                      justifyContent: 'left',
                      columnGap: '1rem',
                    }}
                  >
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="contratorExternalNumber"
                        name={'contratorExternalNumber'}
                        label="No. exterior*"
                        fullWidth
                        disabled={
                          isSameAddress &&
                          additionalConfiguration.isDriverSameContractor
                        }
                        variant="outlined"
                        value={taxExternalNumber}
                        onBlur={(e) => {
                          if (validator.isEmpty(e.target.value)) {
                            setErrorExtNumber({
                              isError: true,
                              msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            });
                          } else {
                            setErrorExtNumber({
                              isError: false,
                              msg: '',
                              correct: true,
                            });
                          }
                        }}
                        onChange={(e) => {
                          setTaxExternalNumber(e.target.value);
                        }}
                        error={errorExtNumber.isError}
                        helperText={errorExtNumber.msg}
                        color={errorExtNumber.isError ? 'success' : 'primary'}
                        InputProps={{
                          style: {
                            borderRadius: 8,
                          },
                          endAdornment: returnAdornment(
                            errorExtNumber.isError,
                            errorExtNumber.isWarning,
                            errorExtNumber.correct
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="contratorInternalNumber"
                        name={'contratorInternalNumber'}
                        label="No. interior"
                        fullWidth
                        disabled={
                          isSameAddress &&
                          additionalConfiguration.isDriverSameContractor
                        }
                        variant="outlined"
                        value={taxInternalNumber}
                        onChange={(e) => {
                          setTaxInternalNumber(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
              <Typography marginY={2}>¿Donde lo podemos contactar?</Typography>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                paddingBottom={{ xs: 0, md: 2 }}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    id="contratorEmail"
                    name={'contratorEmail'}
                    label="Correo electrónico"
                    fullWidth
                    disabled={additionalConfiguration.isDriverSameContractor}
                    variant="outlined"
                    value={contratorEmail}
                    onChange={(e) => {
                      setContratorEmail(e.target.value);
                      if (!validator.isEmail(contratorEmail)) {
                        setErrorEmail({
                          msg: '¡Oops! Debes ingresar un email válido.',
                          isError: true,
                        });
                      } else {
                        setErrorEmail({ msg: '', isError: false });
                      }
                    }}
                    onBlur={() => {
                      if (!validator.isEmail(contratorEmail)) {
                        setErrorEmail({
                          msg: '¡Oops! Debes ingresar un email válido.',
                          isError: true,
                        });
                      } else {
                        setErrorEmail({ msg: '', isError: false });
                      }
                    }}
                    error={errorEmail.isError}
                    helperText={errorEmail.msg}
                    color={
                      !validator.isEmail(contratorEmail) ? 'success' : 'primary'
                    }
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment: returnAdornment(
                        errorEmail.isError,
                        errorEmail.isWarning,
                        errorEmail.correct
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="telefono"
                    label="Telefono"
                    id="telefono"
                    fullWidth
                    disabled={additionalConfiguration.isDriverSameContractor}
                    variant="outlined"
                    placeholder="Telefono"
                    value={phone}
                    onChange={(e) => {
                      const regex = /^[0-9]*$/;
                      const telefono = e.target.value;
                      if (regex.test(telefono)) {
                        if (telefono === '') {
                          setErrorTelefono({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningTelefono({ msg: '', isWarning: false });
                        } else {
                          if (telefono.length < 10) {
                            setErrorTelefono({
                              msg: '',
                              isError: false,
                            });
                            setWarningTelefono({
                              msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 10.',
                              isWarning: true,
                            });
                          }
                        }
                        if (telefono.length <= 10) {
                          setPhone(e.target.value);
                        }
                        if (telefono.length <= 10) {
                          setErrorTelefono({
                            msg: '',
                            isError: false,
                          });
                          setWarningTelefono({ msg: '', isWarning: false });
                        }
                      } else {
                        setErrorTelefono({
                          msg: '¡Oops! Aquí sólo puedes escribir números.',
                          isError: true,
                        });
                        setWarningTelefono({ msg: '', isWarning: false });
                      }
                    }}
                    error={errorTelefono.isError}
                    onBlur={() => {
                      if (phone === '') {
                        setErrorTelefono({
                          msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                          isError: true,
                        });
                        setWarningTelefono({ msg: '', isWarning: false });
                      } else if (phone.length < 10) {
                        setErrorTelefono({
                          msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 10.',
                          isError: true,
                        });
                        setWarningTelefono({ msg: '', isWarning: false });
                      } else if (phone.length === 10) {
                        setErrorTelefono({
                          msg: '',
                          isError: false,
                        });
                        setWarningTelefono({ msg: '', isWarning: false });
                      }
                    }}
                    helperText={
                      <label style={{ color: colorHelperTextTelefono() }}>
                        {errorTelefono.msg || warningTelefono.msg}
                      </label>
                    }
                    color={colorTelefonoAdornment()}
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment: telefonoAdornment(),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction={'row-reverse'}>
            <Grid item display={'flex'} width={'700px'}>
              <Button
                variant="outlined"
                onClick={completar}
                size={'medium'}
                style={{
                  textTransform: 'none',
                  height: '55px',
                  borderRadius: '10px',
                  width: '100%',
                  marginBottom: 20,
                  marginRight: 5,
                }}
              >
                Completar más tarde
                <Box
                  component="img"
                  src={TimeSVG}
                  alt="digicert"
                  width="20px"
                  height="20px"
                  sx={{ marginLeft: 2 }}
                />
              </Button>
              <CustomButton
                color={'primary'}
                onClick={continuar}
                variant="contained"
                disabled={!isValidForm()}
                style={{
                  textTransform: 'none',
                  height: 56,
                  width: '100%',
                  color: 'white'
                }}
                text={'Siguiente'}
                endIcon={<ArrowForward />}
              />
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
