import React, {useEffect, useState} from 'react';
import {
    Modal,
    Box,
    Paper,
    Typography,
    Backdrop,
    Fade,
    useTheme,
    useMediaQuery,
} from '@mui/material';

import {useStyles} from '../ModalNeedHelp/useStyles';
import Card from '../../../assets/images/tarjeta-amazon.png';
import {CustomButton} from '@buttons/CustomButton';
import {ZustandContext} from '@store/modalStore';
import {ModalConfirmPromotions} from './ModalConfirmPromotions';
import {useTokenStore} from '@store/TokenStore';
import {getPromotion} from '@api/getPromotions';
import {usePromotionStore} from '@store/promotionStore';
import {usePolicyStore} from '@store/policyStore';
import {useCarStore} from '@store/carInfoStore';
import {useDriverStore} from '@store/driverInfoStore';
import TagManager from 'react-gtm-module';
import CountdownTimer from '@modals/modalpromotions/components/CountdownTimer';
import {useSendContacts} from '@hooks/useSendContacts';
import {upCRM} from "@utils/CRM";
import {useSelectedQuoteStore} from "@store/QuoteStore";
import {useTrackingStore} from "@store/TrackingStore";
import {useCPStore} from "@store/cpStore";
import {getTracking} from "@api/getTracking";
import {useCarListStore} from "@store/carListStore";

type ModalHomeProps = {
    onClose: () => void;
};

export const ModalCardPromotion: React.FC<ModalHomeProps> = ({onClose}) => {
    const classes = useStyles();
    const {breakpoints} = useTheme();
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const isMobile_sm = useMediaQuery(breakpoints.down('sm'));
    const isMobile_xs = useMediaQuery(breakpoints.down('xs'));
    const {show, hide} = React.useContext(ZustandContext);
    const {token} = useTokenStore();
    const [data, setData] = useState<{
        id: string;
        title: string;
        description: string;
        code: string;
        total: number;
        start_at: any;
        end_at: any;
        promo_picture: string;
        doc_name: string;
        type_promotion: string;
        amount: number;
        updated_by: string;
        created_by: string;
        updated_at: string;
        created_at: string;
    }>({
        id: '',
        title: '',
        description: '',
        code: '',
        total: 0,
        start_at: 0,
        end_at: 0,
        promo_picture: '',
        doc_name: '',
        type_promotion: '',
        amount: 0,
        updated_by: '',
        created_by: '',
        updated_at: '',
        created_at: '',
    });
    const {infoPromotion, setPromotion} = usePromotionStore();
    const policy = usePolicyStore();
    const {addPolicyInfo} = usePolicyStore();
    const car = useCarStore();
    const driver = useDriverStore();
    const {selectedQuote} = useSelectedQuoteStore();
    const {tracking} = useTrackingStore();
    const {cpData} = useCPStore();
    const {carList} = useCarListStore();

    const DataLayer = {
        event: 'CSform_Modal_Amazon',
        eventCategory: 'Formulario.Seguro.Auto.v2',
        eventAction: 'promoción_amazon_view',
        eventLabel: undefined,
        telefono: driver.driver.telefono,
        correo: driver.driver.email,
        noCotizacion: policy.policy.policyNumber,
        modelo: car.car.description,
        marca: car.car.assembler,
        anio: car.car.model,
        paquete: undefined,
        forma_pago: undefined,
        aseguradora: undefined,
        tarjeta: undefined,
        tipo_tarjeta: undefined,
        tipo_vehiculo: car.car.type,
        colaborador: undefined,
        cupon: undefined,
    };

    const tagManagerArgs = {
        dataLayer: DataLayer,
    };

    const SendDataLayer = {
        event: 'CSform_Modal_Amazon',
        eventCategory: 'Formulario.Seguro.Auto.v2',
        eventAction: 'promoción_amazon_select',
        eventLabel: '',
        telefono: driver.driver.telefono,
        correo: driver.driver.email,
        noCotizacion: policy.policy.policyNumber,
        modelo: car.car.description,
        marca: car.car.assembler,
        anio: car.car.model,
        paquete: undefined,
        forma_pago: undefined,
        aseguradora: undefined,
        tarjeta: undefined,
        tipo_tarjeta: undefined,
        tipo_vehiculo: car.car.type,
        colaborador: undefined,
        cupon: undefined,
    };

    const SendManagerLayer = {
        dataLayer: SendDataLayer,
    };

    useEffect(() => {
        TagManager.dataLayer(tagManagerArgs);
        (async () => {
            const resp = await getPromotion(token);
            setData(resp);
            setPromotion({...infoPromotion, ...resp});
        })();
    }, []);
    const styless = () => {
        let styls = {
            top: '50%',
            left: '50%',
        };

        if (isMobile_xs) {
            styls.top = '40%';
        }

        if (isMobile_sm) {
            styls.top = '50%';
        }

        return styls;
    };


    const sendTracking = async () => {
        let initialData = {
            id: tracking.id,
            business: 'creditaria',
            data: {
                car: car.car,
                driver: driver.driver,
                selectedQuote: selectedQuote,
                policy: policy.policy,
                cpData: cpData,
                carList: carList,
            },
        };
        await getTracking(token, initialData);
    }


    const sendUpCRM = () => {
        upCRM(token, car.car, driver.driver, selectedQuote, policy.policy, tracking, cpData);
        sendTracking()
    }

    const {postSendContacts} = useSendContacts();
    const onSend = () => {
        postSendContacts();
        sendUpCRM()
    };

    return (
        <Modal
            sx={{
                zIndex: 10001,
            }}
            open
            //onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in>
                <Paper
                    //className={classes.root}
                    style={{
                        borderRadius: 8,
                        position: 'absolute',
                        transform: 'translate(-50%, -50%)',
                        overflowX: 'hidden',
                        backgroundColor: 'white',
                        ...styless(),
                        margin: 'auto',
                        width: !isTablet ? '461px' : '327px',
                        height: 'auto',
                    }}
                >
                    <Box
                        padding={2}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingLeft: '18px',
                            paddingRight: '18px',
                        }}
                    >
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            textAlign="center"
                            position={'relative'}
                        >
                            <Typography
                                variant={'h6'}
                                fontWeight={'bold'}
                                marginBottom="20px"
                            >
                                {data?.title}
                            </Typography>
                            {/* <IconButton
                onClick={onClose}
                sx={{
                  display: 'flex',
                  alignSelf: 'flex-start',
                  padding: '0 8px',
                }}
              >
                x
              </IconButton> */}
                        </Box>
                        <Typography
                            fontWeight={400}
                            textAlign={'center'}
                            fontSize={16}
                            marginBottom={'24px'}>
                            {data?.type_promotion === 'tarjeta_digital'
                                ? data?.description
                                : data?.amount}
                        </Typography>
                        <picture style={{marginRight: 0}}>
                            <source
                                media="(min-width: 1199px)"
                                srcSet={`${data?.promo_picture}?w=200&h=200&fit=crop&auto=format&dpr=1 1x`}
                            />
                            <source
                                media="(min-width: 1440px)"
                                srcSet={`${data?.promo_picture}?w=164&h=164&fit=crop&auto=format&dpr=1 1x`}
                            />
                            <source
                                media="(min-width: 1024px)"
                                srcSet={`${data?.promo_picture}?w=164&h=164&fit=crop&auto=format&dpr=1 1.2x`}
                            />
                            <source
                                media="(min-width: 768px)"
                                srcSet={`${data?.promo_picture}?w=164&h=164&fit=crop&auto=format&dpr=1 1.2x`}
                            />
                            <source
                                media="(min-width: 320px)"
                                srcSet={`${data?.promo_picture}?w=164&h=164&fit=crop&auto=format&dpr=1 2x`}
                            />
                            <img src={data?.promo_picture} alt="CardGift"/>
                        </picture>

                        {policy.policy.endDateTime !== '' && (
                            <CountdownTimer endDateTime={policy.policy.endDateTime}/>
                        )}
                        <Box mt={2}></Box>
                        <Box mt={3}></Box>
                        <CustomButton
                            text="¡OBVIO LO QUIERO!"
                            onClick={() => {
                                SendDataLayer.eventLabel = 'siLoQuiero';
                                TagManager.dataLayer(SendManagerLayer);
                                TagManager.dataLayer({
                                    dataLayer: {
                                        event: 'coche_seguro_cupon_nocturno',
                                        no_cotizacion: policy.policy.policyNumber,
                                        page_url: window.location.href,
                                        hora: new Date().getHours(),
                                        boton_contacto: 'Super, si lo quiero!'
                                    }
                                })
                                policy.policy.acceptCoupon = '1';
                                addPolicyInfo({...policy.policy});
                                sendUpCRM()
                                show(ModalConfirmPromotions, {onClose: hide});
                            }}
                            color={'primary'}
                            variant={'contained'}
                            style={{
                                height: '48px',
                                width: '100%',
                                textTransform: 'none',
                                marginBottom: '24px',
                            }}
                        />
                        <CustomButton
                            text="NO POR AHORA"
                            onClick={() => {
                                SendDataLayer.eventLabel = 'talVezDespués';
                                TagManager.dataLayer(SendManagerLayer);
                                TagManager.dataLayer({
                                    dataLayer: {
                                        event: 'coche_seguro_cupon_nocturno',
                                        no_cotizacion: policy.policy.policyNumber,
                                        page_url: window.location.href,
                                        hora: new Date().getHours(),
                                        boton_contacto: 'No estoy interesado'
                                    }
                                })
                                policy.policy.acceptCoupon = '0';
                                addPolicyInfo({...policy.policy});
                                onSend();
                                onClose();
                            }}
                            color={'primary'}
                            variant={'outlined'}
                            style={{
                                height: '48px',
                                width: '100%',
                                textTransform: 'none',
                            }}
                        />
                        <Typography
                            fontWeight={400}
                            fontSize={12}
                            textAlign={'center'}
                            color="#595959"
                        >
                            <Box mt={3}></Box>
                            {
                                'Para llevarte el certificado tienes que adquirir el seguro en Plan Amplio en pago de contado'
                            }
                        </Typography>
                    </Box>
                </Paper>
            </Fade>
        </Modal>
    );
};
