import { FormControl, FormLabel, InputLabel, MenuItem, Select } from "@mui/material";
import { useGetQuotePayment } from "@store/QuoteData";
import { usePolicyStore } from "@store/policyStore";
import { useSelectedQuoteStore } from '@store/QuoteStore';

export function PaymentSelectField(){
    const { policy, addPolicyInfo } = usePolicyStore();
    const { Packages, setPackage } = useGetQuotePayment();
    const { selectedQuote, setSelectedQuote } = useSelectedQuoteStore();

    const changePackage = (e: any) => {
        if(!policy.error){
          policy.package_anterior = Packages.value;
        }
        if (selectedQuote?.vehicle?.type === 'MOTO') {
            if (e.target.value === 'AMP') {
                policy.customDM = '10';
                policy.customRT = '20';
            } else if (e.target.value === 'RC') {
                policy.customDM = '0';
                policy.customRT = '0';
            } else if (e.target.value === 'LIMIT') {
                policy.customDM = '0';        
            }
        } else {
            if (e.target.value === 'AMP') {
                policy.customDM = '5';
                policy.customRT = '10';
            } else if (e.target.value === 'RC') {
                policy.customDM = '0';
                policy.customRT = '0';
            } else if (e.target.value === 'LIMIT') {
                policy.customDM = '0';        
            }
        }
        policy.package_new = e.target.value;
        addPolicyInfo({ ...policy });
        if (e.target.value === 'AMP') {
          setPackage('Plan Amplio', 'AMP');
          return;
        } else if (e.target.value === 'RC') {
          setPackage('Plan Basico', 'RC');
          return;
        } else if (e.target.value === 'LIMIT') {
          setPackage('Plan Limitado', 'LIMIT');
          return;
        }
      };

    return (
        <FormControl fullWidth>
            <FormLabel sx={{ fontSize: '14px', fontWeight: '400'}}>Plan</FormLabel>
            <Select
                value={policy.package_new}
                onChange={changePackage}
            >
                {[
                { label: 'Amplio', value: 'AMP' },
                { label: 'Limitado', value: 'LIMIT' },
                {
                    label: 'Básico',
                    value: 'RC',
                },
                ].map((value, i) => (
                <MenuItem key={i} value={value.value}>
                    {value.label}
                </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}