import create from 'zustand';
import { persist } from 'zustand/middleware';

import { getAssamblerList, getCarListApi } from '@api/getCar';
import { CarListData } from '@models/store/carListStore';
import { getDateRangePickerDayUtilityClass } from '@mui/lab';
import TagManager from 'react-gtm-module';

export const initialCarListData: CarListData = {
  vehicle_id: '',
  description: '',
  assembler: '',
  model: '',
  type: '',
  insurers: [
    'zurich',
    'mapfre',
    'gnp',
    'aig',
    'qualitas',
    'axa',
    'aba',
    'primeroseguros',
    'hdi',
  ],
};

interface CarList {
  carList: CarListData[];
  assamblerList: string[];
  getCarList: (
    description: string,
    year: string,
    assembler: string,
    token: string,
    type: string
  ) => void;
  getAssemblerList: (token: string, type: string) => void;
  loading: boolean;
}

export const useCarListStore = create<CarList>(
  persist(
    (set, get) => ({
      carList: [initialCarListData],
      assamblerList: ['Toyota'],
      loading: true,
      getCarList: async (
        description: string,
        year: string,
        assembler: string,
        token: string,
        type: string
      ) => {
        const data: any = await getCarListApi(year, assembler, token, type);
        data.filter((item: any) => item.description.includes(description));
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const carList: CarListData[] =
          data.length === 0 ? [initialCarListData] : data;
        set({ carList });

        // if(data.length === 0 && description && year.length === 4){
        //   TagManager.dataLayer({
        //     dataLayer: {
        //       event: 'coche_seguro_modelo_no_encuentra',
        //       marca: description,
        //       anio: year,
        //     },
        //   });
        // }
      },
      getAssemblerList: async (token: string, type: string) => {
        const data: string[] = await getAssamblerList(token, type);
        const assamblerList: string[] = data as string[];

        set({ assamblerList });
      },
    }),
    {
      name: 'carList-storage',
      getStorage: () => sessionStorage,
    }
  )
);
