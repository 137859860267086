import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  useMediaQuery,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  useTheme
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CustomButton } from '@buttons/CustomButton';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TagManager from 'react-gtm-module';
import { Check, Error, Warning } from '@mui/icons-material';
import { es } from 'date-fns/locale';
import { useTokenStore } from '@store/TokenStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useTrackingStore } from '@store/TrackingStore';
import { useCarStore } from '@store/carInfoStore';
import { usePolicyStore } from '@store/policyStore';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { useCPStore } from '@store/cpStore';
import { createContact } from '@api/createContact';
import { CPCustomMask } from '@inputCustomMasks/CPCustomMask';
import { getToken } from '@api/getToken';
import { getTracking } from '@api/getTracking';
import { upCRM } from '@utils/CRM';
import { useHistory } from 'react-router-dom';
import { formatDate } from '@utils/formatDate';
import {calculateAge} from "@utils/utilityFunctions";

interface Formprops {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export const Form: React.FC<Formprops> = ({
  isMobile,
  isTablet,
  isDesktop,
}) => {
  const isMedium = useMediaQuery('(max-width:1100px)');
  // const [messageComponent, setMessageComponent] = useState<boolean>(false);
  // const [driverMessage, setDriverMessage] = useState<boolean>(false);
  const { palette } = useTheme();
  const { token } = useTokenStore();
  const { driver, setDriver } = useDriverStore();
  const { tracking, setTracking } = useTrackingStore();
  const { car } = useCarStore();
  const { policy, addPolicyInfo } = usePolicyStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const { searchCP, cpData } = useCPStore();
  
  const [errorGender, setErrorGender] = useState<{
    msg: string;
    isError: boolean;
    isCorrect: boolean;
  }>({
    msg: '',
    isError: false,
    isCorrect: false,
  });
  const [colorM, setColorM] = useState<string>('transparent');
  const [colorF, setColorF] = useState<string>('#F5FBFD');
  const [anterior, setAnterior] = useState<string>('');
  const [backM, setBackM] = useState<string>('#424242');
  const [backF, setBackF] = useState<string>('#039ECC');
  const [gender, setGender] = useState<string>('');
  const [limit, setLimit] = useState<any>({
    MaxAgeAcept: new Date(),
    MinAgeAcept: new Date(),
  });
  const [birthDate, setBirthDate] = useState<Date | null>(
    driver.fechaNacimiento || new Date()
  );
  const [errorAge, setErrorAge] = useState<{ msg: string; isError: boolean }>({
    msg: '',
    isError: false,
  });
  const [zip, setZip] = useState<string>(driver.codigoPostal || '');
  const [errorZip, setErrorZip] = useState<{
    msg: string;
    isError: boolean;
    isCorrect: boolean;
  }>({
    msg: '',
    isError: false,
    isCorrect: false,
  });
  const [warningZip, setWarningZip] = useState<{
    msg: string;
    isWarning: boolean;
  }>({
    msg: '',
    isWarning: false,
  });

  const history = useHistory();

  const CalcYear = () => {
    const MaxAgeAcept = 99;
    const MinAgeAcept = 18;
    const currentYear = new Date().getFullYear();
    setLimit({
      MaxAgeAcept: new Date(`${currentYear - MinAgeAcept}-08-18`),
      MinAgeAcept: new Date(`${currentYear - MaxAgeAcept}-08-18`),
    });
  };
  
  useEffect(() => {
    // if (gender === 'Hombre') {
    //   // setColorM('rgb(35, 141, 193, 20%)');
    //   // setColorF('transparent');
    //   setBackM(palette.primary.main);
    //   setBackF('#424242');
    // } else {
    //   // setColorF('rgb(35, 141, 193, 20%)');
    //   // setColorM('transparent');
    //   setBackM('#424242');
    //   setBackF(palette.primary.main);
    // }
    CalcYear();
  }, []);

  const handleChangeGender = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null && anterior !== null) {
      setAnterior(newAlignment);
    } else if (anterior !== null) {
      newAlignment = anterior;
    } else {
      newAlignment = 'Mujer';
    }
    setGender(newAlignment);
    if (newAlignment === 'Hombre' || newAlignment === null) {
      setColorM('#238DC133');
      setColorF('transparent');
      setBackM('#006782');
      setBackF('#006782');
    } else {
      setColorF('#238DC133');
      setColorM('transparent');
      setBackM('#006782');
      setBackF('#006782');
    }
    setDriver({
      ...driver,
      genero: newAlignment,
    });
    setErrorGender({ isError: false, isCorrect: true, msg: '' });
  };

  // const handleChangeDriverBirthday = (newValue: Date | null) => {
  //   setBirthDate(newValue);
  // };

  const handleChangeDriverBirthday = (newValue: Date | null) => {
    // @ts-ignore
    setBirthDate(formatDate(newValue));
    setDriver({
      ...driver,
      fechaNacimiento: formatDate(newValue),
    });
    const isDate = newValue instanceof Date && !isNaN(newValue.valueOf())
    if(!isDate){
      setErrorAge({
        msg: 'La fecha de nacimiento no es válida.',
        isError: true,
      });
      return;
    }
    setErrorAge({
      msg: '',
      isError: false,
    });
  };

  const colorCPAdornment = () => {
    if (warningZip.isWarning) {
      return 'warning';
    }

    if (errorZip.isError) {
      return 'error';
    }

    return 'success';
  };

  const colorHelperTextCP = () => {
    if (warningZip.isWarning) {
      return '#FF9E1B';
    }

    if (errorZip.isError) {
      return '#f44336';
    }

    return '#5AB52F';
  };

  const cpRighIcon = () => {
    if (warningZip.isWarning) {
      return <Warning color={'warning'} />;
    }

    if (errorZip.isError) {
      return <Error color={'error'} />;
    }

    if (errorZip.isCorrect) {
      return <Check color={'success'} />;
    }

    return null;
  };

  const handleCreateDriver = async () => {
    if (tracking.id === undefined || tracking?.id === undefined) {
      setTracking(token);
    }

    if (
        calculateAge(formatDate(birthDate)) < 18 ||
        calculateAge(formatDate(birthDate)) > 99
    ) {
      setErrorAge({
        msg: 'Por favor ingresar una edad valida mayor a 17 años y menor o igual a 99 años',
        isError: true,
      });
      return;
    }

    if (zip === '') {
      setErrorZip({msg: "¡Oh, no! Te hace falta llenar este campo para poder continuar.", isError: true, isCorrect: false});
      return;
    }

    let id = tracking.id;
    let crm = {
      business: 'creditaria',
      tracking_id: id,
      name: driver.name,
      cellphone: driver.telefono,
      last_name: '',
      second_last_name: '',
      phone: driver.telefono,
      sub_channel_sale: 'Inter.mx',
      email: driver.email,
      gender: driver.genero,
      birth_date: driver.fechaNacimiento,
      township_code: '',
      suburb_code: '',
      vehicle_id: car.vehicle_id,
      rfc: '',
      address: '',
      suburb_name: '',
      township_name: '',
      Tiporelacion: '',
      zip_code: driver.codigoPostal,
      insurance: '',
      campana: '',
      portal_origen: 'https://www.inter.mx',
      grupo_economico: 'Coche Seguro',
      url:
        'https://' +
        window.location.hostname +
        '/' +
        '?utm_source=' +
        window.localStorage.getItem('utm_source') +
        '&utm_medium=' +
        window.localStorage.getItem('utm_medium') +
        '&utm_campaign=' +
        window.localStorage.getItem('utm_campaign') +
        '&utm_content=' +
        window.localStorage.getItem('utm_content'),
    };
    let crmresponse = await createContact(token, crm);

    policy.policyNumber = crmresponse.data.Folio;
    policy.uuid = crmresponse.data.Segurify_uuid;
    policy.stage = '1. Registro (Coche)';
    policy.sub_stage = '1. Datos Generales';
    policy.state_code = 'Borrador';
    policy.status_code = 'En revisión';
    policy.customDM = car.type.toUpperCase() === 'MOTO'? '10':'5';
    policy.customRT = car.type.toUpperCase() === 'MOTO'? '20':'10';
    await addPolicyInfo({ ...policy });
    let initialData = {
      id: tracking.id,
      business: 'creditaria',
      data: {
        car: car,
        driver: driver,
        selectedQuote: selectedQuote,
        policy: policy,
        cpData: cpData,
      },
    };
    await getTracking(token, initialData);
    await searchCP(zip, token);
    upCRM(token, car, driver, selectedQuote, policy, tracking, cpData);

    TagManager.dataLayer({
      dataLayer: {
        event: 'CSform_step_2_submit',
        eventCategoy: 'Formulario.Seguro.Auto.v2',
        eventAction: 'step_2_submit',
        eventLabel: undefined,
        telefono: driver.telefono,
        correo: driver.email,
        noCotizacion: policy.policyNumber,
        modelo: car.description,
        marca: car.assembler,
        anio: car.model,
        paquete: undefined,
        forma_pago: undefined,
        aseguradora: undefined,
        tarjeta: undefined,
        tipo_tarjeta: undefined,
        tipo_vehiculo: car.type,
        colaborador: undefined,
        cupon: undefined,
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'coche_seguro_contacto',
        mejor_seguro_desde: '',
        celular: driver.telefono,
        email: driver.email,
        boton_contacto: '¡Listo!',
        tipo_vehiculo: car.type,
        no_cotizacion: policy.policyNumber,
      },
    });
    history.push('/plans');
  };
  

  return (
    <>
      {/* <Box
        display="flex"
        justifyContent="center"
        marginBottom="10px"
        position="relative"
      >
        {messageComponent ? (
          <Information
            setMessageComponent={setMessageComponent}
            setDriverMessage={setDriverMessage}
          />
        ) : (
          <FormInformation 
            setIsFormInformation={() => {}} isFormInformation={false}          
          />
        )}
      </Box> */}
      <Box
        sx={{
          padding: isMobile ? '28px' : isMedium ? '72px' : '55.5px 150px',
        }}
      >
        <Paper
          // elevation={elevation}
          style={{
            borderRadius: 8,
            maxWidth: '350px',
            margin: 'auto',
            paddingBottom: '2rem'
          }}
        >
          <Grid
            container
            rowSpacing={3}
            paddingX={2}
            // paddingX={isMobile ? '16px' : isTablet ? '47.5px' : '97px'}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <label
                  // className={classes.firstText}
                  style={{
                    // fontSize: !isTablet ? '24px' : '22px',
                    lineHeight: '22px',
                    marginBottom: isMobile ? 0 : 10,
                    textAlign: 'initial',
                  }}
                >
                  Ingresa la información del conductor del vehículo asegurado
                </label>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <label style={{ fontSize: '14px', marginTop: '8px' }}>Sexo de nacimiento</label>
              </Box>

              <ToggleButtonGroup
                fullWidth
                sx={{
                  marginTop: '13px',
                  height: 56,
                  backgroundColor: '#F4F4F4',
                  border: errorGender.isError ? '1px solid red' : '',
                  marginBottom: '1.5rem',
                }}
                exclusive
                size={'medium'}
                value={gender}
                defaultValue={''}
                onChange={handleChangeGender}
                style={{ borderRadius: 8 }}
              >
                <ToggleButton
                  sx={{
                    textTransform: 'none',
                    backgroundColor: '#fafafa',
                  }}
                  value="Mujer"
                  style={{
                    fontWeight: 600,
                    borderRadius: 8,
                    border: 'none',
                    textTransform: 'none',
                    color: backF,
                    backgroundColor: colorF,
                  }}
                >
                  Mujer
                </ToggleButton>
                <ToggleButton
                  value=""
                  defaultChecked
                  sx={{ display: 'none' }}
                ></ToggleButton>
                <ToggleButton
                  value="Hombre"
                  style={{
                    fontWeight: 600,
                    borderRadius: 8,
                    border: 'none',
                    textTransform: 'none',
                    color: backM,
                    backgroundColor: colorM,
                  }}
                >
                  Hombre
                </ToggleButton>
              </ToggleButtonGroup>

              <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                <DatePicker
                  maxDate={limit.MaxAgeAcept}
                  minDate={limit.MinAgeAcept}
                  InputProps={{
                    style: {
                      borderRadius: 8,
                    },
                  }}
                  openTo="year"
                  views={['year', 'month', 'day']}
                  label="Fecha de nacimiento*"
                  inputFormat="dd/MM/yyyy"
                  value={new Date(`${birthDate}`)}
                  onChange={handleChangeDriverBirthday}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={errorAge.isError}
                      helperText={errorAge.msg}
                      sx={{ borderRadius: 8 }}
                    />
                  )}
                />
              </LocalizationProvider>

              <TextField
                sx={{ marginTop: '20px' }}
                value={zip}
                onBlur={(e) => {
                  if (e.target.value === '') {
                    setErrorZip({
                      msg: '¡Oh, no! Te hace falta llenar este campo para poder continuar.',
                      isError: true,
                      isCorrect: false,
                    });
                  }
                }}
                onChange={(e) => {
                  setZip(e.target.value);
                  setDriver({
                    ...driver,
                    codigoPostal: e.target.value,
                  });

                  if (e.target.value.length === 4) {
                    setWarningZip({
                      msg: '¿Tu Código Postal tiene menos de 5 números? Agrega un 0 a la izquierda. Ejemplo: 01000',
                      isWarning: true,
                    });
                    setErrorZip({ msg: '', isError: false, isCorrect: false });
                    return;
                  }
                  setWarningZip({ msg: '', isWarning: false });
                }}
                fullWidth
                label="Código postal*"
                variant="outlined"
                maxRows={4}
                color={colorCPAdornment()}
                error={errorZip.isError}
                helperText={
                  <label style={{ color: colorHelperTextCP() }}>
                    {errorZip.msg || warningZip.msg}
                  </label>
                }
                InputProps={{
                  inputComponent: CPCustomMask as any,
                  style: {
                    borderRadius: 8,
                  },
                  endAdornment: cpRighIcon(),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display={'flex'}
            >
              <CustomButton
                text={'COTIZAR'}
                color={'primary'}
                variant={'contained'}
                style={{
                  width: '100%',
                  height: 56,
                  textTransform: 'none',
                  color: "white"
                }}
                onClick={handleCreateDriver}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};
