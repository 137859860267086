import create from 'zustand';
import { persist } from 'zustand/middleware';
import { getQuoteCreated } from '@api/getQuoteCreated';

interface Coverage {
  code: string;
  sum_assured: string;
  name: string;
  deductive: number;
}

interface Receipts {
  number: number;
  start_date: string;
  end_date: string;
  net_premium: string;
  total_premium: string;
  right: string;
  recharge: string;
  tax: string;
  commission: string;
}

interface PaymentOptions {
  value: string;
  label: string;
}

interface Quote {
  insurance_premium: {
    tax: string;
    commission: string;
    net_premium: string;
    right: string;
    surcharge: string;
    total: string;
  };
  coverages_array: Coverage[];
  quote_id: string;
  company_logo: string;
  receipts: Receipts[];
  payment_options_json: PaymentOptions[];
  police_id: string;
  insurance: string;
}

interface IContact {
  name: string;
  telefono: string;
  email: string;
  genero: string;
  fechaDeNacimiento?: string;
  codigoPostal: string;
}

interface IVehicle {
  vehicle_id: string;
  description: string;
  assembler: string;
  model: string | number;
  type: string;
  insurers: string[];
}

interface ICP {
  zip_code: string;
  state_code: string;
  state_name: string;
  suburb_code: string;
  suburb_name: string;
  city_name: string;
  township_code: string;
  township_name: string;
}

interface QuoteStore {
  quoteList: Quote[] | [];
  quoteListReactQuery: Quote[] | [];
  isFetching: boolean;
  setIsFetching: (isFetching: boolean) => void;
  setQuoteList: (data: any[]) => void;
  // setQuoteListReactQuery: (data: any[]) => void;
}

interface SelectedQuoteStore {
  selectedQuote: any;
  setSelectedQuote: (selectedQuote: Quote) => void;
}

export const defaultQuote = {
  insurance_premium: {
    tax: '',
    commission: '',
    net_premium: '',
    right: '',
    surcharge: '',
    total: ''
  },
  coverages_array: [{
    code: '',
    sum_assured: '',
    name: '',
    deductive: 0,
  }],
  quote_id: '',
  company_logo: '',
  receipts: [{
    number: 0,
    start_date: '',
    end_date: '',
    net_premium: '',
    total_premium: '',
    right: '',
    recharge: '',
    tax: '',
    commission: '',
  }],
  payment_options_json: [{
    value: '',
    label: '',
  }],
  police_id: '',
  insurance: ''
}

export const useQuoteStore = create<QuoteStore>((set, get) => ({
  quoteList: [],
  quoteListReactQuery: [],
  isFetching: false,
  setIsFetching: (isFetching: boolean) => {
    set({ isFetching });
  },
  setQuoteList: (data: any) => {
    set({ quoteList: data });
  },
  // setQuoteListReactQuery: (data: any) => {
  //   set({ quoteList: data });
  // },
}));
export const useSelectedQuoteStore = create<SelectedQuoteStore>(
  //@ts-ignore
  persist(
    (set) => ({
      selectedQuote: {},
      setSelectedQuote: (selectedQuote: Quote) => {
        set({ selectedQuote });
      },
    }),
    {
      name: 'quote-storage',
      getStorage: () => sessionStorage,
    }
  )
);
