import React, { useState} from 'react';
import { useHistory } from 'react-router-dom';
import {Box, Typography, Theme, Tooltip, useTheme, useMediaQuery} from '@mui/material';
import { CustomButton } from '@buttons/CustomButton';
import TagManager from 'react-gtm-module';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { useCarStore } from '@store/carInfoStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useGetQuotePayment } from '@store/QuoteData';
import { upCRM } from '@utils/CRM';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { usePolicyStore } from '@store/policyStore';
import { useCPStore } from '@store/cpStore';
import { useCarListStore } from '@store/carListStore';
import { getTracking } from '@api/getTracking';
import { makeStyles } from '@mui/styles';
import Info from '@mui/icons-material/Info';
import { useClamp } from '@utils/useClamp';

interface QuotationProps {
  isMobile: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  pago: {
    width: '25%',
  },
}));

export const Quotation: React.FC<QuotationProps> = ({ isMobile }) => {
  const history = useHistory();
  const { selectedQuote } = useSelectedQuoteStore();
  const { payment_option } = useGetQuotePayment();
  const { car } = useCarStore();
  const { driver } = useDriverStore();
  const { token } = useTokenStore();
  const { policy, addPolicyInfo } = usePolicyStore();
  const { tracking } = useTrackingStore();
  const { cpData } = useCPStore();
  const { carList } = useCarListStore();
  const classes = useStyles();
  const [percentageRT, setPercentageRT] = React.useState(0);
  const [percentageDM, setPercentageDM] = React.useState(0);

  const { breakpoints, palette } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isTablet = useMediaQuery(breakpoints.down('md'));

  React.useEffect(() => {
    const rtCoverage = selectedQuote?.coverages_array?.find(
      (coverage: any) => coverage.code === 'RT'
    );
    const dmCoverage = selectedQuote?.coverages_array?.find(
      (coverage: any) => coverage.code === 'DM'
    );

    rtCoverage && setPercentageRT(rtCoverage.deductive);
    dmCoverage && setPercentageDM(dmCoverage.deductive);
  }, [selectedQuote]);

  const DataLayer = {
    event: 'CSform_step_4_submit',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_4_submit',
    eventLabel: undefined,
    telefono: driver.telefono,
    correo: driver.email,
    noCotizacion: policy.policyNumber,
    modelo: car.description,
    marca: car.assembler,
    anio: car.model,
    paquete: selectedQuote?.package,
    forma_pago: selectedQuote?.payment_option,
    aseguradora: selectedQuote?.insurance,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: car.type,
    colaborador: undefined,
    ecommerce: {
      checkout: {
        actionField: { step: 1, action: 'CSform_step_4_submit' },
        products: [
          {
            name: selectedQuote?.insurance,
            id: undefined, //string
            price: parseInt(selectedQuote?.insurance_premium?.total), //numero
            brand:
              '[paquete:' +
              selectedQuote?.package +
              '_pago:' +
              selectedQuote?.payment_option +
              ']',
            category: 'SeguroCoche',
            variant: car.model,
            quantity: 1,
          },
        ],
      },
    },
  };

  const tagManagerArgs = {
    dataLayer: DataLayer,
  };

  const produc_detail = {
    event: 'productDetail',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_3_submit',
    eventLabel: 'productDetail',
    ecommerce: {
      detail: {
        actionField: {
          list:
            '[' +
            selectedQuote?.insurance +
            ',' +
            selectedQuote?.insurance_premium?.total +
            ']',
          action: 'productDetail',
        },
        products: [
          {
            name: selectedQuote?.insurance,
            id: undefined,
            price: selectedQuote?.insurance_premium?.total, //número
            brand:
              '[paquete:' +
              selectedQuote?.package +
              '_pago:' +
              selectedQuote?.payment_option +
              ']',
            category: 'SeguroCoche',
            variant: selectedQuote?.vehicle?.model,
            quantity: 1,
          },
        ],
      },
    },
  };
  const tagManagerProduct = {
    dataLayer: produc_detail,
  };
  const goToHiring = () => {
    TagManager.dataLayer(tagManagerArgs);
    TagManager.dataLayer(tagManagerProduct);
    TagManager.dataLayer({
      dataLayer: {
        event: 'cocheapp_seguro_contratar',
        aseguradora: policy.package_new,
        modalidad_pago: policy.payment_new,
        costo_anual: selectedQuote?.insurance_premium?.total,
        Cotizacion: policy.policyNumber,
        boton_contratar: 'Contratar',
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'coche_seguro_loquiero',
        no_cotizacion: policy.policyNumber,
        plan: selectedQuote?.package,
        modalidad_pago: policy.payment_label,
        aseguradora: policy.package_new,
        costo_anual: selectedQuote.insurance_premium.total,
        boton_loquiero: 'Lo Quiero',
      },
    });
    policy.stage = '2. Cotización (Coche)';
    policy.sub_stage = '3. Elección';
    policy.state_code = 'Activa';
    policy.status_code = 'En curso';
    addPolicyInfo({ ...policy });
    let initialData = {
      id: tracking.id,
      business: 'creditaria',
      data: {
        car: car,
        driver: driver,
        selectedQuote: selectedQuote,
        policy: policy,
        cpData: cpData,
      },
    };
    getTracking(token, initialData);
    upCRM(
      token,
      car,
      driver,
      selectedQuote,
      policy,
      tracking,
      cpData,
      selectedQuote
    );

    history.push('/hiring');
  };

  let totalNumber = 0;
  selectedQuote?.receipts?.map((item: any) => {
    const number = Number(item?.total_premium);
    totalNumber += number;
  });

  let totalPayment = totalNumber.toString();

  const [attach, setAttach] = useState(false);
  const { clamp } = useClamp();

  onscroll = (e: any) => {
    window.scrollY >= 800 && window.scrollY <= 2400 ? setAttach(true) : setAttach(false);
  };

  return (
    <Box
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      position={'relative'}
      style={{
        backgroundColor: 'white',
        padding: '20px',
        marginTop: '5px',
        width: isDesktop ? '385px' : '100%',
        marginLeft: !isTablet ? '15px' : '0px',
        borderRadius: '8px',
      }}
    >
      <Box
        margin="0px 0px 20px 0px"
        sx={{
          display: 'flex',
        }}
      >
        <Typography fontWeight="700" fontSize="20px">
          Total a pagar
        </Typography>
      </Box>
      <Typography
        fontWeight="400"
        fontSize="14px"
        sx={{
          marginBottom: '20px',
        }}
      >
        Cobertura {policy.package_label}
      </Typography>
      {policy.payment_label === 'Anual' ? (
        <Box display="flex" justifyContent="space-between">
          <Typography
            sx={{
              margin:
                policy.payment_label === 'Anual' ? '0px 0px 20px 0px' : 'unset',
            }}
            fontWeight="400"
            fontSize="14px"
          >
            Único pago de
          </Typography>
          <Typography fontWeight="600" fontSize="16px">
            $
            {parseFloat(parseFloat(totalPayment).toFixed(2)).toLocaleString(
              'es-MX',
              { minimumFractionDigits: 2 }
            )}
          </Typography>
        </Box>
      ) : (
        <Payment
          paymentMode={policy.payment_label}
          payResults={selectedQuote?.receipts}
        />
      )}
      {policy.package_label === 'Plan Amplio' ? (
        <Box
          display="flex"
          justifyContent="space-between"
          margin="0px 0px 20px 0px"
        >
          <Typography fontWeight="400" fontSize="14px">
            Porcentaje de Daños Materiales
          </Typography>
          <Typography
            fontWeight="600"
            fontSize="16px"
          >{`${percentageDM}%`}</Typography>
        </Box>
      ) : null}
      {policy.package_label !== 'Plan Basico' ? (
        <Box
          display="flex"
          justifyContent="space-between"
          margin="0px 0px 20px 0px"
        >
          <Typography fontWeight="400" fontSize="14px">
            Porcentaje de Robo Total
          </Typography>
          <Typography
            fontWeight="600"
            fontSize="16px"
          >{`${percentageRT}%`}</Typography>
        </Box>
      ) : null}
      <TotalPayment payment={totalPayment} payment_option={payment_option} />
      <CustomButton
        onClick={goToHiring}
        color={'primary'}
        variant={'contained'}
        text="CONTRATAR"
        style={{
          height: 56,
          width: isMobile && attach ? '85%' : '100%',
          textTransform: 'none',
          bottom: isMobile && attach ? 70 : 'unset',
          left: isMobile && attach ? 34 : 'unset',
          position: isMobile && attach ? 'fixed' : 'relative',
          zIndex: '1000',
          color: 'white'
        }}
      />
    </Box>
  );
};

export const Payment = ({ paymentMode, payResults }: any) => {
  if (paymentMode === 'Anual') return null;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      margin="0px 0px 20px 0px"
    >
      <Typography fontWeight="400" fontSize="14px">
        Primer pago de
        <ul
          style={{
            margin: 0,
          }}
        >
          <li style={{ color: '#8F8F8F', fontWeight: 400 }}>
            {paymentMode === 'Semestral'
              ? 'Más un pago de'
              : `Más ${payResults.length - 1} pagos de`}
          </li>
        </ul>
      </Typography>
      <Typography fontWeight="600" fontSize="16px">
        <span style={{ marginLeft: '19px' }}>
          + $
          {parseFloat(
            parseFloat(payResults[0].total_premium).toFixed(2)
          ).toLocaleString('es-MX', {
            minimumFractionDigits: 2,
          })}
        </span>
        <ul
          style={{
            margin: 0,
          }}
        >
          <div
            style={{
              color: '#8F8F8F',
              fontWeight: 400,
              fontSize: 14,
            }}
          >
            + $
            {parseFloat(
              parseFloat(
                payResults[1]
                  ? payResults[1].total_premium
                  : payResults[0].total_premium
              ).toFixed(2)
            ).toLocaleString('es-MX', {
              minimumFractionDigits: 2,
            })}
          </div>
        </ul>
      </Typography>
    </Box>
  );
};

export const TotalPayment = ({ payment, payment_option }: any) => {
  let isMobile = false;
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        margin={`${!isMobile ? '25px 0px 15px' : '25px 0px 30px'}}`}
      >
        <Typography>Costo anual:</Typography>
        <Typography fontSize="20px" fontWeight="700">
          $
          {parseFloat(parseFloat(payment).toFixed(2)).toLocaleString('es-MX', {
            minimumFractionDigits: 2,
          })}
        </Typography>
      </Box>
      <Box
          sx={{
            backgroundColor: '#F2F5F7',
            padding: '16px 16px 16px 16px',
            marginBottom: '18px',
            borderRadius: '8px',
          }}
      >
        <Typography sx={{fontWeight: 'bold', fontSize: '16px', marginBottom: '15px'}}>
          ¡Importante!
        </Typography>
        <Typography sx={{fontSize: '14px'}}>
          <b>
            Esta aseguradora renovará y cobrará tu seguro en automático cada
            año.{" "}
          </b>
          ¡Claro que en Inter te recordaremos siempre tu fecha de renovación
          para que no te caiga de sorpresa!. Si quieres cancelar tu seguro,
          basta con una llamada al <b>55 442 46837</b> hasta un mes antes de la
          renovación.
        </Typography>
      </Box>
    </>
  );
};
