import {useEffect, useRef} from 'react';
import { useQuery } from 'react-query';

import { getQuoteCreated } from '@api/getQuoteCreated';
import { useCarStore } from '@store/carInfoStore';
import { useCPStore } from '@store/cpStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { useQuoteStore } from '@store/QuoteStore';
import { usePolicyStore } from '@store/policyStore';
import { useVehiculoStore } from '@store/vehiculoStore';

export let retries: string[] = [];

export const useQuoteList = () => {
  const { setQuoteList, setIsFetching, quoteList } = useQuoteStore();
  const { driver } = useDriverStore();
  const { token } = useTokenStore();
  const {
    tracking: { id },
  } = useTrackingStore();

  const { car: carData } = useCarStore();
  const { cpData } = useCPStore();
  const { policy } = usePolicyStore();
    const isFirstRender = useRef(true);

  const fetchData = async (aseguradora: string) => {
    const {
      data: { data },
    } = await getQuoteCreated(
      token,
      id,
      aseguradora,
      driver,
      carData,
      cpData[0],
      policy.package,
      policy.payment_option,
        undefined,
        undefined,
        retries
    );
    return [data];
  };

  const { data: zurich, isLoading: isLoadingZurich, refetch: refetchZurich } = useQuery(
    ['quoteZurich', carData.vehicle_id],
    () => fetchData('zurich'),
    {
      staleTime: 600000, // 10 minute
      refetchInterval: 600000,
      refetchIntervalInBackground: true,
        enabled: carData.type.toLowerCase() !== 'moto' ? !!carData.vehicle_id : false,
        retry: 2,
        refetchOnWindowFocus: false,
    }
  );

  const { data: qualitas, isLoading: isLoadingQualitas, refetch: refetchQualitas } = useQuery(
    ['quoteQualitas', carData.vehicle_id],
    () => fetchData('qualitas'),
    {
      staleTime: 600000, // 10 minute
      refetchInterval: 600000,
      refetchIntervalInBackground: true,
        enabled: !!carData.vehicle_id,
        retry: 2,
        refetchOnWindowFocus: false,
    }
  );

  const { data: aig, isLoading: isLoadingAig, refetch: refetchAig } = useQuery(
    ['quoteAig', carData.vehicle_id],
    () => fetchData('aig'),
    {
      staleTime: 600000, // 10 minute
      refetchInterval: 600000,
      refetchIntervalInBackground: true,
        enabled: carData.type.toLowerCase() !== 'moto' ? !!carData.vehicle_id : false,
        retry: 2,
        refetchOnWindowFocus: false,
    }
  );

  const { data: mapfre, isLoading: isLoadingMapfre, refetch: refetchMapfre } = useQuery(
    ['quoteMapfre', carData.vehicle_id],
    () => fetchData('mapfre'),
    {
      staleTime: 600000, // 10 minute
      refetchInterval: 600000,
      refetchIntervalInBackground: true,
        enabled: !!carData.vehicle_id,
        retry: 2,
        refetchOnWindowFocus: false,
    }
  );

  const { data: gnp, isLoading: isLoadingGnp, refetch: refetchGnp } = useQuery(
    ['quoteGnp', carData.vehicle_id],
    () => fetchData('gnp'),
    {
      staleTime: 600000, // 10 minute
      refetchInterval: 600000,
      refetchIntervalInBackground: true,
        enabled: !!carData.vehicle_id,
        retry: 2,
        refetchOnWindowFocus: false,
    }
  );

  const { data: axa, isLoading: isLoadingAxa, refetch: refetchAxa } = useQuery(
    ['quoteAxa', carData.vehicle_id],
    () => fetchData('axa'),
    {
      staleTime: 600000, // 10 minute
      refetchInterval: 600000,
      refetchIntervalInBackground: true,
        enabled: carData.type.toLowerCase() !== 'moto' ? !!carData.vehicle_id : false,
        retry: 2,
        refetchOnWindowFocus: false,
    }
  );

  const { data: hdi, isLoading: isLoadingHdi, refetch: refetchHdi } = useQuery(
    ['quoteHdi', carData.vehicle_id],
    () => fetchData('hdi'),
    {
      staleTime: 600000, // 10 minute
      refetchInterval: 600000,
      refetchIntervalInBackground: true,
        enabled: carData.type.toLowerCase() !== 'moto' ? !!carData.vehicle_id : false,
        retry: 2,
        refetchOnWindowFocus: false,
    }
  );

  const { data: aba, isLoading: isLoadingAba, refetch: refetchAba } = useQuery(
    ['quoteAba', carData.vehicle_id],
    () => fetchData('aba'),
    {
      staleTime: 600000, // 10 minute
      refetchInterval: 600000,
      refetchIntervalInBackground: true,
        enabled: carData.type.toLowerCase() !== 'moto' ? !!carData.vehicle_id : false,
        retry: 2,
        refetchOnWindowFocus: false,
    }
  );

  const { data: primeroseguros, isLoading: isLoadingPrimeroseguros, refetch: refetchPrimero } = useQuery(
    ['quotePrimeroseguros', carData.vehicle_id],
    () => fetchData('primeroseguros'),
    {
      staleTime: 600000, // 10 minute
      refetchInterval: 600000,
      refetchIntervalInBackground: true,
        enabled: carData.type.toLowerCase() !== 'moto' ? !!carData.vehicle_id : false,
        retry: 2,
        refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
      if (zurich && carData.type.toLowerCase() !== 'moto') {
      //@ts-ignore
      const newQuote = quoteList.filter((item) => item.insurance !== 'zurich');
      zurich[0].insurance_premium.total =
        parseFloat(zurich[0]?.insurance_premium?.net_premium) +
        parseFloat(zurich[0]?.insurance_premium?.commission) +
        parseFloat(zurich[0]?.insurance_premium?.right) +
        parseFloat(zurich[0]?.insurance_premium?.surcharge) +
        parseFloat(zurich[0]?.insurance_premium?.tax);
      setQuoteList([...newQuote, ...zurich]);
    }
  }, [zurich, carData.type]);

  useEffect(() => {
      if (aba && carData.type.toLowerCase() !== 'moto') {
      //@ts-ignore
      const newQuote = quoteList.filter((item) => item.insurance !== 'aba');
      aba[0].insurance_premium.total =
        parseFloat(aba[0]?.insurance_premium?.net_premium) +
        parseFloat(aba[0]?.insurance_premium?.commission) +
        parseFloat(aba[0]?.insurance_premium?.right) +
        parseFloat(aba[0]?.insurance_premium?.surcharge) +
        parseFloat(aba[0]?.insurance_premium?.tax) -
        parseFloat(aba[0]?.insurance_premium?.discount);
      setQuoteList([...newQuote, ...aba]);
    }
  }, [aba, carData.type]);

  useEffect(() => {
    if (qualitas) {
      const newQuote = quoteList.filter(
        //@ts-ignore
        (item) => item.insurance !== 'qualitas'
      );
      qualitas[0].insurance_premium.total =
        parseFloat(qualitas[0]?.insurance_premium?.net_premium) +
        parseFloat(qualitas[0]?.insurance_premium?.right) +
        parseFloat(qualitas[0]?.insurance_premium?.surcharge) +
        parseFloat(qualitas[0]?.insurance_premium?.tax);
      setQuoteList([...newQuote, ...qualitas]);
    }
  }, [qualitas]);

  useEffect(() => {
      if (aig && carData.type.toLowerCase() !== 'moto') {
      const newQuote = quoteList.filter(
        //@ts-ignore
        (item) => item.insurance !== 'aig'
      );
      aig[0].insurance_premium.total =
        parseFloat(aig[0]?.insurance_premium?.net_premium) +
        parseFloat(aig[0]?.insurance_premium?.commission) +
        parseFloat(aig[0]?.insurance_premium?.right) +
        parseFloat(aig[0]?.insurance_premium?.surcharge) +
        parseFloat(aig[0]?.insurance_premium?.tax);
      setQuoteList([...newQuote, ...aig]);
    }
  }, [aig, carData.type]);

  useEffect(() => {
    if (mapfre) {
      const newQuote = quoteList.filter(
        //@ts-ignore
        (item) => item.insurance !== 'mapfre'
      );
      mapfre[0].insurance_premium.total =
        parseFloat(mapfre[0]?.insurance_premium?.net_premium) +
        parseFloat(mapfre[0]?.insurance_premium?.right) +
        parseFloat(mapfre[0]?.insurance_premium?.surcharge) +
        parseFloat(mapfre[0]?.insurance_premium?.tax);
      setQuoteList([...newQuote, ...mapfre]);
    }
  }, [mapfre]);

  useEffect(() => {
    if (gnp) {
      const newQuote = quoteList.filter(
        //@ts-ignore
        (item) => item.insurance !== 'gnp'
      );
      gnp[0].insurance_premium.total =
        parseFloat(gnp[0]?.insurance_premium?.net_premium) +
        parseFloat(gnp[0]?.insurance_premium?.commission) +
        parseFloat(gnp[0]?.insurance_premium?.right) +
        parseFloat(gnp[0]?.insurance_premium?.surcharge) +
        parseFloat(gnp[0]?.insurance_premium?.tax);
      setQuoteList([...newQuote, ...gnp]);
    }
  }, [gnp]);

  /*useEffect(() => {
    if (afirme) {
      const newQuote = quoteList.filter(
        //@ts-ignore
        (item) => item.insurance !== 'afirme'
      );
      afirme[0].insurance_premium.total = parseFloat(afirme[0]?.insurance_premium?.net_premium) + parseFloat(afirme[0]?.insurance_premium?.commission) + parseFloat(afirme[0]?.insurance_premium?.right) + parseFloat(afirme[0]?.insurance_premium?.surcharge) + parseFloat(afirme[0]?.insurance_premium?.tax);
      setQuoteList([...newQuote, ...afirme]);
    }
  }, [afirme]);*/

  useEffect(() => {
      if (axa && carData.type.toLowerCase() !== 'moto') {
      const newQuote = quoteList.filter(
        //@ts-ignore
        (item) => item.insurance !== 'axa'
      );

      axa[0].insurance_premium.total =
        parseFloat(axa[0]?.insurance_premium?.net_premium) +
        parseFloat(axa[0]?.insurance_premium?.commission) +
        parseFloat(axa[0]?.insurance_premium?.right) +
        parseFloat(axa[0]?.insurance_premium?.surcharge) +
        parseFloat(axa[0]?.insurance_premium?.tax);
      setQuoteList([...newQuote, ...axa]);
    }
  }, [axa, carData.type]);

  useEffect(() => {
      if (hdi && carData.type.toLowerCase() !== 'moto') {
      const newQuote = quoteList.filter(
        //@ts-ignore
        (item) => item.insurance !== 'hdi'
      );
      hdi[0].insurance_premium.total =
        parseFloat(hdi[0]?.insurance_premium?.net_premium) +
        parseFloat(hdi[0]?.insurance_premium?.commission) +
        parseFloat(hdi[0]?.insurance_premium?.right) +
        parseFloat(hdi[0]?.insurance_premium?.surcharge) +
        parseFloat(hdi[0]?.insurance_premium?.tax) +
        parseFloat(hdi[0]?.insurance_premium?.discount);
      setQuoteList([...newQuote, ...hdi]);
    }
  }, [hdi, carData.type]);

  useEffect(() => {
      if (primeroseguros && carData.type.toLowerCase() !== 'moto') {
      const newQuote = quoteList.filter(
        //@ts-ignore
        (item) => item.insurance !== 'primeroseguros'
      );
      primeroseguros[0].insurance_premium.total =
        parseFloat(primeroseguros[0]?.insurance_premium?.net_premium) +
        parseFloat(primeroseguros[0]?.insurance_premium?.commission) +
        parseFloat(primeroseguros[0]?.insurance_premium?.right) +
        parseFloat(primeroseguros[0]?.insurance_premium?.surcharge) +
        parseFloat(primeroseguros[0]?.insurance_premium?.tax);
      setQuoteList([...newQuote, ...primeroseguros]);
    }
  }, [primeroseguros, carData.type]);

  useEffect(() => {
      if(carData.type.toLowerCase() !== 'moto') {
      refetchZurich() 
      refetchAig()
      refetchAxa()
      refetchHdi()
      refetchAba()
      refetchPrimero()
    }
    refetchQualitas()
    refetchMapfre()
    refetchGnp()
  }, [policy.package])

    useEffect(() => {
        // This condition checks if it's not the initial render
        if (!isFirstRender.current) {
            // This function will be executed when the `policy.package` changes,
            // but not during the initial render.
            // Add your logic here.
            retries = []
        } else {
            isFirstRender.current = false;
        }
    }, [policy.package]);

  useEffect(() => {
      if(carData.type.toLowerCase() !== 'moto') {
          setIsFetching(
              isLoadingZurich ||
              isLoadingAba ||
              //isLoadingAfirme ||
              isLoadingAig ||
              isLoadingAxa ||
              isLoadingGnp ||
              isLoadingHdi ||
              isLoadingMapfre ||
              isLoadingPrimeroseguros ||
              isLoadingQualitas
          );
          return
      }

      setIsFetching(
          isLoadingGnp ||
          isLoadingMapfre ||
          isLoadingQualitas
      );
  }, [
    isLoadingZurich,
    isLoadingAba,
    //isLoadingAfirme,
    isLoadingAig,
    isLoadingAxa,
    isLoadingGnp,
    isLoadingHdi,
    isLoadingMapfre,
    isLoadingPrimeroseguros,
    isLoadingQualitas,
    setIsFetching,
  ]);
};
