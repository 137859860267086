import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Clear } from '@mui/icons-material';
import {
  Fade,
  Modal,
  Paper,
  Box,
  Backdrop,
  Theme,
  Typography,
  IconButton,
} from '@mui/material';

import { ZustandContext } from '@store/modalStore';
import { CustomButton } from '@buttons/CustomButton';
import { ModalHome } from './ModalHome/ModalHome';
import TagManager from 'react-gtm-module';

type ModalShareProps = {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    top: '50%',
    left: '50%',
    width: 364,
    borderRadius: 8,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
}));

export const ModalChangeVehicle: React.FC<ModalShareProps> = ({ onClose }) => {
  const classes = useStyles();
  const { show, hide } = useContext(ZustandContext);

  useEffect(() => {
    TagManager.dataLayer({ dataLayer: {
        event: 'CS_interactions',
        eventCategory:'Formulario.Seguro.Auto.v2',
        eventAction: 'Interacciones',
        eventLabel: 'update_datosVehiculo_view'//Texto del botón al que se hizo clic.
      }});
  }, []);

  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper className={classes.root}>
          <Box padding={2}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              alignItems={'flex-end'}
            >
              <IconButton onClick={onClose}>
                <Clear />
              </IconButton>
            </Box>
            <Box width={'80%'} style={{ marginTop: '-20px' }}>
              <Typography variant={'h6'} fontSize={'20px'} fontWeight={'bold'}>
                {
                  'Si cambias la info de tu vehículo, se actualizarán los resultados.'
                }
              </Typography>
            </Box>
            <Box marginTop={2}>
              <CustomButton
                text={'¡Ay no, mejor no!'}
                onClick={onClose}
                color={'primary'}
                variant={'contained'}
                style={{ height: 56, width: '100%', textTransform: 'none' }}
              />
            </Box>
            <Box marginTop={2}>
              <CustomButton
                text={'No importa, ¡quiero cambiarlo!'}
                onClick={() => show(ModalHome, { onClose: hide })}
                color={'primary'}
                variant={'outlined'}
                style={{ height: 56, width: '100%', textTransform: 'none' }}
              />
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
