import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

import { AppleButton } from '@buttons/AppleButton';
import { GoogleButton } from '@buttons/GoogleButton';
import LogoWhite from '@assets/images/LogoWhite.svg';
import { productos, contactanos, clientes } from './data';
import TagManager from 'react-gtm-module';

const handleFooterClick = (name: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'ev_footer_button_cs',
      option: name,
    }
  })
} 

export const Footer: React.FC = () => (
  <Box
    component={'footer'}
    position="absolute"
    width="100%"
    sx={{ bgcolor: '#282828' }}
  >
    <Container maxWidth={'xl'} sx={{ padding: '2rem' }}>
      <Grid container>
        <Grid item container xs={12} md={12} paddingBottom={4}>
          <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Box
              component={'img'}
              src={LogoWhite}
              sx={{
                width: { xs: 180, md: 160 },
                maxWidth: { xs: 180, md: 180 },
              }}
              alt={'Inter, seguro, seguro'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
              <Typography
                variant={'subtitle1'}
                color={'white'}
                fontWeight={'bold'}
                style={{ cursor: 'pointer', marginBottom: '1rem' }}
              >
                {'Contáctanos'}
              </Typography>
              {contactanos.map((item: string, index: number) =>
                index === 0 ? (
                  <Link
                    href={`tel:${item.replaceAll(' ', '')}`}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                    onClick={() => handleFooterClick('Telefono')}
                  >
                    <Typography
                      key={item}
                      variant={'subtitle1'}
                      color={'white'}
                      style={{ cursor: 'pointer' }}
                    >
                      {item}
                    </Typography>
                  </Link>
                ) : (
                  <Link
                    href={`mailto:${item.replaceAll(' ', '')}`}
                    target={'_blank'}
                    style={{ textDecoration: 'none' }}
                    onClick={() => handleFooterClick('Email')}
                  >
                    <Typography
                      key={item}
                      variant={'subtitle1'}
                      color={'white'}
                      style={{ cursor: 'pointer' }}
                    >
                      {item}
                    </Typography>
                  </Link>
                )
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
              <Typography
                variant={'subtitle1'}
                color={'white'}
                fontWeight={'bold'}
              >
                {'Clientes'}
              </Typography>
              <Link rel='/' sx={{ textDecoration: 'none', color: '#FFF' }}>Aviso de Privacidad</Link>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            textAlign={'center'}
            variant={'caption'}
            color={'white'}
            style={{ fontWeight: 300 }}
          >
            {'Copyright 2021 ® Interprotección'}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </Box>
);
