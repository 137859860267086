import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Clear } from '@mui/icons-material';
import { ExpandMore } from '@mui/icons-material';
import {
  Fade,
  Modal,
  Paper,
  Box,
  Backdrop,
  Theme,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { CustomButton } from '@buttons/CustomButton';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { useGetQuotePayment } from '@store/QuoteData';
import { getQuoteCreated } from '@api/getQuoteCreated';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { useCarStore } from '@store/carInfoStore';
import { useCPStore } from '@store/cpStore';
import { useDriverStore } from '@store/driverInfoStore';
import { LoadingButton } from '@mui/lab';
import TagManager from 'react-gtm-module';
import { usePolicyStore } from '@store/policyStore';

type ModalConfirmDetailsProps = {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    top: '50%',
    left: '50%',
    width: 364,
    borderRadius: 8,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
}));

const paymentOptionsHDI = [
    {
      value: 'Mensual',
      label: 'M',
    },
    {
      value: 'Trimestral',
      label: '3M',
    },
    {
      value: 'Semestral',
      label: '6M',
    },
    {
      value: 'Anual',
      label: 'A',
    },
  ];
const paymentOptionsAll = [
  {
    value: 'Mensual',
    label: 'M',
  },
  {
    value: 'Trimestral',
    label: '3M',
  },
  {
    value: 'Semestral',
    label: '6M',
  },
  {
    value: 'Anual',
    label: 'A',
  },
];

export const ModalConfirmDetails: React.FC<ModalConfirmDetailsProps> = ({
  onClose,
}) => {
  const classes = useStyles();

  const { selectedQuote, setSelectedQuote } = useSelectedQuoteStore();
  const { Packages, payment_option, setPackage, setPaymentOption } =
    useGetQuotePayment();
  const { token } = useTokenStore();
  const {
    tracking: { id },
  } = useTrackingStore();
  const { car: carData } = useCarStore();
  const { cpData } = useCPStore();
  const { driver } = useDriverStore();

  const [payment, setPayment] = React.useState(payment_option.value);
  const [packageId, setPackageId] = React.useState(Packages.value);
  const [isLoading, setIsLoading] = React.useState(false);
  const { policy, addPolicyInfo } = usePolicyStore();

  const handleCreateCover = async () => {
    setIsLoading(true);
    try {
      const { data } = await getQuoteCreated(
        token,
        id,
        selectedQuote.insurance,
        driver,
        carData,
        cpData[0],
        packageId,
        payment
      );
       if (Packages.value === 'AMP') {
        policy.package_label = 'Plan Amplio';
      } else if (Packages.value === 'RC') {
        policy.package_label = 'Plan Basico';
      } else if (Packages.value === 'LIMIT') {
        policy.package_label = 'Plan Limitado';
      }
      await addPolicyInfo({ ...policy });
      TagManager.dataLayer({
        dataLayer: {
          event: 'CS_interactions',
          eventCategoy: 'Formulario.Seguro.Auto.v2',
          eventAction: 'Interacciones',
          eventLabel: 'validate_planSeguro_addToCart',
          telefono: driver.telefono,
          correo: driver.email,
          noCotizacion: policy.policyNumber,
          modelo: carData.description,
          marca: carData.assembler,
          anio: carData.model,
          paquete: selectedQuote.package,
          forma_pago: selectedQuote.payment_option,
          aseguradora: selectedQuote.insurance,
          tarjeta: undefined,
          tipo_tarjeta: undefined,
          colaborador: policy.employee_id,
          ecommerce: {
            currencyCode: 'MXN',
            add: {
              products: [
                {
                  name: selectedQuote.insurance,
                  id: undefined,
                  price: parseInt(selectedQuote.insurance_premium.total), //numero
                  brand:
                    '[paquete:' +
                    selectedQuote.package +
                    '_pago:' +
                    selectedQuote.payment_option +
                    ']',
                  category: 'SeguroCoche',
                  variant: carData.model,
                  quantity: 1,
                },
              ],
            },
          },
        },
      });
      TagManager.dataLayer({
        dataLayer: {
          event: 'productDetail',
          eventCategoy: 'Formulario.Seguro.Auto.v2',
          eventAction: 'step_3_submit',
          eventLabel: 'productDetail',
          ecommerce: {
            detail: {
              actionField: {
                list:
                  '[' +
                  selectedQuote.insurance +
                  ',' +
                  selectedQuote.insurance_premium.total +
                  ']',
                action: 'productDetail',
              },
              products: [
                {
                  name: selectedQuote.insurance,
                  id: undefined, //string
                  price: selectedQuote.insurance_premium.total, //número
                  brand:
                    '[paquete:' +
                    selectedQuote.package +
                    '_pago:' +
                    selectedQuote.payment_option +
                    ']',
                  category: 'SeguroCoche',
                  variant: selectedQuote.vehicle.model,
                  quantity: 1,
                },
              ],
            },
          },
        },
      });
      await setSelectedQuote(data.data);
      await setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
    await window.location.replace('');
    await onClose();
  };
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'CS_interactions',
        eventCategory: 'Formulario.Seguro.Auto.v2',
        eventAction: 'Interacciones',
        eventLabel: 'validate_planSeguro_view', //Texto del botón al que se hizo clic.
      },
    });
  }, []);

  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper className={classes.root}>
          <Box padding={2}>
            <Box
              marginBottom={2}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant={'h6'} fontWeight={'bold'}>
                {'¿El plan es correcto?'}
              </Typography>
              <IconButton onClick={onClose}>
                <Clear />
              </IconButton>
            </Box>
            <Box padding={1}>
              <FormControl fullWidth>
                <InputLabel>Plan</InputLabel>
                <Select
                  value={packageId}
                  label="Cobertura"
                  size={'medium'}
                  onChange={(e) => {
                    setPackageId(e.target.value);
                    if (e.target.value === 'AMP') {
                      setPackage('Plan Amplio', 'AMP');
                      return;
                    } else if (e.target.value === 'RC') {
                      setPackage('Plan Basico', 'RC');
                      return;
                    } else if (e.target.value === 'LIMIT') {
                      setPackage('Plan Limitado', 'LIMIT');
                      return;
                    }
                  }}
                  style={{ borderRadius: 8 }}
                  IconComponent={(props) => (
                    <ExpandMore style={{ color: '#039ECC' }} {...props} />
                  )}
                >
                  {[
                    { label: 'Plan amplio', value: 'AMP' },
                    { label: 'Plan limitado', value: 'LIMIT' },
                    {
                      label: 'Plan básico',
                      value: 'RC',
                    },
                  ].map(({ value, label }, i) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box padding={1} marginBottom={2}>
              <FormControl fullWidth>
                <InputLabel>Modalidad de pago</InputLabel>
                <Select
                  value={payment}
                  label="Modalidad de pago"
                  size={'medium'}
                  onChange={(e) => {
                    setPayment(e.target.value);
                    if (e.target.value === 'M') {
                      setPaymentOption('Mensual', 'M');
                      return;
                    } else if (e.target.value === '3M') {
                      setPaymentOption('Trimestral', '3M');
                      return;
                    } else if (e.target.value === '6M') {
                      setPaymentOption('Semestral', '6M');
                      return;
                    } else if (e.target.value === 'A') {
                      setPaymentOption('Anual', 'A');
                      return;
                    }
                  }}
                  style={{ borderRadius: 8 }}
                  IconComponent={(props) => (
                    <ExpandMore style={{ color: '#039ECC' }} {...props} />
                  )}
                >
                  { selectedQuote.insurance === 'hdi' &&
                    paymentOptionsHDI.map((value: any, i: number) => (
                      <MenuItem key={i} value={value.label}>
                        {value.value}
                      </MenuItem>
                    ))
                  }
                  { selectedQuote.insurance !== 'hdi' &&
                    paymentOptionsAll.map((value: any, i: number) => (
                      <MenuItem key={i} value={value.label}>
                        {value.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            {isLoading ? (
              <LoadingButton
                loading={isLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                style={{ height: 56, width: '100%', textTransform: 'none' }}
              >
                ¡Listísimo!
              </LoadingButton>
            ) : (
              <CustomButton
                text={'¡Listísimo!'}
                onClick={handleCreateCover}
                color={'primary'}
                variant={'contained'}
                style={{ height: 56, width: '100%', textTransform: 'none' }}
              />
            )}
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
