import { ContentCopy } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { usePolicyStore } from "@store/policyStore";
import { useClamp } from "@utils/useClamp";

export function NumeroCotizacion(){
    const {policy} = usePolicyStore();
    const {clamp} = useClamp();

    const copyToClipboard = () => {
        navigator.clipboard.writeText(policy.policyNumber)
    }

    return (
        <Grid item container xs={12} sm={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: clamp('flex-end', 'flex-end', 'space-between'), alignItems: 'center', marginBottom: '24px' }}>
            <Typography style={{ fontWeight: '700', marginRight: clamp('24px', '24px', '0') }}>Número de cotización</Typography>
            <Typography style={{ fontWeight: '400', marginRight: clamp('16px', '16px', '0'), color: '#212121', fontStyle: 'italic' }}>{policy.policyNumber}</Typography>
            <IconButton onClick={e => copyToClipboard()}>
                <ContentCopy color="primary" fontSize='small' />
            </IconButton>
        </Grid>
    )
}