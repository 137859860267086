import { Box, Button } from '@mui/material';

import { ModalHelp } from '@modals/ModalNeedHelp/ModalHelp';
//import { CarListData } from '@models/store/carListStore';
import TagManager from 'react-gtm-module';
import { usePolicyStore } from '@store/policyStore';

interface Props {
  //carList: CarListData[];
  //data: CarListData[];
  marca?: string;
  modelo?: string;
  anio?: string;
  type?: string;
  show: (component: React.ElementType, props: Object) => void;
  hide: () => void;
}
export const FoundSearchText: React.FC<Props> = ({
  /*carList, data */ show,
  hide,
  marca,
  modelo,
  anio,
  type,
}: Props) => {
  const policy = usePolicyStore();

  const DataLayer = {
    event: 'CSform_step_0_noEncuentro',
    eventCategory: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_0_noEncuentro',
    eventLabel: 'No Encuentro mi vehículo',
    telefono: undefined,
    correo: undefined,
    noCotizacion: policy.policy.policyNumber,
    modelo: modelo,
    marca: marca,
    anio: anio,
    paquete: undefined,
    forma_pago: undefined,
    aseguradora: undefined,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: type,
    colaborador: undefined,
    cupon: undefined,
  };

  const tagManagerArgs = {
    dataLayer: DataLayer,
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'flex-end'}
      width={'100%'}
    >
      {/*<Typography fontSize={14}>{data.length} resultados</Typography>*/}
      <Button
        onClick={() => {
          show(ModalHelp, {
            onClose: hide,
            marca: marca,
            modelo: modelo,
            anio: anio,
            type: type,
          });
          TagManager.dataLayer(tagManagerArgs);
        }}
        variant={'text'}
        //disabled={carList[0].description !== '' ? false : true}
        style={{
          padding: 0,
          fontSize: 14,
          fontWeight: 400,
          textTransform: 'none',
          textDecoration: 'underline',
          color: '#004876',
        }}
      >
        {'No encuentro mi vehículo'}
      </Button>
    </Box>
  );
};
