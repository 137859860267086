import { Typography, useTheme, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { usePlansDataStore } from '@store/plansStore';
import { useQuoteStore, useSelectedQuoteStore } from '@store/QuoteStore';
import { numberWithCommas } from '@utils/comparationUtils';

export const BrandDesktop = () => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const { selectedPlan, paymentMode } = usePlansDataStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const [totalPayment, setTotalPayment] = useState<string>('');
  useEffect(() => {
    let totalNumber = 0;
    selectedQuote.receipts.map((item: any) => {
      const number = Number(item?.total_premium);
      totalNumber += number;
    });
    setTotalPayment(totalNumber.toString());
  }, []);

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      marginTop={4}
      borderRadius={2}
    >
      <Box
        width={278}
        height={100}
        borderRadius={'10px 0 0 10px '}
        style={{ backgroundColor: '#e3f2fd' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          height={100}
        >
          <Box
            component={'img'}
            src={selectedQuote.company_logo}
            sx={{
              width: '55%',
              height: '100%',
              margin: '0',
              padding: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            alt={selectedQuote.company_logo}
            style={{ objectFit: 'contain' }}
          />
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        width={isTablet ? 250 : 278}
        height={100}
        borderRadius={'0 10px 10px 0'}
        style={{ backgroundColor: '#e8eaf6' }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'start'}
          padding={1}
        >
          <Typography variant="subtitle2" color="#8F8F8F" fontSize={16}>
            Costo anual
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 'bold', display: 'flex', gap: '0.5rem' }}
            fontSize={28}
          >
            <span>$</span>
            {parseFloat(
              parseFloat(totalPayment).toFixed(2)
            ).toLocaleString('es-MX', { minimumFractionDigits: 2 })}
          </Typography>
          <Typography fontSize={12} style={{ color: '#8F8F8F' }}>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
