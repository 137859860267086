import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import {
  Fade,
  Modal,
  Paper,
  Box,
  Backdrop,
  Theme,
  Typography,
} from '@mui/material';

import { ZustandContext } from '@store/modalStore';
import { CustomButton } from '@buttons/CustomButton';
import TagManager from 'react-gtm-module';

type ModalShareProps = {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    top: '50%',
    left: '50%',
    width: 499,
    borderRadius: 8,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
}));

export const ModalTime: React.FC<ModalShareProps> = ({ onClose }) => {
  const classes = useStyles();
  const { show, hide } = useContext(ZustandContext);
  const history = useHistory();
  
  useEffect(() => {
    TagManager.dataLayer({ dataLayer: {
        event: 'CS_interactions',
        eventCategory:'Formulario.Seguro.Auto.v2',
        eventAction: 'Interacciones',
        eventLabel: 'validate_cotización_view'//Texto del botón al que se hizo clic.
      }});
  }, []);

  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper className={classes.root}>
          <Box padding={3} paddingTop={3}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              alignItems={'flex-end'}
            ></Box>
            <Box width={'95%'} style={{ marginTop: '-20px' }}>
              <Typography
                variant={'h6'}
                fontSize={'20px'}
                fontWeight={'bold'}
                marginTop={3}
              >
                {
                  '¿Todavía te late este plan o te gustaría checar otras opciones?'
                }
              </Typography>
            </Box>
            <Box marginTop={2}>
              <CustomButton
                text={'¡Seguir con mi contratación!'}
                onClick={() => {
                  TagManager.dataLayer({ dataLayer: {
                    event: 'CS_interactions',
                    eventCategory:'Formulario.Seguro.Auto.v2',
                    eventAction: 'Interacciones',
                    eventLabel: 'validate_cotización_continuar'//Texto del botón al que se hizo clic.
                  }});
                  onClose();
                }}
                color={'primary'}
                variant={'contained'}
                style={{ height: 56, width: '100%', textTransform: 'none' }}
              />
            </Box>
            <Box marginTop={2}>
              <CustomButton
                text={'¡Prefiero ver más opciones!'}
                onClick={() => {
                  TagManager.dataLayer({ dataLayer: {
                    event: 'CS_interactions',
                    eventCategory:'Formulario.Seguro.Auto.v2',
                    eventAction: 'Interacciones',
                    eventLabel: '¡Prefiero ver más opciones!'//Texto del botón al que se hizo clic.
                  }});
                  history.push('/plans');
                  onClose();
                }}
                color={'primary'}
                variant={'outlined'}
                style={{ height: 56, width: '100%', textTransform: 'none' }}
              />
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
