import React, { useState } from 'react';
import validator from 'validator';
import { makeStyles } from '@mui/styles';
import { ArrowForward, Check } from '@mui/icons-material';
import {
  Fade,
  Modal,
  Paper,
  Box,
  Backdrop,
  Typography,
  Theme,
  TextField,
  Checkbox,
} from '@mui/material';

import { useForm } from '@hooks/useForm';
import { CustomButton } from '@buttons/CustomButton';

type ModalHomeAltProps = {
  name: string;
  value: string;
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    top: '50%',
    left: '50%',
    width: 420,
    borderRadius: 8,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
}));

export const ModalHomeAlt: React.FC<ModalHomeAltProps> = ({
  name,
  value,
  onClose,
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState<boolean>(false);

  const [formState, setFormState] = useForm(
    value === 'email'
      ? {
          email: '',
          phone: '',
        }
      : { phone: '', email: '' }
  );

  const { email, phone } = formState;

  const isValidForm = (): boolean => {
    if (value === 'email') {
      if (!validator.isEmail(email)) {
        return false;
      }
    } else {
      if (!validator.isMobilePhone(phone)) {
        return false;
      } else if (phone.length !== 10) {
        return false;
      }
    }
    if (!checked) {
      return false;
    }
    return true;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked);
  };

  const color = (key: string): 'success' | 'primary' => {
    if (key === 'email') {
      if (value === 'email') {
        if (validator.isEmail(formState[key])) {
          return 'success';
        }
        return 'primary';
      }
    } else {
      if (value === 'phone') {
        if (
          validator.isMobilePhone(formState[key]) &&
          formState[key].length === 10
        ) {
          return 'success';
        }
        return 'primary';
      }
    }
    return 'primary';
  };

  const endAdornment = (key: string): React.ReactNode => {
    if (key === 'email') {
      if (value === 'email') {
        if (validator.isEmail(formState[key])) {
          return <Check color={'success'} />;
        }
        return null;
      }
    } else {
      if (value === 'phone') {
        if (
          validator.isMobilePhone(formState[key]) &&
          formState[key].length === 10
        ) {
          return <Check color={'success'} />;
        }
        return null;
      }
    }
    return null;
  };

  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper className={classes.root}>
          <Box padding={2}>
            {Object.keys(formState).map((key, i) => (
              <Box marginBottom={2} key={i}>
                {key === 'email' ? (
                  Object.keys(formState).indexOf('email') === 0 ? (
                    <Typography marginBottom={2.4} variant={'subtitle2'}>
                      Por favor, indícanos en qué{' '}
                      <span style={{ fontWeight: 'bold' }}>correo</span> deseas
                      recibir nuestra comunicación y seguiminto.
                    </Typography>
                  ) : (
                    <Typography marginBottom={2.4} variant={'subtitle2'}>
                      En caso de no poder contactarte, a que{' '}
                      <span style={{ fontWeight: 'bold' }}>correo</span>{' '}
                      podríamos llamarte
                    </Typography>
                  )
                ) : Object.keys(formState).indexOf('phone') === 0 ? (
                  <Typography marginBottom={2.4} variant={'subtitle2'}>
                    Por favor, indícanos en a qué{' '}
                    <span style={{ fontWeight: 'bold' }}>teléfono</span> deseas
                    recibir nuestra comunicación y seguimiento.
                  </Typography>
                ) : (
                  <Typography marginBottom={2.4} variant={'subtitle2'}>
                    En caso de no poder contactarte, a que{' '}
                    <span style={{ fontWeight: 'bold' }}>teléfono</span>{' '}
                    podríamos llamarte
                  </Typography>
                )}
                <TextField
                  fullWidth
                  name={key}
                  variant="outlined"
                  color={color(key)}
                  value={formState[key]}
                  onChange={(e) => setFormState(e)}
                  InputProps={{
                    style: {
                      borderRadius: 8,
                    },
                    endAdornment: endAdornment(key),
                  }}
                  label={
                    key === 'email'
                      ? value === 'email'
                        ? 'Correo electrónico'
                        : 'Correo electrónico (Opcional)'
                      : value === 'phone'
                      ? 'Teléfono'
                      : 'Teléfono (Opcional)'
                  }
                />
              </Box>
            ))}
            <Box
              marginBottom={2.4}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
            >
              <Checkbox
                checked={checked}
                style={{ padding: 0 }}
                onChange={handleChange}
              />
              <Box marginLeft={1}>
                <Typography margin={0} variant={'subtitle2'}>
                  {'Acepto '}
                  <span
                    style={{
                      color: '#039ECC',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                  >
                    {'Aviso de Privacidad'}
                  </span>
                </Typography>
              </Box>
            </Box>
            <CustomButton
              text={'Continuar'}
              color={'primary'}
              variant={'contained'}
              disabled={!isValidForm()}
              endIcon={<ArrowForward />}
              onClick={() => {
                onClose();
              }}
              style={{ width: '100%', height: 56, textTransform: 'none' }}
            />
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
