import { InterInstance } from '@api/config';

export const getVIN = async (id: string, insurance: string, token: string) => {
  try {
    const { data } = await InterInstance({
      method: 'get',
      // eslint-disable-next-line no-useless-concat
      url: '/emision/check-vin/' + id + '?insurance=' + `${insurance}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (e) {
    return e;
  }
};
