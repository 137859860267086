import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
//import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Theme, Container, Box, useMediaQuery, useTheme,  Grid, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FeacturesList } from '@screens/insurerDetails/FeacturesList';
import { Quotation } from '@screens/insurerDetails/Quotation';
import { useChangePrice } from '@hooks/useChangePrice';
import { useCarStore } from '@store/carInfoStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useSelectedQuoteStore } from '@store/QuoteStore';
//import { useGetQuotePayment } from '@store/QuoteData';

import TagManager from 'react-gtm-module';
import Loading from '@components/Loading/Loading';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { useCPStore } from '@store/cpStore';
//import { getQuoteCreated } from '@api/getQuoteCreated';
import { usePolicyStore } from '@store/policyStore';
import { Return } from '@screens/insurerDetails/Return';
import { VehicleData } from '@screens/insurerDetails/VehicleData';
import { InsuranceCustomizer } from '../components/screens/insurerDetails/InsuranceCustomizer';
import { useClamp } from '@utils/useClamp';
//import { ModalPagoMen } from '@modals/ModalPagoMen';
import StickyButton from "@buttons/StickyButton";
import {upCRMContact} from "@utils/CRM";
import {NameSubstring} from "@utils/utilityFunctions";
import {useVisibleButtonHelp} from "@hooks/useVisibleButtonHelp";
import {HeaderPlans} from "@screens/plans/HeaderPlans";

// @ts-ignore
const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(10px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  root: {
    background: '#f5f5f5',
    paddingBottom: '40px',
  },
  card: {
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    borderRadius: 8,

    '& .hidden': {
      display: 'none',
    },
    '&:hover .hidden': {
      display: 'block',
      animation: '$fadeIn .2s ease-in-out',
    },
  },
}));

export const InsurerDetails: React.FC = () => {
  const history = useHistory();
  const { selectedQuote, setSelectedQuote } = useSelectedQuoteStore();

  if (!selectedQuote?.insurance) {
    history.push('/plans');
  }

  const classes = useStyles();
  const { breakpoints, palette } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));

  const [url, setUrl] = useState<any>('');
  const [loading, setLoading] = React.useState(false);
  //const { Packages, payment_option } = useGetQuotePayment();
  //const [open, setOpen] = useState<boolean>(false);


  const [success, setSuccess] = useState(false);
  const [loadingSticky, setLoadingSticky] = useState(false);

  /*const trigger = useScrollTrigger({
    threshold: 970,
    disableHysteresis: true,
  });*/

  //DataLayer
  const { car } = useCarStore();
  const { driver } = useDriverStore();
  const zip = driver.codigoPostal || '';
  const { policy, addPolicyInfo } = usePolicyStore();
  const { searchCP } = useCPStore();
  const { clamp } = useClamp();
  const { tracking } = useTrackingStore();
  if (selectedQuote?.vehicle?.type === 'MOTO' && !policy?.customRT?.toString()) {
    policy.customRT = '20';
    policy.customDM = '10';
  }
  /*const custom_RT = !policy?.customRT?.toString()
    ? '10'
    : policy?.customRT?.toString();
  const custom_DM = !policy?.customDM?.toString()
    ? '5'
    : policy?.customDM?.toString();*/
  const DataLayer = {
    event: 'CSform_step_4_view',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_4_view',
    eventLabel: undefined,
    telefono: driver.telefono,
    correo: driver.email,
    noCotizacion: policy.policyNumber,
    modelo: car.description,
    marca: car.assembler,
    anio: car.model,
    paquete: selectedQuote?.package,
    forma_pago: selectedQuote?.payment_option,
    aseguradora: selectedQuote?.insurance,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: car.type,
    colaborador: undefined,
    cupon: undefined,
  };

  useChangePrice();

  const tagManagerArgs = {
    dataLayer: DataLayer,
  };

  useEffect(() => {
    window.scroll(0, 0);
    searchCP(zip, token);
  }, []);

  useEffect(() => {
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  const { token } = useTokenStore();
  const {
    tracking: { id },
  } = useTrackingStore();

  const { car: carData } = useCarStore();
  const { cpData } = useCPStore();

  /*useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await getQuoteCreated(
          token,
          id,
          selectedQuote.insurance,
          driver,
          carData,
          cpData[0],
          Packages.value,
          payment_option.value,
          custom_RT,
          custom_DM
        );
        await setSelectedQuote(data.data);
        policy.payment_new = payment_option.value;
        policy.package_new = Packages.value;
        if (payment_option.value === 'M') {
          setOpen(true);
          policy.payment_label = 'Mensual';
        } else if (payment_option.value === '3M') {
          setOpen(false);
          policy.payment_label = 'Trimestral';
        } else if (payment_option.value === '6M') {
          setOpen(false);
          policy.payment_label = 'Semestral';
        } else if (payment_option.value === 'A') {
          setOpen(false);
          policy.payment_label = 'Anual';
        }
        if (Packages.value === 'AMP') {
          policy.package_label = 'Plan Amplio';
        } else if (Packages.value === 'RC') {
          policy.package_label = 'Plan Basico';
        } else if (Packages.value === 'LIMIT') {
          policy.package_label = 'Plan Limitado';
        }
        policy.error = false;
        await addPolicyInfo({ ...policy });
        await setLoading(false);
      } catch (error) {
        policy.error = true;
        policy.payment_new = policy.payment_anterior;
        policy.package_new = policy.package_anterior;
        if (policy.payment_anterior === 'M') {
          policy.payment_label = 'Mensual';
        } else if (policy.payment_anterior === '3M') {
          policy.payment_label = 'Trimestral';
        } else if (policy.payment_anterior === '6M') {
          policy.payment_label = 'Semestral';
        } else if (policy.payment_anterior === 'A') {
          policy.payment_label = 'Anual';
        }
        if (policy.package_anterior === 'AMP') {
          policy.package_label = 'Plan Amplio';
        } else if (policy.package_anterior === 'RC') {
          policy.package_label = 'Plan Basico';
        } else if (policy.package_anterior === 'LIMIT') {
          policy.package_label = 'Plan Limitado';
        }
        await addPolicyInfo({ ...policy });
        await setLoading(false);
      }
    })();
  }, [Packages, payment_option]);*/

const sendApi = async () => {
  try {
    setLoadingSticky(true);
    let response = await upCRMContact(
        token,
        car,
        driver,
        selectedQuote,
        policy,
        tracking,
        cpData,
        selectedQuote,
        url
    );

    setTimeout(() => {
      if (response.success) {
        setLoadingSticky(false);
        setSuccess(true);
      } else {
        setLoadingSticky(false);
      }
    }, 1000);
  }catch (e) {
    setLoadingSticky(false);
    setSuccess(false);
  }

};

  const title = (
      <p style={{ margin: 0 }}>
        Hola 👋 <strong>{NameSubstring(driver.name)}</strong> ¿Tienes dudas sobre las coberturas
        de tu seguro?
      </p>
  );
  useVisibleButtonHelp();

  return (
    <div className={classes.root}>
      <Loading open={loading} handleClose={() => true} />
      <Container maxWidth="xl" style={{ padding: 0 }}>
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            marginTop: isTablet ? 64 : 70,
            minHeight: clamp('70vh', '52vh'),
            position: 'relative',
          }}
        >
          <Box
            sx={{ flexGrow: 1 }}
            display="block"
            flexDirection={'row'}
            justifyContent={'space-between'}
            marginTop={clamp('20px', '20px', '10px')}
          >
            {/*<Return />*/}
            <Container maxWidth={'lg'} style={{ paddingBottom: '40px' }}>
              <Box
                  sx={{ flexGrow: 1 }}
                  style={{
                    marginTop: isTablet ? 120 : 90,
                  }}
              >
                <HeaderPlans isInsurerDetail />
              </Box>
            </Container>
            <VehicleData />
            <InsuranceCustomizer setLoading={setLoading} />
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '12px'}}>
              <Grid container sx={{width: '1152px', background:'transparent', borderRadius: '8px', display: 'flex', flexDirection: isDesktop ? 'row' : 'column'}}>
                <Grid item sx={{flex: 1}} >
                  <FeacturesList />
                </Grid>
                <Grid item sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                  <Quotation isMobile={isMobile} />
                </Grid>
              </Grid>
            </Box>

          </Box>
        </Box>
      </Container>
      {
          //isMobile &&
          <StickyButton
              loading={loadingSticky}
              success={success} action={sendApi} title={title} />
      }

    </div>
  );
};
