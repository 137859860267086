import { updateCRM } from '@api/updateCRM';
import { validateCouponDateTime } from '@utils/utilityFunctions';

export const upCRM = async (
  token: string,
  car: any,
  driver: any,
  selectedQuote: any,
  policy: any,
  tracking: any,
  cpData: any,
  quote?: any,
  url?: any
) => {
  let date = new Date();
  let day = `${date.getDate()}`.padStart(2, '0');
  let month = `${date.getMonth() + 1}`.padStart(2, '0');
  let year = date.getFullYear();
  let fechaHoy = `${year}-${month}-${day}`;
  const Quote = {
    tracking_id: tracking.id,
    business: 'creditaria',
    folio: policy.policyNumber,
    vehicle_id: car.vehicle_id,
    tax_regime: '',
    contact_now: false,
    night_promo: validateCouponDateTime(
      policy.endDateTime,
      policy.acceptCoupon
    ),
    stage: policy.stage,
    sub_stage: policy.sub_stage,
    sub_channel_sale: 'Inter.mx',
    Tiporelacion: '',
    tema: '',
    campana: '',
    portal_origen: 'https://www.inter.mx',
    localidad: 'CDMX',
    grupo_economico: 'Coche Seguro',
    contractor_first_name: policy.contrator?.firstName,
    contractor_lastname: policy.contrator?.middleName,
    contractor_second_lastname: policy.contrator?.lastName,
    contractor_birth_date: policy.contrator?.fechaNacimiento,
    contractor_rfc: policy.contrator?.RFC,
    contractor_email: policy.contrator?.email,
    contractor_phone: policy.contrator?.telefono,
    contractor_gender: policy.contrator?.genero === 'Hombre' ? 'M' : 'F',
    contractor_zip_code: policy.taxResidence.zip,
    contractor_state_code: policy.taxResidence.estado,
    contractor_township_code: policy.taxResidence.city,
    contractor_suburb_code: policy.taxResidence.colonia,
    contractor_street: policy.taxResidence.street,
    contractor_ext: policy.taxResidence.numeroExterior,
    contractor_int: policy.taxResidence.numeroInterior,
    custom_DM: policy.customDM,
    custom_RT: policy.customRT,
    same_tax_residence: policy.taxResidence.zip !== '' ? false : true,
    legal_zip_code: policy.taxResidence.zip,
    legal_state_code: policy.taxResidence.estado,
    legal_township_code: policy.taxResidence.city,
    legal_suburb_code: policy.taxResidence.colonia,
    legal_street: policy.taxResidence.street,
    legal_ext: policy.taxResidence.numeroExterior,
    legal_int: policy.taxResidence.numeroInterior,
    contractor_driver: policy.selectorDriver === 'No' ? false : true,
    driver_first_name: policy.driver.firstName || driver.name,
    driver_last_name: policy.driver.middleName,
    driver_second_last_name: policy.driver.lastName,
    driver_phone: driver.telefono,
    driver_mail: driver.email,
    driver_gender: driver.genero === 'Hombre' ? 'M' : 'F',
    driver_birth_date: driver.fechaNacimiento,
    driver_location: '',
    driver_rfc: policy.driver.RFC,
    driver_zip_code: driver.codigoPostal,
    driver_state_code: policy.driver.stateCode,
    driver_township_code: cpData[0].township_code,
    driver_suburb_code: policy.driver.suburbCode,
    driver_street: policy.street,
    driver_ext: policy.numeroExterior,
    driver_int: policy.numeroInterior,
    vehicle_use: 'Particular',
    promo_code: policy.code,
    associate_id: policy.employee_id,
    insurance: selectedQuote.insurance,
    policy_url: '',
    policy_id: policy.no_poliza,
    importe_total: quote?.insurance_premium?.total,
    derecho_poliza: quote?.insurance_premium?.right,
    quotes: [
      {
        quote_doc: '',
        quote_id: quote?.quote_id,
        insurance: quote?.insurance,
        vehicle_service: 'PARTICULAR',
        vehicle_use: 'NORMAL',
        vehicle_type: quote?.vehicle?.type,
        payment_option: quote?.payment_option,
        package: quote?.package,
        promotion_code: policy.code,
        associate_id: policy.employee_id,
        quote_date: fechaHoy,
        start_at: fechaHoy,
        stage: policy.stage,
        sub_stage: policy.sub_stage,
        status_code: policy.status_code,
        state_code: policy.state_code,
        vehicle_plate: policy.plate,
        vehicle_vin: policy.Niv,
        net_premium: quote?.insurance_premium?.total,
        payment_url: url,
      },
    ],
  };
  let response = await updateCRM(token, Quote);
  return response;
};

export const upCRMContact = async (
  token: string,
  car?: any,
  driver?: any,
  selectedQuote?: any,
  policy?: any,
  tracking?: any,
  cpData?: any,
  quote?: any,
  url?: any
) => {
  let date = new Date();
  let day = `${date.getDate()}`.padStart(2, '0');
  let month = `${date.getMonth() + 1}`.padStart(2, '0');
  let year = date.getFullYear();
  let fechaHoy = `${year}-${month}-${day}`;
  const Quote = {
    tracking_id: tracking?.id,
    business: 'creditaria',
    folio: policy?.policyNumber,
    vehicle_id: car?.vehicle_id,
    tax_regime: '',
    contact_now: true,
    night_promo: false,
    stage: policy?.stage,
    sub_stage: policy?.sub_stage,
    sub_channel_sale: 'Inter.mx',
    Tiporelacion: '',
    tema: '',
    campana: '',
    portal_origen: 'https://www.inter.mx',
    localidad: 'CDMX',
    grupo_economico: 'Coche Seguro',
    contractor_first_name: policy.contrator?.firstName,
    contractor_lastname: policy.contrator?.middleName,
    contractor_second_lastname: policy.contrator?.lastName,
    contractor_birth_date: policy.contrator?.fechaNacimiento,
    contractor_rfc: policy.contrator?.RFC,
    contractor_email: policy.contrator?.email,
    contractor_phone: policy.contrator?.telefono,
    contractor_gender: policy.contrator?.genero === 'Hombre' ? 'M' : 'F',
    contractor_zip_code: policy.taxResidence.zip,
    contractor_state_code: policy.taxResidence.estado,
    contractor_township_code: policy.taxResidence.city,
    contractor_suburb_code: policy.taxResidence.colonia,
    contractor_street: policy.taxResidence.street,
    contractor_ext: policy.taxResidence.numeroExterior,
    contractor_int: policy.taxResidence.numeroInterior,
    same_tax_residence: policy.taxResidence.zip !== '' ? false : true,
    legal_zip_code: policy.taxResidence.zip,
    legal_state_code: policy.taxResidence.estado,
    legal_township_code: policy.taxResidence.city,
    legal_suburb_code: policy.taxResidence.colonia,
    legal_street: policy.taxResidence.street,
    legal_ext: policy.taxResidence.numeroExterior,
    legal_int: policy.taxResidence.numeroInterior,
    contractor_driver: policy.selectorDriver === 'No' ? false : true,
    driver_first_name: policy.driver.firstName,
    driver_last_name: policy.driver.middleName,
    driver_second_last_name: policy.driver.lastName,
    driver_phone: driver.telefono,
    driver_mail: driver.email,
    driver_gender: driver.genero === 'Hombre' ? 'M' : 'F',
    driver_birth_date: driver.fechaNacimiento,
    driver_location: '',
    driver_rfc: policy.driver.RFC,
    driver_zip_code: driver.codigoPostal,
    driver_state_code: policy.driver.stateCode,
    driver_township_code: cpData[0].township_code,
    driver_suburb_code: policy.driver.suburbCode,
    driver_street: policy.street,
    driver_ext: policy.numeroExterior,
    driver_int: policy.numeroInterior,
    vehicle_use: 'Particular',
    promo_code: policy.code,
    associate_id: policy.employee_id,
    insurance: selectedQuote.insurance,
    policy_url: '',
    policy_id: policy.no_poliza,
    importe_total: quote?.insurance_premium?.total,
    derecho_poliza: quote?.insurance_premium?.right,
    quotes: [
      {
        quote_doc: '',
        quote_id: quote?.quote_id,
        insurance: quote?.insurance,
        vehicle_service: 'PARTICULAR',
        vehicle_use: 'NORMAL',
        vehicle_type: quote?.vehicle?.type,
        payment_option: quote?.payment_option,
        package: quote?.package,
        promotion_code: policy.code,
        associate_id: policy.employee_id,
        quote_date: fechaHoy,
        start_at: fechaHoy,
        stage: policy.stage,
        sub_stage: policy.sub_stage,
        status_code: policy.status_code,
        state_code: policy.state_code,
        vehicle_plate: policy.plate,
        vehicle_vin: policy.Niv,
        net_premium: quote?.insurance_premium?.total,
        payment_url: url,
      },
    ],
  };
  let response = await updateCRM(token, Quote);
  return response;
};
