import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Clear, Error, Warning } from '@mui/icons-material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Check } from '@mui/icons-material';
import validator from 'validator';
import {
  Fade,
  Modal,
  Paper,
  Box,
  Backdrop,
  Theme,
  Typography,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import { CustomButton } from '@buttons/CustomButton';
import {
  allEqual,
  calculateAge,
  isThereFourConsecutiveNumber,
} from '@utils/utilityFunctions';
import { useDriverStore } from '@store/driverInfoStore';
import TagManager from 'react-gtm-module';
import { useCPStore } from '@store/cpStore';
import { CPCustomMask } from '@inputCustomMasks/CPCustomMask';
import { useTokenStore } from '@store/TokenStore';
import { useQuoteStore } from '@store/QuoteStore';
import { formatDate } from '@utils/formatDate';
import { es } from 'date-fns/locale';
import { useTrackingStore } from '@store/TrackingStore';
import { useCarStore } from '@store/carInfoStore';
import { usePolicyStore } from '@store/policyStore';
import getAllByPackets from '@api/getAllByPackets';
import { createContact } from '@api/createContact';
import ReactTooltip from 'react-tooltip';
import { getCPById } from '@api/getCP';
import { validateEmail } from '../../utils/utilityFunctions';

type ModalShareProps = {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    top: '50%',
    left: '50%',
    width: 364,
    borderRadius: 8,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
}));

export const ModalDriverToSave: React.FC<ModalShareProps> = ({ onClose }) => {
  const { driver, setDriver } = useDriverStore();
  const { cpData, searchCP } = useCPStore();
  const { token } = useTokenStore();

  const [changeModal, setChangeModal] = useState<boolean>(false);
  const [valuesChanged, setValuesChanged] = useState<boolean>(false);

  const classes = useStyles();
  const [Limit, setLimit] = useState<any>({
    MaxAgeAcept: new Date(),
    MinAgeAcept: new Date(),
  });
  const [name, setName] = useState<string>(driver.name);
  const [errorName, setErrorName] = useState({ msg: '', isError: false });
  const [warningName, setWarningName] = useState({ msg: '', isWarning: false });
  const [birthDate, setBirthDate] = useState<Date | string>(
    driver.fechaNacimiento
  );
  const [errorBirthdate, setErrorBirthdate] = useState({
    msg: '',
    isError: false,
  });
  const [warningBirthdate, setWarningBirthdate] = useState({
    msg: '',
    isWarning: false,
  });
  const [email, setEmail] = useState<string | ''>(driver.email || '');
  const [errorEmail, setErrorEmail] = useState({ msg: '', isError: false });
  const [warningEmail, setWarningEmail] = useState({
    msg: '',
    isWarning: false,
  });
  const [anterior, setAnterior] = useState<string>(driver.genero);
  const [colorM, setColorM] = useState<string>('transparent');
  const [colorF, setColorF] = useState<string>('#F5FBFD');
  const [backM, setBackM] = useState<string>('#424242');
  const [backF, setBackF] = useState<string>('#039ECC');
  const [zip, setZip] = useState<string>(driver.codigoPostal);
  const [isContinueClick, setIsContinueClick] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>(driver.telefono || '');
  const [gender, setGender] = React.useState<string>(driver.genero);
  const [errorZip, setErrorZip] = useState({
    msg: '',
    isError: false,
  });
  const [warningZip, setWarningZip] = useState({
    msg: '',
    isWarning: false,
  });
  const [errorPhone, setErrorPhone] = useState({
    msg: '',
    isError: false,
  });
  const [errorAge, setErrorAge] = useState<{ msg: string; isError: boolean }>({
    msg: '',
    isError: false,
  });
  const [errorTelefono, setErrorTelefono] = useState({
    msg: '',
    isError: false,
  });
  const [warningTelefono, setWarningTelefono] = useState({
    msg: '',
    isWarning: false,
  });

  useEffect(() => {
    let currentGender;
    let currentBirthDate;
    let currentZip;
    currentGender = gender;
    currentBirthDate = birthDate;
    currentZip = zip;
    if (currentZip.length === 5) {
      setErrorZip({ msg: '', isError: false });
    }
    if (currentZip.length < 5 || currentZip.length > 5) {
      setErrorZip({
        msg: 'El código postal debe ser de 5 digitos',
        isError: true,
      });
    }
    if (phone.length < 10) {
      setErrorPhone({
        msg: '',
        isError: false,
      });
      setWarningTelefono({
        msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 10. ',
        isWarning: true,
      });
    } else {
      setErrorPhone({
        msg: '',
        isError: false,
      });
    }
    if (
      currentGender !== gender ||
      currentBirthDate !== birthDate ||
      currentZip !== zip
    ) {
      setValuesChanged(true);
    } else {
      setValuesChanged(false);
    }
  }, [gender, birthDate, zip, phone]);

  const handleChangeDriverBirthday = (newValue: any) => {
    setBirthDate(formatDate(newValue));
    setWarningBirthdate({ msg: '', isWarning: false });
    setErrorBirthdate({ msg: '', isError: false });
  };

  const handleChangeGender = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment != null && anterior != null) {
      setAnterior(newAlignment);
    } else if (anterior != null) {
      newAlignment = anterior;
    } else {
      newAlignment = 'Mujer';
    }
    setGender(newAlignment);
    if (newAlignment === 'Hombre') {
      setColorM('#F5FBFD');
      setColorF('transparent');
      setBackM('#039ECC');
      setBackF('#424242');
    } else {
      setColorF('#F5FBFD');
      setColorM('transparent');
      setBackM('#424242');
      setBackF('#039ECC');
    }
  };

  const colorToggle = (attribute: string, value: string): string => {
    return attribute === value ? '#039ECC' : '#424242';
  };

  const backgroundToggle = (attribute: string, value: string): string => {
    return attribute === value ? '#F5FBFD' : 'transparent';
  };

  const validateName = () => {
    if (name === '') {
      setErrorName({
        msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
        isError: true,
      });
      setWarningName({ msg: '', isWarning: false });
      return;
    }

    if (name.length < 2) {
      setWarningName({
        msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2. ',
        isWarning: true,
      });
      setErrorName({ msg: '', isError: false });
      return;
    }
  };

  const validateBirthdate = () => {
    if (
      birthDate.toString() === 'NaN-NaN-NaN' ||
      birthDate.toString().length < 10
    ) {
      setErrorBirthdate({
        msg: '',
        isError: false,
      });
      setWarningBirthdate({
        msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 8.',
        isWarning: true,
      });
      return;
    }

    if (birthDate === '') {
      setErrorBirthdate({
        msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
        isError: true,
      });
      setWarningBirthdate({ msg: '', isWarning: false });
      return;
    }
  };

  const validateEmailInput = () => {
    if (email === '') {
      setErrorEmail({
        msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
        isError: true,
      });
      setWarningEmail({ msg: '', isWarning: false });
      return;
    }

    if (!validateEmail(email)) {
      setErrorEmail({
        msg: '¡Oops! Debes ingresar un email válido.',
        isError: true,
      });
      setWarningEmail({ msg: '', isWarning: false });
      return;
    }
  };

  const validatePhone = () => {
    if (phone === '') {
      setErrorPhone({
        msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
        isError: true,
      });
      setWarningTelefono({ msg: '', isWarning: false });
      return;
    }

    if (phone.length < 10) {
      setErrorPhone({
        msg: '',
        isError: false,
      });
      setWarningTelefono({
        msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 10. ',
        isWarning: true,
      });
      return;
    }
  };

  const handleEditDriver = async () => {
    validateName();
    validateBirthdate();
    validateEmailInput();
    validatePhone();
    if (calculateAge(birthDate) >= 18 && calculateAge(birthDate) <= 99) {
      setDriver({
        ...driver,
        name,
        fechaNacimiento: birthDate,
        genero: gender,
        telefono: phone,
        email,
      });

      const data = await getCPById(zip, token);
      if (data.errors) {
        setErrorZip({
          msg: '¡Oops! Parece que hay un error en tu Código Postal.',
          isError: true,
        });
        setWarningZip({ msg: '', isWarning: false });
        return;
      }
      setWarningZip({ msg: '', isWarning: false });
      setErrorZip({
        msg: '',
        isError: false,
      });
      if (phone.length < 10) {
        return;
      }
      setErrorPhone({
        msg: '',
        isError: false,
      });
      onClose();
      await searchCP(zip, token);
      let crm = {
        business: 'creditaria',
        tracking_id: id,
        name: driver.name,
        cellphone: driver.telefono,
        last_name: '',
        second_last_name: '',
        phone: driver.telefono,
        sub_channel_sale: 'Inter.mx',
        email: driver.email,
        gender: driver.genero,
        birth_date: driver.fechaNacimiento,
        township_code: '',
        suburb_code: '',
        vehicle_id: carData.vehicle_id,
        rfc: '',
        address: '',
        suburb_name: '',
        township_name: '',
        Tiporelacion: '',
        zip_code: driver.codigoPostal,
        insurance: '',
        campana: '',
        portal_origen: 'https://www.inter.mx',
        grupo_economico: 'Coche Seguro',
        url:
          'https://' +
          window.location.hostname +
          '/' +
          '?utm_source=' +
          window.localStorage.getItem('utm_source') +
          '&utm_medium=' +
          window.localStorage.getItem('utm_medium') +
          '&utm_campaign=' +
          window.localStorage.getItem('utm_campaign') +
          '&utm_content=' +
          window.localStorage.getItem('utm_content'),
      };
      let crmresponse = await createContact(token, crm);
      policy.policyNumber = crmresponse.data.Folio;
      policy.uuid = crmresponse.data.Segurify_uuid;
      policy.stage = '1. Registro (Coche)';
      policy.sub_stage = '1. Datos Generales';
      policy.state_code = 'Borrador';
      policy.status_code = 'En revisión';
      TagManager.dataLayer({
        dataLayer: {
          event: 'CS_interactions',
          eventCategory: 'Formulario.Seguro.Auto.v2',
          eventAction: 'Interacciones',
          eventLabel: 'update_conductor_submit', //Texto del botón al que se hizo clic.
        },
      });
      await addPolicyInfo({ ...policy });
      await setIsContinueClick(true);
      await window.location.reload();
    } else {
      setErrorBirthdate({
        msg: 'Por favor ingresar una edad valida mayor a 17 años y menor o igual a 99 años',
        isError: true,
      });
    }
  };

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'CS_interactions',
        eventCategory: 'Formulario.Seguro.Auto.v2',
        eventAction: 'Interacciones',
        eventLabel: 'update_conductor_view', //Texto del botón al que se hizo clic.
      },
    });
    if (gender === 'Hombre') {
      setColorM('#F5FBFD');
      setColorF('transparent');
      setBackM('#039ECC');
      setBackF('#424242');
    } else {
      setColorF('#F5FBFD');
      setColorM('transparent');
      setBackM('#424242');
      setBackF('#039ECC');
    }
    if (calculateAge(birthDate) >= 18 && calculateAge(birthDate) <= 99) {
      setErrorAge({
        msg: '',
        isError: false,
      });
      if (cpData.length === 0) {
        setErrorZip({ msg: 'Código postal invalido', isError: true });
        return;
      }
      if (isContinueClick) {
        setIsContinueClick(false);
        onClose();
      }
      setDriver({
        ...driver,
        name: name ? name : driver.name,
        genero: gender ? gender : driver.genero,
        fechaNacimiento: birthDate ? birthDate : driver.fechaNacimiento,
        codigoPostal: zip ? zip : driver.codigoPostal,
        email: email ? email : driver.email,
        telefono: phone ? phone : driver.telefono,
      });
      setErrorAge({
        msg: '',
        isError: false,
      });
    } else {
      setErrorAge({
        msg: 'Por favor ingresar una edad valida mayor a 17 años y menor o igual a 99 años',
        isError: true,
      });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpData]);

  const CalcYear = () => {
    const MaxAgeAcept = 99;
    const MinAgeAcept = 18;
    const currentYear = new Date().getFullYear();
    setLimit({
      MaxAgeAcept: new Date(`${currentYear - MinAgeAcept}-08-18`),
      MinAgeAcept: new Date(`${currentYear - MaxAgeAcept}-08-18`),
    });
  };

  useEffect(() => {
    setErrorAge({ msg: '', isError: false });
    setErrorZip({ msg: '', isError: false });
    setErrorPhone({ msg: '', isError: false });
    CalcYear();
  }, []);

  const { setQuoteList, setIsFetching } = useQuoteStore();
  const {
    tracking: { id },
  } = useTrackingStore();
  const { car: carData } = useCarStore();
  const { policy, addPolicyInfo } = usePolicyStore();

  const updateQuote = async () => {
    setQuoteList([]);
    setIsFetching(true);
    const dataFilter = await getAllByPackets(
      token,
      id,
      driver,
      carData,
      cpData,
      policy.package,
      policy.payment_option
    );
    setQuoteList(dataFilter);
    setIsFetching(false);
  };

  const colorHelperTextCP = () => {
    if (warningZip.isWarning) {
      return '#FF9E1B';
    }

    if (errorZip.isError) {
      return '#f44336';
    }

    return '#5AB52F';
  };

  const cpRighIcon = () => {
    if (warningZip.isWarning) {
      return <Warning color={'warning'} />;
    }

    if (errorZip.isError) {
      return <Error color={'error'} />;
    }

    return <Check color={'success'} />;
  };

  const colorCPAdornment = () => {
    if (warningZip.isWarning) {
      return 'warning';
    }

    if (errorZip.isError) {
      return 'error';
    }

    return 'success';
  };

  const colorNameAdornment = () => {
    if (warningName.isWarning) {
      return 'warning';
    }

    if (errorName.isError) {
      return 'error';
    }

    return 'success';
  };

  const colorHelperText = () => {
    if (warningName.isWarning) {
      return '#FF9E1B';
    }

    if (errorName.isError) {
      return '#f44336';
    }

    return '#5AB52F';
  };

  const colorHelperTextEmail = () => {
    if (warningEmail.isWarning) {
      return '#FF9E1B';
    }

    if (errorEmail.isError) {
      return '#f44336';
    }

    return '#5AB52F';
  };

  const colorHelperBirthdate = () => {
    if (warningBirthdate.isWarning) {
      return '#FF9E1B';
    }

    if (errorBirthdate.isError) {
      return '#f44336';
    }

    return '#5AB52F';
  };

  const nameAdornment = () => {
    if (warningName.isWarning) {
      return <Warning color={'warning'} />;
    }

    if (errorName.isError) {
      return <Error color={'error'} />;
    }

    return <Check color={'success'} />;
  };

  const emailAdornment = () => {
    if (warningEmail.isWarning) {
      return <Warning color={'warning'} />;
    }

    if (errorEmail.isError || !validator.isEmail(email)) {
      return <Error color={'error'} />;
    }

    return <Check color={'success'} />;
  };

  const colorTelefonoAdornment = () => {
    if (warningTelefono.isWarning) {
      return 'warning';
    }

    if (errorTelefono.isError) {
      return 'error';
    }

    return 'success';
  };

  const colorHelperTextTelefono = () => {
    if (warningTelefono.isWarning) {
      return '#FF9E1B';
    }

    if (errorTelefono.isError) {
      return '#f44336';
    }

    return '#5AB52F';
  };

  const telefonoAdornment = () => {
    if (warningTelefono.isWarning) {
      return <Warning color={'warning'} />;
    }

    if (errorTelefono.isError) {
      return <Error color={'error'} />;
    }

    return <Check color={'success'} />;
  };

  return (
    <>
      {changeModal === false ? (
        <Modal
          open
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in>
            <Paper className={classes.root}>
              <Box padding={2}>
                <Box
                  marginBottom={2}
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Typography fontSize={'16px'} fontWeight={'bold'}>
                    {'Conductor asegurado'}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      if (phone.length < 10) {
                        setWarningTelefono({
                          msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 10. ',
                          isWarning: true,
                        });
                        return;
                      }
                      onClose();
                    }}
                  >
                    <Clear />
                  </IconButton>
                </Box>
                <Box>
                  <TextField
                    id="name"
                    sx={{
                      marginTop: 1,
                    }}
                    label="Nombre"
                    fullWidth
                    variant="outlined"
                    placeholder="Nombre"
                    value={name}
                    onBlur={() => {
                      setWarningName({ msg: '', isWarning: false });
                      setErrorName({ msg: '', isError: false });
                    }}
                    onChange={(e) => {
                      const nameText = e.target.value;
                      const regex = /^[a-zA-Z ]*$/;
                      if (regex.test(nameText)) {
                        if (nameText.length <= 30) {
                          if (nameText.length < 2) {
                            setWarningName({
                              msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2. ',
                              isWarning: true,
                            });
                          } else {
                            setName(nameText);
                            setWarningName({
                              msg: '',
                              isWarning: false,
                            });
                          }
                          setName(nameText);
                        } else {
                          setWarningName({
                            msg: '¡Oops! Parece que estás superando el máximo de caracteres. Sólo puedes escribir 30 caracteres.',
                            isWarning: true,
                          });
                        }
                        setErrorName({
                          msg: '',
                          isError: false,
                        });
                      } else {
                        setWarningName({
                          msg: '¡Oops! Aquí sólo puedes escribir letras.',
                          isWarning: true,
                        });
                        setErrorName({
                          msg: '',
                          isError: false,
                        });
                      }
                    }}
                    error={errorName.isError}
                    helperText={
                      <label style={{ color: colorHelperText() }}>
                        {errorName.msg || warningName.msg}
                      </label>
                    }
                    color={colorNameAdornment()}
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment: nameAdornment(),
                    }}
                  />
                </Box>
                <Box marginTop={2}>
                  <Typography fontSize={'14px'} fontWeight={'normal'}>
                    {'Sexo de nacimiento'}
                  </Typography>
                </Box>
                <Box marginTop={2}>
                  <ToggleButtonGroup
                    fullWidth
                    sx={{
                      height: 45,
                      backgroundColor: '#F4F4F4',
                    }}
                    exclusive
                    size={'medium'}
                    value={gender}
                    onChange={handleChangeGender}
                    style={{ borderRadius: 8, marginBottom: 40, padding: 3 }}
                  >
                    <ToggleButton
                      sx={{
                        textTransform: 'none',
                        backgroundColor: '#fafafa',
                      }}
                      value="Mujer"
                      style={{
                        fontWeight: 600,
                        borderRadius: 8,
                        border: 'none',
                        textTransform: 'none',
                        color: backF,
                        backgroundColor: colorF,
                      }}
                    >
                      Mujer
                    </ToggleButton>
                    <ToggleButton
                      value="Hombre"
                      style={{
                        fontWeight: 600,
                        borderRadius: 8,
                        border: 'none',
                        textTransform: 'none',
                        color: backM,
                        backgroundColor: colorM,
                      }}
                    >
                      Hombre
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={es}
                  >
                    <DatePicker
                      maxDate={Limit.MaxAgeAcept}
                      minDate={Limit.MinAgeAcept}
                      openTo="year"
                      views={['year', 'month', 'day']}
                      label="Fecha de nacimiento"
                      inputFormat="dd/MM/yyyy"
                      value={new Date(`${birthDate}T00:00:00`)}
                      onChange={handleChangeDriverBirthday}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => {
                            const regex = /^[0-9]*$/;
                            if (e.target.value.length === 11) {
                              return;
                            }
                            if (regex.test(e.target.value)) {
                              setWarningBirthdate({
                                msg: '',
                                isWarning: false,
                              });
                              setErrorBirthdate({
                                msg: '',
                                isError: false,
                              });
                            } else {
                              setErrorBirthdate({
                                msg: '¡Oops! Aquí sólo puedes escribir números.',
                                isError: true,
                              });
                              setWarningBirthdate({
                                msg: '',
                                isWarning: false,
                              });
                            }
                          }}
                          fullWidth
                          error={errorBirthdate.isError}
                          helperText={
                            <label style={{ color: colorHelperBirthdate() }}>
                              {errorBirthdate.msg || warningBirthdate.msg}
                            </label>
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box marginTop={2}>
                  <ReactTooltip
                    id="cp"
                    type="light"
                    place="right"
                    effect="solid"
                    backgroundColor="#EBF8FB"
                  >
                    <Typography width={350}>
                      Este es el código postal donde duerme tu coche. Con él
                      podemos calcular tu tarifa.
                    </Typography>
                  </ReactTooltip>
                  <TextField
                    data-for="cp"
                    data-tip={''}
                    data-iscapture={'true'}
                    value={zip}
                    onChange={(e) => {
                      setZip(e.target.value);
                      setDriver({
                        ...driver,
                        codigoPostal: e.target.value,
                      });
                      if (e.target.value.length === 4) {
                        setWarningZip({
                          msg: '¿Tu Código Postal tiene menos de 5 números? Agrega un 0 a la izquierda. Ejemplo: 01000',
                          isWarning: true,
                        });
                        setErrorZip({ msg: '', isError: false });
                        return;
                      }
                      setWarningZip({ msg: '', isWarning: false });
                      if (calculateAge(birthDate) >= 18)
                        setErrorZip({ msg: '', isError: false });
                    }}
                    id="outlined-basic"
                    fullWidth
                    label="Código postal*"
                    variant="outlined"
                    maxRows={4}
                    style={{ marginBottom: '20px' }}
                    color={colorCPAdornment()}
                    error={errorZip.isError}
                    helperText={
                      <label style={{ color: colorHelperTextCP() }}>
                        {errorZip.msg || warningZip.msg}
                      </label>
                    }
                    InputProps={{
                      inputComponent: CPCustomMask as any,
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment: cpRighIcon(),
                    }}
                  />
                </Box>
                <Box>
                  <TextField
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (validateEmail(email)) {
                        setErrorEmail({
                          msg: '',
                          isError: false,
                        });
                        setWarningEmail({ msg: '', isWarning: false });
                        return;
                      }
                    }}
                    id="outlined-basic"
                    fullWidth
                    label="E-mail"
                    variant="outlined"
                    size={'medium'}
                    maxRows={4}
                    style={{ marginBottom: '20px' }}
                    color={
                      validator.isEmail(email) && email !== ''
                        ? 'success'
                        : 'primary'
                    }
                    error={
                      !validator.isEmail(email) && email !== ''
                        ? true
                        : errorEmail.isError
                    }
                    helperText={
                      !validator.isEmail(email) && email !== '' ? (
                        '¡Oops! Debes ingresar un email válido.'
                      ) : (
                        <label style={{ color: colorHelperTextEmail() }}>
                          {errorEmail.msg || warningEmail.msg}
                        </label>
                      )
                    }
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment: emailAdornment(),
                    }}
                  />
                </Box>
                <Box>
                  <TextField
                    sx={{ marginTop: 1 }}
                    name="telefono"
                    label="Telefono"
                    id="telefono"
                    fullWidth
                    variant="outlined"
                    placeholder="Telefono"
                    value={phone}
                    onChange={(event) => {
                      let regex = /^[0-9]*$/;
                      const telefono = event.target.value;
                      if (event.target.id === 'telefono') {
                        if (regex.test(telefono)) {
                          if (telefono.length <= 10) {
                            setPhone(event.target.value);
                            setDriver({
                              ...driver,
                              telefono: telefono,
                            });
                            setErrorTelefono({
                              msg: '',
                              isError: false,
                            });
                          } else {
                            setWarningTelefono({
                              msg: '¡Oops! Parece que estás superando el máximo de caracteres. Sólo puedes escribir 10 caracteres.',
                              isWarning: true,
                            });
                            setErrorTelefono({
                              msg: '',
                              isError: false,
                            });
                          }
                        } else {
                          setErrorTelefono({
                            msg: '¡Oops! Aquí sólo puedes escribir números.',
                            isError: true,
                          });
                          setWarningTelefono({
                            msg: '',
                            isWarning: false,
                          });
                        }
                        return;
                      }
                    }}
                    error={errorTelefono.isError}
                    onBlur={() => {
                      setWarningTelefono({ msg: '', isWarning: false });
                      const telefonoArray = driver.telefono.split('');

                      const isResultAllEquals = allEqual(telefonoArray);
                      const isConsecutive = isThereFourConsecutiveNumber(
                        driver.telefono
                      );
                      if (
                        isResultAllEquals ||
                        driver.telefono === '0123456789' ||
                        isConsecutive
                      ) {
                        if (driver.telefono === '') {
                          setErrorTelefono({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          return;
                        }
                      }

                      setErrorTelefono({
                        msg: '',
                        isError: false,
                      });
                    }}
                    helperText={
                      <label style={{ color: colorHelperTextTelefono() }}>
                        {errorTelefono.msg || warningTelefono.msg}
                      </label>
                    }
                    color={colorTelefonoAdornment()}
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment: telefonoAdornment(),
                    }}
                  />
                </Box>
                <CustomButton
                  text={'Continuar'}
                  onClick={() => {
                    if (valuesChanged) {
                      setChangeModal(true);
                    } else {
                      handleEditDriver();
                    }
                  }}
                  color={'primary'}
                  variant={'contained'}
                  style={{ height: 56, width: '100%', textTransform: 'none' }}
                />
              </Box>
            </Paper>
          </Fade>
        </Modal>
      ) : (
        <Modal
          open
          onClose={onClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in>
            <Paper className={classes.root}>
              <Box padding={2}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'space-between'}
                  alignItems={'flex-end'}
                >
                  <IconButton onClick={onClose}>
                    <Clear />
                  </IconButton>
                </Box>
                <Box width={'80%'} style={{ marginTop: '-20px' }}>
                  <Typography
                    variant={'h6'}
                    fontSize={'20px'}
                    fontWeight={'bold'}
                  >
                    {'Cambiar tus datos eliminará tus resultados actuales'}
                  </Typography>
                </Box>
                <Box marginTop={2}>
                  <CustomButton
                    text={'Cancelar'}
                    onClick={onClose}
                    color={'primary'}
                    variant={'contained'}
                    style={{ height: 56, width: '100%', textTransform: 'none' }}
                  />
                </Box>
                <Box marginTop={2}>
                  <CustomButton
                    text={'Deseo cambiar mis datos'}
                    onClick={handleEditDriver}
                    color={'primary'}
                    variant={'outlined'}
                    style={{ height: 56, width: '100%', textTransform: 'none' }}
                  />
                </Box>
              </Box>
            </Paper>
          </Fade>
        </Modal>
      )}
    </>
  );
};
