import React, {useContext, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import Loading from "@components/Loading/Loading";
import {getCRM} from "@api/getCRM";
import {getDataTracking} from "@api/getTracking";
import {useTokenStore} from "@store/TokenStore";
import {useCarStore} from "@store/carInfoStore";
import {useDriverStore} from "@store/driverInfoStore";
import {useCPStore} from "@store/cpStore";
import {usePolicyStore} from "@store/policyStore";
import {useSelectedQuoteStore} from "@store/QuoteStore";
import {useTrackingStore} from "@store/TrackingStore";
import {ZustandContext} from "@store/modalStore";
import axios from "axios";

function useQuery() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const ConfigRecoverQuote: React.FC = () => {
    const {token} = useTokenStore();
    const history = useHistory();
    const {setCar} = useCarStore();
    const {driver, setDriver} = useDriverStore();
    const {searchCP} = useCPStore();
    const {policy, addPolicyInfo} = usePolicyStore();
    const {setSelectedQuote} = useSelectedQuoteStore();
    const {setDataTracking} = useTrackingStore();

    const [loading, setLoading] = React.useState(true);

    let searchParams = useQuery();

    const getIp = async () => {
        let ip: string = '';
        if (policy.ipRecuperado === '') {
            const res = await axios.get('https://api.ipify.org/?format=json');
            ip = res.data.ip;
        }
        return ip;
    };


    const getRecoverQuote = async ( token: string= "",numCotizacion: string = "", emailCotizacion: string= "",code:string="") => {
        let cotiza = await getCRM(numCotizacion, emailCotizacion, token);
        if (cotiza?.tracking_id) {
            let dataTracking = await getDataTracking(token, cotiza?.tracking_id);
            if (dataTracking?.data?.data?.driver) {
                await setDataTracking(dataTracking.data);
                await setDriver(dataTracking.data.data.driver);
                await searchCP(dataTracking.data.data.driver.codigoPostal, token);
                await setCar(dataTracking.data.data.car);
                const ip_: string = await getIp();
                dataTracking.data.data.policy.ipRecuperado = ip_;
                await addPolicyInfo({...dataTracking.data.data.policy});
                await setSelectedQuote(dataTracking.data.data.selectedQuote);
                if (
                    cotiza?.sub_stage === '1. Datos Generales' &&
                    cotiza?.stage === '1. Registro (Coche)'
                ) {
                    history.push('/plans');
                }
                if (
                    cotiza?.sub_stage === '2. Datos Cotización' &&
                    cotiza?.stage === '1. Registro (Coche)'
                ) {
                    history.push('/insurer-details');
                }
                if (
                    cotiza?.sub_stage === '3. Elección' &&
                    cotiza?.stage === '2. Cotización (Coche)'
                ) {
                    history.push('/hiring');
                }
                if (
                    cotiza?.sub_stage === '4. Datos Complementarios Asegurado' &&
                    cotiza?.stage === '3. Contratación (Coche)'
                ) {
                    history.push('/hiring');
                }
                if (
                    cotiza?.sub_stage === '5. Datos Complementarios Contratante' &&
                    cotiza?.stage === '3. Contratación (Coche)'
                ) {
                    history.push('/confirm');
                }
                if (
                    cotiza?.sub_stage === '6. Datos Bancarios' &&
                    cotiza?.stage === '3. Contratación (Coche)'
                ) {
                    history.push('/payment');
                }
                if (
                    cotiza?.sub_stage === '7. Pago' &&
                    cotiza?.stage === '4. Emisión (Coche)'
                ) {
                    history.push('/welcome');
                }
            }
        }
    };

    let folio = searchParams.get("folio");
    let email = searchParams.get("email");
    let code = searchParams.get("code");

    useEffect(()=>{
        if (token && folio && email && code) {
            getRecoverQuote(token,folio,email,code)
        } 
        else if(token && folio && email) {
            getRecoverQuote(token, folio, email);
        }
    },[token])


    return (<div style={{
        height: '100vh',
        paddingTop: '15%',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center'
    }}>
        <span style={{fontWeight: 600, fontSize: 25}}>Recuperando...</span>
        <Loading open={loading} handleClose={() => {
            setLoading(true)
        }}/>
    </div>)

}
