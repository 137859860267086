import {InterInstanceWithoutV1} from "./config"

type QuotationFileProps = {
    sendMail: boolean;
    firstName: string;
    numberQuote: string;
    dateQuote: string;
    dateQuoteValid: string;
    fullname: string;
    zipcode: string;
    birthdate: string;
    email: string;
    vehicleDescription: string;
    paymentPlan: string;
    coverage: string;
    coverages: object;
    quotes: object;
    token: string;
}

export const getQuotationFile = async ({
    sendMail,
    firstName,
    numberQuote,
    dateQuote,
    dateQuoteValid,
    fullname,
    zipcode,
    birthdate,
    email,
    vehicleDescription,
    paymentPlan,
    coverages,
    coverage,
    quotes,
    token,
}: QuotationFileProps) => {
    const { data } = await InterInstanceWithoutV1.post("/compartamos/mail/creditaria-quote-insurances", {
    send_mail: sendMail,
    first_name: firstName,
    numberQuote: numberQuote,
    dateQuote: dateQuote,
    dateQuoteValid: dateQuoteValid,
    fullname,
    zipcode,
    birthdate,
    email,
    vehicleDescription,
    paymentPlan,
    coverage,
    coverages: coverages,
    quotes: quotes,
    business: "creditaria"
    }, {headers: { Authorization: `Bearer ${token}` }})

    return data
}