import React from 'react';
import { makeStyles } from '@mui/styles';
import { Clear } from '@mui/icons-material';
import {
  Fade,
  Modal,
  Paper,
  Box,
  Backdrop,
  Theme,
  Typography,
  IconButton,
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { CustomButton } from '@buttons/CustomButton';
import warningIcon from '@assets/images/warning.jpeg';

type ModalShareProps = {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    top: '50%',
    left: '50%',
    width: 499,
    borderRadius: 8,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
}));

export const ModalCotizacion: React.FC<ModalShareProps> = ({ onClose }) => {
  const classes = useStyles();
  
  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper className={classes.root}>
          <Box padding={3} paddingTop={3}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              alignItems={'flex-end'}
            ></Box>
            <IconButton
              onClick={onClose}
              sx={{
                marginLeft: '90%',
              }}
            >
              <Clear />
            </IconButton>
            <Box width={'95%'} style={{ marginTop: '20px' }}>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <WarningAmberIcon color='error' fontSize='large' sx={{ fontSize: '80px' }} />
              </Box>
              <Typography variant={'h6'} fontSize={'16px'} marginTop={3} textAlign={'center'}>
                {
                  'El número de cotización y/o correo no coinciden. ¿Quieres intentarlo de nuevo?'
                }
              </Typography>
            </Box>
            <Box marginTop={2}>
              <CustomButton
                text={'Volver a intentar'}
                onClick={() => {
                  window.localStorage.clear();
                  onClose();
                }}
                color={'primary'}
                variant={'contained'}
                style={{ height: 56, width: '100%', textTransform: 'uppercase', color: '#FFF'}}
              />
            </Box>
            <Box marginTop={2}>
              <CustomButton
                text={'Mejor más tarde'}
                onClick={() => {
                  window.localStorage.clear();
                  window.location.reload();
                  onClose();
                }}
                color={'primary'}
                variant={'outlined'}
                style={{ height: 56, width: '100%', textTransform: 'uppercase' }}
              />
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
