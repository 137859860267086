import { useState } from 'react';
import {Box, Button, useMediaQuery, useTheme} from '@mui/material';

import { Features } from './Features';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { useClamp } from '@utils/useClamp';
import {selectedInsurer} from "@api/features";
import {OrderSelect} from "@screens/insurerDetails/OrderSelect";

export const FeacturesList = () => {
  const { selectedQuote } = useSelectedQuoteStore();
  const { clamp } = useClamp();
  const [select, setSelect] = useState('');

  const { breakpoints, palette } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));

  return (
    <Box
      style={{
        marginTop: '5px',
        backgroundColor: 'white',
          padding: '20px',
          width: isDesktop ? '752px' : '100%',
          borderRadius: '8px',
      }}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          height: '48px',
          alignItems: 'center',
        }}
      >
          <OrderSelect icon={selectedInsurer.logo} name={selectedInsurer.name} />
      </Box>
      <Box>
        {selectedQuote?.coverages_array?.map((feature: any, index: any) =>
          feature.sum_assured === '' ? null : (
            <Features
              key={`feature-${index}`}
              logo={feature.logo}
              title={feature.name}
              descripcion={feature.descripcion}
              titleTicket={feature.titleTicket}
              numberTicket={feature.deductive}
              feature={feature}
            />
          )
        )}
      </Box>
    </Box>
  );
};
