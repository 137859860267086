import {Box} from '@mui/material';
import { CoverageForm } from '@screens/plans/form/CoverageForm';
import { HeaderPlans } from '@screens/plans/HeaderPlans';
import { Toats } from '@components/alerts/Toats';
import { useChangePrice } from '@hooks/useChangePrice';
import { ZustandContext } from '@store/modalStore';
import React, { useState, useEffect } from 'react';
import { Wrapper } from '@screens/plans/Wrapper';
import { useDriverStore } from '@store/driverInfoStore';
import { useHistory } from 'react-router-dom';
import { useCPStore } from '@store/cpStore';
import { useTokenStore } from '@store/TokenStore';
import { ModalCardPromotion } from '@modals/modalpromotions/ModalCardPromotion';
import { getPromotion } from '@api/getPromotions';
import StickyButton from "@buttons/StickyButton";
import {upCRMContact} from "@utils/CRM";
import {useCarStore} from "@store/carInfoStore";
import {useSelectedQuoteStore} from "@store/QuoteStore";
import {usePolicyStore} from "@store/policyStore";
import {useTrackingStore} from "@store/TrackingStore";
import {NameSubstring} from "@utils/utilityFunctions";
import {useVisibleButtonHelp} from "@hooks/useVisibleButtonHelp";
import axios from "axios";

type ModalHomeProps = {
  onClose: () => void;
};

export const Plans: React.FC<ModalHomeProps> = ({ onClose }) => {
  const [url, setUrl] = useState<any>('');
  const [coverage, setCoverage] = useState<string>('Plan amplio');
  const [showToast, setShowToast] = useState(true);
  const [success, setSuccess] = useState(false);
  const [loadingSticky, setLoadingSticky] = useState(false);
  const { searchCP, cpData } = useCPStore();
  const { car } = useCarStore()
  const history = useHistory();
  const { token } = useTokenStore();
  const { driver } = useDriverStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const { policy, addPolicyInfo } = usePolicyStore();
  const { tracking } = useTrackingStore();
  const [zip, setZip] = useState<string>(driver.codigoPostal || '');
  const { show, hide } = React.useContext(ZustandContext);
  const [visible,setVisible]=useState(false);
  const [isData, setIsData] = useState(false);

  const saveDateVisibleModal = () => {
    if (policy.endDateTime === '') {
      let startDate = new Date();
      let day = 60 * 60 * 24 * 1000;
      let endDate = new Date(startDate.getTime() + day);
      addPolicyInfo({ ...policy, endDateTime: new Date(endDate).toString() });
    }
  };
 

  useEffect(() => {
    window.scroll(0, 0);
    getDay_();
    getIp();
    (async () => {
      if (selectedQuote?.vehicle?.type === 'MOTO') {
        await addPolicyInfo({ ...policy, customDM: '10', customRT: '20' });
      } else {
        await addPolicyInfo({ ...policy, customDM: '5', customRT: '10' });
      }

    })();
    searchCP(zip, token);
  }, []);


  useEffect(() => {
    (async () => {
      const resp = await getPromotion(token);
      if (resp) {
        setIsData(true);
      }
    })();
  }, []);


  useEffect(() => {
    if (visible) {
      if (isData) {
        saveDateVisibleModal();
        if (policy.acceptCoupon === '') {
          show(ModalCardPromotion, { onClose: hide });
        }
      }
    } else {
      hide();
    }
  }, [visible, isData]);

  const viewCoupon = () => {
    setVisible(true);
  };

  const closeCoupon = () => {
    setVisible(false);
  };

 useEffect(() => {
    if (!visible) {
      if (window.location.pathname === '/plans') {
        // Create and append the element again
        const newWidgetElement = document.createElement('div');
        newWidgetElement.id = 'twilio-webchat-widget-root';
        document.body.appendChild(newWidgetElement);
        const appConfig = {
          serverUrl: 'https://api.inter.mx/twilio-webchat'
        };

        setTimeout(() => {
          const targetElement = document.querySelector(
            '.css-wonh0e'
          ) as HTMLElement;

          if (targetElement) {
            targetElement.style.zIndex = '1000';
          }
        }, 100);

        // Assuming Twilio is available globally, you may need to import it or access it differently
        // @ts-ignore
        Twilio.initWebchat(appConfig);
      }
    }
    return () => {
      document.getElementById('twilio-webchat-widget-root')?.remove();
    };
  }, []);
  const getIp = async () => {
    if (policy.ipInicio === '') {
      const res = await axios.get('https://api.ipify.org/?format=json');
      policy.ipInicio = res.data.ip;
      await addPolicyInfo({ ...policy});
    }
  };

  function isActivated(hoursActive: any) {
    const dates = hoursActive.map((dateString: string) => {
      const [hour, minute] = dateString.split(':');
      let date = new Date();
      date.setHours(Number(hour), Number(minute), 0, 0);
      return date;
    });

    let isActive = false;
    const now = new Date();

    for (let i = 0; i < dates.length; i = i + 2) {
      isActive =
          isActive ||
          (now.valueOf() >= dates[i].valueOf() &&
              now.valueOf() <= dates[i + 1].valueOf());
    }
    return isActive;
  }

  const weekendAction = () => {
    setInterval(function () {
      checkButton();
    }, 2000);
    function checkButton() {
      // let hoursActive = ['15:00', '07:50'];
      let hoursActive = ['9:00', '15:01'];
      if (!isActivated(hoursActive)) {
        //viewCoupon();
      } else {
        closeCoupon();
      }
    }
  };

  const weekdayAction = () => {
    setInterval(function () {
      checkButton();
    }, 2000);

    function checkButton() {
      // let hoursActive = ['07:50', '20:59'];
      let hoursActive = ['09:00', '21:01'];
      if (!isActivated(hoursActive)) {
        //viewCoupon();
      } else {
        closeCoupon();
      }
    }
  };

  const getDay_ = () => {
    let date = new Date();
    let day = date.getDay();
    let ans = day === 6 || day === 0;
    if (ans) {
      weekendAction();
    } else {
      weekdayAction();
    }
  };

  useEffect(() => {
    getDay_();
  }, []);

  useChangePrice();

  const sendApi = async () => {
    try {
      setLoadingSticky(true);
      let response = await upCRMContact(
          token,
          car,
          driver,
          selectedQuote,
          policy,
          tracking,
          cpData,
          selectedQuote,
          url
      );
      setTimeout(() => {
        if (response.success) {
          setLoadingSticky(false);
          setSuccess(true);
        } else {
          setLoadingSticky(false);
        }
      }, 1000);
    }catch (e) {
      setLoadingSticky(false);
      setSuccess(false);
    }
  };
  useVisibleButtonHelp();


  const title = (
      <p style={{ margin: 0 }}>
        Hola 👋 <strong>{NameSubstring(driver.name)}</strong> ¿Necesitas ayuda para escoger la
        mejor opción?
      </p>
  );
  return (
    <Wrapper>
      <Toats
        showToast={showToast}
        setShowToast={setShowToast}
        Text="¡Ojo! Estos precios podrían cambiar. ¡Pero no te preocupes! Te avisaremos siempre que las aseguradoras hagan modificaciones."
        isWarning
      />
      <HeaderPlans />
      <Box marginTop={3}>
        <CoverageForm coverage={coverage} setCoverage={setCoverage} />
      </Box>

      {
        <StickyButton
            loading={loadingSticky}
            success={success} action={sendApi} title={title} />
      }

    </Wrapper>
  );
};
