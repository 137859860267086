import {useDriverStore} from '@store/driverInfoStore';
import {usePolicyStore} from '@store/policyStore';
import moment from 'moment';
import {postMarketingCloud} from "@api/postMarketingCloud";
import {useTokenStore} from "@store/TokenStore";
import { useCarStore } from '@store/carInfoStore';

export const useSendContacts = () => {
    const {driver} = useDriverStore();
    const {policy} = usePolicyStore();
    const {token} = useTokenStore();
    const { car: carData } = useCarStore();

    var hoy = new Date();
    var getYear = hoy.toLocaleString("default", { year: "numeric" });
    var getMonth = hoy.toLocaleString("default", { month: "2-digit" });
    var getDay = hoy.toLocaleString("default", { day: "2-digit" });
    var getTime = hoy.toLocaleString("es-ES", { hour: "2-digit", minute: "2-digit" });

    let fecha_lead = getYear + "-" + getMonth + "-" + getDay +" "+ getTime;

    const postSendContacts = async () => {
        try {
            let params = {
                ContactKey: policy.uuid,
                EventDefinitionKey: 'APIEvent-b5773015-62e8-22fc-22e8-f49410b37ec2',
                Data: {
                    SubscriberKey: policy.uuid,
                    uuid: policy.uuid,
                    nombre: driver.name,
                    correo: driver.email,
                    telefono: driver.telefono,
                    fecha_lead: fecha_lead,
                    marca: carData.assembler,
                    modelo: carData.model
                },
            };
            let response = await postMarketingCloud(token, params);
        } catch (e) {
            console.error(e)
        }


    };
    return {postSendContacts};
};
