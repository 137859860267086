import React, { useEffect } from 'react';
import { Box } from '@mui/material';

import { useSelectedQuoteStore } from '@store/QuoteStore';
import { useGetQuotePayment } from '@store/QuoteData';
import { usePolicyStore } from '@store/policyStore';
import { useClamp } from '@utils/useClamp';

interface Props {
  icon: string;
  name: string;
}

export const OrderSelect: React.FC<Props> = ({ name }) => {
  const { selectedQuote } = useSelectedQuoteStore();
  const { Packages, payment_option } = useGetQuotePayment();
  const { policy, addPolicyInfo } = usePolicyStore();
  const { clamp } = useClamp();

  useEffect(() => {
    (async () => {
      policy.package_anterior = Packages.value;
      policy.package_new = Packages.value;
      policy.payment_anterior = payment_option.value;
      policy.payment_new = payment_option.value;
      addPolicyInfo({ ...policy });
    })();
  }, []);

  return (
    <Box
      alignItems="center"
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        component="img"
        sx={{
          width: selectedQuote?.insurance.toLowerCase() === 'axa' ? '50px' : '100px'
        }}
        alt={name}
        style={{ borderRadius: 8, margin: '16px 30px' }}
        src={selectedQuote?.company_logo}
      />
    </Box>
  );
};
