import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';

import { CarData } from '@screens/hiring/CarData';
import { ContratorData } from '@screens/hiring/ContratorData';
import { DataContrator } from './DataContrator';
import { Header } from '@navigationMenu/Header';
import { useCarStore } from '@store/carInfoStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { usePolicyStore } from '@store/policyStore';

import TagManager from 'react-gtm-module';

interface Props {
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
  setShowErrorToast: React.Dispatch<React.SetStateAction<boolean>>;
}

type AdditionalConfig = {
  shouldBill: boolean;
  isDriverSameContractor: boolean;
};

export const AccordionHiring = ({ setShowToast, setShowErrorToast }: Props) => {
  const [active, setActive] = useState<{
    first: boolean;
    second: boolean;
    third: boolean;
  }>({
    first: true,
    second: false,
    third: false,
  });
  const [activeStep, setActiveStep] = useState<number>(1);
  const [checked, setChecked] = useState({
    first: false,
    second: false,
  });
  const [expanded, setExpanded] = useState({
    car: true,
    contrator: false,
    dataContrator: false,
  });

  const [additionalConfiguration, setAdditionalConfiguration] =
    useState<AdditionalConfig>({
      shouldBill: false,
      isDriverSameContractor: false,
    });

  const { first, second } = checked;
  const { car, contrator, dataContrator } = expanded;

  //DataLayer
  const { car: Car } = useCarStore();
  const { driver } = useDriverStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const { policy } = usePolicyStore();
  const subtitle =
    'con los que ' +
    selectedQuote.insurance.toUpperCase() +
    ' producirá tu póliza';
  const DataLayer = {
    event: 'CSform_step_5_view',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_5_view',
    eventLabel: undefined,
    telefono: driver.telefono,
    correo: driver.email,
    noCotizacion: policy.policyNumber,
    modelo: Car.description,
    marca: Car.assembler,
    anio: Car.model,
    paquete: selectedQuote.package,
    forma_pago: selectedQuote.payment_option,
    aseguradora: selectedQuote.insurance,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: Car.type,
    colaborador: undefined,
    cupon: undefined,
  };

  const DataLayerOne = {
    event: 'CSform_step_5_submit',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_5_submit',
    eventLabel: undefined,
    telefono: driver.telefono,
    correo: driver.email,
    noCotizacion: policy.policyNumber,
    modelo: Car.description,
    marca: Car.assembler,
    anio: Car.model,
    paquete: selectedQuote.package,
    forma_pago: selectedQuote.payment_option,
    aseguradora: selectedQuote.insurance,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: Car.type,
    colaborador: undefined,
    ecommerce: {
      checkout: {
        actionField: { step: 2, action: 'CSform_step_5_submit' },
        products: [
          {
            name: selectedQuote.insurance,
            id: undefined, //string
            price: parseInt(selectedQuote.insurance_premium.total), //numero
            brand:
              '[paquete:' +
              selectedQuote.package +
              '_pago:' +
              selectedQuote.payment_option +
              ']',
            category: 'SeguroCoche',
            variant: Car.model,
            quantity: 1,
          },
        ],
      },
    },
  };
  const DataLayerTwo = {
    event: 'CSform_step_6_view',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_6_view',
    eventLabel: undefined,
    telefono: driver.telefono,
    correo: driver.email,
    noCotizacion: policy.policyNumber,
    modelo: Car.description,
    marca: Car.assembler,
    anio: Car.model,
    paquete: selectedQuote.package,
    forma_pago: selectedQuote.payment_option,
    aseguradora: selectedQuote.insurance,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: Car.type,
    colaborador: undefined,
    cupon: undefined,
  };

  const DataLayerTwoSubmit = {
    event: 'CSform_step_6_submit',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_6_submit',
    eventLabel: undefined,
    telefono: driver.telefono,
    correo: driver.email,
    noCotizacion: policy.policyNumber,
    modelo: Car.description,
    marca: Car.assembler,
    anio: Car.model,
    paquete: selectedQuote.package,
    forma_pago: selectedQuote.payment_option,
    aseguradora: selectedQuote.insurance,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: Car.type,
    colaborador: undefined,
    ecommerce: {
      checkout: {
        actionField: { step: 3, action: 'CSform_step_6_submit' },
        products: [
          {
            name: selectedQuote.insurance,
            id: undefined, //string
            price: parseInt(selectedQuote.insurance_premium.total), //numero
            brand:
              '[paquete:' +
              selectedQuote.package +
              '_pago:' +
              selectedQuote.payment_option +
              ']',
            category: 'SeguroCoche',
            variant: Car.model,
            quantity: 1,
          },
        ],
      },
    },
  };

  const DataLayerThree = {
    event: 'CSform_step_7_view',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_7_view',
    eventLabel: undefined,
    telefono: driver.telefono,
    correo: driver.email,
    noCotizacion: policy.policyNumber,
    modelo: Car.description,
    marca: Car.assembler,
    anio: Car.model,
    paquete: selectedQuote.package,
    forma_pago: selectedQuote.payment_option,
    aseguradora: selectedQuote.insurance,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: Car.type,
    colaborador: undefined,
    cupon: undefined,
  };

  const tagManagerArgs = {
    dataLayer: DataLayer,
  };

  const tagManagerArgsOne = {
    dataLayer: DataLayerOne,
  };

  const tagManagerArgsTwo = {
    dataLayer: DataLayerTwo,
  };
  const tagManagerArgsTwoSubmit = {
    dataLayer: DataLayerTwoSubmit,
  };

  const tagManagerArgsThree = {
    dataLayer: DataLayerThree,
  };

  useEffect(() => {
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  useEffect(() => {
    if (active.first) {
      setActiveStep(1);
    } else if (active.second) {
      setActiveStep(2);
      TagManager.dataLayer(tagManagerArgsOne);
      TagManager.dataLayer(tagManagerArgsTwo);
    } else {
      setActiveStep(3);
      TagManager.dataLayer(tagManagerArgsTwoSubmit);
      TagManager.dataLayer(tagManagerArgsThree);
    }
  }, [active]);

  const setFinishedCarForm = () => {
    setChecked((prev) => ({ ...prev, first: true }));
    setExpanded((prev) => ({ ...prev, car: false, contrator: true }));
  };

  const setFinishedContratorForm = () => {
    setChecked((prev) => ({ ...prev, second: true }));
    setExpanded((prev) => ({
      ...prev,
      contrator: false,
      address: false,
      dataContrator: true,
    }));
  };

  return (
    <Grid item>
      <Grid container paddingY={2} spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12}>
          <Box marginBottom={1}>
            <Header
              number="1"
              isChecked={first}
              title="Datos del vehículo"
              subtitle={subtitle}
              active={active}
              activeStep={activeStep}
            />
          </Box>
          <CarData
            expanded={car}
            setExpanded={() => setFinishedCarForm()}
            setActive={setActive}
            setShowToast={setShowToast}
            setShowErrorToast={setShowErrorToast}
          />
          <Box marginBottom={1}>
            <Header
              number="2"
              isChecked={second}
              title="Datos del Conductor Asegurado"
              subtitle="El conductor asegurado es la persona que conduce la mayor parte del tiempo el vehículo"
              active={active}
              activeStep={activeStep}
            />
          </Box>
          <ContratorData
            expanded={contrator}
            setExpanded={() => setFinishedContratorForm()}
            setActive={setActive}
            setShowErrorToast={setShowErrorToast}
            setAdditionalConfiguration={setAdditionalConfiguration}
          />
          <Box marginBottom={1}>
            <Header
              title="Datos del Contratante"
              subtitle="El contratante es la persona que paga la póliza"
              number="3"
              active={active}
              activeStep={activeStep}
            />
          </Box>
          <DataContrator
            additionalConfiguration={additionalConfiguration}
            expanded={dataContrator}
            setActive={setActive}
            setShowErrorToast={setShowErrorToast}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
