import {Button, Typography, Grid, Tooltip, Modal, TextField, Box, IconButton, CircularProgress} from '@mui/material';
import { useQuoteStore } from '@store/QuoteStore';
import React, {useState} from 'react';
import { getQuotationFile } from '@api/getQuotationFile';
import { useDriverStore } from '@store/driverInfoStore';
import { usePolicyStore } from '@store/policyStore';
import { useCarStore } from '@store/carInfoStore';
import { useGetQuotePayment } from '@store/QuoteData';
import { useTokenStore } from '@store/TokenStore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useClamp } from '@utils/useClamp';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { plansData } from '@api/plans';
import TagManager from 'react-gtm-module';
import EnviarEmail from '@assets/images/EnviarEmail.svg';
import Descargar from '@assets/images/DescargarCotizacion.svg'
interface PropsHeader {
  options: string;
  isLoading: boolean;
}

const Loading = () => {
  return(
      <Box sx={{width: '64px', height: '48px', background: '#006782', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '6px', ':hover': {bgcolor: '#006782'}}}>
        <CircularProgress style={{color: 'white', width: '25px', height: '25px'}} />
      </Box>
  )
}

const QuoteHeader: React.FC<PropsHeader> = ({ options, isLoading }) => {
  const { quoteList, isFetching } = useQuoteStore();
  const [openModal, setOpenModal] = React.useState(false)
  const { driver } = useDriverStore();
  const { policy } = usePolicyStore();
  const { car } = useCarStore();
  const { Packages, payment_option } = useGetQuotePayment();
  const { token } = useTokenStore();
  const [ sendEmail, setSendEmail ] = React.useState(driver.email);
  const { clamp } = useClamp();
  const [ mailSent, setMailSent ] = React.useState(false);
  const [openModalSent, setOpenModalSent] = React.useState(false);
  const emailSent = React.useRef(false)
  const [isDownloadingLoading, setIsDownloadingLoading] = React.useState(false)

  React.useEffect(() => {
    if (openModalSent == true){
      mailSent
      ?
        TagManager.dataLayer({
          dataLayer: {
            event : "coche_seguro_cotizacion_enviar_correcto",
            no_cotizacion: policy.policyNumber,
            celular: driver.telefono,
            correo: sendEmail,      
          }
        })
        :
        TagManager.dataLayer({
          dataLayer: {
            event : "coche_seguro_cotizacion_enviar_error",
            no_cotizacion: policy.policyNumber,
            celular: driver.telefono,
            correo: sendEmail,      
          }
        })
      }
    }, [openModalSent])

  const fetchQuotationFile = async (sendMail: boolean, email: string) => {
    const plans: any = {
      "Plan amplio": "Amplia",
      "Plan limitado": "Limitada",
      "Plan básico": "Básica"
    }
    const quotes: any[] = [];
    quoteList.forEach((plan: any) => {
      quotes.push({insurance: plan?.insurance, price: plan?.insurance_premium?.total?.toLocaleString("en")})
    });
    const coverages: any[] = [];
    var i = 0;

    quoteList.forEach((datosQ: any) => {

      if (i == 0){
        datosQ?.coverages_array.forEach((datos2: any) => {

          if(datos2?.code == 'GM' && datos2?.sum_assured !="300000" && car.type.toUpperCase() !== 'MOTO'){
            coverages.push(
            {
                "code": 'GM',
                "deductive": datos2?.deductive,
                "sum_assured": "300000"
            })
          } else {
            if(datos2?.code != 'ERC' && datos2?.code != 'AL'){
              coverages.push(
              {
                  "code": datos2?.code,
                  "deductive": datos2?.deductive,
                  "sum_assured": datos2?.sum_assured
              })
            }
            if(datos2?.code == 'AL'){
              coverages.push(
              {
                  "code": 'AV',
                  "deductive": datos2?.deductive,
                  "sum_assured": datos2?.sum_assured
              })
            }
          }
          
        });
      }
      i = 1;
    });

    if (plans[Packages.label] == "Limitada"){
      coverages.push(
      {
          "code": 'DM',
          "deductive": 0,
          "sum_assured": 'No Amparada'
      })
    }
    if (plans[Packages.label] == "Básica"){
      coverages.push(
      {
          "code": 'DM',
          "deductive": 0,
          "sum_assured": 'No Amparada'
      })
      coverages.push(
      {
          "code": 'RT',
          "deductive": 0,
          "sum_assured": 'No Amparada'
      })
    }

    var res = new Date();
    res.setDate(res.getDate() + 7);
    var getYearR = res.toLocaleString("default", { year: "numeric" });
    var getMonthR = res.toLocaleString("default", { month: "2-digit" });
    var getDayR = res.toLocaleString("default", { day: "2-digit" });

    var hoy = new Date();
    var getYear = hoy.toLocaleString("default", { year: "numeric" });
    var getMonth = hoy.toLocaleString("default", { month: "2-digit" });
    var getDay = hoy.toLocaleString("default", { day: "2-digit" });


    
    return await getQuotationFile({
      sendMail,
      firstName: driver.name,
      numberQuote: policy.policyNumber,
      dateQuote: getYear + "-" + getMonth + "-" + getDay,
      dateQuoteValid: getYearR + "-" + getMonthR + "-" + getDayR,
      fullname: driver.name,
      zipcode: driver.codigoPostal,
      birthdate: driver.fechaNacimiento,
      email: email,
      vehicleDescription: car.assembler + " " + car.description,
      paymentPlan: payment_option.label,
      coverage: plans[Packages.label],
      coverages,
      quotes,
      token,
    })
  }

  const downloadOrSendQuotation = async (reason: string) => {
    if(reason === "send") {
      const data = await fetchQuotationFile(true, sendEmail)
      TagManager.dataLayer({
        dataLayer: {
          event : "coche_seguro_cotizacion_enviar_correo",
          no_cotizacion: policy.policyNumber,
          celular: driver.telefono,
          correo: sendEmail,
          correo_diferente: driver.email != sendEmail ? 'Si' : 'No',      
        }
      })
      setMailSent(data.ok)
      setOpenModalSent(true)
      setOpenModal(false)
      setTimeout(() => {
        setOpenModalSent(false)
      }, 2000)
    } else {
      const data = await fetchQuotationFile(false, driver.email)
      const link = document.createElement("a");
      
      link.href = data.data;
      link.setAttribute(
        'target',
        '_blank',
      );
      link.setAttribute(
        'download',
        policy.policyNumber,
      );
      TagManager.dataLayer({
        dataLayer: {
          event : "coche_seguro_cotizacion_descargar",
          no_cotizacion: policy.policyNumber,
          celular: driver.telefono,
          correo: driver.email,    
        }
      })
      setTimeout(() => {
        link.click()
        setIsDownloadingLoading(false)
      }, 3000)
    }
  }

  return (
    <Grid container
      marginTop={3}
      padding={3}
      style={{
        marginInline: 'auto',
        borderTop: '1px solid #8F8F8F',
      }}
    >
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <Typography
          textAlign={'start'}
          fontWeight={'bold'}
          fontSize={16}
          letterSpacing={'0.25px'}
        >
          {options}
          <span
            style={{ fontWeight: 'normal', marginLeft: '10px' }}
          >{`(${quoteList.length})`}</span>
        </Typography>
      </Grid>
      <Grid item container xs={6} sm={6} md={6} lg={6} xl={6} justifyContent="flex-end" columnGap="14px">
        <Grid item>
          <Tooltip title="Envío de cotización">
            <Button
              onClick={() => {
                setOpenModal(true)
                TagManager.dataLayer({
                  dataLayer: {
                    event : "coche_seguro_cotizacion_enviar",
                    no_cotizacion: policy.policyNumber,
                    celular: driver.telefono,
                    correo: driver.email,      
                  }
                })
              }}
              sx={{width: '52px', height: '48px', background: '#006782', ':hover': {bgcolor: '#006782'}}}
              variant='contained' disabled={isFetching}>
              <Box
                  component={'img'}
                  src={EnviarEmail}
                  alt={'asistencia'}
                  style={{ objectFit: 'contain' }}
              />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          {
            isDownloadingLoading ? (
                <Loading />
            ) : (
                <Tooltip title="Descarga de cotización">
                  <Button onClick={() => {
                    setIsDownloadingLoading(true)
                    downloadOrSendQuotation("download")
                  }}
                          sx={{width: '52px', height: '48px', background: '#006782', ':hover': {bgcolor: '#006782'}}}
                          variant='contained' disabled={isFetching}>
                    <Box
                        component={'img'}
                        src={Descargar}
                        alt={'asistencia'}
                        style={{ objectFit: 'contain' }}
                    />
                  </Button>
                </Tooltip>
            )
          }
        </Grid>
      </Grid>
      <Modal open={openModal} onClose={() => {
        setOpenModal(false)
        }}> 
          <Grid container sx={{  
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: clamp(461, 327),
            bgcolor: 'background.paper',
            boxShadow: 24,
            padding: '16px 18px'
            }} rowGap={'24px'}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={() => 
                { 
                  setOpenModal(false)
                  TagManager.dataLayer({
                    dataLayer: {
                      event : "coche_seguro_cotizacion_enviar_cerrar",
                      no_cotizacion: policy.policyNumber,
                      celular: driver.telefono,
                      correo: driver.email,    
                    }
                  })
                }} >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography textAlign={'center'} sx={{ fontSize: '20px', fontWeight: '700' }}>Confirma tu correo para que podamos enviarte tu cotización</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>Correo</Typography>
              <TextField fullWidth id="email" defaultValue={driver.email} value={sendEmail} onChange={(e) => setSendEmail(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button onClick={() => downloadOrSendQuotation("send")} fullWidth variant='contained' endIcon={<ChevronRightIcon />} sx={{ textTransform: 'none', height: '48px' }}>Enviar</Button>
            </Grid>
          </Grid>
      </Modal>
      <Modal open={openModalSent} onClose={() => setOpenModalSent(false)}>
        <Grid sx={{ 
            width: '461px', 
            height: '294px', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            padding: '16px 18px'
            }}>
          <Grid item sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
          { mailSent ?
            <>
              <CheckIcon color='success' fontSize='large' />
              <Typography sx={{ width: '296px', textAlign: 'center', fontSize: '20px', fontWeight: '600' }}>Se envió la cotización a tu correo electrónico</Typography>
            </>
             :
            <>
              <CloseIcon color='error' fontSize='large' />
              <Typography sx={{ width: '296px', textAlign: 'center', fontSize: '20px', fontWeight: '600' }}>¡Los sentimos! No se pudo completar el envío, inténtalo más tarde</Typography>
            </> 
          }
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default QuoteHeader;
