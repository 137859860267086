import React, { useState } from 'react';

import {Box, useTheme, useMediaQuery, Grid} from '@mui/material';
import { Coupon } from './Coupon';
import { BrandConfirm } from './brand/BrandConfirm';
import { ButtonConfirm } from './ButtonConfirm';
import { DetailsConfirm } from './info/DetailsConfirm';
import { Collaborator } from './collaborator/Collaborator';
import { ModalPagoMen } from '@modals/ModalPagoMen';
import {usePolicyStore} from "@store/policyStore";

export const RightCard: React.FC = () => {
  const { breakpoints } = useTheme();
  const {policy} = usePolicyStore();
  //const { selectedPlan, paymentMode } = usePlansDataStore();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Box
      padding={`${isMobile ? '0px' : '30px'}`}
      height={!isTablet ? 'inherit' : 950}
      width={isMobile ? '100%' : isTablet ? '90%' : '100%'}
      borderRadius={!isTablet ? '10px' : 2}
      style={{
        backgroundColor: 'white',
      }}
    >
      <DetailsConfirm />
      <Box
        borderRadius={2}
        marginTop={2}
        style={{
          backgroundColor: 'white',
        }}
      >
        <Box
          padding={isTablet ? 2 : 0}
          style={{
            borderRadius: '10px',
          }}
        >
          <BrandConfirm />
          <ButtonConfirm open={open} setOpen={setOpen} />
          <ModalPagoMen open={open} setOpen={setOpen} />
        </Box>
      </Box>
    </Box>
  );
};
