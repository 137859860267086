import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { gtmId } from './gtmId';

import './assets/styles/index.css';
import { App } from './App';


// Google Tag Manager numero de prueba
TagManager.initialize(gtmId);
ReactDOM.render(<App />, document.getElementById('root'));
