import React from 'react';
import { Box, Typography } from '@mui/material';

interface PaymentMethodProps {
  text: string;
  images: string[];
  payWay: string;
  setPayWay: Function;
  id: string;
}

export const PaymentMethod: React.FC<PaymentMethodProps> = ({
  text,
  images,
  payWay,
  setPayWay,
  id,
}) => {
  return (
    <Box
      padding="15px 15px"
      border={payWay === id ? '2px solid #039ECC' : '1px solid grey'}
      borderRadius="8px"
      display="block"
      justifyContent="space-between"
      margin="10px 0px 0px 0px"
      onClick={() => setPayWay(id)}
      style={{ cursor: 'pointer' }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 'bold' }}
          textAlign="start"
        >
          {text}
        </Typography>
        <Box display="flex">
          {images.map((image) => (
            <Box
              key={image + '-' + id}
              component={'img'}
              src={image}
              sx={{
                width: 30,
              }}
              alt="image"
              style={{ objectFit: 'contain' }}
            />
          ))}
        </Box>
      </Box>
      {text === 'Tarjeta de crédito' && (
        <Typography>
          Paga a meses sin intereses, con tarjetas participantes.
        </Typography>
      )}
    </Box>
  );
};
