import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { ArrowForward } from '@mui/icons-material';
import { useLocation, useHistory } from 'react-router-dom';
import { ZustandContext } from '@store/modalStore';
import {
  Box,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  Grid,
  FormControl,
  Autocomplete,
} from '@mui/material';
import { FoundSearchText } from '@modals/ModalHome';
import { CarListData } from '@models/store/carListStore';
import { CustomButton } from '@buttons/CustomButton';
import { useSearchCarStore } from '@store/searchCarStore';
import { AnioCustomMask } from '@inputCustomMasks/AnioCustomMask';
import { useCarModalHomeStore, initialCarData } from '@store/ModalHomeStore';
import { useCarListStore } from '@store/carListStore';
import { useCarStore, initialCarListData } from '@store/carInfoStore';
import { useTokenStore } from '@store/TokenStore';
import { years } from '@modals/ModalHome/Data';
import {VehiculoType, useVehiculoStore} from '@store/vehiculoStore';
import CocheAzulIcon from '@assets/images/CocheAzul.svg';
import CocheGrisIcon from '@assets/images/CocheGris.svg';
import MotoAzulIcon from '@assets/images/MotoAzul.svg';
import MotoGrisIcon from '@assets/images/MotoGris.svg';
import PickupAzulIcon from '@assets/images/TrocaAzul.svg';
import PickupGrisIcon from '@assets/images/TrocaGris.svg';
import { getToken } from '@api/getToken';

type HeroActionProps = {
  padding?: number;
  elevation?: number;
  isAbsolute?: boolean;
  onClose?: () => void;
};

const useStyles = makeStyles({
  root: {
    width: ({ isAbsolute, isMobile, isTablet, isDesktop }: any) => {
      return isAbsolute
        ? isMobile
          ? ''
          : isTablet
          ? ''
          : isDesktop
          ? 500
          : 320
        : '';
    },
    position: ({ isAbsolute }: any) => {
      return isAbsolute ? 'absolute' : 'relative';
    },
    left: ({ isMobile, isTablet, isDesktop, isAbsolute }: any) => {
      return isAbsolute
        ? isMobile
          ? '3vw'
          : isTablet
          ? '8vw'
          : isDesktop
          ? ''
          : ''
        : '';
    },
    top: ({ isMobile, isTablet, isDesktop, isAbsolute }: any) => {
      return isAbsolute
        ? isMobile
          ? 344
          : isTablet
          ? 334
          : isDesktop
          ? 148
          : 128
        : '';
    },
    right: ({ isMobile, isTablet, isAbsolute }: any) => {
      return isAbsolute ? (isMobile ? '3vw' : isTablet ? '8vw' : '10vw') : '';
    },
    zIndex: 3,
  },
});

export const HeroAction: React.FC<HeroActionProps> = ({
  padding = 0,
  elevation = 0,
  isAbsolute = false,
  onClose,
}) => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const classes = useStyles({ isMobile, isTablet, isDesktop, isAbsolute });
  const { setCar, car: carModal } = useCarModalHomeStore();
  const { assamblerList, getAssemblerList, getCarList, carList } =
    useCarListStore();
  const { car, setCar: setCarStore } = useCarStore();
  const { show, hide } = useContext(ZustandContext);
  const [marca, setMarca] = useState( car ? car.assembler : initialCarListData.assembler);
  const { searchCarFields, setMarcaModeloState, setAnioState, setIsError } =
    useSearchCarStore();
  const { token } = useTokenStore();
  const history = useHistory();
  const location = useLocation();
  const [data] = useState<CarListData[]>(carList);
  const [marcaModelo, setMarcaModelo] = useState('');
  const [anio, setAnio] = useState('');
  const { setSelectedVehiculo } = useVehiculoStore();
  const [vehiculoSeleccionado, setVehiculoSeleccionado] =
    useState<VehiculoType>('coche');

  useEffect(() => {
    (async () => {
      setCar(initialCarData)
      await getAssemblerList(
        await getToken(),
        vehiculoSeleccionado === 'coche'
          ? 'CAR'
          : vehiculoSeleccionado === 'camioneta'
          ? 'PICKUP'
          : 'MOTO'
      );
    })();
  }, []);

  useEffect(() => {
    setSelectedVehiculo(vehiculoSeleccionado);
    (async () => {
      await getAssemblerList(
        await getToken(),
        vehiculoSeleccionado === 'coche'
          ? 'CAR'
          : vehiculoSeleccionado === 'camioneta'
          ? 'PICKUP'
          : 'MOTO'
      );
    })();
  }, [vehiculoSeleccionado]);

  useEffect(() => {
    if (!marca) setMarca(assamblerList[0]);
  }, [assamblerList]);

  useEffect(() => {
    getCarList(carModal.marca, searchCarFields.anio, marca, token, vehiculoSeleccionado === 'coche'
    ? 'CAR'
    : vehiculoSeleccionado === 'camioneta'
    ? 'PICKUP'
    : 'MOTO');
  }, [carModal.marca, searchCarFields.anio]);

  const resetValues = () => {
    setCar({ anio: '', marca: '', modelo: '' });
    setMarcaModelo('');
    setMarcaModeloState('');
    setCarStore({
      assembler: '',
      description: '',
      insurers: [''],
      model: '',
      type: '',
      vehicle_id: '',
    });
    getCarList('fasdf', '1900', 'fsadf', token, '');
  };

  const isValidForm = (): boolean => {
    if (anio === '' && searchCarFields.anio === '') {
      return false;
    } else if (
      (anio.length < 4 && searchCarFields.anio.length < 4) ||
      (anio.length > 4 && searchCarFields.anio.length > 4)
    ) {
      return false;
    } else if (
      +searchCarFields.anio < years[years.length - 1] ||
      +searchCarFields.anio > years[0]
    ) {
      return false;
    } else if (carModal.modelo === '') {
      return false;
    }
    if(!carList.length || !carList[0].vehicle_id){
      return false
    }

    return true;
  };

  const foundProps = {
    hide,
    show,
    data,
    carList,
  };
  const traer = async () => {
    await getCarList(
      '',
      searchCarFields.anio,
      marca,
      token,
      vehiculoSeleccionado === 'coche'
      ? 'CAR'
      : vehiculoSeleccionado === 'camioneta'
      ? 'PICKUP'
      : 'MOTO'
    );
    setCarStore(
      carList.filter((item) => item.description.toUpperCase().includes(carModal.modelo))[0]
    );
    if (location.pathname === '/plans') {
      onClose && onClose();
    } else {
      onClose && onClose();
      history.push('/options');
    }
  };

  const handleSubmit = () => {
    traer();
  };

  return (
    <Box className={classes.root}>
      <Paper elevation={elevation} style={{ borderRadius: 8 }}>
        <Box style={{ padding: padding }}>
            <Box>
              <Typography
                fontWeight={'bold'}
                sx={{
                  fontSize: {
                    xl: 16,
                    lg: 16,
                    md: 14,
                    sm: 24,
                    xs: 16,
                  },
                }}
              >
                {'Datos del vehículo a asegurar'}
              </Typography>
            </Box>
          <Box marginBottom={2} width={'100%'}>
            <Typography
              textAlign={'start'}
              variant={'caption'}
              fontSize={'13px'}
              style={{ fontWeight: 'normal' }}
            >
              {'Busca tu moto, coche o camioneta'}
            </Typography>
          </Box>
          <Box style={{ marginBottom: 10 }} />
          <Grid container spacing={0}>
            <Grid
                item
                width={'100%'}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                marginBottom={'24px'}
              >
                <Typography
                  textAlign={'start'}
                  variant={'caption'}
                  fontSize={'14px'}
                  style={{ fontWeight: '600' }}
                >
                  {'Tipo de vehículo:'}
                </Typography>
              </Grid>
              <Grid
                marginBottom={'24px'}
                sx={{
                  display: 'flex',
                  justifyContent: isMobile ? 'center' : 'left',
                  width: '100%',
                }}
              >
                {vehiculoSeleccionado === 'coche' && (
                  <Box
                    component={'img'}
                    src={CocheAzulIcon}
                    alt={'vehiculo'}
                    sx={{
                      cursor: 'pointer',
                      width: isMobile ? '94px' : '132px',
                    }}
                  />
                )}
                {vehiculoSeleccionado !== 'coche' && (
                  <Box
                    component={'img'}
                    src={CocheGrisIcon}
                    alt={'vehiculo'}
                    sx={{
                      cursor: 'pointer',
                      width: isMobile ? '94px' : '132px',
                    }}
                    onClick={() => {
                      setVehiculoSeleccionado('coche');
                      resetValues();
                    }}
                  />
                )}
                {vehiculoSeleccionado === 'moto' && (
                  <Box
                    component={'img'}
                    src={MotoAzulIcon}
                    alt={'vehiculo'}
                    sx={{
                      cursor: 'pointer',
                      width: isMobile ? '94px' : '132px',
                    }}
                  />
                )}
                {vehiculoSeleccionado !== 'moto' && (
                  <Box
                    component={'img'}
                    src={MotoGrisIcon}
                    alt={'vehiculo'}
                    sx={{
                      cursor: 'pointer',
                      width: isMobile ? '94px' : '132px',
                    }}
                    onClick={() => {
                      setVehiculoSeleccionado('moto');
                      resetValues();
                    }}
                  />
                )}
                {vehiculoSeleccionado === 'camioneta' && (
                  <Box
                    component={'img'}
                    src={PickupAzulIcon}
                    alt={'vehiculo'}
                    sx={{
                      cursor: 'pointer',
                      width: isMobile ? '94px' : '132px',
                    }}
                  />
                )}
                {vehiculoSeleccionado !== 'camioneta' && (
                  <Box
                    component={'img'}
                    src={PickupGrisIcon}
                    alt={'vehiculo'}
                    sx={{
                      cursor: 'pointer',
                      width: isMobile ? '94px' : '132px',
                    }}
                    onClick={() => {
                      setVehiculoSeleccionado('camioneta');
                      resetValues();
                    }}
                  />
                )}
              </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <Autocomplete
                  id="combo-box-demo"
                  options={assamblerList}
                  value={carModal.marca}
                  onChange={(event: any, newValue: any) => {
                    setMarca(newValue.toUpperCase());
                    setCar({ ...carModal, marca: newValue.toUpperCase() });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Marca"
                      value={carModal.marca}
                      onChange={({ target: { value } }) => {
                        setMarca(value.toUpperCase());
                        setCar({ ...carModal, marca: value.toUpperCase() });
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Año"
                placeholder="Año"
                variant="outlined"
                onChange={(e) => {
                  setAnio(e.target.value);
                  setAnioState(e.target.value);
                  if (
                    +e.target.value < years[years.length - 1] ||
                    +e.target.value > years[0]
                  ) {
                    setIsError(
                      `Modelos entre ${years[years.length - 1]} y ${years[0]}`,
                      true
                    );
                  } else {
                    setCar({ ...carModal, anio: e.target.value });
                    setIsError('', false);
                  }
                }}
                error={searchCarFields.isError}
                helperText={searchCarFields.errorMsg}
                value={searchCarFields.anio}
                InputProps={{
                  inputComponent: AnioCustomMask as any,
                  style: {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Box marginTop={2}>
            <Autocomplete
              id="combo-box-demo"
              options={carList}
              getOptionLabel={(option) => option.description}
              onChange={(event: any, newValue: any) => {
                setCar({
                  ...carModal,
                  modelo: newValue.description.toUpperCase(),
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Modelo"
                  value={car ? car.assembler : marca}
                  onChange={({ target: { value } }) => {
                    setMarcaModelo(value.toUpperCase());
                    setMarcaModeloState(value.toUpperCase());
                    setCar({
                      ...carModal,
                      modelo: value.toUpperCase(),
                    });
                  }}
                />
              )}
            />
          </Box>
          <Box padding={2} textAlign="center">
            <FoundSearchText {...foundProps} />
          </Box>
          <Box style={{ marginBottom: 16 }} />
          <Box style={{ marginBottom: 16 }} />
          <CustomButton
            disabled={!isValidForm()}
            text={'Siguiente'}
            color={'primary'}
            variant={'contained'}
            endIcon={<ArrowForward />}
            style={{ width: '100%', height: 56, textTransform: 'none', color: 'white' }}
            onClick={handleSubmit}
          />
        </Box>
      </Paper>
    </Box>
  );
};
