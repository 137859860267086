import React, { useEffect, useState } from 'react';
//import validator from 'validator';
import {
  Modal,
  FormControlLabel,
  Box,
  Paper,
  TextField,
  Typography,
  Backdrop,
  Fade,
  FormHelperText,
  Checkbox,
  useTheme,
  useMediaQuery,
  Autocomplete,
  IconButton,
} from '@mui/material';
import {
  validateEmail,
  //isThereFourConsecutiveNumber,
  //allEqual,
} from '@utils/utilityFunctions';
import { Link } from 'react-router-dom';
import { useStyles } from '../ModalNeedHelp/useStyles';
import { CustomButton } from '@buttons/CustomButton';
import { useTokenStore } from '@store/TokenStore';
import { usePromotionStore } from '@store/promotionStore';
import { getDataPromotion } from '@api/getPromotions';
import { useDriverStore } from '@store/driverInfoStore';
import { usePolicyStore } from '@store/policyStore';
import { useCarStore } from '@store/carInfoStore';
import TagManager from 'react-gtm-module';
import {upCRM} from "@utils/CRM";
import {useSelectedQuoteStore} from "@store/QuoteStore";
import {useTrackingStore} from "@store/TrackingStore";
import {useCPStore} from "@store/cpStore";
import {getTracking} from "@api/getTracking";
import {useCarListStore} from "@store/carListStore";
import {blue} from "@mui/material/colors";
import Stack from "@mui/material/Stack";

type ModalHomeProps = {
  onClose: () => void;
};

const options = ['Correo electrónico'];

export const ModalConfirmPromotions: React.FC<ModalHomeProps> = ({
                                                                   onClose,
                                                                 }) => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));

  const { token } = useTokenStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const { tracking } = useTrackingStore();
  const { cpData } = useCPStore();
  const { carList } = useCarListStore();

  const { infoPromotion, dataPromo, select, sendPromotion,setPromotion, setChooseSelected } =
      usePromotionStore();
  const { addPolicyInfo } = usePolicyStore();

  const policy = usePolicyStore();
  const driver = useDriverStore();
  const car = useCarStore();

  const [errorCheckbox, setErrorCheckbox] = useState({
    msg: '',
    isError: false,
  });
  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState({
    msg: '',
    isError: false,
  });
  const [warningName, setWarningName] = useState({
    msg: '',
    isWarning: false,
  });
  const [nombre, setNombre] = useState('Nombre');
  //const [phone, setPhone] = useState(policy.dataPromotion?.telefono || '');
  /*const [errorTelefono, setErrorTelefono] = useState({
    msg: '',
    isError: false,
  });
  const [warningTelefono, setWarningTelefono] = useState({
    msg: '',
    isWarning: false,
  });*/
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState({
    msg: '',
    isError: false,
  });
  const [correo, setCorreo] = useState('Correo');

  const [selected, setSelected] = useState('Correo electrónico');
  const [acceptTnC, setAcceptTnC] = useState(false);
  const [aviso, setAviso] = useState('Aviso privacidad');
  const [isEmpty, setIsEmpty] = useState(false);

  const colorHelperText = () => {
    if (warningName.isWarning) {
      return '#FF9E1B';
    }

    if (errorName.isError) {
      return '#f44336';
    }

    return '#5AB52F';
  };
  const colorHelperTextMail = () => {
    if (errorEmail.isError) {
      return '#f44336';
    }
  };

  /*const colorHelperTextPhone = () => {
    if (errorTelefono.isError) {
      return '#f44336';
    }
  };*/

  // const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setAcceptTnC(e.target.checked);
  //   setErrorCheckbox({
  //     msg: '',
  //     isError: false,
  //   });
  //   setAviso('');
  // };

  /*const isValidatePhone = (): boolean => {
    if (!validator.isMobilePhone(phone)) {
      return false;
    } else if (validator.isEmpty(phone)) {
      return false;
    } else if (errorTelefono.isError || warningTelefono.isWarning) {
      return false;
    }
    return true;
  };*/
  useEffect(() => {
    if (driver?.driver) {
      setName(driver.driver.name);
      setEmail(driver.driver.email);
    }
  }, []);

  const handleInputChange = (event: any) => {
    let regex = /^[a-zA-Z ]*$/;
    if (event.target.id === 'name') {
      if (event.target.value.trim() === '') {
        setErrorName({
          msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
          isError: true,
        });
        setName('');
        setNombre('Nombre');
        setIsEmpty(true);
      } else {
        setNombre('');
        setIsEmpty(false);
      }
      if (regex.test(event.target.value)) {
        const nameText = event.target.value;
        if (nameText.length <= 30) {
          if (nameText.length < 2) {
            setWarningName({
              msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2. ',
              isWarning: true,
            });
          } else {
            setWarningName({
              msg: '',
              isWarning: false,
            });
          }
          setName(nameText.trim());
        } else {
          setWarningName({
            msg: '¡Oops! Parece que estás superando el máximo de caracteres. Sólo puedes escribir 30 caracteres.',
            isWarning: true,
          });
        }
        setErrorName({
          msg: '',
          isError: false,
        });
      } else {
        setErrorName({
          msg: '¡Oops! Aquí sólo puedes escribir letras.',
          isError: true,
        });
        setWarningName({
          msg: '',
          isWarning: false,
        });
      }
      return;
    }

    if (event.target.id === 'email') {
      if (event.target.value === '') {
        setErrorEmail({
          msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
          isError: true,
        });
        setCorreo('Correo');
        setIsEmpty(true);
      } else {
        setCorreo('');
        setIsEmpty(false);
      }
      setEmail(event.target.value);
      setErrorEmail({
        msg: '',
        isError: false,
      });
    }
  };

  const eventError = [nombre, correo, aviso];
  const check = eventError.filter((campo) => campo !== '');

  const DataLayer = {
    event: 'CSform_Modal_Amazon',
    eventCategory: 'Formulario.Seguro.Auto.v2',
    eventAction: 'promoción_amazon_submit',
    eventLabel: selected,
    camposError: undefined,
    telefono: driver.driver.telefono,
    correo: email,
    noCotizacion: policy.policy.policyNumber,
    modelo: car.car.description,
    marca: car.car.assembler,
    anio: car.car.model,
    paquete: undefined,
    forma_pago: undefined,
    aseguradora: undefined,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: car.car.type,
    colaborador: undefined,
    cupon: undefined,
  };

  const tagManagerArgs = {
    dataLayer: DataLayer,
  };

  const ErrorDataLayer = {
    event: 'CSform_Modal_Amazon',
    eventCategory: 'Formulario.Seguro.Auto.v2',
    eventAction: 'promoción_amazon_error',
    eventLabel: selected,
    camposError: check,
    telefono: driver.driver.telefono,
    correo: email,
    noCotizacion: policy.policy.policyNumber,
    modelo: car.car.description,
    marca: car.car.assembler,
    anio: car.car.model,
    paquete: undefined,
    forma_pago: undefined,
    aseguradora: undefined,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: car.car.type,
    colaborador: undefined,
    cupon: undefined,
  };

  const errorTagManagerArgs = {
    dataLayer: ErrorDataLayer,
  };

  const handleGet = async () => {
    (selected && isEmpty) || !acceptTnC
    ? TagManager.dataLayer(errorTagManagerArgs)
    : TagManager.dataLayer(tagManagerArgs);

    if (name.length > 2 && validateEmail(email)){
      TagManager.dataLayer({dataLayer:{
        event: "coche_seguro_cupon_nocturno_lo_quiero",      //Static data
        no_cotizacion: policy.policy.policyNumber,
        page_url: window.location.href,
        hora: new Date().getHours(),
        boton_contacto: 'Quiero mi cupón!',
        correo: email,
      }})
    }

    if (name === '') {
      setErrorName({
        msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
        isError: true,
      });
      setWarningName({
        msg: '',
        isWarning: false,
      });
      return;
    }
    if (name.length < 2) {
      setWarningName({
        msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2. ',
        isWarning: true,
      });
      setErrorName({
        msg: '',
        isError: false,
      });
      return;
    }
    setErrorName({
      msg: '',
      isError: false,
    });
    setWarningName({
      msg: '',
      isWarning: false,
    });

    if (selected === 'Correo electrónico') {
      if (email === '') {
        setErrorEmail({
          msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
          isError: true,
        });
        return;
      }
      if (!validateEmail(email)) {
        setErrorEmail({
          msg: '¡Oh, no! Debes ingresar un correo válido.',
          isError: true,
        });
        return;
      }
      const [beforeAt, afterAt] = email.split('@');
      const blacklist = [
        'gmail.mx',
        'hotmail.mx',
        'aol',
        'hotmail.com.mx',
        'gmail.com.mx',
        'live.com.mx',
        'gmail.co',
        'ensamble.dev',
        'outloot.com',
        'yahoo.com.sg',
        '@',
        'yopmail.com',
      ];
      if (beforeAt.length <= 2 || blacklist.includes(afterAt)) {
        setErrorEmail({
          msg: '¡Oh, no! Debes ingresar un correo válido.',
          isError: true,
        });
        return;
      }
      /*} else {
      const isResultAllEquals = allEqual(Array.from(phone));
      const isConsecutive = isThereFourConsecutiveNumber(phone);

      if (
        isResultAllEquals ||
        phone.includes('123456789') ||
        phone.includes('987654321') ||
        isConsecutive
      ) {
        setErrorTelefono({
          msg: '¡Oops! Debes ingresar un teléfono válido.',
          isError: true,
        });
        return;
      }
      setErrorTelefono({
        msg: '',
        isError: false,
      });
      isValidatePhone();*/
    }

    // if (!acceptTnC) {
    //   setErrorCheckbox({
    //     msg: 'Por favor, acepta los terminos y condiciones',
    //     isError: true,
    //   });
    //   return;
    // }

    const sendTracking= async ()=>{
      let initialData = {
        id: tracking.id,
        business: 'creditaria',
        data: {
          car: car.car,
          driver: driver.driver,
          selectedQuote: selectedQuote,
          policy: policy.policy,
          cpData: cpData,
          carList: carList,
        },
      };
      await getTracking(token, initialData);
    }

    let data = {
      name: name,
      promotion_id: infoPromotion.id,
      email: email,
      folio:policy.policy.policyNumber,
    };
    let choose = {
      selected: selected,
    };
    let res = await getDataPromotion(token, data);
    infoPromotion.code = res.data.promotion_code;
    policy.policy.codeCoupon = infoPromotion
    addPolicyInfo({ ...policy.policy});
    sendPromotion({ ...dataPromo, ...data });
    setPromotion({...infoPromotion})
    setChooseSelected({ ...select, ...choose });
    upCRM(token, car.car, driver.driver, selectedQuote, policy.policy, tracking, cpData);
    sendTracking();
    onClose();
  };

  return (
      <Modal
          open
          //onClose={onClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
      >
        <Fade in>
          <Paper
              className={classes.root}
              style={{
                margin: 'auto',
                width: !isTablet ? '461px' : '327px',
                height:
                    selected === ''
                        ? !isTablet
                            ? '441px'
                            : '548px'
                        : !isTablet
                            ? '565px'
                            : '670px',
              }}
          >
            <Box
                padding={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: '18px',
                  paddingRight: '18px',
                }}
            >
              <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  textAlign="center"
                  position={'relative'}
                  sx={{ mt: 2 }}
              >

              </Box>
              <Box height={'207px'}>

                <Box
                    height={!isTablet ? '110px' : '128px'}
                >
                <span
                    style={{ fontSize: 16, fontWeight: 400, fontStyle: 'normal' }}
                >
                  <b>1. Confirma tus datos</b> para mandarte el código de la
                  promoción
                </span>
                  <Typography
                      fontWeight={400}
                      textAlign={'start'}
                      fontSize={18}
                      mt={2}
                  >
                    {'Nombre'}
                  </Typography>
                  <TextField
                      id="name"
                      sx={{
                        marginTop: 1,
                        width: '100%',
                        height: '48px',
                      }}
                      variant="outlined"
                      placeholder="Nombre"
                      onChange={handleInputChange}
                      value={name}
                      error={errorName.isError}
                      helperText={
                        <label style={{ color: colorHelperText() }}>
                          {errorName.msg || warningName.msg}
                        </label>
                      }
                  />
                </Box>
                {/* <Box height={'100px'}>
                <Typography
                  fontWeight={400}
                  textAlign={'start'}
                  fontSize={18}
                  marginBottom={'16px'}
                >
                  {'¿Dónde quieres recibirlo?'}
                </Typography>
                <Autocomplete
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  id="promotions"
                  value={selected}
                  onInputChange={(event: any, newValue: string) => {
                    setSelected(newValue);
                  }}
                  options={options}
                  renderInput={(paramas) => (
                    <TextField
                      {...paramas}
                      placeholder="Seleccionar"
                      label="Medio de contacto"
                    />
                  )}
                />
              </Box> */}
                {
                  <Box
                      height={'105px'}
                      style={{ marginTop: !isTablet ? '15px' : '25px' }}
                  >
                    <Typography
                        fontWeight={400}
                        textAlign={'start'}
                        fontSize={18}
                        mt={6}
                    >
                      {'Correo'}
                    </Typography>
                    <TextField
                        id="email"
                        sx={{
                          marginTop: 1,
                          width: '100%',
                          height: '48px',
                        }}
                        value={email}
                        variant="outlined"
                        placeholder="Correo electrónico"
                        onChange={handleInputChange}
                        error={errorEmail.isError}
                        helperText={
                          <label style={{ color: colorHelperTextMail() }}>
                            {errorEmail.msg}
                          </label>
                        }
                    />
                  </Box> /*: selected === 'Whatsapp' ? (
                <Box height={'105px'} style={{ marginTop: '15px' }}>
                  <Typography
                    fontWeight={400}
                    textAlign={'start'}
                    fontSize={18}
                  >
                    {'Pásanos tu Whatsapp'}
                  </Typography>
                  <TextField
                    id="telefono"
                    value={phone}
                    sx={{
                      marginTop: 1,
                      width: '100%',
                      height: '48px',
                    }}
                    variant="outlined"
                    placeholder="Número a 10 dígitos"
                    onChange={(e) => {
                      const regex = /^[0-9]*$/;
                      const telefono = e.target.value;
                      if (regex.test(telefono)) {
                        if (telefono === '') {
                          setErrorTelefono({
                            msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                            isError: true,
                          });
                          setWarningTelefono({ msg: '', isWarning: false });
                        } else {
                          if (telefono.length < 10) {
                            setErrorTelefono({
                              msg: '',
                              isError: false,
                            });
                            setWarningTelefono({
                              msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 10.',
                              isWarning: true,
                            });
                          }
                        }
                        if (telefono.length <= 10) {
                          setPhone(e.target.value);
                        }
                        if (telefono.length <= 10) {
                          setErrorTelefono({
                            msg: '',
                            isError: false,
                          });
                          setWarningTelefono({ msg: '', isWarning: false });
                        }
                      } else {
                        setErrorTelefono({
                          msg: '¡Oops! Aquí sólo puedes escribir números.',
                          isError: true,
                        });
                        setWarningTelefono({ msg: '', isWarning: false });
                      }
                    }}
                    error={errorTelefono.isError}
                    inputProps={{ maxLength: 10 }}
                    onBlur={() => {
                      if (phone === '') {
                        setErrorTelefono({
                          msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
                          isError: true,
                        });
                        setWarningTelefono({ msg: '', isWarning: false });
                      } else if (phone.length < 10) {
                        setErrorTelefono({
                          msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 10.',
                          isError: true,
                        });
                        setWarningTelefono({ msg: '', isWarning: false });
                      } else if (phone.length === 10) {
                        setErrorTelefono({
                          msg: '',
                          isError: false,
                        });
                        setWarningTelefono({ msg: '', isWarning: false });
                      }
                    }}
                    helperText={
                      <label style={{ color: colorHelperTextPhone() }}>
                        {errorTelefono.msg}
                      </label>
                    }
                  />
                </Box>
              ) */
                }
              </Box>
              {/* <Box
              sx={{
                marginTop:
                  selected !== ''
                    ? !isTablet
                      ? '110px'
                      : '162px'
                    : !isTablet
                    ? '0px'
                    : '40px',
              }}
            > */}
              {/* <FormControlLabel
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  margin: '19px 0',
                }}
                control={
                  <Checkbox
                    checked={acceptTnC}
                    onChange={handleChangeCheckbox}
                    style={{ padding: 0 }}
                  />
                }
                label={ */}
              <>
                <Stack sx={{ mt: 10 }} spacing={3}>
                <span
                    style={{ fontSize: 16, fontWeight: 400, fontStyle: 'normal' }}
                >
                  <b>2. Ingresa tu código</b> al terminar tu compra para hacerlo
                  válido
                </span>
                  <span
                      style={{ fontSize: 16, fontWeight: 400, fontStyle: 'normal' }}
                  >
                  <b>3. ¡Listo!</b> Así de fácil es estar seguro, seguro, con un
                  regalito
                </span>
                </Stack>
                <Typography margin={0} variant={'subtitle2'} marginTop={2}>
                  {'Ver'}
                  &nbsp;
                  {/* <Link
                        to={{
                          pathname: 'https://inter.mx/aviso-de-privacidad',
                        }}
                        target="_blank"
                      >
                        <span
                          style={{
                            color: '#039ECC',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                        >
                          {'Aviso de Privacidad'}
                        </span>
                      </Link>
                      {' y '} */}
                  <Link
                      to={{
                        pathname: 'https://inter.mx/terminos-condiciones',
                      }}
                      onClick={() => {
                        TagManager.dataLayer({dataLayer: {
                            event: "coche_seguro_cupon_nocturno_terminos",      //Static data
                            no_cotizacion: policy.policy.policyNumber,
                            page_url: window.location.href,
                            hora: new Date().getHours(),
                            boton_contacto: 'Terminos y Condiciones',
                            correo: email,
                        }})
                      }}
                      target="_blank"
                  >
                  <span
                      style={{
                        color: '#039ECC',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                  >
                    {'Términos y Condiciones'}
                  </span>
                  </Link>
                </Typography>
                {/* <FormHelperText error={errorCheckbox.isError}>
                  {errorCheckbox.msg}
                </FormHelperText> */}
              </>
              {/* } */}
              {/* /> */}
              <Box mt={3}></Box>
              <CustomButton
                  text={selected === '' ? '¡QUIERO MI CUPÓN!' : '¡QUIERO MI CÓDIGO'}
                  disabled={!selected}
                  onClick={handleGet}
                  color={'primary'}
                  variant={'contained'}
                  style={{
                    height: '48px',
                    width: '100%',
                    textTransform: 'none',
                  }}
              />
            </Box>
            {/* </Box> */}
          </Paper>
        </Fade>
      </Modal>
  );
};
