import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { usePlansDataStore } from '@store/plansStore';
import { useQuoteStore, useSelectedQuoteStore } from '@store/QuoteStore';

export const BrandTablet = () => {
  const { selectedPlan } = usePlansDataStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const [totalPayment, setTotalPayment] = useState<string>('');
  useEffect(() => {
    let totalNumber = 0;
    selectedQuote.receipts.map((item: any) => {
      const number = Number(item?.total_premium);
      totalNumber += number;
    });
    setTotalPayment(totalNumber.toString());
  }, []);
  return (
    <Box
      borderRadius={2}
      marginTop={2}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      padding={2}
      style={{ backgroundColor: '#e3f2fd' }}
    >
      <Box
        component={'img'}
        src={selectedQuote.company_logo}
        sx={{
          width: 157,
        }}
        alt={selectedQuote.company_logo}
        style={{ objectFit: 'contain' }}
      />
      <Box
        width={'90%'}
        borderRadius={2}
        marginTop={2}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        padding={1}
        style={{ backgroundColor: '#e8eaf6' }}
      >
        <Typography color="#8F8F8F" fontSize={13}>
          Costo anual
        </Typography>
        <Typography style={{ fontWeight: 'bold' }} fontSize={28}>
          $
          {parseFloat(
            parseFloat(totalPayment).toFixed(2)
          ).toLocaleString('es-MX', { minimumFractionDigits: 2 })}
        </Typography>
        <Typography fontSize={14} style={{ color: '#8F8F8F' }}>
        </Typography>
      </Box>
    </Box>
  );
};
