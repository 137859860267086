import isEmail from 'validator/lib/isEmail';

export const validateEmail = (valor: string): boolean => {
  return isEmail(valor);
};

export const calculateAge = (birthday: any): number => {
    const fechaActual = new Date();
    const anoActual = fechaActual.getFullYear();
    const mesActual = fechaActual.getMonth() + 1;
    const diaActual = fechaActual.getDate();

    const anoNacimiento = parseInt(String(birthday).substring(0, 4));
    const mesNacimiento = parseInt(String(birthday).substring(5, 7));
    const diaNacimiento = parseInt(String(birthday).substring(8, 10));

    let edad = anoActual - anoNacimiento;
    if (mesActual < mesNacimiento) {
        edad--;
    } else if (mesActual === mesNacimiento) {
        if (diaActual < diaNacimiento) {
            edad--;
        }
    }
    return edad;
};

export const allEqual = (arr: string[]) => arr.every((val) => val === arr[0]);

export const isThereFourConsecutiveNumber = (numbers: string) => {
  if (/(^|(.)(?!\2))(\d)\3{4,}(?!\3)/.test(numbers)) {
    return true;
  }
  return false;
};
export const RemainTime = (endDateTime: string) => {
    let now: any = new Date();
    let end: any = new Date(endDateTime);
    let remainTime = (end - now + 1000) / 1000;
    return {
        remainTime,
    };
};
export const validateCouponDateTime = (endDateTime = '',acceptCoupon="") => {
    let isValidate: boolean = false;
    if (endDateTime !== '' && acceptCoupon ==='1') {
        let t = RemainTime(endDateTime);
        if (t.remainTime <= 1) {
            isValidate = false;
        } else if (acceptCoupon ==='1') {
            isValidate = true;
        }
    }

    return isValidate;
};
export const NameSubstring =(name:string= "") =>{
    let new_name = ""
    if (name.length > 8){
        new_name = name.substring(0,9)+"..."
    }else {
        new_name = name
    }
    return new_name
}
