import {useEffect, useState} from "react";



const CountdownTimer = ({endDateTime="",...props}) => {
    const [day,setDays] = useState('00')
    const [hours,setHours] = useState('00')
    const [minutes,setMinutes] = useState('00')
    const [seconds,setSeconds] = useState('00')

    const [message,setMessage] = useState('')

    const getRemainTime = (endDateTime) =>{
        let now = new Date(),
            remainTime = (new Date(endDateTime)- now +1000)/ 1000,
            remainSeconds = ('0'+Math.floor(remainTime % 60)).slice(-2),
            remainMinutes = ('0'+Math.floor(remainTime / 60 % 60)).slice(-2),
            remainHours = ('0'+Math.floor(remainTime / 3600 % 24)).slice(-2),
            remainDays = Math.floor(remainTime / (3600 * 24))
        return {
            remainTime,
            remainSeconds,
            remainMinutes,
            remainHours,
            remainDays
        }
    }

    const CountDown =(endDateTime,finalMessage)=>{
        const timerUpdate =setInterval(()=>{
            let t = getRemainTime(endDateTime);
            setDays(t.remainDays);
            setHours(t.remainHours);
            setMinutes(t.remainMinutes);
            setSeconds(t.remainSeconds);
            if (t.remainTime <= 1){
                setMessage(finalMessage)
                clearInterval(timerUpdate)
            }
        },1000)
    }


    useEffect(()=>{
        CountDown(endDateTime,"A terminado la promoción del cupón.")
    },[])


    return (<div>
        {

            message ?
                <span style={{
                    color:'red',fontWeight:700,
                    fontSize:'16px'
                }}>{message}</span>
                :
                <div>
               <span style={{
                   color:'black',fontWeight:700,
                   fontSize:'16px'
               }}>Promoción válida por</span> <span style={{color:'red',
                    fontSize:'16px',
                    fontWeight:700}}> {hours}:{minutes}:{seconds}</span>
                </div>
        }

    </div>);
}
export default CountdownTimer;
