import React from 'react';
import { PaymentMethod } from './PaymentMethod';
import mastercard from '@assets/images/mastercard.png';
import visa from '@assets/images/visa.png';

interface PaymentMethodsProps {
  payWay: string;
  setPayWay: Function;
}

export const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  payWay,
  setPayWay,
}) => {
  return (
    <>
      <PaymentMethod
        text="Tarjeta de crédito"
        images={[mastercard, visa]}
        payWay={payWay}
        setPayWay={setPayWay}
        id="tc"
      />
      <PaymentMethod
        text="Tarjeta de débito"
        images={[mastercard, visa]}
        payWay={payWay}
        setPayWay={setPayWay}
        id="td"
      />
    </>
  );
};
