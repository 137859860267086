import React, {useEffect, useState} from 'react';
import { makeStyles } from '@mui/styles';
import {
  Theme,
  Container,
  Box,
  useTheme,
  useMediaQuery,
  Button,
  useScrollTrigger,
  Grid,
} from '@mui/material';

import { Header } from '@navigationMenu/Header';
import { RightCard } from '@screens/hiring/confirm/RightCard';
import { LeftCard } from '@screens/hiring/confirm/LeftCard';
import { ModalPay } from '@modals/ModalPay';
import { ZustandContext } from '@store/modalStore';
import { DigicertField } from '@navigationMenu/DigicertField';
import { useChangePrice } from '@hooks/useChangePrice';
import { PolicyNumber } from '@screens/hiring/confirm/policyNumber/PolicyNumber';

import TagManager from 'react-gtm-module';
import { useCarStore } from '@store/carInfoStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { usePolicyStore } from '@store/policyStore';
import { useHistory } from 'react-router-dom';
import { CustomButton } from '@buttons/CustomButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import StickyButton from "@buttons/StickyButton";
import {upCRMContact} from "@utils/CRM";
import {useTokenStore} from "@store/TokenStore";
import {useTrackingStore} from "@store/TrackingStore";
import {useCPStore} from "@store/cpStore";
import {NameSubstring} from "@utils/utilityFunctions";
import {useVisibleButtonHelp} from "@hooks/useVisibleButtonHelp";
import {useGetQuotePayment} from "@store/QuoteData";
import {ToastDynamic} from "@components/alerts/ToastDynamic";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#f5f5f5',
    paddingBottom: '100px',
  },
}));

export const Confirm: React.FC = () => {
  const classes = useStyles();
  const { breakpoints, palette } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const { show, hide } = React.useContext(ZustandContext);
  const history = useHistory();

  const [url, setUrl] = useState<any>('');
  const [success, setSuccess] = useState(false);
  const [loadingSticky, setLoadingSticky] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [showWarningToast, setShowWarningToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');

  const { token } = useTokenStore();

  const { policy } = usePolicyStore();
  const { car } = useCarStore();

  const trigger = useScrollTrigger({
    threshold: 970,
    disableHysteresis: true,
  });

  const { car: Car } = useCarStore();
  const { driver } = useDriverStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const { tracking } = useTrackingStore();
  const { cpData } = useCPStore();


  if (
    policy.Niv === '' ||
    policy.contrator?.firstName === '' ||
    policy.driver.firstName === '' ||
    policy.street === '' ||
    car.assembler === '' ||
    driver.codigoPostal === ''
  ) {
    history.push('/hiring');
  }

  //DataLayer
  const DataLayer = {
    event: 'CSform_step_8_view',
    eventCategoy: 'Formulario.Seguro.Auto.v2',
    eventAction: 'step_8_view',
    eventLabel: undefined,
    telefono: driver.telefono,
    correo: driver.email,
    noCotizacion: policy.policyNumber,
    modelo: Car.description,
    marca: Car.assembler,
    anio: Car.model,
    paquete: selectedQuote.package,
    forma_pago: selectedQuote.payment_option,
    aseguradora: selectedQuote.insurance,
    tarjeta: undefined,
    tipo_tarjeta: undefined,
    tipo_vehiculo: Car.type,
    colaborador: undefined,
  };

  useEffect(() => {
    window.scroll(0, 0);
    TagManager.dataLayer({ dataLayer: DataLayer });
    TagManager.dataLayer({
      dataLayer: {
        event: 'cocheapp_resumen', //Static data,
        boton_resumen: 'Continuar',
        cotización: policy.policyNumber,
        aseguradora: policy.package_new,
        costo_anual: selectedQuote.insurance_premium.total,
      },
    });
  }, []);

  useChangePrice();



  const sendApi = async () => {
    try {
      setLoadingSticky(true);
      let response = await upCRMContact(
          token,
          car,
          driver,
          selectedQuote,
          policy,
          tracking,
          cpData,
          selectedQuote,
          url
      );
      setTimeout(() => {
        if (response.success) {
          setLoadingSticky(false);
          setSuccess(true);
        } else {
          setLoadingSticky(false);
        }
      }, 1000);
    } catch (e) {
      setLoadingSticky(false);
      setSuccess(false);
    }
  };

  useVisibleButtonHelp();

  const title = (
      <p style={{ margin: 0 }}>
        Hola 👋 <strong>{NameSubstring(driver.name)}</strong> ¿Necesitas ayuda con el pago de tu
        seguro?
      </p>
  );

  const { Packages, payment_option } = useGetQuotePayment();

  useEffect(() => {
    if (Packages.value !== 'AMP' || payment_option.value !== 'A') {
    } else {
      if (policy?.codeCoupon?.code) {
        setShowToast(true);
        setShowWarningToast(false);
        setMessageToast('¡Listo! Ya tienes tu cupón aplicado');
      }
    }
  }, [Packages, payment_option, policy.codeCoupon]);


  return (
    <>
      <div className={classes.root}>
        {showToast && (
            <ToastDynamic
                showToast={showToast}
                setShowToast={setShowToast}
                Text={messageToast}
                isWarning={showWarningToast}
            />
        )}
        {isMobile && !trigger ? (
          <Button
            onClick={() => show(ModalPay, { onClose: hide })}
            fullWidth
            variant={'contained'}
            style={{
              position: 'fixed',
              zIndex: 3,
              textTransform: 'none',
              height: 45,
              bottom: 0,
              borderRadius: 0,
            }}
          >
            Contratar
          </Button>
        ) : null}
        <Container maxWidth={'lg'}>
          <Box
              sx={{ flexGrow: 1 }}
              style={{
                marginTop: 100,
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start'
              }}
          >
            <CustomButton
                text={'Regresar'}
                onClick={() => history.push('/insurer-details')}
                size={isDesktop ? 'medium' : 'small'}
                variant={'text'}
                startIcon={<KeyboardArrowLeftIcon />}
                style={{
                  color: '#006782',
                  fontSize: isMobile ? 14 : 16,
                  textTransform: 'none',
                  textDecoration: 'underline',
                }}
            />
            <Button
                startIcon={<AddIcon sx={{color: '#006782'}}  />}
                onClick={() => {
                  history.push('/home');
                }}
                variant="outlined"
                style={{
                  textTransform: 'none',
                  backgroundColor: '#FFFFFF',
                  padding: '14px 19px 14px 19px',
                  borderRadius: '4px',
                  height: '40px',
                  color: '#006782',
                  border: '1px solid #006782'
                }}
            >
              Nueva cotización
            </Button>
          </Box>
        </Container>
        <Container maxWidth={'lg'}>
          <Box
            sx={{ flexGrow: 1 }}
            padding={isMobile ? 0 : 2}
            style={{
              position: 'relative',
              minHeight: '70vh',
              marginTop: 10,
            }}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Header
                title="Confirma tu compra"
                subtitle="¡Ya casi terminamos! Checa bien tus datos, ¿están correctísimos?"
                number="4"
              />
              <PolicyNumber isConfirm />
            </Box>
            <Grid
              container
              spacing={isTablet ? 0 : 10}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'start'}
              justifyContent={'space-between'}
            >
              <Grid item xs={12} md={12} lg={6} sx={{ marginTop: '21px' }}>
                {policy.Niv !== '' &&
                  policy.contrator?.firstName !== '' &&
                  policy.driver.firstName !== '' &&
                  policy.street !== '' &&
                  car.assembler !== '' &&
                  driver.codigoPostal !== '' && <LeftCard />}
              </Grid>
              <Grid item xs={12} md={12} lg={6} sx={{ marginTop: '21px' }}>
                {policy.Niv !== '' &&
                  policy.contrator?.firstName !== '' &&
                  policy.driver.firstName !== '' &&
                  policy.street !== '' &&
                  car.assembler !== '' &&
                  driver.codigoPostal !== '' && <RightCard />}
              </Grid>
            </Grid>
          </Box>
        </Container>
        {
            //isMobile &&
            <StickyButton
                loading={loadingSticky}
                success={success} action={sendApi} title={title} />
        }
      </div>
      {isTablet ? <DigicertField /> : null}
    </>
  );
};
