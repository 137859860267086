import { InterInstance } from '@api/config';
export const postMarketingCloud = async (token: string, params: any) => {
  try {
    const { data } = await InterInstance({
      method: 'post',
      url: '/marketingcloud',
      headers: { Authorization: `Bearer ${token}` },
      data: params,
    });
    return data;
  } catch (e) {
    return e;
  }
};
