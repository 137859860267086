import { useGetQuotePayment } from '@store/QuoteData';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { usePolicyStore } from '@store/policyStore';
import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';

interface Pagoprops {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const paymentOptionsHDI = [
  {
    value: 'Mensual',
    label: 'M',
  },
  {
    value: 'Trimestral',
    label: '3M',
  },
  {
    value: 'Semestral',
    label: '6M',
  },
  {
    value: 'Anual',
    label: 'A',
  },
];

const paymentOptionsAll = [
  {
    value: 'Mensual',
    label: 'M',
  },
  {
    value: 'Trimestral',
    label: '3M',
  },
  {
    value: 'Semestral',
    label: '6M',
  },
  {
    value: 'Anual',
    label: 'A',
  },
];

export const PlanSelectField: React.FC<Pagoprops> = ({ setOpen }) => {
  const { selectedQuote } = useSelectedQuoteStore();
  const { policy, addPolicyInfo } = usePolicyStore();
  const { payment_option, setPaymentOption } = useGetQuotePayment();

  const changePayment = (e: any) => {
    if (!policy.error) {
      policy.payment_anterior = payment_option.value;
    }
    policy.payment_new = e.target.value;
    addPolicyInfo({ ...policy });
    if (e.target.value === 'M') {
      setOpen(true);
      setPaymentOption('Mensual', 'M');
      return;
    } else if (e.target.value === '3M') {
      setOpen(false);
      setPaymentOption('Trimestral', '3M');
      return;
    } else if (e.target.value === '6M') {
      setOpen(false);
      setPaymentOption('Semestral', '6M');
      return;
    } else if (e.target.value === 'A') {
      setOpen(false);
      setPaymentOption('Anual', 'A');
      return;
    }
  };

  useEffect(() => {
    policy.payment_new === 'M' ? setOpen(true) : setOpen(false);
  }, [policy.payment_new, setOpen]);

  return (
    <FormControl fullWidth>
      <FormLabel sx={{ fontSize: '14px', fontWeight: '400' }}>
        Modalidad de pago
      </FormLabel>
      <Select
        style={{ color: 'black' }}
        value={policy.payment_new}
        onChange={changePayment}
      >
        {selectedQuote?.insurance === 'hdi' &&
          paymentOptionsHDI.map((value: any, i: number) => (
            <MenuItem key={i} value={value.label}>
              {value.value}
            </MenuItem>
          ))}
        {selectedQuote?.insurance !== 'hdi' &&
          paymentOptionsAll.map((value: any, i: number) => (
            <MenuItem key={i} value={value.label}>
              {value.value}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
