import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Modal, Box, Paper, Backdrop, Fade } from '@mui/material';

import { useStyles } from './ModalPay.styles';

import { HeaderModalPay } from './HeaderModalPay';
import { PaymentMethods } from './PaymentMethods';
import { ButtonModalPay } from './ButtonModalPay';
import TagManager from 'react-gtm-module';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { usePolicyStore } from '@store/policyStore';

type ModalPayProps = {
  onClose: () => void;
};

export const ModalPay: React.FC<ModalPayProps> = ({ onClose }) => {
  const [payWay, setPayWay] = useState<string>('');
  const { selectedQuote } = useSelectedQuoteStore();
  const { policy } = usePolicyStore();

  const history = useHistory();
  const classes = useStyles();

  const handleGoPayment = () => {
    onClose();
    TagManager.dataLayer({
      dataLayer: {
        event: 'CS_interactions',
        eventCategory: 'Formulario.Seguro.Auto.v2',
        eventAction: 'Interacciones',
        eventLabel: payWay,
        ecommerce: {
          checkout_option: {
            actionField: { step: 6, option: payWay },
          },
        },
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'add_payment_info',
        value: selectedQuote.insurance_premium.total,
        coupon: '',
        payment_type: payWay === 'tc' ? 'Crédito' : 'Débito',
        item_id: policy.policyNumber,
        item_name: `Coche Seguro ${policy.package_new}`,
        price: selectedQuote.insurance_premium.total,
        quantity: '1',
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event : 'coche_seguro_tipo_tarjeta',
        tipo_tarjeta: payWay === 'tc' ? 'Crédito' : 'Débito',
        boton_pagar: 'Ya Quiero Pagar',
    
      }
    })
    history.push('/payment');
  };

  const isValidForm = () => {
    if (payWay) {
      return true;
    }
  };

  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper className={classes.root}>
          <Box padding={2}>
            <HeaderModalPay onClose={onClose} />
            <PaymentMethods payWay={payWay} setPayWay={setPayWay} />
            <ButtonModalPay
              handleGoPayment={handleGoPayment}
              isValidForm={isValidForm}
            />
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
